import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router'
import { HttpClient } from '@angular/common/http';
import { bmxurl, getAccess } from 'src/globals';
import { class_returndata } from 'src/types/class_returndata'; 
import { Router, RouterLink } from '@angular/router';
import { cClass, classes } from 'src/types/class';
import { returnmessage } from 'src/types/returnmessage';
import {MessageService} from 'primeng/api';
import { users_returndata } from 'src/types/user_returndata';
import { link } from 'src/types/link';
import { link_returndata } from 'src/types/link_returndata';
import { Package } from 'src/PackageClasses';
import { cPackagegroups } from 'src/types/packagegroups';
import { cPackage } from 'src/types/package';
import { package_returndata } from 'src/types/package_returndata';
import { packagegroups_returndata } from 'src/types/packagegroup_returndata';
import { BusyService } from '../_services/busy.service';


@Component({
  selector: 'app-linkeditor',
  templateUrl: './linkeditor.component.html',
  styleUrls: ['./linkeditor.component.css']
})
export class LinkeditorComponent  implements OnInit {

  VenueID: string = localStorage.getItem("SmartVenueID")!;
  Link: link;
  httpResponse: any;
  id: string;
  pageReady: boolean = true;

  LinkNameValid: boolean = true;
  LinkPackageValid: boolean = true;
  LinkPackageGroupValid: boolean = true;
  LinkDateValid: boolean = true;
  LinkExpiryValid: boolean = true;

  AccessAllowed: boolean = false;
  CompletedLoading: boolean = false;

  pkgStateOptions: any[];
  stateOptions: any[];
  LinkType:string = "PACKAGE";
  LinkExpire: boolean = false;
  LinkDate: boolean = false;
  LinkTime: boolean = false;
  linkStartTimeHours: string;
  linkStartTimeMinutes: string;
  linkTimeHours: any[] = [];
  linkTimeMins: any[] = [];
  packages: any[];
  packagegroups: any[];
  selectedpackage: any;
  selectedpackagegroup: any;

  
  constructor(private route: ActivatedRoute, private http: HttpClient, private router: Router, private messageService: MessageService, private busyService: BusyService) { 
    this.Link = {
      id: null,
      linkid:  null,
      linkname: "",
      venueid: this.VenueID,
      packageid: "",
      packagegroupid: "",
      datetobook: new Date(1,0,1, 0, 0, 0),
      timetobook: "",
      admissionlist: "",
      expirydate: new Date(1,0,1, 0, 0, 0),
      disabled: false,
    }
    this.pkgStateOptions = [{ label: 'Package', value: "PACKAGE" }, { label: 'Package Group', value: "PACKAGEGROUP" }];
    this.stateOptions = [{ label: 'Yes', value: true }, { label: 'No', value: false }];
    
    this.linkTimeHours = [
      {name: '00', code: '00'},
      {name: '01', code: '01'},
      {name: '02', code: '02'},
      {name: '03', code: '03'},
      {name: '04', code: '04'},
      {name: '05', code: '05'},
      {name: '06', code: '06'},
      {name: '07', code: '07'},
      {name: '08', code: '08'},
      {name: '09', code: '09'},
      {name: '10', code: '10'},
      {name: '11', code: '11'},
      {name: '12', code: '12'},
      {name: '13', code: '13'},
      {name: '14', code: '14'},
      {name: '15', code: '15'},
      {name: '16', code: '16'},
      {name: '17', code: '17'},
      {name: '18', code: '18'},
      {name: '19', code: '19'},
      {name: '20', code: '20'},
      {name: '21', code: '21'},
      {name: '22', code: '22'},
      {name: '23', code: '23'}
      ];
   
    this.linkTimeMins = [
        {name: '00', code: '00'},
        {name: '01', code: '01'},
        {name: '02', code: '02'},
        {name: '03', code: '03'},
        {name: '04', code: '04'},
        {name: '05', code: '05'},
        {name: '06', code: '06'},
        {name: '07', code: '07'},
        {name: '08', code: '08'},
        {name: '09', code: '09'},
        {name: '10', code: '10'},
        {name: '11', code: '11'},
        {name: '12', code: '12'},
        {name: '13', code: '13'},
        {name: '14', code: '14'},
        {name: '15', code: '15'},
        {name: '16', code: '16'},
        {name: '17', code: '17'},
        {name: '18', code: '18'},
        {name: '19', code: '19'},
        {name: '20', code: '20'},
        {name: '21', code: '21'},
        {name: '22', code: '22'},
        {name: '23', code: '23'},
        {name: '24', code: '24'},
        {name: '25', code: '25'},
        {name: '26', code: '26'},
        {name: '27', code: '27'},
        {name: '28', code: '28'},
        {name: '29', code: '29'},
        {name: '30', code: '30'},
        {name: '31', code: '31'},
        {name: '32', code: '32'},
        {name: '33', code: '33'},
        {name: '35', code: '34'},
        {name: '35', code: '35'},
        {name: '36', code: '36'},
        {name: '37', code: '37'},
        {name: '38', code: '38'},
        {name: '39', code: '39'},
        {name: '40', code: '40'},
        {name: '41', code: '41'},
        {name: '42', code: '42'},
        {name: '43', code: '43'},
        {name: '44', code: '44'},
        {name: '45', code: '45'},
        {name: '46', code: '46'},
        {name: '47', code: '47'},
        {name: '48', code: '48'},
        {name: '49', code: '49'},
        {name: '50', code: '50'},
        {name: '51', code: '51'},
        {name: '52', code: '52'},
        {name: '53', code: '53'},
        {name: '54', code: '54'},
        {name: '55', code: '55'},
        {name: '56', code: '56'},
        {name: '57', code: '57'},
        {name: '58', code: '58'},
        {name: '59', code: '59'},
      ];
  }

  ngOnInit(): void {

    let userid = localStorage.getItem("SmartUserID");

    this.http.get(bmxurl + "User/" + userid).subscribe(response => {
      this.httpResponse =  (response as users_returndata)
      if(this.httpResponse.succeeded)
      {
        let user = this.httpResponse.data;

        this.busyService.busy();

        if(getAccess("CLASSES", user)){
          this.AccessAllowed = true;

          this.id = this.route.snapshot.paramMap.get('id');
          if(this.id != "0")
          {
            this.pageReady = false;
            this.http.get(bmxurl + "Link/" + this.VenueID + "/" + this.id).subscribe(response => {
              this.httpResponse = (response as link_returndata)
              if (this.httpResponse.succeeded)
              {
              
                this.Link = {
                  id: (this.httpResponse.data as link).id,
                  linkid: (this.httpResponse.data as link).linkid,
                  linkname: (this.httpResponse.data as link).linkname,
                  venueid: (this.httpResponse.data as link).venueid,
                  packageid: (this.httpResponse.data as link).packageid,
                  packagegroupid: (this.httpResponse.data as link).packagegroupid,
                  datetobook: new Date((this.httpResponse.data as link).datetobook),
                  timetobook: (this.httpResponse.data as link).timetobook,
                  admissionlist: (this.httpResponse.data as link).admissionlist,
                  expirydate: new Date((this.httpResponse.data as link).expirydate),
                  disabled: (this.httpResponse.data as link).disabled,
                };

                if(this.Link.packageid != "")
                {
                  this.LinkType = "PACKAGE";
                }
                else
                {
                  this.LinkType = "PACKAGEGROUP";
                }

                if(this.Link.datetobook.getFullYear() > 1901)
                {
                  this.LinkDate = true;
                }

                if(this.Link.timetobook != "")
                {
                  this.LinkTime = true;

                  this.linkStartTimeHours =  this.Link.timetobook.split(":")[0];
                  this.linkStartTimeMinutes = this.Link.timetobook.split(":")[1];
                }

                if(this.Link.expirydate.getFullYear() > 1901)
                {
                  this.LinkExpire = true;
                }

                this.http.get(bmxurl + "/packagelist/" + this.VenueID).subscribe(response => {
                  this.packages = (response as package_returndata).data

                  this.packages.forEach(element => {
                    if(element.packageId == this.Link.packageid)
                    {
                      this.selectedpackage = element;
                    }
                  });

                  this.http.get(bmxurl + "/packagegrouplist/" + this.VenueID).subscribe(response => {
                    this.packagegroups = (response as packagegroups_returndata).data

                    this.packagegroups.forEach(element => {
                      if(element.packagegroupid == this.Link.packagegroupid)
                      {
                        this.selectedpackagegroup = element;
                      }
                    });

                    this.pageReady = true;
                    this.busyService.idle();


                  }, error => {
                    console.log(error);
                  })


                }, error => {
                  console.log(error);
                })
              }
              else
              {
                this.messageService.add({severity:'error', summary:'Error', detail:'Error while getting links'});
                this.pageReady = false;
              }
            }, error => {
              this.messageService.add({severity:'error', summary:'Error', detail:'Error while getting links, error:' + error.messages[0]});
              this.pageReady = false;
            });
          }
          else
          {
            this.http.get(bmxurl + "/packagelist/" + this.VenueID).subscribe(response => {
              this.packages = (response as package_returndata).data

              this.packages.forEach(element => {
                if(element.packageId == this.Link.packageid)
                {
                  this.selectedpackage = element;
                }
              });

              this.http.get(bmxurl + "/packagegrouplist/" + this.VenueID).subscribe(response => {
                this.packagegroups = (response as packagegroups_returndata).data

                this.pageReady = true;
                this.busyService.idle();


              }, error => {
                console.log(error);
              })


            }, error => {
              console.log(error);
            })

          }
        }

        this.CompletedLoading = true;
      }
    }, error => {
      this.messageService.add({severity:'error', summary:'Error', detail:'Error while getting User, error:' + error.messages[0]});
    });

  }

  updateData()
  {
    if (this.DoValidCheck())
    {

      if(this.LinkType == "PACKAGE")
      {
        this.Link.packageid = this.selectedpackage.packageId;
        this.Link.packagegroupid = "";
      }
      else
      {
        this.Link.packageid = "";
        this.Link.packagegroupid = this.selectedpackagegroup.packagegroupid;

        this.Link.timetobook = "";
        this.LinkTime = false;
      }

      if(this.LinkTime)
      {
        this.Link.timetobook = this.linkStartTimeHours + ":" + this.linkStartTimeMinutes;
      }
      else
      {
        this.Link.timetobook = "";
      }

      if(this.id == "0")
      {
        this.http.post<link>(bmxurl + "/Link", this.Link).subscribe(response => {
          this.httpResponse = response
            if(this.httpResponse.succeeded)
            {
              this.messageService.add({severity:'success', summary:'Saved', detail:'Link successfully updated'});
              this.router.navigateByUrl("/linkmanager");
            }
            }, error => {
              this.messageService.add({severity:'error', summary:'Not Saved', detail:error.messages});
          })
      }
      else
      {
        this.Link.disabled = false;
        
        this.http.put<link>(bmxurl + "/Link", this.Link).subscribe(response => {
        this.httpResponse = response
          if(this.httpResponse.succeeded)
          {
            this.messageService.add({severity:'success', summary:'Saved', detail:'Link successfully updated'});
            this.router.navigateByUrl("/linkmanager");
          }
          }, error => {
            this.messageService.add({severity:'error', summary:'Not Saved', detail:error.messages});
        })
      }
    }
  }

  DoValidCheck()
  {
    let AllValid = true;

    if(this.Link.linkname == null || this.Link.linkname == "")
    {
      AllValid = false;
      this.LinkNameValid = false;
    }
    else
    {
      this.LinkNameValid = true;
    }

    if(this.selectedpackage == null && this.LinkType == "PACKAGE")
    {
      AllValid = false;
      this.LinkPackageValid = false;
    }
    else
    {
      this.LinkPackageValid = true;
    }

    if(this.selectedpackagegroup == null && this.LinkType == "PACKAGEGROUP")
    {
      AllValid = false;
      this.LinkPackageGroupValid = false;
    }
    else
    {
      this.LinkPackageGroupValid = true;
    }

    if(this.LinkDate)
    {
      if(new Date(this.Link.datetobook) < new Date())
      {
        AllValid = false;
        this.LinkDateValid = false;
      }
      else
      {
        this.LinkDateValid = true;
      }
    }

    if(this.LinkExpire)
    {
      if(new Date(this.Link.expirydate) < new Date())
      {
        AllValid = false;
        this.LinkExpiryValid = false;
      }
      else
      {
        this.LinkExpiryValid = true;
      }
    }


    return AllValid;
  }

  returnToList()
  {
    this.router.navigateByUrl("/linkmanager");
  }
}
