import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router'
import { HttpClient } from '@angular/common/http';
import { bmxurl, getAccess } from 'src/globals';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { eventObj, cEvent, cEventResource, adhocDates, eventtickettype } from 'src/types/event';
import { cResource, resource } from 'src/types/resource';
import { cAdmission } from 'src/types/admission';
import { tag_returndata } from 'src/types/tag_returndata';
import { resourceType_returndata } from 'src/types/resource_returndata';
import { admission_returndata } from 'src/types/admission_returndata';
import { v4 as uuidv4  } from 'uuid';
import { restriction_returndata } from 'src/types/restriction_returndata';
import { depositpolicy_returndata } from 'src/types/depositpolicy_returndata';

import { resourcegroupname_returndata } from 'src/types/resourcegroupname_returndata';
import { resourcegroupname } from 'src/types/resourcegroupname';
import { cClass } from 'src/types/class';
import { class_returndata } from 'src/types/class_returndata';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { AuthService } from '@auth0/auth0-angular';
import { confirmation_returndata } from 'src/types/confirmation_returndata';
import { environment } from 'src/environments/environment';
import { users_returndata } from 'src/types/user_returndata';
import { el, s } from '@fullcalendar/core/internal-common';
import { DatePipe } from '@angular/common';
import { warnings_returndata } from 'src/types/warnings_returndata';

@Component({
  selector: 'app-eventedit',
  templateUrl: './eventedit.component.html',
  styleUrls: ['./eventedit.component.css']
})
export class EventeditComponent implements OnInit {

  //#region Variables
    VenueID: string = localStorage.getItem("SmartVenueID")!;
    EventObj: cEvent;
    httpResponse: any;
    id: string;
    stateOptionsRes: any;
    stateOptionsPPP: any;
    stateOptionsMI: any;
    stateOptionsPart: any;
    stateOptions: any;
    stateOptionsComplexSimple: any;

    eventLen: number = 0;
    availableResources: any;
    selectedResources: any = [];
    stateOptionsSelMode: any;
    stateOptionsSel: any;
    stateOptionsQty: any;
    stateOptionsQty2: any;
    stateOptionsStart: any;
    stateOptionsBool: any;
    stateOptionsVisibility: any;
    stateOptionsPrice: any;
    stateOptions3: any;
    eventStartTimeHours: any[];
    eventStartTimeMins: any[];
    stateOptionsRangeType: any[];
    delayBookingStateOptions: any[];

    availableAdmissions: any = [];
    selectedAdmissions: any = [];
    draggedAdmission: any;
    DepositPolicies: any;

    builtResources: any;
    builtAdmissions: any;

    eventRules: any = [];
    eventRestriction: any = [];
    classes: cClass[];

    tags: any;
    items: any;
    activeIndex: number = 0;

    eventNameValid: boolean = true;
    eventDescValid: boolean = true;

    itinerary: string = "";
    discrepantitinerary: string[] = [];

    //maxRecoveredResourceIndex: number = 0;

    availableResourceGroups: resourcegroupname[] = [];
    selectedItems: any;

    showDialog: boolean;

    hubConnection: HubConnection;

    HTMLbmxurl: string = bmxurl;
    Identifier: string = uuidv4();
    FileChosen: string = "";

    Confirmations: any[];

    SortedItinerary: any[];

    AccessAllowed: boolean = false;
    CompletedLoading: boolean = false;

    BookingQuestionnaires: any[] = [];
    CurrencySymbol: string = localStorage.getItem("Currency") == null ? "GBP" : localStorage.getItem("Currency")!;

    RepeatOptionInterval: any[];
    RepeatOptionPeriod: any[];

    EventStartDate: Date = new Date();
    EventEndDate: Date = new Date();

    ChosenRepeatOptionInterval: any;
    ChosenRepeatOptionPeriod: any;

    eventSelectedStartTimeHours: string = "00";
    eventSelectedStartTimeMins: string = "00";

    eventSelectedEndTimeHours: string = "00";
    eventSelectedEndTimeMins: string = "00";

    EventMonday:boolean = false;
    EventTuesday:boolean = false;
    EventWednesday:boolean = false;
    EventThursday:boolean = false;
    EventFriday:boolean = false;
    EventSaturday:boolean = false;
    EventSunday:boolean = false;

    DateError: boolean = false;

    TicketType: string;
    TicketNumber: number = 0;
    TicketNameValid: boolean = true;
    TicketNumberValid: boolean = true;

    eventConfirmationValid: boolean = false;
    eventPublicDepositValid: boolean = false;
    eventVenueDepositValid: boolean = false;

    TicketLimits:boolean = false;
    EarlyBirdDiscount:boolean = false;

    eventeventtickettypeValid: boolean = true;

    CalculatedEndTime: Date = new Date();

    EventEarlybirdDiscountValid: boolean = true;
    EventEarlybirdDiscountType: string = "PERCENTAGE";
    EventEarlybirdDiscountAmount: number = 0;

    EventRepeat: boolean = false;

    datePipe: DatePipe = new DatePipe("en-GB");

    bookingCannotBeMadeWithinXMinutesD: number = 0;
    bookingCannotBeMadeWithinXMinutesH: number = 0;
    bookingCannotBeMadeWithinXMinutesM: number = 0;

    bookingscannotbemadeafterxDays: number = 0;

    tempStartDate: Date = new Date();
    tempEndDate: Date = this.addYears(new Date(), 5);

    tempDiscountStartDate: Date = new Date();
    tempDiscountEndDate: Date = this.addYears(new Date(), 5);

    CurrentSelectedAdmission: cAdmission;
    CurrentSelectedLinkedAdmission: cAdmission;

    Warnings: any[];

  //#endregion

    constructor(private route: ActivatedRoute, 
      private http: HttpClient, 
      private router: Router, 
      private messageService: MessageService, 
      private auth: AuthService) {
      this.stateOptionsRes = [{ label: 'Shared', value: false }, { label: 'Exclusive', value: true }];
      this.stateOptionsPPP = [{ label: 'Yes', value: true }, { label: 'No', value: false }];
      this.stateOptions = [{ label: 'Yes', value: true }, { label: 'No', value: false }];
      this.stateOptionsMI = [{ label: 'Yes', value: true }, { label: 'No', value: false }];
      this.stateOptionsPart = [{ label: 'Yes', value: true }, { label: 'No', value: false }];
      this.stateOptionsPrice = [{ label: 'Fixed Price', value: true }, { label: 'Per Person', value: false }];
      this.stateOptionsBool = [{ label: 'Yes', value: false }, { label: 'No', value: true }];
      //this.stateOptionsVisibility = [{ label: 'Neither', value: 'NEITHER' }, { label: 'Public', value: 'PUBLIC' }, { label: 'Venue', value: 'VENUE' }, { label: 'Both', value: 'BOTH' }];
      this.stateOptionsVisibility = [{ label: 'Hidden', value: 'NEITHER' }, { label: 'Public Bookings Only', value: 'PUBLIC' }, { label: 'Preview/Venue Only', value: 'VENUE' }, { label: 'Public and Venue Bookings', value: 'BOTH' }];
      this.stateOptionsRangeType = [{ label: 'Date Range', value: 0 }, { label: 'Days before', value: 1 }];

      this.stateOptionsSel =  [{ label: 'Customer chooses from any', value: "ANY" }, { label: 'Customer chooses from list', value: "LIST" }, { label: 'System chooses from any', value: "SYSTEMANY" }, { label: 'System chooses from list', value: "SYSTEMLIST" }];
      this.stateOptionsQty =  [{ label: 'Group Size', value: "GROUPSIZE" }, { label: 'Participant', value: "PARTICIPANT" }, { label: 'Customer Chooses', value: "CUSTOM" }];
      
      this.stateOptionsQty2 =  [{ label: 'Group Size', value: "GROUPSIZE" }, { label: 'Participant', value: "PARTICIPANT" }];
      
      this.stateOptionsStart = [{ label: 'At Start', value: "ATSTART"}, { label: 'At End', value: "ATEND"}, { label: 'Offset', value: "OFFSET"}];
      this.stateOptionsComplexSimple = [{ label: 'Simple', value: 'SIMPLE' }, { label: 'Complex', value: "COMPLEX"}];

      this.stateOptions3 = [{ label: 'Fixed Amount', value: "FIXED" }, { label: 'Percentage', value: "PERCENTAGE" }];

      this.delayBookingStateOptions = [{label: 'No', value: false}, {label: 'Yes', value: true}];

      this.EventObj = {
        id: null,
        eventid: null,
        name: " ",
        description: " ",
        imageurl: "",
        message: "",
        disabled: false,
        venueid: this.VenueID,
        resources: [],
        admissions: [],
        eventlength: 5,
        questionnaire: null,
        min: 0,
        max: 0,
        tags: null,
        visibility: "VENUE",
        colour: "#990000",
        publicdepositpolicy: null,
        venuedepositpolicy: null,
        confirmation:  null,
        warning: null,
        eventdates: [],
        eventdateexceptions: [],
        originatingid: "",
        nooftickets: 1,
        noofticketssold: 0,
        eventtickettypes: [],
        discounttype: "PERCENTAGE",
        discountamount: 0,
        discountcode: "",
        discountstart: this.tempDiscountStartDate.toISOString().split("T")[0],
        discountend: this.tempDiscountEndDate.toISOString().split("T")[0],
        earlybirddiscount: false,
        discountrangetype: 0,
        discountdaysbefore: 0,
        imageguid: this.Identifier,
        bookingscannotbemadewithinxminutes: 0,
        bookingscannotbemadeafterxminutes: 0,
        cannotmakebookingstoday: true,
    };

    this.eventStartTimeHours = [
      {name: '00', code: '00'},
      {name: '01', code: '01'},
      {name: '02', code: '02'},
      {name: '03', code: '03'},
      {name: '04', code: '04'},
      {name: '05', code: '05'},
      {name: '06', code: '06'},
      {name: '07', code: '07'},
      {name: '08', code: '08'},
      {name: '09', code: '09'},
      {name: '10', code: '10'},
      {name: '11', code: '11'},
      {name: '12', code: '12'},
      {name: '13', code: '13'},
      {name: '14', code: '14'},
      {name: '15', code: '15'},
      {name: '16', code: '16'},
      {name: '17', code: '17'},
      {name: '18', code: '18'},
      {name: '19', code: '19'},
      {name: '20', code: '20'},
      {name: '21', code: '21'},
      {name: '22', code: '22'},
      {name: '23', code: '23'}
      ];
   
    this.eventStartTimeMins = [
        {name: '00', code: '00'},
        {name: '05', code: '05'},
        {name: '10', code: '10'},
        {name: '15', code: '15'},
        {name: '20', code: '20'},
        {name: '25', code: '25'},
        {name: '30', code: '30'},
        {name: '35', code: '35'},
        {name: '40', code: '40'},
        {name: '45', code: '45'},
        {name: '50', code: '50'},
        {name: '55', code: '55'},
      ];

      this.RepeatOptionInterval = [
        {name: 'No Repeat', code: 'No Repeat'},
        {name: '1', code: '1'},
        {name: '2', code: '2'},
        {name: '3', code: '3'},
        {name: '4', code: '4'},
        {name: '5', code: '5'},
        {name: '6', code: '6'},
        {name: '7', code: '7'},
        {name: '8', code: '8'},
        {name: '9', code: '9'},
        {name: '10', code: '10'},
        {name: '11', code: '11'},
        {name: '12', code: '12'},
        {name: '13', code: '13'},
        {name: '14', code: '14'},
        {name: '15', code: '15'},
        {name: '16', code: '16'},
        {name: '17', code: '17'},
        {name: '18', code: '18'},
        {name: '19', code: '19'},
        {name: '20', code: '20'},
        {name: '21', code: '21'},
        {name: '22', code: '22'},
        {name: '23', code: '23'},
        {name: '24', code: '24'},
        {name: '25', code: '25'},
        {name: '26', code: '26'},
        {name: '27', code: '27'},
        {name: '28', code: '28'},
        {name: '29', code: '29'},
        {name: '30', code: '30'},
        {name: '31', code: '31'}
      ];
  
      this.RepeatOptionPeriod = [ 
        {name: 'Hour(s)', code: 'Hour(s)'},
        {name: 'Day(s)', code: 'Day(s)'},
        {name: 'Week(s)', code: 'Week(s)'},
        {name: 'Month(s)', code: 'Month(s)'},
        {name: 'Year(s)', code: 'Year(s)'}
      ];

    }

    setEndDate(){
      this.EventEndDate = new Date(this.EventStartDate);

      this.CalculatedEndTime = new Date(this.EventStartDate);
      this.CalculatedEndTime.setHours(Number(this.eventSelectedStartTimeHours));
      this.CalculatedEndTime.setMinutes(Number(this.eventSelectedStartTimeMins));

      this.addMinutes(this.CalculatedEndTime, this.EventObj.eventlength);
    }

    createDates()
    {
      if(!this.EventRepeat)
      {
        this.ChosenRepeatOptionInterval = "No Repeat";
      }

      this.DateError = false;
      //add dates to eventdates

      let startDate =new  Date(this.EventStartDate);
      let untilDate = new Date(this.EventEndDate);

      startDate.setHours(Number(this.eventSelectedStartTimeHours));
      startDate.setMinutes(Number(this.eventSelectedStartTimeMins));
      startDate.setSeconds(0);
      startDate.setMilliseconds(0);

      untilDate.setHours(Number(this.eventSelectedEndTimeHours));
      untilDate.setMinutes(Number(this.eventSelectedEndTimeMins));
      untilDate.setSeconds(0);
      untilDate.setMilliseconds(0);

      let eventdates: adhocDates[] = [];

      let interval = 0;
      if(this.ChosenRepeatOptionInterval != null)
      {
        interval = this.ChosenRepeatOptionInterval.code;
      }

      if(this.ChosenRepeatOptionPeriod != null)
      {
        switch(this.ChosenRepeatOptionPeriod.code) { 
          case "Hour(s)": { 
            if(startDate > untilDate)
            {
              this.DateError = true;
            }
            else
            {
              while(startDate <= untilDate)
              {
                let tempEndData = new Date(startDate);

                let newDate: adhocDates = {
                  id: uuidv4(),
                  disabled: false,
                  startdatetime: this.stringifyDateWithTime(startDate),
                  enddatetime: this.stringifyDateAddTime(tempEndData, this.EventObj.eventlength),
                  nooftickets: this.EventObj.nooftickets,
                  noofticketssold: 0,
                  eventtickettypes : this.clone(this.EventObj.eventtickettypes),
                  cancelledsubject: "",
                  cancelledcontent: ""
                }
                this.pushDateToStack(newDate);

                startDate = this.addHours(startDate, Number(interval));
              }
            }
            break; 
          } 
          case "Day(s)": { 
            untilDate = this.addMinutes(new Date(untilDate), this.EventObj.eventlength);
            if(startDate > untilDate)
            {
              this.DateError = true;
            }
            else
            {
              while(startDate <= untilDate)
              {
                let tempEndData = new Date(startDate);

                let newDate: adhocDates = {
                  id: uuidv4(),
                  disabled: false,
                  startdatetime: this.stringifyDateWithTime(startDate),
                  enddatetime: this.stringifyDateAddTime(tempEndData, this.EventObj.eventlength),
                  nooftickets: this.EventObj.nooftickets,
                  noofticketssold: 0,
                  eventtickettypes : this.clone(this.EventObj.eventtickettypes),
                  cancelledsubject: "",
                  cancelledcontent: ""
                }
                this.pushDateToStack(newDate);
                startDate = this.addDays(startDate, Number(interval));
              }
            }
            break; 
          } 
          case "Week(s)": { 
            untilDate = this.addMinutes(new Date(untilDate), this.EventObj.eventlength);
            if(startDate > untilDate)
            {
              this.DateError = true;
            }
            else
            {
              while(startDate <= untilDate)
              {

                let tempEndData = new Date(startDate);

                if(this.EventMonday && startDate.getDay() == 1 && startDate <= untilDate)
                {
                  let newDate: adhocDates = {
                    id: uuidv4(),
                    disabled: false,
                    startdatetime: this.stringifyDateWithTime(startDate),
                    enddatetime: this.stringifyDateAddTime(tempEndData, this.EventObj.eventlength),
                    nooftickets: this.EventObj.nooftickets,
                    noofticketssold: 0,
                    eventtickettypes : this.clone(this.EventObj.eventtickettypes),
                    cancelledsubject: "",
                    cancelledcontent: ""
                  }
                  this.pushDateToStack(newDate);
                }

                if(this.EventTuesday && startDate.getDay() == 2 && startDate <= untilDate)
                {
                  let newDate: adhocDates = {
                    id: uuidv4(),
                    disabled: false,
                    startdatetime: this.stringifyDateWithTime(startDate),
                    enddatetime: this.stringifyDateAddTime(tempEndData, this.EventObj.eventlength),
                    nooftickets: this.EventObj.nooftickets,
                    noofticketssold: 0,
                    eventtickettypes : this.clone(this.EventObj.eventtickettypes),
                    cancelledsubject: "",
                    cancelledcontent: ""
                  }
                  this.pushDateToStack(newDate);
                }

                if(this.EventWednesday && startDate.getDay() == 3 && startDate <= untilDate)
                {
                  let newDate: adhocDates = {
                    id: uuidv4(),
                    disabled: false,
                    startdatetime: this.stringifyDateWithTime(startDate),
                    enddatetime: this.stringifyDateAddTime(tempEndData, this.EventObj.eventlength),
                    nooftickets: this.EventObj.nooftickets,
                    noofticketssold: 0,
                    eventtickettypes : this.clone(this.EventObj.eventtickettypes),
                    cancelledsubject: "",
                    cancelledcontent: ""
                  }
                  this.pushDateToStack(newDate);
                }

                if(this.EventThursday && startDate.getDay() == 4 && startDate <= untilDate)
                {
                  let newDate: adhocDates = {
                    id: uuidv4(),
                    disabled: false,
                    startdatetime: this.stringifyDateWithTime(startDate),
                    enddatetime: this.stringifyDateAddTime(tempEndData, this.EventObj.eventlength),
                    nooftickets: this.EventObj.nooftickets,
                    noofticketssold: 0,
                    eventtickettypes : this.clone(this.EventObj.eventtickettypes),
                    cancelledsubject: "",
                    cancelledcontent: ""
                  }
                  this.pushDateToStack(newDate);
                }   

                if(this.EventFriday && startDate.getDay() == 5 && startDate <= untilDate)
                {
                  let newDate: adhocDates = {
                    id: uuidv4(),
                    disabled: false,
                    startdatetime: this.stringifyDateWithTime(startDate),
                    enddatetime: this.stringifyDateAddTime(tempEndData, this.EventObj.eventlength),
                    nooftickets: this.EventObj.nooftickets,
                    noofticketssold: 0,
                    eventtickettypes : this.clone(this.EventObj.eventtickettypes),
                    cancelledsubject: "",
                    cancelledcontent: ""
                  }
                  this.pushDateToStack(newDate);
                }

                if(this.EventSaturday && startDate.getDay() == 6 && startDate <= untilDate)
                {
                  let newDate: adhocDates = {
                    id: uuidv4(),
                    disabled: false,
                    startdatetime: this.stringifyDateWithTime(startDate),
                    enddatetime: this.stringifyDateAddTime(tempEndData, this.EventObj.eventlength),
                    nooftickets: this.EventObj.nooftickets,
                    noofticketssold: 0,
                    eventtickettypes : this.clone(this.EventObj.eventtickettypes),
                    cancelledsubject: "",
                    cancelledcontent: ""
                  }
                  this.pushDateToStack(newDate);
                }

                if(this.EventSunday && startDate.getDay() == 0 && startDate <= untilDate)
                {
                  let newDate: adhocDates = {
                    id: uuidv4(),
                    disabled: false,
                    startdatetime: this.stringifyDateWithTime(startDate),
                    enddatetime: this.stringifyDateAddTime(tempEndData, this.EventObj.eventlength),
                    nooftickets: this.EventObj.nooftickets,
                    noofticketssold: 0,
                    eventtickettypes : this.clone(this.EventObj.eventtickettypes),
                    cancelledsubject: "",
                    cancelledcontent: ""
                  }
                  this.pushDateToStack(newDate);
                }

                startDate = this.addDays(startDate, Number(interval * 1));
              }
            }
            break; 
          } 
          case "Month(s)": { 
          untilDate = this.addMinutes(new Date(untilDate), this.EventObj.eventlength);
          if(startDate > untilDate)
            {
              this.DateError = true;
            }
            else
            {
              while(startDate <= untilDate)
              {
                let tempEndData = new Date(startDate);

                let newDate: adhocDates = {
                  id: uuidv4(),
                  disabled: false,
                  startdatetime: this.stringifyDateWithTime(startDate),
                  enddatetime: this.stringifyDateAddTime(tempEndData, this.EventObj.eventlength),
                  nooftickets: this.EventObj.nooftickets,
                  noofticketssold: 0,
                  eventtickettypes : this.clone(this.EventObj.eventtickettypes),
                  cancelledsubject: "",
                  cancelledcontent: ""
                }
                this.pushDateToStack(newDate);
                startDate = this.addMonths(startDate, Number(interval));
              }
            }
          break; 
          } 
          case "Year(s)": { 
            untilDate = this.addMinutes(new Date(untilDate), this.EventObj.eventlength);
            if(startDate > untilDate)
            {
              this.DateError = true;
            }
            else
            {
            while(startDate <= untilDate)
              {
                let tempEndData = new Date(startDate);

                let newDate: adhocDates = {
                  id: uuidv4(),
                  disabled: false,
                  startdatetime: this.stringifyDateWithTime(startDate),
                  enddatetime: this.stringifyDateAddTime(tempEndData, this.EventObj.eventlength),
                  nooftickets: this.EventObj.nooftickets,
                  noofticketssold: 0,
                  eventtickettypes : this.clone(this.EventObj.eventtickettypes),
                  cancelledsubject: "",
                  cancelledcontent: ""
                }
                eventdates.push(newDate);

                startDate = this.addYears(startDate, Number(interval));
              }

              this.EventObj.eventdates = eventdates;
            }
            break; 
          } 
          default: { 
            //Do Nothing there is no default action.
            break; 
          } 
        }
      }
      else
      {
        let tempEndData = new Date(startDate);

        let newDate: adhocDates = {
          id: uuidv4(),
          disabled: false,
          startdatetime: this.stringifyDateWithTime(startDate),
          enddatetime: this.stringifyDateAddTime(tempEndData, this.EventObj.eventlength),
          nooftickets: this.EventObj.nooftickets,
          noofticketssold: 0,
          eventtickettypes : this.clone(this.EventObj.eventtickettypes),
          cancelledsubject: "",
          cancelledcontent: ""
        }
 
        this.pushDateToStack(newDate);
      }

      this.EventObj.eventdates.sort((a, b) => (new Date(a.startdatetime) > new Date(b.startdatetime)) ? 1 : -1);
    }

    clone<T>(obj: T): T {
      return JSON.parse(JSON.stringify(obj));
    }

    pushDateToStack(inDate)
    {
      let found = false;
      this.EventObj.eventdates.forEach(element => {
        let indateFromDate = inDate.startdatetime;
        let indateToDate = inDate.enddatetime;
        let elementFromDate = element.startdatetime;
        let elementToDate = element.enddatetime;

      if(indateFromDate == elementFromDate && indateToDate == elementToDate)
          found = true;
      });
      if(!found)
      {
        this.EventObj.eventdates.push(inDate);
      }
      else
      {
        this.messageService.add({severity:'error', summary:'Error', detail:'You cannot add the same event date more than once.'});
      }
    }

    clearDates()
    {
      this.EventObj.eventdates = [];
    }

    add_days(date: Date, days: number) {
      const result = new Date(date);
      result.setDate(result.getDate() + days);
      return result;
    }

    addYears(date: Date, years: number): Date {
      date.setFullYear(date.getFullYear() + years);
      return date;
    }

    addMonths(date: Date, months: number): Date {
      date.setMonth(date.getMonth() + months);
      return date;
    }

    addDays(date: Date, days: number): Date {
      date.setDate(date.getDate() + days);
      return date;
    }

    addHours(date: Date, hours: number): Date {
      date.setHours(date.getHours() + hours);
      return date;
    }
  
    addMinutes(date: Date, minutes: number): Date {
      date.setMinutes(date.getMinutes() + minutes);
      return date;
    }
  
    addSeconds(date: Date, seconds: number): Date {
      date.setSeconds(date.getSeconds() + seconds);
      return date;
    }

    add_years(dt,n) 
    {
    return new Date(dt.setFullYear(dt.getFullYear() + n));      
    }

    uploadedFile(event){
      this.EventObj.imageurl = event.originalEvent.body.data;

      let newNonce = uuidv4();
      this.FileChosen = event.originalEvent.body.data + "?n=" + newNonce;
    }

    onErrorFileUpload(event){
      this.messageService.add({ severity: 'error', summary: 'Image not uploaded', detail: 'Could not upload image: ' + event.error });
    }

    doRefresh(){
      if(this.id != "0")
      {
        this.http.get(bmxurl + "event/" + this.VenueID + "/" + this.id).subscribe(response => {
          this.httpResponse = response;
          this.EventObj = {
            id: null,
            eventid: null,
            name: (this.httpResponse.data as cEvent).name,
            description: (this.httpResponse.data as cEvent).description,
            imageurl: (this.httpResponse.data as cEvent).imageurl,
            message: (this.httpResponse.data as cEvent).message,
            disabled: (this.httpResponse.data as cEvent).disabled,
            venueid: (this.httpResponse.data as cEvent).venueid,
            resources: (this.httpResponse.data as cEvent).resources,
            admissions: (this.httpResponse.data as cEvent).admissions,
            eventlength: (this.httpResponse.data as cEvent).eventlength,
            questionnaire: (this.httpResponse.data as cEvent).questionnaire,
            min: (this.httpResponse.data as cEvent).min,
            max: (this.httpResponse.data as cEvent).max,
            tags: (this.httpResponse.data as cEvent).tags,
            visibility: (this.httpResponse.data as cEvent).visibility,
            colour: (this.httpResponse.data as cEvent).colour,
            publicdepositpolicy: (this.httpResponse.data as cEvent).publicdepositpolicy,
            venuedepositpolicy: (this.httpResponse.data as cEvent).venuedepositpolicy,
            confirmation:  (this.httpResponse.data as cEvent).confirmation,
            warning:  (this.httpResponse.data as cEvent).warning,
            eventdates:  (this.httpResponse.data as cEvent).eventdates,
            eventdateexceptions:  (this.httpResponse.data as cEvent).eventdateexceptions,
            originatingid: (this.httpResponse.data as cEvent).originatingid,
            nooftickets: (this.httpResponse.data as cEvent).nooftickets,
            noofticketssold: (this.httpResponse.data as cEvent).noofticketssold,
            eventtickettypes: (this.httpResponse.data as cEvent).eventtickettypes,
            discounttype: (this.httpResponse.data as cEvent).discounttype,
            discountamount:(this.httpResponse.data as cEvent).discountamount,
            discountcode: (this.httpResponse.data as cEvent).discountcode,
            discountstart: (this.httpResponse.data as cEvent).discountstart,
            discountend: (this.httpResponse.data as cEvent).discountend,
            earlybirddiscount: (this.httpResponse.data as cEvent).earlybirddiscount,
            discountrangetype: (this.httpResponse.data as cEvent).discountrangetype,
            discountdaysbefore: (this.httpResponse.data as cEvent).discountdaysbefore,
            imageguid: (this.httpResponse.data as cEvent).imageguid,
            bookingscannotbemadewithinxminutes: (this.httpResponse.data as cEvent).bookingscannotbemadewithinxminutes,
            bookingscannotbemadeafterxminutes: (this.httpResponse.data as cEvent).bookingscannotbemadeafterxminutes,
            cannotmakebookingstoday: (this.httpResponse.data as cEvent).cannotmakebookingstoday,
          }

          //this.buildItinerary();
          this.showDialog = false;

        }, error => {

        });

      }

    }

    // stringifyDateAddTime(date: Date, end: boolean, time: number): string
    // {
    //   let returnString = "";

    //   date = this.addMinutes(date, time);

    //   if (end)
    //   {
    //     returnString = date.getFullYear() + "-" +  ('00' + (Number(date.getMonth()) + 1)).slice(-2) + "-" +  ('00' + date.getDate()).slice(-2)+ "T23:59:59.999Z";
    //   }
    //   else
    //   {
    //     returnString = date.getFullYear() + "-" +  ('00' + (Number(date.getMonth()) + 1)).slice(-2) + "-" +  ('00' + date.getDate()).slice(-2)+ "T00:00:00.000Z";
    //   }

    //   return returnString;
    // }

    stringifyDateWithTime(date: Date): string
    {
      let returnString = "";

      returnString = date.getFullYear() + "-" +  ('00' + (Number(date.getMonth()) + 1)).slice(-2) + "-" +  ('00' + date.getDate()).slice(-2) + "T" + ('00' + date.getHours()).slice(-2) + ":" + ('00' + date.getMinutes()).slice(-2) + ":00.000Z";

      return returnString;
    }

    
    stringifyDateAddTime(date: Date, time: number): string
    {
      let returnString = "";

      date = this.addMinutes(date, time);
      
      returnString = date.getFullYear() + "-" +  ('00' + (Number(date.getMonth()) + 1)).slice(-2) + "-" +  ('00' + date.getDate()).slice(-2) + "T" + ('00' + date.getHours()).slice(-2) + ":" + ('00' + date.getMinutes()).slice(-2) + ":00.000Z";

      return returnString;
    }

    stringifyDate(date: Date, end: boolean): string
    {
      let returnString = "";

      if (end)
      {
        returnString = date.getFullYear() + "-" +  ('00' + (Number(date.getMonth()) + 1)).slice(-2) + "-" +  ('00' + date.getDate()).slice(-2)+ "T23:59:59.999Z";
      }
      else
      {
        returnString = date.getFullYear() + "-" +  ('00' + (Number(date.getMonth()) + 1)).slice(-2) + "-" +  ('00' + date.getDate()).slice(-2)+ "T00:00:00.000Z";
      }

      return returnString;
    }

    DatifyString(date: string): Date
    {
      let returnDate = new Date();

      returnDate.setUTCFullYear(Number(date.substring(0, 4)));
      returnDate.setUTCMonth(Number(date.substring(5, 7)) - 1);
      returnDate.setUTCDate(Number(date.substring(8, 10)));
      returnDate.setUTCHours(0);
      returnDate.setUTCMinutes(0);
      returnDate.setUTCSeconds(0);

      return returnDate;
    }

    ngOnInit(): void {

      let userid = localStorage.getItem("SmartUserID");

      this.http.get(bmxurl + "User/" + userid).subscribe(response => {
        this.httpResponse =  (response as users_returndata)
        if(this.httpResponse.succeeded)
        {
          let user = this.httpResponse.data;
  
          if(getAccess("EVENT", user)){
            this.AccessAllowed = true;

            
          this.id = this.route.snapshot.paramMap.get('id');
    
          this.auth.getAccessTokenSilently().subscribe(tokenReturned => {
            this.hubConnection = new HubConnectionBuilder()
            .withUrl(environment.servicestarget + "/venue/" + this.VenueID, { 
            accessTokenFactory: () => tokenReturned 
          }).build();
      
          this.hubConnection.start();
      
          this.hubConnection.on("eventEvent", data => {
            console.log(data);
            if(data.eventId == this.id && data.eventId != '0') 
              this.showDialog = true;
          });
          
          });
    
          this.items = [
            {label: 'Event Details'},
            {label: 'Resources'},
            {label: 'Admissions'}
          ];
    
          if(this.id != "0")
          {
            this.http.get(bmxurl + "event/" + this.VenueID + "/" + this.id).subscribe(response => {
              this.httpResponse = response;
        
              let stringStartDate = (this.httpResponse.data as cEvent).discountstart.toString();
              let stringStartDateYear = Number(stringStartDate.substring(0, 4));
              let stringStartDateMonth = Number(stringStartDate.substring(5, 7));
              let stringStartDateDay = Number(stringStartDate.substring(8, 10));

              let stringEndDate = (this.httpResponse.data as cEvent).discountend.toString();
              let stringEndDateYear = Number(stringEndDate.substring(0, 4));
              let stringEndDateMonth = Number(stringEndDate.substring(5, 7));
              let stringEndDateDay = Number(stringEndDate.substring(8, 10));

              // this.tempDiscountStartDate.setUTCFullYear(Number((this.httpResponse.data as cEvent).discountstart.substring(0, 4)))
              // this.tempDiscountStartDate.setUTCMonth(Number((this.httpResponse.data as cEvent).discountstart.substring(5, 7)))
              // this.tempDiscountStartDate.setUTCDate(Number((this.httpResponse.data as cEvent).discountstart.substring(8, 10)));

              this.tempDiscountStartDate =this.DatifyString((this.httpResponse.data as cEvent).discountstart);
              this.tempDiscountEndDate =this.DatifyString((this.httpResponse.data as cEvent).discountend);
              
              // this.tempDiscountEndDate.setUTCFullYear(Number((this.httpResponse.data as cEvent).discountend.substring(0, 4)))
              // this.tempDiscountEndDate.setUTCMonth(Number((this.httpResponse.data as cEvent).discountend.substring(5, 7)))
              // this.tempDiscountEndDate.setUTCDate(Number((this.httpResponse.data as cEvent).discountend.substring(8, 10)));

              this.EventObj = {
                id: (this.httpResponse.data as cEvent).id,
                eventid: (this.httpResponse.data as cEvent).eventid,
                name: (this.httpResponse.data as cEvent).name,
                description: (this.httpResponse.data as cEvent).description,
                imageurl: (this.httpResponse.data as cEvent).imageurl,
                message: (this.httpResponse.data as cEvent).message,
                disabled: (this.httpResponse.data as cEvent).disabled,
                venueid: (this.httpResponse.data as cEvent).venueid,
                resources: (this.httpResponse.data as cEvent).resources,
                admissions: (this.httpResponse.data as cEvent).admissions,
                eventlength: (this.httpResponse.data as cEvent).eventlength,
                questionnaire: (this.httpResponse.data as cEvent).questionnaire,
                min: (this.httpResponse.data as cEvent).min,
                max: (this.httpResponse.data as cEvent).max,
                tags: (this.httpResponse.data as cEvent).tags,
                visibility: (this.httpResponse.data as cEvent).visibility,
                colour: (this.httpResponse.data as cEvent).colour,
                publicdepositpolicy: (this.httpResponse.data as cEvent).publicdepositpolicy,
                venuedepositpolicy: (this.httpResponse.data as cEvent).venuedepositpolicy,
                confirmation:  (this.httpResponse.data as cEvent).confirmation,
                warning:  (this.httpResponse.data as cEvent).warning,
                eventdates:  (this.httpResponse.data as cEvent).eventdates,
                eventdateexceptions:  (this.httpResponse.data as cEvent).eventdateexceptions,
                originatingid: (this.httpResponse.data as cEvent).originatingid,
                nooftickets: (this.httpResponse.data as cEvent).nooftickets,
                noofticketssold: (this.httpResponse.data as cEvent).noofticketssold,
                eventtickettypes: (this.httpResponse.data as cEvent).eventtickettypes,
                discounttype: (this.httpResponse.data as cEvent).discounttype,
                discountamount:(this.httpResponse.data as cEvent).discountamount,
                discountcode: (this.httpResponse.data as cEvent).discountcode,

                discountstart: (this.httpResponse.data as cEvent).discountstart,
                discountend: (this.httpResponse.data as cEvent).discountend,

                earlybirddiscount: (this.httpResponse.data as cEvent).earlybirddiscount,
                discountrangetype: (this.httpResponse.data as cEvent).discountrangetype,
                discountdaysbefore: (this.httpResponse.data as cEvent).discountdaysbefore,
                imageguid: (this.httpResponse.data as cEvent).imageguid,
                bookingscannotbemadewithinxminutes: (this.httpResponse.data as cEvent).bookingscannotbemadewithinxminutes,
                bookingscannotbemadeafterxminutes: (this.httpResponse.data as cEvent).bookingscannotbemadeafterxminutes,
                cannotmakebookingstoday: (this.httpResponse.data as cEvent).cannotmakebookingstoday,
              }
     
              this.EventObj.admissions.forEach(adm => {
                if(adm.linkedadmissions != null)
                {
                  adm.uselinkedadmissions = true;
                }
                else
                {
                  adm.uselinkedadmissions = false;
                }

                if(adm.linkeddiscounts != null)
                  {
                    adm.uselinkeddiscounts = true;
                  }
                  else
                  {
                    adm.uselinkeddiscounts = false;
                  }
              });

              this.bookingCannotBeMadeWithinXMinutesD = Math.floor(this.EventObj.bookingscannotbemadewithinxminutes / 1440);
              this.bookingCannotBeMadeWithinXMinutesH = Math.floor((this.EventObj.bookingscannotbemadewithinxminutes % 1440) / 60);
              this.bookingCannotBeMadeWithinXMinutesM = this.EventObj.bookingscannotbemadewithinxminutes % 60;
              
              this.bookingscannotbemadeafterxDays = this.EventObj.bookingscannotbemadeafterxminutes / 1440;

              this.Identifier = this.EventObj.imageguid;
              
              this.EarlyBirdDiscount = this.EventObj.discountcode != null && this.EventObj.discountcode != "";

              this.TicketLimits = this.EventObj.eventtickettypes != null && this.EventObj.eventtickettypes.length > 0;

              this.FileChosen = this.EventObj.imageurl;
              //this.buildItinerary();
    
            }, error => {
            });
          }
    
          this.loadConfirmations(false);
          this.loadWwarnings(false);
          this.loadResources(false);
          this.loadAdmissions(false);
          this.loadTags(false);
          this.loadDepositPolidies(false);
          this.loadClasses(false);
          this.loadQuestionnaires();
          }
  
          this.CompletedLoading = true;

          this.CalculatedEndTime.setHours(0);
          this.CalculatedEndTime.setMinutes(0);

          this.addMinutes(this.CalculatedEndTime, this.EventObj.eventlength);

        }
      }, error => {
        this.messageService.add({severity:'error', summary:'Error', detail:'Error while getting User, error:' + error.messages[0]});
      });
    }

    loadWwarnings(showdisabled: boolean) {
      this.http.get(bmxurl + "WarningList/" + this.VenueID + "/" + showdisabled).subscribe(response => {
        this.Warnings = (response as warnings_returndata).data;
      }, error => {
      });
    }

    setEndDateLabel()
    {
      this.CalculatedEndTime = new Date(this.EventStartDate);
      this.CalculatedEndTime.setHours(Number(this.eventSelectedStartTimeHours));
      this.CalculatedEndTime.setMinutes(Number(this.eventSelectedStartTimeMins));

      this.addMinutes(this.CalculatedEndTime, this.EventObj.eventlength);

    }

    deleteType(inTicketType){
      this.EventObj.eventtickettypes = this.EventObj.eventtickettypes.filter(obj => obj.id !== inTicketType.id);
    }

    AddToTicketTypes(){

      //add validators
      this.TicketNameValid = true;
      this.TicketNumberValid = true;

      if(this.TicketType == null || this.TicketType == "")
      {
        this.TicketNameValid = false;
      }

      if(this.TicketNumber == null || this.TicketNumber < 1)
      {
        this.TicketNumberValid = false;
      }

        if(this.TicketNameValid && this.TicketNumberValid)
        {
          if(this.EventObj.eventtickettypes == null)
            this.EventObj.eventtickettypes = [];

          let newTicketType: eventtickettype = {
            id: uuidv4(),
            name: this.TicketType,
            quantity: this.TicketNumber,
          }

          this.EventObj.eventtickettypes.push(newTicketType);
          this.TicketType = "";
          this.TicketNumber = 0;
        }
    }

    loadQuestionnaires(){
      this.http.get(bmxurl + "QuestionnaireList/" + this.VenueID).subscribe(response => {
        this.BookingQuestionnaires = (response as any).data;
      }, error => {
        this.messageService.add({severity:'error', summary:'Error', detail:'Error while getting Questionnaires, error:' + error.messages[0]});
      });
    }

    genRandomNum()
    {
      return Math.floor((Math.random()*10000)+1).toString();
    }

    loadConfirmations(showdisabled: boolean) {
      this.http.get(bmxurl + "ConfirmationList/" + this.VenueID).subscribe(response => {
        this.Confirmations = (response as confirmation_returndata).data;
      }, error => {
      });
    }

    loadClasses(showdisabled: boolean) {
      this.http.get(bmxurl + "ClassList/" + this.VenueID).subscribe(response => {
        this.classes = (response as class_returndata).data;
      }, error => {
      });
    }

    loadResourceGroups(showdisabled: boolean) {
      this.http.get(bmxurl + "ResourceGroupNameList/" + this.VenueID + "/" + showdisabled).subscribe(response => {
        this.availableResourceGroups = (response as resourcegroupname_returndata).data;
      }, error => {
      });
    }

    loadResources(showdisabled: boolean) {
      this.http.get(bmxurl + "ResourceList/" + this.VenueID + "/" + showdisabled).subscribe(response => {
        this.availableResources = (response as resourceType_returndata).data.filter(obj => obj.eventid == null);
      }, error => {
      });
    }

    loadTags(showdisabled: boolean) {
      this.http.get(bmxurl + "TagList/" + this.VenueID + "/" + showdisabled).subscribe(response => {
        this.tags = (response as tag_returndata).data;
        console.log(this.tags);
      }, error => {
      });
    }

    loadAdmissions(showdisabled: boolean) {
      this.http.get(bmxurl + "AdmissionList/" + this.VenueID + "/" + showdisabled).subscribe(response => {
        this.availableAdmissions = (response as admission_returndata).data;
      }, error => {
      });
    }

    loadDepositPolidies(showdisabled: boolean) {
      this.http.get(bmxurl + "DepositPolicyList/" + this.VenueID + "/" + showdisabled).subscribe(response => {
        this.DepositPolicies = (response as depositpolicy_returndata).data;
      }, error => {
      });
    }

    returnToList() {
      this.router.navigateByUrl("/event");
    }

    AddToExcluded(datetoexclude)
    {
      if(this.EventObj.eventdateexceptions == null)
        this.EventObj.eventdateexceptions = [];

        let newDate: adhocDates = {
          id: datetoexclude.id,
          disabled: datetoexclude.disabled,
          startdatetime: datetoexclude.startdatetime,
          enddatetime: datetoexclude.enddatetime,
          nooftickets: datetoexclude.nooftickets,
          noofticketssold: datetoexclude.noofticketssold,
          eventtickettypes : datetoexclude.eventtickettypes,
          cancelledsubject: datetoexclude.cancelledsubject,
          cancelledcontent: datetoexclude.cancelledcontent
        }

        this.EventObj.eventdateexceptions.push(newDate);
        newDate = null;

        this.EventObj.eventdates = this.EventObj.eventdates.filter(obj => obj.id !== datetoexclude.id);

        this.EventObj.eventdateexceptions.sort((a, b) => (new Date(a.startdatetime) > new Date(b.startdatetime)) ? 1 : -1);
    }

    removeFromExcluded(dateexcluded){
      this.EventObj.eventdateexceptions = this.EventObj.eventdateexceptions.filter(obj => obj.id !== dateexcluded.id);   

      let newDate: adhocDates = {
        id: dateexcluded.id,
        disabled: dateexcluded.disabled,
        startdatetime: dateexcluded.startdatetime,
        enddatetime: dateexcluded.enddatetime,
        nooftickets: dateexcluded.nooftickets,
        noofticketssold: dateexcluded.noofticketssold,
        eventtickettypes : dateexcluded.eventtickettypes,
        cancelledsubject: dateexcluded.cancelledsubject,
        cancelledcontent: dateexcluded.cancelledcontent
      }

      this.EventObj.eventdates.push(newDate);

      this.EventObj.eventdates.sort((a, b) => (new Date(a.startdatetime) > new Date(b.startdatetime)) ? 1 : -1);

    }
    
    AddToResources(resource){
      if(this.EventObj.resources == null)
        this.EventObj.resources = [];

        if(resource.isresourcecontainer)
        {
          let newRes: cEventResource = {
            id: uuidv4(),
            resourceid: resource.resourceId,
            orderindex: resource.orderindex,
            disabled: false,
            resourceindex: 0,
            name: resource.name,
            minutesbefore: 0,
            minutesafter: 0,
            colour: resource.colour,
            admissions: null,
            tags: resource.tags,
          }
  
          this.EventObj.resources.push(newRes);
          newRes = null;
        }
        else
        {
          let newRes: cEventResource = {

            id: uuidv4(),
            resourceid: resource.resourceId,
            orderindex: resource.orderindex,
            disabled: false,
            resourceindex: 0,
            name: resource.name,
            minutesbefore:0,
            minutesafter: 0,
            colour: resource.colour,
            admissions: resource.admissions,
            tags: resource.tags,
          }
  
          this.EventObj.resources.push(newRes);
          newRes = null;
        }

    }

    AddToAdmissions(admission){
      if(this.EventObj.admissions == null)
        this.EventObj.admissions = [];

      if(admission.max <= 0)
      {
        admission.max = 1;
      }

      let newAdm: cAdmission = {
        id: uuidv4(),
        name: admission.name,
        classid:null,
        bookingwizardtext: admission.bookingwizardtext,
        fixedPrice: admission.fixedPrice,
        participant: true,
        venueid: admission.venueid,
        admissionid: admission.admissionid,
        noofpeople: admission.noofpeople,
        price: admission.price,
        admissionClass: admission.admissionClass,
        min: admission.min,
        max: admission.max,
        index: admission.index,
        disabled: admission.disabled,
        mustinclude: admission.mustinclude,
        eventtickettype: admission.eventtickettype,
        counter: admission.counter,
        uselinkeddiscounts: admission.uselinkeddiscounts,
        linkedadmissions: admission.linkedadmissions,
        linkeddiscounts: admission.linkeddiscounts,
        uselinkedadmissions: admission.uselinkedadmissions,
        maxlinkeddiscounts: admission.maxlinkeddiscounts,
        maxlinkedadmissions:admission.maxlinkedadmissions,
        fixed: admission.fixed,
        onlysetmax: admission.onlysetmax
      }

      this.EventObj.admissions.push(newAdm);
    }

    removeFromAdmissions(admission){
      this.EventObj.admissions = this.EventObj.admissions.filter(obj => obj.id !== admission.id);
    }

    removeFromResources(resource){
      this.EventObj.resources = this.EventObj.resources.filter(obj => obj.id !== resource.id);
      //this.buildItinerary();
    }

    removeLinkedDiscountAdmission(adm)
    {
      this.EventObj.admissions.forEach(element => {
        if(element.linkeddiscounts != null)
        {
          element.linkeddiscounts = element.linkeddiscounts.filter(obj => obj.admissionid !== adm.admissionid);
        }
      });
    }

    addAdmissionToLinkedDiscount(adm){  
      if(this.CurrentSelectedAdmission != null)
      {
        if (adm.linkeddiscounts == null)
          adm.linkeddiscounts = [];

        let found: boolean = false;
        adm.linkeddiscounts.forEach(element => {
          if(element.admissionid == this.CurrentSelectedAdmission.admissionid)
            found = true;
        });

        if(!found)
        {
          let newLinkedAdm: any = {
            admissionname: this.CurrentSelectedAdmission.name,
            threshold: 1,
            percentagediscount: 100,
            admissionid: this.CurrentSelectedAdmission.admissionid
          }
          adm.linkeddiscounts.push(newLinkedAdm);
        }
        else
        {
          this.messageService.add({severity:'error', summary:'Error', detail:'Admission already linked'});
        }
      }
      else
      {
        this.messageService.add({severity:'error', summary:'Error', detail:'You must select an admission to link to'});
      }
    }

    
    removeLinkedAdmission(adm)
    {
      this.EventObj.admissions.forEach(element => {
        if(element.linkedadmissions != null)
        {
          element.linkedadmissions = element.linkedadmissions.filter(obj => obj.admissionid !== adm.admissionid);
        }
      });
    }

    addAdmissionToLinked(adm){  
      if(this.CurrentSelectedLinkedAdmission != null)
      {
        if (adm.linkedadmissions == null)
          adm.linkedadmissions = [];

        let found: boolean = false;
        adm.linkedadmissions.forEach(element => {
          if(element.admissionid == this.CurrentSelectedLinkedAdmission.admissionid)
            found = true;
        });

        if(!found)
        {
          let newLinkedAdm: any = {
            admissionname: this.CurrentSelectedLinkedAdmission.name,
            threshold: 1,
            percentagediscount: 100,
            admissionid: this.CurrentSelectedLinkedAdmission.admissionid
          }
          adm.linkedadmissions.push(newLinkedAdm);
        }
        else
        {
          this.messageService.add({severity:'error', summary:'Error', detail:'Admission already linked'});
        }
      }
      else
      {
        this.messageService.add({severity:'error', summary:'Error', detail:'You must select an admission to link to'});
      }
    }

    
    updateData() {

      if (this.DoValidCheck())
      {
        let AllOK: boolean = true;

        //this.EventObj.discountstart = this.tempDiscountStartDate.getFullYear() + "-" +  ('00' + Number(this.tempDiscountStartDate.getMonth()) + 1).slice(-2) + "-" +  ('00' + this.tempDiscountStartDate.getDate()).slice(-2) + "T00:00:00.000Z";
        //this.EventObj.discountend = this.tempDiscountEndDate.getFullYear() + "-" +  ('00' + Number(this.tempDiscountEndDate.getMonth()) + 1).slice(-2) + "-" +  ('00' + this.tempDiscountEndDate.getDate()).slice(-2)+ "T23:59:59.999Z";
        this.EventObj.discountstart = this.stringifyDate(this.tempDiscountStartDate, false);
        this.EventObj.discountend = this.stringifyDate(this.tempDiscountEndDate, true);

        if(AllOK)
        {
          this.EventObj.bookingscannotbemadewithinxminutes = (this.bookingCannotBeMadeWithinXMinutesD * 1440) + (this.bookingCannotBeMadeWithinXMinutesH * 60) + this.bookingCannotBeMadeWithinXMinutesM;
          this.EventObj.bookingscannotbemadeafterxminutes = this.bookingscannotbemadeafterxDays * 1440;

          this.EventObj.admissions.forEach(adm => {
            if(!adm.uselinkedadmissions)
            {
              adm.linkedadmissions = null;
            }

            if(!adm.uselinkeddiscounts)
              {
                adm.linkeddiscounts = null;
              }
          });

          if (this.id == "0") {

            this.EventObj.name = this.EventObj.name.trim();
            this.EventObj.description = this.EventObj.description.trim();

            this.http.post<eventObj>(bmxurl + "/event", this.EventObj).subscribe(response => {
              this.httpResponse = response
              if (this.httpResponse.succeeded) {
                this.messageService.add({ severity: 'success', summary: 'Saved', detail: 'Event successfully created' });
                this.router.navigateByUrl("/event");
              }
              else
              {
                this.messageService.add({ severity: 'error', summary: 'Error Occurred', detail: this.httpResponse.messages[0] });
              }
            }, error => {
              this.messageService.add({ severity: 'error', summary: 'Not Saved', detail: error.messages });
            })
          }
          else
          {
            this.EventObj.disabled = false;
            this.EventObj.name = this.EventObj.name.trim();
            this.EventObj.description = this.EventObj.description.trim();

            this.http.put<eventObj>(bmxurl + "/event", this.EventObj).subscribe(response => {
              this.httpResponse = response
              if (this.httpResponse.succeeded) {
                this.messageService.add({ severity: 'success', summary: 'Saved', detail: 'Event successfully updated' });
                this.router.navigateByUrl("/event");
              }
              else
              {
                this.messageService.add({ severity: 'error', summary: 'Error Occurred', detail: this.httpResponse.messages[0] });
              }
            }, error => {
              this.messageService.add({ severity: 'error', summary: 'Not Saved', detail: error.messages });
            })
          }
        }
      }
    }

    delay(ms: number) {
    return new Promise( resolve => setTimeout(resolve, ms) );
  }

    convertTime(mins: number)
    {
        var hours = Math.floor(mins / 60);          
        var minutes = mins % 60;
      
        return hours.toString().padStart(2, '0') + ":" + minutes.toString().padStart(2, '0');
    }

    NextRes(){  
      this.activeIndex = 3;
    }

    NextAds(){
      this.activeIndex = 1;
    }

    NextDates(){
      this.activeIndex = 2;
    }

    removeImage(){
      this.FileChosen = null;
      this.EventObj.imageurl = null;
    }

    DoValidCheck()
    {
      let AllValid = true;

      if(this.EventObj.name == null || this.EventObj.name == "")
      {
        AllValid = false;
        this.eventNameValid = false;
        if(this.activeIndex !=0)
          this.messageService.add({ severity: 'error', summary: 'Name Required', detail: 'You must provide a name for your Event, please return to the first tab and correct this.' });
      }
      else
      {
        this.eventNameValid = true;
      }

      if(this.EventObj.description == null || this.EventObj.description == "")
      {
        AllValid = false;
        this.eventDescValid = false;
        if(this.activeIndex !=0)
          this.messageService.add({ severity: 'error', summary: 'Description required', detail: 'You must provide a description for your Event, please return to the first tab and correct this.'});
      }
      else
      {
        this.eventDescValid = true;
      }

      if(this.EventObj.admissions == null || this.EventObj.admissions.length == 0)
      {
        AllValid = false;
        this.eventDescValid = false;
        if(this.activeIndex !=0)
          this.messageService.add({ severity: 'error', summary: 'Admissions required', detail: 'You must provide at least one admission'});
      }

      if(this.EventObj.resources == null || this.EventObj.resources.length == 0)
      {
        AllValid = false;
        this.eventDescValid = false;
        if(this.activeIndex !=0)
          this.messageService.add({ severity: 'error', summary: 'Resources required', detail: 'You must provide at least one resource'});
      }

      if(this.EventObj.confirmation == null)
      {
        AllValid = false;
        this.eventConfirmationValid = true;
        
        if(this.activeIndex !=0)
          this.messageService.add({ severity: 'error', summary: 'Resources required', detail: 'You must provide a confirmation'});
      }
      else
      {
        this.eventConfirmationValid = false;
      }

      if(this.EventObj.publicdepositpolicy == null)
      {
        AllValid = false;
        this.eventPublicDepositValid = true;
      
        if(this.activeIndex !=0)
          this.messageService.add({ severity: 'error', summary: 'Resources required', detail: 'You must provide a public deposit policy'});
      }
      else
      {
        this.eventPublicDepositValid = false;
      }

      if(this.EventObj.venuedepositpolicy == null)
      {
        AllValid = false;
        this.eventVenueDepositValid = true;
        
        if(this.activeIndex !=0)
          this.messageService.add({ severity: 'error', summary: 'Resources required', detail: 'You must provide a venue deposit policy'});
      }
      else
      {
        this.eventVenueDepositValid = false;
      }

      if(this.EventObj.eventdates == null)
      {
        AllValid = false;
        this.eventVenueDepositValid = true;
        
        if(this.activeIndex !=0)
          this.messageService.add({ severity: 'error', summary: 'Dates required', detail: 'You must provide at least one date'});
      }
      else
      {
        this.eventVenueDepositValid = false;
      }
     

      if(this.EventObj.eventdates == null || this.EventObj.eventdates.length == 0)
      {    
        AllValid = false;
        this.messageService.add({ severity: 'error', summary: 'Event Dates Required', detail: 'You must choose some event dates.'});
        this.activeIndex = 2;
      }

      if(this.EventObj.admissions != null && this.TicketLimits)
      {
        this.EventObj.admissions.forEach(element => {

          if(element.eventtickettype == null && element.participant)
          {
            AllValid = false;
            this.eventeventtickettypeValid = true;
            this.messageService.add({ severity: 'error', summary: 'Ticket type required', detail: 'You must select a ticket type for each ticket.'});
          }
          else
          {
            this.eventeventtickettypeValid = false;
          }

        });
      }
      else
      {
        this.eventVenueDepositValid = false;
      }

      return AllValid;
    }
}
