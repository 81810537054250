import { Component, ElementRef, OnInit, QueryList, ViewChildren } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Resource, Package } from '../../PackageClasses';
import { AppComponent } from '../app.component';
import { packagerule_returndata } from 'src/types/packagerule_returndata'; 
import { bmxurl, getAccess } from 'src/globals';
import { NgModule } from '@angular/core';
import { NgModel } from '@angular/forms';
import { Router, RouterLink } from '@angular/router';
import { packagerule } from 'src/types/packagerule';
import { MessageService } from 'primeng/api';
import { Checkbox } from 'primeng/checkbox';
import { users_returndata } from 'src/types/user_returndata';



@Component({
  selector: 'app-packagerule',
  templateUrl: './packagerule.component.html',
  styleUrls: ['./packagerule.component.css']
})
export class PackageruleComponent implements OnInit {
  ListPackageRules: any;
  bmxurl: string;
  VenueID: string = localStorage.getItem("SmartVenueID")!;
  CreateUpdateResponse: any;
  DisabledPackagerule: any;
  checked: boolean = true;
  isDisabled: boolean = true;
  selectedItems: any;

  AccessAllowed: boolean = false;
  CompletedLoading: boolean = false;
  httpResponse: any;

  constructor(
    private http: HttpClient, private appComp: AppComponent, private router: Router, private messageService: MessageService) { 

    }

  ngOnInit(): void {

    localStorage.removeItem("screenMemoryBookingss");
    localStorage.removeItem("screenMemoryEvents");
    
    let userid = localStorage.getItem("SmartUserID");

    this.http.get(bmxurl + "User/" + userid).subscribe(response => {
      this.httpResponse =  (response as users_returndata)
      if(this.httpResponse.succeeded)
      {
        let user = this.httpResponse.data;

        if(getAccess("PACKAGERULES", user)){
          this.AccessAllowed = true;
          this.loadgrid(false);
        }

        this.CompletedLoading = true;
        
      }
    }, error => {
      this.messageService.add({severity:'error', summary:'Error', detail:'Error while getting User, error:' + error.messages[0]});
    });


  }

  toggleDisabled(){
    this.loadgrid(!this.isDisabled);
  }

  disableData(PackageRuleID)
  {
    this.http.delete(bmxurl + "PackageType/" + this.VenueID + "/" + PackageRuleID).subscribe(response => {
      this.CreateUpdateResponse = response
      if(this.CreateUpdateResponse.succeeded = true)
      {
        this.messageService.add({severity:'success', summary:'Disabled', detail:'Restriction successfully disabled'});
        this.router.navigateByUrl("/packagerule");

        this.loadgrid(false);
      }
    }, error => {
      this.messageService.add({severity:'error', summary:'Not Disabled', detail: 'An error occurred disabling this Restriction'});
    })
  }
  
  loadgrid(showdisabled: boolean)
  {
    this.http.get(bmxurl + "PackageTypeList/" + this.VenueID + "/" + showdisabled).subscribe(response => {
      this.ListPackageRules = (response as packagerule_returndata);
    }, error => {
    });
  }

  editData(EditData)
  {
    let PackageRuleID = EditData;
    this.router.navigateByUrl("/packageruleedit/" + PackageRuleID);
  }

  disableMulti()
  {
    this.selectedItems.forEach(element => {

    this.http.delete(bmxurl + "PackageType/" + this.VenueID + "/" + element.packageruleID).subscribe(response => {
      this.CreateUpdateResponse = response
      if(this.CreateUpdateResponse.succeeded = true)
      {
        this.loadgrid(false);
      } 
    }, error => {
    
    })
    });
    
  }

  
  }