import { Component, ElementRef, OnInit, QueryList, ViewChildren } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Resource, Package } from '../../PackageClasses';
import { AppComponent } from '../app.component';
import { tag_returndata } from 'src/types/tag_returndata';
import { bmxurl, getAccess } from 'src/globals';
import { NgModule } from '@angular/core';
import { NgModel } from '@angular/forms';
import { Router, RouterLink } from '@angular/router';
import { tag } from 'src/types/tag';
import { MessageService } from 'primeng/api';
import { Checkbox } from 'primeng/checkbox';
import { users_returndata } from 'src/types/user_returndata';

@Component({
  selector: 'app-Tag',
  templateUrl: './tag.component.html',
  styleUrls: ['./tag.component.css']
})
export class tagComponent implements OnInit {
  ListTags: any;
  bmxurl: string;
  VenueID: string = localStorage.getItem("SmartVenueID")!;
  CreateUpdateResponse: any;
  Tag: any;
  DisabledTag: any;
  checked: boolean = true;
  isDisabled: boolean = true;
  selectedItems: any;

  httpResponse: any;
  AccessAllowed: boolean = false;
  CompletedLoading: boolean = false;

  constructor(
    private http: HttpClient, private appComp: AppComponent, private router: Router, private messageService: MessageService) {
      //this.isRowSelectable = this.isRowSelectable.bind(this);


    }

  ngOnInit(): void {

    localStorage.removeItem("screenMemoryBookingss");
    localStorage.removeItem("screenMemoryEvents");
    
    let userid = localStorage.getItem("SmartUserID");

    this.http.get(bmxurl + "User/" + userid).subscribe(response => {
      this.httpResponse =  (response as users_returndata)
      if(this.httpResponse.succeeded)
      {
        let user = this.httpResponse.data;

        if(getAccess("TAGS", user)){
          this.AccessAllowed = true;
          this.loadgrid(false);
        }

        this.CompletedLoading = true;

      }
    }, error => {
      this.messageService.add({severity:'error', summary:'Error', detail:'Error while getting User, error:' + error.messages[0]});
    });


  }

  toggleDisabled(){
    this.loadgrid(!this.isDisabled);
  }

  disableData(TagID)
  {
    this.http.delete(bmxurl + "Tag/" + this.VenueID + "/" + TagID).subscribe(response => {
      this.CreateUpdateResponse = response
      if(this.CreateUpdateResponse.succeeded = true)
      {
        this.messageService.add({severity:'success', summary:'Disabled', detail:'Tag successfully disabled'});
        this.router.navigateByUrl("/Tag");

        this.loadgrid(false);
      }
    }, error => {
      this.messageService.add({severity:'error', summary:'Not Disabled', detail: 'An error occurred disabling this Tag'});
    })
  }

  loadgrid(showdisabled: boolean)
  {
    this.http.get(bmxurl + "taglist/" + this.VenueID + "/" + showdisabled).subscribe(response => {
      this.ListTags = (response as tag_returndata);

      if ((response as tag_returndata).messages.length > 0)
        {
          (response as tag_returndata).messages.forEach(error => {
            this.messageService.add({severity:'warn', summary:'Response Message', detail: error});
          });
        }

    }, error => {
    });
  }

  editData(EditData)
  {
    let TagID = EditData;
    this.router.navigateByUrl("/tagedit/" + TagID);
  }

  disableMulti()
  {
    this.selectedItems.forEach(element => {
      if(element.system == null || element.system == false)
      {
        this.http.delete(bmxurl + "Tag/" + this.VenueID + "/" + element.tagId).subscribe(response => {
          this.CreateUpdateResponse = response
          if(this.CreateUpdateResponse.succeeded = true)
          {
            this.loadgrid(false);
          }
        }, error => {

        })
      }
    });

  }


  }
