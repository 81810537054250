<div *ngIf="!AccessAllowed && CompletedLoading">
    <h3 style="border: 2px solid;border-radius: 6px;position: absolute;top: 50%;left: 50%;transform: translate(-50%, -50%);padding: 10px;">You don't have access to this function</h3>
</div>

<div *ngIf="ListVouchers && AccessAllowed" class="p-3 flex flex-column flex-auto">
    <div class="text-900 font-medium text-xl mb-3"><h3>Vouchers
    </h3></div>
    <p class="m-0 mb-4 p-0 text-600 line-height-3 mr-3">
        <span class="pi pi-info-circle" style="margin-right:2px;"></span>
    </p>
    <div style="text-align: right; margin-bottom: 20px;">

        <p-toggleButton class="p-button-sm p-button-danger p-component" [(ngModel)]="isDisabled" onLabel="Show Disabled" offLabel="Hide Disabled" offIcon="pi pi-times" onIcon="pi pi-check" [style]="{'margin-right': '10px', 'color' : 'white', 'background-color': '#EF4444', 'height': '50px'}" (click)="toggleDisabled()">
        </p-toggleButton>

        <span class="p-input-icon-left ml-auto" style="margin-right:20px; "> 
            <i class="pi pi-search"></i>
            <input pInputText type="text" (input)="voucherTable.filterGlobal($event.target.value, 'contains')" style="height: 50px;padding-left:33px;" />
        </span>
        
        <button pButton type="button" style="height: 50px;" class="p-button-sm p-element p-ripple p-button-danger p-button p-component" (click)="disableMulti()">
        <span class="pi pi-trash" style="margin-right:8px;"></span>
        <span i18>Disable</span>
        </button>
    </div>
    <div class="surface-card p-4 shadow-2 border-round">
    <p-table #voucherTable
    [value]="ListVouchers.data" [sort]="created" [sortOrder]="-1" [paginator]="true" [rows]="10"
    [showCurrentPageReport]="true" responsiveLayout="scroll"
    currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
    [rowsPerPageOptions]="[10,25,50]"
    [globalFilterFields]="['vouchercode','customername']"
    [(selection)]="selectedItems"
    responsiveLayout="scroll"
    datakey="id"
    [selectionPageOnly]="true">
        <ng-template pTemplate="header">
            <tr>
                <th width="1%">
                    <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                </th>
                <th pSortableColumn="vouchercode" width="15%">Code
                    <p-sortIcon [field]="vouchercode"></p-sortIcon>
                </th>
                <th pSortableColumn="created" width="15%">Purchase Date
                    <p-sortIcon [field]="created"></p-sortIcon>
                </th>
                <th pSortableColumn="expiry" width="10%">Expiry Date
                    <p-sortIcon [field]="expiry"></p-sortIcon>
                </th>
                <th pSortableColumn="customername" width="29%">Customer Name
                    <p-sortIcon [field]="customername"></p-sortIcon>
                </th>
                <th pSortableColumn="balance" width="10%">Balance
                    <p-sortIcon [field]="balance"></p-sortIcon>
                </th>
                <th width="5%"></th>
                <th width="5%"></th>
                <th width="5%"></th>
                <th width="10%"></th>
            </tr>
        </ng-template>

        <ng-template pTemplate="body" let-data>
            <tr [ngStyle]="{'background-color': (data.disabled==true) ? '#fee' : '#ffffff'}">
                <td>
                    <p-tableCheckbox [value]="data"></p-tableCheckbox>
                </td>
                <td>{{data.vouchercode}}</td>
                <td>{{data.created | date:'dd/MM/yy'}}</td>
                <td>{{data.expiry | date:'dd/MM/yy'}}</td>
                <td>{{data.customername}}</td>
                <td>{{data.balance | currency: CurrencySymbol}}</td>
                <td><button pButton type="button" class="p-button-sm p-element p-ripple p-button-success p-button p-component" style="background-color: yellow o !important;" (click)="editData(data.voucherid);" i18>
                    <span class="pi pi-pencil" style="margin-right:8px;"></span>
                    <span i18n>Edit</span>
                </button></td>
                <td><button pButton type="button" class="p-button-sm p-element p-ripple p-button-danger p-button p-component" style="margin-left:-20px;"  (click)="disableData(data.voucherid)" i18>
                    <span class="pi pi-trash" style="margin-right:8px;"></span>
                    <span i18n>Disable</span>
                </button></td>
                <td><button pButton type="button" class="p-button-sm p-element p-ripple p-button-warning p-button p-component" style="margin-left:-20px;"  (click)="sendEmailConfirmationById(data.voucherid)" i18>
                    <span class="pi pi-send" style="margin-right:8px;"></span>
                    <span i18n>Resend</span>
                </button></td>
                <td><button pButton type="button" class="p-button-sm p-element p-ripple p-button-info p-button p-component" style="margin-left:-20px; width:160px;"  (click)="showHistory(data.voucherid)" i18>
                    <span class="pi pi-history" style="margin-right:8px;"></span>
                    <span i18n>Usage History</span>
                </button></td>
            </tr>
        </ng-template>
    </p-table>
    </div>
</div>
