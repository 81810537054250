<div *ngIf="!AccessAllowed && CompletedLoading">
    <h3 style="border: 2px solid;border-radius: 6px;position: absolute;top: 50%;left: 50%;transform: translate(-50%, -50%);padding: 10px;">You don't have access to this function</h3>
</div>

<div *ngIf="AccessAllowed && pageReady" class="p-3 flex flex-column flex-auto">
    <div>
        <div class="text-900 font-medium text-xl mb-3"><h3>Task Types
        </h3></div>
        <div class="surface-card p-4 shadow-2 border-round">
            <div class="grid formgrid p-fluid">

                <div class="field mb-4 col-12">
                    <label for="classname" class="font-medium">What would you like to do?</label>
                    <p-selectButton [options]="taskOptions" [(ngModel)]="TaskType.tasktype" style="height:50px;">
                        <ng-template let-item pTemplate>
                            <span [class]="item.icon" style="margin-right:10px"></span><br>
                            <span>{{item.label}}</span>
                        </ng-template>
                    </p-selectButton>
                </div>

                <div class="field mb-4 col-12">
                    <label for="classname" class="font-medium">What would you like to call this task?</label>
                    <input [(ngModel)]="TaskType.name" id="classname" type="text" [ngStyle]="{'border-color': (taskNameValid==false) ? '#FF0000':''}"  pInputText>
                </div>
                <div *ngIf="!taskNameValid" class="text-danger" Style="margin-left:10px; margin-top: -23px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>You must enter a Task Type name.</div>

                <div class="field mb-4 col-12">
                    <label for="classname" class="font-medium">You can add a description here, this may help you remember what the task does in the future.</label>
                    <input [(ngModel)]="TaskType.desc" id="classname" type="text" [ngStyle]="{'border-color': (taskDescValid==false) ? '#FF0000':''}"  pInputText>
                </div>

                <div *ngIf="TaskType.tasktype == 'REMIND' || TaskType.tasktype == 'COLLECT'" class="field mb-4 col-12">
                    <label for="classname" class="font-medium">How many days before the booking would you like this sent?</label>
                    <p-inputNumber id="length" [style]="{'width': '300px'}"  [(ngModel)]="TaskType.noofdays" [showButtons]="true" max="365" min="1" buttonLayout="horizontal" spinnerMode="horizontal"></p-inputNumber>
                </div>

                <div *ngIf="TaskType.tasktype == 'FEEDBACK' || TaskType.tasktype == 'THANK'" class="field mb-4 col-12">
                    <label for="classname" class="font-medium">How many days after the booking would you like this sent?</label>
                    <p-inputNumber id="length" [style]="{'width': '300px'}"  [(ngModel)]="TaskType.noofdays" [showButtons]="true" max="365" min="1" buttonLayout="horizontal" spinnerMode="horizontal"></p-inputNumber>
                </div>

                <div class="field mb-4 col-12">
                    <label for="classname" class="font-medium">What Email Template would you like to use for this task?</label><br>
                    <p-dropdown [options]="EmailTemplates"  placeholder="Select a template" [showClear]="true" [(ngModel)]="TaskType.emailtemplate" optionLabel="name"></p-dropdown>
                </div>
                <div *ngIf="!taskEmailTemplateValid" class="text-danger" Style="margin-left:10px; margin-top: -23px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>You must select an Email for this task.<br></div>
                <div style="width:100%; clear:both;"></div>
                <div *ngIf="TaskType.tasktype == 'COLLECT'" class="field mb-4 col-12">
                    <label for="classname" class="font-medium">If Payment is not made, how regularly would you like to send this reminder</label>
                    <p-dropdown [options]="taskfrequency" [(ngModel)]="TaskType.frequency" optionLabel="label" optionValue="value" placeholder="Select a Frequency"></p-dropdown>
                </div>

                <div *ngIf="TaskType.tasktype == 'THANK'">
                    <div class="field mb-4 col-12">
                        <label for="discountType" class="font-medium">Would you like to give this customer a discount on their next visit?</label>
                        <p-selectButton id="discountType" [style]="{'width': '300px'}" [options]="stateOptionsDiscount" [(ngModel)]="TaskType.discounttype" ></p-selectButton>
                    </div>

                    <div *ngIf="TaskType.discounttype == 'FIXED'" class="field mb-4 col-12">
                        <label for="autogeneratenumber" class="font-medium">Discount Amount</label>
                        <p-inputNumber id="autogeneratenumber" [style]="{'width': '300px'}" mode="currency" currency="{{CurrencySymbol}}" [(ngModel)]="TaskType.discountamount == null ? 0: TaskType.discountamount" [showButtons]="true" max="10000" min="0" buttonLayout="horizontal" spinnerMode="horizontal"></p-inputNumber>
                    </div>

                    <div *ngIf="TaskType.discounttype == 'PERCENTAGE'" class="field mb-4 col-12">
                        <label for="autogeneratenumber" class="font-medium">Discount Amount</label>
                        <p-inputNumber id="autogeneratenumber" [style]="{'width': '300px'}" suffix="%" [(ngModel)]="TaskType.discountamount == null ? 0: TaskType.discountamount" [showButtons]="true" max="100" min="0" buttonLayout="horizontal" spinnerMode="horizontal"></p-inputNumber>
                    </div>

                    <div class="field mb-4 col-12">
                        <label for="ddlPackage" class="font-medium">Number of months that the vouchers is valid for from receipt</label>
                        <p-inputNumber  [style]="{'width': '300px'}" [(ngModel)]="TaskType.voucherexpiry" [showButtons]="true" max="12" min="1" buttonLayout="horizontal" spinnerMode="horizontal"></p-inputNumber>
                    </div>
                </div>

                <div *ngIf="needSelectedPackages" class="text-danger" Style="margin-left:10px; margin-top: -23px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>You must select some packages.<br></div>

                <div style="clear:both;width:100%"></div>
                <div><p-selectButton id="allpackages" [style]="{'width': '450px','margin-bottom' : '15px'}" [options]="stateOptionsPackages" [(ngModel)]="allPackages"></p-selectButton>
                </div>
                <div style="clear:both;width:100%"></div>

                <div *ngIf="allPackages == false" style="width:100%">
                    <div style="margin-bottom:10px;">Please add the packages that you would like this task to be assigned to.</div>
                    <div class="surface-card p-4 shadow-2 border-round" style="width:100%;">

                        <div class="grid formgrid p-fluid" style="width:100%">
                        
                        <div class="col-12 md:col-6 drag-column">
                        <label class="font-medium">Available Packages</label>
            
                        <p-table [value]="packages">
                            <ng-template pTemplate="header">
                                <tr>
                                    <th style="width:90%">Name</th>
                                    <th style="width:10%">&nbsp;</th>
                                </tr>
                            </ng-template>
                                <ng-template pTemplate="body" let-package>
                                <tr pDraggable="packages" (onDragStart)="dragStart($event, package)" (onDragEnd)="dragEnd()">
                                    <td>{{package.name}}</td>
                                    <td>
                                        <button pButton pRipple type="button" [style]="{'margin-bottom': '-6px', 'margin-top': '-4px !important'}" class="p-button-sm p-element p-button-success w-auto mt-3" (click)="AddToList(package)">
                                        <span class="pi pi-angle-right"></span>
                                        </button>
                                    </td>
                                </tr>
                                </ng-template>
            
                            </p-table>
            
            
                            <div *ngIf="packages == null" style="padding: 15px;">
                            <i>No packages available, add some <a href="packageedit/0">here</a></i>
            
                            </div>
                        </div>
            
                        <div class="col-12 md:col-6 drop-column" pDroppable="packages" (onDrop)="drop()">
                        <label class="font-medium">Selected Packages</label>
            
                        <p-table [value]="selectedPackages">
                            <ng-template  pTemplate="header" let-columns>
                                <tr>
                                    <th style="width:90%">Name</th>
                                    <th style="width:10%">&nbsp;</th>
                                </tr>
                            </ng-template>
                                <ng-template pTemplate="body" let-package let-columns="columns" let-index="rowIndex">
                                <tr>
                                    <td>{{package.name}}</td>
                                    <td>
                                        <button pButton pRipple type="button" [style]="{'margin-bottom': '-6px', 'margin-top': '-4px !important'}" class="p-button-sm p-element p-button-danger w-auto mt-3" (click)="RemoveFromList(package)">
                                        <span class="pi pi-angle-left"></span>
                                        </button>
                                    </td>
                                </tr>
                                </ng-template>
            
                            </p-table>
                        </div>
                        </div>
                    </div>
                </div>

                <div class="col-12">
                    <button pButton pRipple type="button" class="p-button p-element p-button-success w-auto mt-3" (click)="updateData();">
                    <span class="pi pi-pencil" style="margin-right:8px;"></span>
                    Save Changes</button>
                    &nbsp;
                    <button pButton pRipple type="button" class="p-button p-element p-button-danger w-auto mt-3" (click)="returnToList()">
                    <span class="pi pi-times" style="margin-right:8px;"></span>
                    Cancel</button>
                </div>
            </div>
        </div>
    </div>
</div>