<div *ngIf="!AccessAllowed && CompletedLoading">
    <h3 style="border: 2px solid;border-radius: 6px;position: absolute;top: 50%;left: 50%;transform: translate(-50%, -50%);padding: 10px;">You don't have access to this function</h3>
  </div>

<div *ngIf="TimeSet && AccessAllowed" class="p-3 flex flex-column flex-auto">
    <div>
        <div class="text-900 font-medium text-xl mb-3"><h3>Time Set
        </h3></div>
        <p class="m-0 mb-4 p-0 text-600 line-height-3 mr-3">
            <span class="pi pi-info-circle" style="margin-right:2px;"></span>
             [NEED TEXT HERE]
        </p>
        <div class="surface-card p-4 shadow-2 border-round">
            <div class="grid formgrid p-fluid">
                <div class="field mb-4 col-12">
                    <label for="classname" class="font-medium">Time Set Name</label><a href="#"><span style="margin-left:5px;" class="pi pi-question-circle"></span></a>
                    <input [(ngModel)]="TimeSet.name" id="classname" type="text" [disabled]="true" pInputText>
                </div>
                <div *ngIf="!timesetNameValid" class="text-danger" Style="margin-left:10px; margin-top: -23px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>You must enter an Time Set name.</div>

                <div class="field mb-4 col-12">
                    <label for="isDefault" class="font-medium">Default</label>
                    <p-selectButton [style]="{'width': '150px'}" [options]="stateOptions" [(ngModel)]="TimeSet.isdefault" [disabled]="true"></p-selectButton>
                </div>

                <div style="clear:both;"></div>

                <div *ngIf="!TimeSet.isdefault" class="surface-card p-4 shadow-2 border-round" style="width: 100%;">
                <div style="clear:both;"></div>
                <p-table #HolidayOpeningsTable 
                [value]="TimeSet.holidayopenings"
                responsiveLayout="scroll"
                datakey="id"
                [selectionPageOnly]="true"
                [reorderableColumns]="true">
                    <ng-template pTemplate="header">
                        <tr>
                            <th width="45%">Start Date</th>
                            <th width="45%">End Date</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-data let-index="rowIndex">
                        <tr [ngStyle]="{'background-color': (data.disabled==true) ? '#fee' : '#ffffff'}">
                            <td>{{data.dateFrom | date : 'dd/MM/yyyy'}}</td>
                            <td>{{data.dateTo | date : 'dd/MM/yyyy'}}</td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>

                <div class="col-12">
                    <button pButton pRipple type="button" class="p-button p-element p-button-success w-auto mt-3" (click)="returnToList();">
                    <span class="pi pi-check" style="margin-right:8px;"></span>
                    Return</button>
                </div>
            </div>
        </div>
    </div>
</div>