//Global Variables
import { environment } from 'src/environments/environment';
import { user } from './types/users';
import { el } from '@fullcalendar/core/internal-common';

export const bmxurl: string = environment.apitarget;

//global function
export function getAccess(AccessCode: string, user : user) {
    let access: string = "";
    let found: boolean = false;
    if (user) {
        user.accesscontrol.access.forEach(element => {
            if(element.code == AccessCode) {
                if(element.preventionmode == "ACCESS")
                found =  true;
            }
        });

        if(found) {
            found = false;
            return true;
        }
        else {
            return false;
        }
    }
}

export function ConvertDateFromDB(date: Date): Date {
    let hoursOut: number = Number(new Date(date).getTimezoneOffset());
    return new Date(new Date(date).setHours(new Date(date).getHours() + (hoursOut / 60)));
  }

export function ConvertDateToDB(date: Date): Date {
    let hoursOut: number = Number(new Date(date).getTimezoneOffset());
    return new Date(new Date(date).setHours(new Date(date).getHours() - (hoursOut / 60)));
  }

export function getAdjustedDateWithTimeZone(indate: Date, timeZone: string)
{
    let date = new Date(indate);
    let jsondate = date.toJSON();
    let newjsondate = jsondate + timeZone;
    let newdate = new Date(newjsondate);

    //take into account daylinght savings
    let offset = newdate.getTimezoneOffset();
    newdate.setMinutes(newdate.getMinutes() + offset);
    
    return newdate;
}


