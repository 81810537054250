import { Component, OnInit } from '@angular/core';
import { GlobalService } from '../_services/global.service';
import { Router } from '@angular/router';
import { BusyService } from '../_services/busy.service';
import { HttpClient } from '@angular/common/http';
import { MessageService } from 'primeng/api';
import { bmxurl, getAccess } from 'src/globals';
import { users_returndata } from 'src/types/user_returndata';
import { voucher_returndata } from 'src/types/voucher_returndata';

@Component({
  selector: 'app-voucherlist',
  templateUrl: './voucherlist.component.html',
  styleUrls: ['./voucherlist.component.css']
})

export class VoucherlistComponent implements OnInit {

  AccessAllowed: boolean = false;
  CompletedLoading: boolean = false;
  ListVouchers: any = [];
  httpResponse: any;
  VenueID: string = localStorage.getItem("SmartVenueID")!;
  CurrencySymbol: string = localStorage.getItem("Currency") == null ? "GBP" : localStorage.getItem("Currency")!;
  isDisabled: boolean = true;
  selectedItems: any;

  constructor(private http: HttpClient, 
    private router: Router, 
    private messageService: MessageService, 
    private busyService: BusyService,
    private globalService: GlobalService) {

    let userid = localStorage.getItem("SmartUserID");

    this.http.get(bmxurl + "User/" + userid).subscribe(response => {
      this.httpResponse =  (response as users_returndata)
      if(this.httpResponse.succeeded)
      {
        let user = this.httpResponse.data;

        if(getAccess("VOUCHERS", user)){
          this.AccessAllowed = true;
        }

        this.CompletedLoading = true;
      }
    }, error => {
      this.messageService.add({severity:'error', summary:'Error', detail:'Error while getting User, error:' + error.messages[0]});
    });
   }

  ngOnInit() {
    this.loadgrid(false);
  }

  toggleDisabled(){
    this.loadgrid(!this.isDisabled);
  }

  sendEmailConfirmationById(VoucherID: string){
    this.http.get<any>(bmxurl + "sendvoucher/" + this.VenueID + "/" + VoucherID).subscribe(response => {
      if(response.succeeded)
      {
        this.messageService.add({severity:'success', summary:'Success', detail:'Email Message sent.'})
      }
      else{
        this.messageService.add({severity:'error', summary:'Failure', detail: response.messages[0]});
      }
    }, error => {
      this.messageService.add({severity:'error', summary:'Failure', detail: 'An error occurred whilst trying to send the Email'});
    })
  }

  disableMulti()
  {
    this.selectedItems.forEach(element => {

    this.http.delete(bmxurl + "Voucher/" + this.VenueID + "/" + element.voucherid).subscribe(response => {
      this.httpResponse = response
      if(this.httpResponse.succeeded)
      {
        this.loadgrid(false);
      } 
      else{
        this.messageService.add({severity:'error', summary:'Not Saved', detail:'Error disabling Vouchers, error: ' + this.httpResponse.messages[0]});
      }
    }, error => {
      this.messageService.add({severity:'error', summary:'Not Saved', detail: 'Error disabling Vouchers, error: ' + error.messages});
    })
    });
    
  }


  disableData(id)
  {
    this.http.delete(bmxurl + "Voucher/" + this.VenueID + "/" + id).subscribe(response => {
      this.httpResponse = response
      if(this.httpResponse.succeeded == true)
      {
        this.messageService.add({severity:'success', summary:'Disabled', detail:'Voucher successfully disabled'});
        this.router.navigateByUrl("/voucherlist");

        this.loadgrid(false);
      }
    }, error => {
      this.messageService.add({severity:'error', summary:'Not Disabled', detail: 'An error occurred disabling this Class'});
    })
  }
  
  editData(id)
  {
    this.router.navigate(['/voucheredit', id]);
  }

  showHistory(id)
  {

  }

  loadgrid(showdisabled: boolean)
  {
    this.http.get(bmxurl + "VoucherList/" + this.VenueID + "/" + showdisabled).subscribe(response => {
      this.httpResponse = response
      if(this.httpResponse.succeeded)
      {
        this.ListVouchers = (response as voucher_returndata);

        if ((response as voucher_returndata).messages.length > 0)
        {
          (response as voucher_returndata).messages.forEach(error => {
            this.messageService.add({severity:'warn', summary:'Response Message', detail: error});
          });
        }
      }
      else
      {
        this.messageService.add({severity:'error', summary:'Not Saved', detail:'Error getting Vouchers, error: ' + this.httpResponse.messages[0]});
      }
    }, error => {
      this.messageService.add({severity:'error', summary:'Not Saved', detail: 'Error getting Vouchers, error: ' + error.messages});
    });
  }
}
