<div *ngIf="!AccessAllowed && CompletedLoading">
    <h3 style="border: 2px solid;border-radius: 6px;position: absolute;top: 50%;left: 50%;transform: translate(-50%, -50%);padding: 10px;">You don't have access to this function</h3>
  </div>

<div *ngIf="pageReady && AccessAllowed" class="p-3 flex flex-column flex-auto">
    <div>
        <div class="text-900 font-medium text-xl mb-3"><h3>Tag</h3></div>
        <p class="m-0 mb-4 p-0 text-600 line-height-3 mr-3">
            <span class="pi pi-info-circle" style="margin-right:2px;"></span>
            Give the tag a name that means something to you such as party, student offer, peak.
        </p>
        <div class="surface-card p-4 shadow-2 border-round">
            <div class="grid formgrid p-fluid">
              <div class="field mb-4 col-12">
                  <label for="tagname" class="font-medium">Tag Name</label>
                  <input [(ngModel)]="Tag.name" [ngStyle]="{'border-color': (tagNameValid==false) ? '#FF0000':''}" (keyup)="DoValidCheck()"  id="tagname" type="text" pInputText>
              </div>
              <div *ngIf="!tagNameValid" class="text-danger" Style="margin-left:10px; margin-top: -23px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>You must enter a Venue name.</div>
                  
              <div class="field mb-4 col-12">
                  <label for="tagcolour" class="font-medium">Colour Name</label><br>
                  <p-colorPicker [inline]="false" [style]="{'margin-right': '10px'}" id="tagcolour" [(ngModel)]="Tag.colour"></p-colorPicker>
                  <input style="width:200px;" [(ngModel)]="Tag.colour" id="hexcolour" type="text" pInputText>
                </div>
            </div>

              <div class="col-12">
                  <button pButton pRipple type="button" class="p-button p-element p-button-success w-auto mt-3" (click)="updateData();">
                  <span class="pi pi-pencil" style="margin-right:8px;"></span>
                  Save Changes</button>
                  &nbsp;
                  <button pButton pRipple type="button" class="p-button p-element p-button-danger w-auto mt-3" (click)="returnToList()">
                  <span class="pi pi-times" style="margin-right:8px;"></span>
                  Cancel</button>
              </div>
          </div>

    </div>
</div>
