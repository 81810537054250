import { Component, ElementRef, OnInit, QueryList, ViewChildren } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Resource, Package } from '../../PackageClasses';
import { AppComponent } from '../app.component';
import { class_returndata } from 'src/types/class_returndata'; 
import { bmxurl, getAccess } from 'src/globals';
import { NgModule } from '@angular/core';
import { NgModel } from '@angular/forms';
import { Router, RouterLink } from '@angular/router';
import { classes } from 'src/types/class';
import { MessageService } from 'primeng/api';
import { Checkbox } from 'primeng/checkbox';
import { confirmation_returndata } from 'src/types/confirmation_returndata';
import { cConfimration, confimration } from 'src/types/confirmations';
import { users_returndata } from 'src/types/user_returndata';
import { emailtemplate_returndata } from 'src/types/emailtemplate_returndata';
import { emailtemplate } from 'src/types/emailtemplate';

@Component({
  selector: 'app-emailtemplate',
  templateUrl: './emailtemplate.component.html',
  styleUrls: ['./emailtemplate.component.css']
})
export class EmailtemplateComponent implements OnInit {
  ListEmailTemplates: any =[];
  bmxurl: string;
  VenueID: string = localStorage.getItem("SmartVenueID")!;
  httpResponse: any;
  DisabledClass: any;
  checked: boolean = true;
  isDisabled: boolean = true;
  selectedItems: any;
  EmailTemplate: any;
  AccessAllowed: boolean = false;
  CompletedLoading: boolean = false;

  constructor(
    private http: HttpClient, private appComp: AppComponent, private router: Router, private messageService: MessageService) { 
      //this.isRowSelectable = this.isRowSelectable.bind(this);
      
    }

  ngOnInit(): void {

    localStorage.removeItem("screenMemoryBookingss");
    localStorage.removeItem("screenMemoryEvents");
    
    let userid = localStorage.getItem("SmartUserID");

    this.http.get(bmxurl + "User/" + userid).subscribe(response => {
      this.httpResponse =  (response as users_returndata)
      if(this.httpResponse.succeeded)
      {
        let user = this.httpResponse.data;

        if(getAccess("EMAILTEMPLATES", user)){
          this.AccessAllowed = true;
          this.loadgrid(false);
        }

        this.CompletedLoading = true;

      }
    }, error => {
      this.messageService.add({severity:'error', summary:'Error', detail:'Error while getting User, error:' + error.messages[0]});
    });

  }

  toggleDisabled(){
    this.loadgrid(!this.isDisabled);
  }

  disableData(EmailTemplateID)
  {
    this.http.delete(bmxurl + "/EmailTemplate/" + this.VenueID + "/" + EmailTemplateID).subscribe(response => {
      this.httpResponse = response
      if(this.httpResponse.succeeded == true)
      {
        this.messageService.add({severity:'success', summary:'Disabled', detail:'Email Template successfully disabled'});
        this.router.navigateByUrl("/emailtemplate");

        this.loadgrid(false);
      }
    }, error => {
      this.messageService.add({severity:'error', summary:'Not Disabled', detail: 'An error occurred disabling this Email Template'});
    })
  }
  
  loadgrid(showdisabled: boolean)
  {
    this.http.get(bmxurl + "/EmailTemplateList/" + this.VenueID + "/" + showdisabled).subscribe(response => {
      this.httpResponse = response
      if(this.httpResponse.succeeded)
      {
        this.ListEmailTemplates = (response as emailtemplate_returndata).data;
      }
      else
      {
        this.messageService.add({severity:'warn', summary:'Response Message', detail: this.httpResponse.messages[0]});
      }
    }, error => {
      this.messageService.add({severity:'error', summary:'Not Saved', detail: 'Error getting Email Templates, error: ' + error.messages});
    });
  }

  editData(EditData)
  {
    let ConfirmationID = EditData;
    this.router.navigateByUrl("/emailtemplateeditor/" + ConfirmationID);
  }

  copydata(id)
  {
      this.http.get(bmxurl + "EmailTemplate/" + this.VenueID + "/" + id).subscribe(response => {
        this.httpResponse = (response as class_returndata)
        if (this.httpResponse.succeeded)
        {
          this.EmailTemplate = {
            id: null,
            emailtemplateid: null,
            name: (this.httpResponse.data as emailtemplate).name,
            emailsubject: (this.httpResponse.data as emailtemplate).emailsubject,
            disabled: (this.httpResponse.data as emailtemplate).disabled,
            venueid:(this.httpResponse.data as emailtemplate).venueid,
            message: (this.httpResponse.data as emailtemplate).message,
            //showfreetextmessage: (this.httpResponse.data as emailtemplate).showfreetextmessage,
            paymentmessagetype: (this.httpResponse.data as emailtemplate).paymentmessagetype,
            paymentmessage:(this.httpResponse.data as emailtemplate).paymentmessage,
            showcustomerservices:(this.httpResponse.data as emailtemplate).showcustomerservices,
            showemail:Boolean((this.httpResponse.data as emailtemplate).showemail),
            emailtext:(this.httpResponse.data as emailtemplate).emailtext,
            showphone:Boolean((this.httpResponse.data as emailtemplate).showphone),
            phonetext:(this.httpResponse.data as emailtemplate).phonetext,
            showtandcslink: Boolean((this.httpResponse.data as emailtemplate).showtandcslink),
            showendtime: Boolean((this.httpResponse.data as emailtemplate).showendtime),
            originatingid: (this.httpResponse.data as emailtemplate).emailtemplateid
          };

            this.http.post<confimration>(bmxurl + "/EmailTemplate", this.EmailTemplate).subscribe(response => {
            this.httpResponse = response
            if (this.httpResponse.succeeded) {
              this.messageService.add({ severity: 'success', summary: 'Saved', detail: 'Email Template successfully copied' });
              this.editData(this.httpResponse.data.confirmationid)
            }
            else
            {
              this.messageService.add({ severity: 'error', summary: 'Error Copying Email Template', detail: this.httpResponse.messages[0] });
            }
          }, error => {
            this.messageService.add({ severity: 'error', summary: 'Error Copying Email Template', detail: error.messages });
          })
      
        }
        else
        {
          this.messageService.add({severity:'error', summary:'Error', detail:'Error while getting Email Template'});

        }
      }, error => {
        this.messageService.add({severity:'error', summary:'Error', detail:'Error while getting Email Template, error:' + error.messages[0]});

      });
  }

  disableMulti()
  {
    this.selectedItems.forEach(element => {

    this.http.delete(bmxurl + "EmailTemplate/" + this.VenueID + "/" + element.confirmationid).subscribe(response => {
      this.httpResponse = response
      if(this.httpResponse.succeeded)
      {
        this.loadgrid(false);
      } 
      else{
        this.messageService.add({severity:'error', summary:'Not Saved', detail:'Error disabling Email Templates, error: ' + this.httpResponse.messages[0]});
      }
    }, error => {
      this.messageService.add({severity:'error', summary:'Not Saved', detail: 'Error disabling Email Templates, error: ' + error.messages});
    })
    });
    
  }

  
  }

