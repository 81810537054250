<div *ngIf="!AccessAllowed && CompletedLoading">
    <h3 style="border: 2px solid;border-radius: 6px;position: absolute;top: 50%;left: 50%;transform: translate(-50%, -50%);padding: 10px;">You don't have access to this function</h3>
  </div>

<p-dialog header="Temporary PIN Number" [(visible)]="displayTempPIN" [style]="{width: '50vw'}" [baseZIndex]="10000"  [modal]="true">
    <p style="font-size: 17px;width: 100%;text-align: center;">please give this to the user, they will be required to change it when they first log in.</p>
    <p style="font-size: 90px;width: 100%;text-align: center;">{{TempPIN}}</p>
  <ng-template pTemplate="footer">
      <p-button icon="pi pi-check" (click)="returnToUsers()" label="OK" styleClass="p-button-text"></p-button>
  </ng-template>
</p-dialog>

<div *ngIf="pageReady && AccessAllowed" class="p-3 flex flex-column flex-auto">
    <div>
        <div class="text-900 font-medium text-xl mb-3"><h3>User Edit
        </h3></div>
        <p class="m-0 mb-4 p-0 text-600 line-height-3 mr-3">
            <span class="pi pi-info-circle" style="margin-right:2px;"></span>
            User allows you to give someone access to your system. You can specify what level of access they can have and the levels are available: Venue Administrator, Venue User, Reception User and Block Access.
        </p>
        <div class="surface-card p-4 shadow-2 border-round">
            <div class="grid formgrid p-fluid">
                <div class="field mb-4 col-12">
                    <label for="name" class="font-medium">Name</label>
                    <input [(ngModel)]="User.name" id="name" type="text" [ngStyle]="{'border-color': (UserNameValid==false) ? '#FF0000':''}" (keyup)="DoValidCheck()" pInputText>
                </div>
                <div *ngIf="!userNameValid" class="text-danger" Style="margin-left:10px; margin-top: -23px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>You must enter a User name.</div>

                <div class="field mb-4 col-12">
                    <label for="usertype" class="font-medium">User Type</label>
                    <p-dropdown [options]="usertypes" [(ngModel)]="User.accesscontrol" optionLabel="name" placeholder="Select a Type" [showClear]="true"></p-dropdown>
                </div>

                <div class="col-12">
                    <button pButton pRipple type="button" class="p-button p-element p-button-success w-auto mt-3" (click)="updateData();">
                    <span class="pi pi-pencil" style="margin-right:8px;"></span>
                    Save Changes</button>
                    &nbsp;
                    <button pButton pRipple type="button" class="p-button p-element p-button-danger w-auto mt-3" (click)="returnToList()">
                    <span class="pi pi-times" style="margin-right:8px;"></span>
                    Cancel</button>
                    &nbsp;
                    <button pButton pRipple type="button" class="p-button p-element p-button-info w-auto mt-3" (click)="resetPIN()">
                    <span class="pi pi-times" style="margin-right:8px;"></span>
                    Reset PIN</button>
                </div>
            </div>
        </div>
    </div>
</div>