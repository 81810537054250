<div *ngIf="!AccessAllowed && CompletedLoading">
    <h3 style="border: 2px solid;border-radius: 6px;position: absolute;top: 50%;left: 50%;transform: translate(-50%, -50%);padding: 10px;">You don't have access to this function</h3>
</div>

<div *ngIf="ListExtras && AccessAllowed" class="p-3 flex flex-column flex-auto">
    <div class="text-900 font-medium text-xl mb-3"><h3>Extras</h3></div>
    <p class="m-0 mb-4 p-0 text-600 line-height-3 mr-3">
        <span class="pi pi-info-circle" style="margin-right:2px;"></span>
        At the time of booking you can offer your customers the opportunity to buy additional items.  These items only add to the total cost, they don’t require you to check any availability.  An example of this would be adding socks if you were selling trampoline bookings.
    </p>
  <div style="text-align: right; margin-bottom: 20px;">

      <p-toggleButton class="p-button-sm p-button-danger p-component" [(ngModel)]="isDisabled" onLabel="Show Disabled" offLabel="Hide Disabled" offIcon="pi pi-times" onIcon ="pi pi-check" [style]="{'margin-right': '10px', 'color' : 'white', 'background-color': '#EF4444', 'font-weight': 'normal', 'height': '50px'}" (click)="toggleDisabled()">
      </p-toggleButton>

      <span class="p-input-icon-left ml-auto" style="margin-right:20px; ">
          <i class="pi pi-search"></i>
          <input *ngIf="ListExtras"  pInputText type="text" (input)="extraTable.filterGlobal($event.target.value, 'contains')" style="height: 50px;padding-left:33px;" />
      </span>

      <button pButton type="button" style="height: 50px;" class="p-button-sm p-element p-ripple p-button-success p-button p-component" [routerLink]="['/extraedit/0']" i18>
      <span class="pi pi-plus" style="margin-right:8px;"></span>
      <span i18>Add</span>
      </button>
      &nbsp;
      <button pButton type="button" style="height: 50px;" class="p-button-sm p-element p-ripple p-button-danger p-button p-component" (click)="disableMulti()">
      <span class="pi pi-trash" style="margin-right:8px;"></span>
      <span i18>Disable</span>
      </button>
  </div>
  <div class="surface-card p-4 shadow-2 border-round">
  <p-table #extraTable
  [value]="ListExtras" sortField="name" [paginator]="true" [rows]="10"
  [showCurrentPageReport]="true" responsiveLayout="scroll"
  currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
  [rowsPerPageOptions]="[10,25,50]"
  [globalFilterFields]="['name']"
  [(selection)]="selectedItems"
  responsiveLayout="scroll"
  datakey="ExtraID"
  [selectionPageOnly]="true">
      <ng-template pTemplate="header">
          <tr>
              <th>
                  <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
              </th>
              <th pSortableColumn="name" width="90%">Name
                  <p-sortIcon [field]="name"></p-sortIcon>
              </th>
              <th width="5%"></th>
              <th width="5%"></th>
          </tr>
      </ng-template>
      <ng-template pTemplate="body" let-data>
          <tr [ngStyle]="{'background-color': (data.disabled==true) ? '#fee' : '#ffffff'}">
              <td>
                  <p-tableCheckbox [value]="data"></p-tableCheckbox>
              </td>
              <td>{{data.name}}</td>
              <td><button pButton type="button" class="p-button-sm p-element p-ripple p-button-success p-button p-component" style="background-color: yellow o !important;" (click)="editData(data.extraId);" i18>
                  <span class="pi pi-pencil" style="margin-right:8px;"></span>
                  <span i18n>Edit</span>
              </button></td>
              <td><button pButton type="button" class="p-button-sm p-element p-ripple p-button-danger p-button p-component"  style="margin-left:-20px;" (click)="disableData(data.extraId)" i18>
                  <span class="pi pi-trash" style="margin-right:8px;"></span>
                  <span i18n>Disable</span>
              </button></td>
          </tr>
      </ng-template>
  </p-table>
  </div>
</div>
