import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppComponent } from '../app.component';
import { bmxurl, getAccess } from 'src/globals';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { users_returndata } from 'src/types/user_returndata';
import { bookingquestion_returndata } from 'src/types/bookingquestion_returndata';
import { bookingquestion } from 'src/types/bookingquestion';

@Component({
  selector: 'app-bookingquestions',
  templateUrl: './bookingquestions.component.html',
  styleUrls: ['./bookingquestions.component.css']
})
export class BookingquestionsComponent implements OnInit {
  ListBookingQuestion: any;
  bmxurl: string;
  VenueID: string = localStorage.getItem("SmartVenueID")!;
  httpResponse: any;
  DisabledBookingQuestion: any;
  checked: boolean = true;
  isDisabled: boolean = true;
  selectedItems: any;

  AccessAllowed: boolean = false;
  CompletedLoading: boolean = false;


  constructor(
    private http: HttpClient, private appComp: AppComponent, private router: Router, private messageService: MessageService) { 
    }

    copydata(id)
    {
      this.http.get(bmxurl + "bookingquestion/" + this.VenueID + "/" + id).subscribe(response => {
        this.httpResponse = (response as bookingquestion_returndata)
        if (this.httpResponse.succeeded)
        {
          let bookingQuestion = {
            id: null,
            bookingquestionid: null,
            questiontext: (this.httpResponse.data as bookingquestion).questiontext,
            listorder: (this.httpResponse.data as bookingquestion).listorder,
            listitems: (this.httpResponse.data as bookingquestion).listitems,
            questiontype:(this.httpResponse.data as bookingquestion).questiontype,
            warningtext: (this.httpResponse.data as bookingquestion).warningtext,
            mandatory: (this.httpResponse.data as bookingquestion).mandatory,
            disabled: (this.httpResponse.data as bookingquestion).disabled,
            venueid: (this.httpResponse.data as bookingquestion).venueid,
            showname: (this.httpResponse.data as bookingquestion).showname,
            showdob: (this.httpResponse.data as bookingquestion).showdob,
            showage: (this.httpResponse.data as bookingquestion).showage,
            originatingid: (this.httpResponse.data as bookingquestion).bookingquestionid,
            answers: null
          };

          this.http.post<bookingquestion>(bmxurl + "/bookingquestion", bookingQuestion).subscribe(response => {
            this.httpResponse = response
              if(this.httpResponse.succeeded)
              {
                this.messageService.add({severity:'success', summary:'Saved', detail:'Booking Question successfully copied'});

                this.loadgrid(false);
              }
              }, error => {
                this.messageService.add({severity:'error', summary:'Not Saved', detail:error.messages});
            })
        }
        else
        {
          this.messageService.add({severity:'error', summary:'Error', detail:'Error while getting Booking Question'});
        }
      }, error => {
        this.messageService.add({severity:'error', summary:'Error', detail:'Error while getting Booking Question, error:' + error.messages[0]});
      });

    }
  

  ngOnInit(): void {

    localStorage.removeItem("screenMemoryBookingss");
    localStorage.removeItem("screenMemoryEvents");
    
    let userid = localStorage.getItem("SmartUserID");

    this.http.get(bmxurl + "User/" + userid).subscribe(response => {
      this.httpResponse =  (response as users_returndata)
      if(this.httpResponse.succeeded)
      {
        let user = this.httpResponse.data;

        if(getAccess("BOOKINGQUESTIONS", user)){
          this.AccessAllowed = true;
          this.loadgrid(false);
        }

        this.CompletedLoading = true;

      }
    }, error => {
      this.messageService.add({severity:'error', summary:'Error', detail:'Error while getting User, error:' + error.messages[0]});
    });

  }

  toggleDisabled(){
    this.loadgrid(!this.isDisabled);
  }

  disableData(BookingQuestionID)
  {
    this.http.delete(bmxurl + "BookingQuestion/" + this.VenueID + "/" + BookingQuestionID).subscribe(response => {
      this.httpResponse = response
      if(this.httpResponse.succeeded == true)
      {
        this.messageService.add({severity:'success', summary:'Disabled', detail:'Booking Question successfully disabled'});
        this.router.navigateByUrl("/bookinguestion");

        this.loadgrid(false);
      }
    }, error => {
      this.messageService.add({severity:'error', summary:'Not Disabled', detail: 'An error occurred disabling this Booking Question'});
    })
  }
  
  loadgrid(showdisabled: boolean)
  {
    this.http.get(bmxurl + "bookingquestionlist/" + this.VenueID + "/" + showdisabled).subscribe(response => {
      this.httpResponse = response
      if(this.httpResponse.succeeded)
      {
        this.ListBookingQuestion = (response as bookingquestion_returndata);

        if ((response as bookingquestion_returndata).messages.length > 0)
        {
          (response as bookingquestion_returndata).messages.forEach(error => {
            this.messageService.add({severity:'warn', summary:'Response Message', detail: error});
          });
        }
      }
      else
      {
        this.messageService.add({severity:'error', summary:'Not Saved', detail:'Error getting Booking Question, error: ' + this.httpResponse.messages[0]});
      }
    }, error => {
      this.messageService.add({severity:'error', summary:'Not Saved', detail: 'Error getting Booking Question, error: ' + error.messages});
    });
  }

  editData(EditData)
  {
    let BookingQuestionID = EditData;
    this.router.navigateByUrl("/bookingquestionedit/" + BookingQuestionID);
  }

  disableMulti()
  {
    this.selectedItems.forEach(element => {

    this.http.delete(bmxurl + "bookingquestion/" + this.VenueID + "/" + element.BookingQuestionID).subscribe(response => {
      this.httpResponse = response
      if(this.httpResponse.succeeded)
      {
        this.loadgrid(false);
      } 
      else{
        this.messageService.add({severity:'error', summary:'Not Saved', detail:'Error disabling Booking Questions, error: ' + this.httpResponse.messages[0]});
      }
    }, error => {
      this.messageService.add({severity:'error', summary:'Not Saved', detail: 'Error disabling Booking Questions, error: ' + error.messages});
    })
    });
    
  }

  
  }
