import { Component, ElementRef, OnInit, OnDestroy, QueryList, ViewChildren, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Resource, Package } from '../../PackageClasses';
import { AppComponent } from '../app.component';
import { packagerule_returndata } from 'src/types/packagerule_returndata';
import { bmxurl } from 'src/globals';
import { getAccess } from 'src/globals';
import { NgModule } from '@angular/core';
import { NgModel } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router, RouterLink } from '@angular/router';
import { packagerule } from 'src/types/packagerule';
import { MessageService } from 'primeng/api';
import { Checkbox } from 'primeng/checkbox';
import { booking_returndata } from 'src/types/booking_returndata';
import { BusyService } from '../_services/busy.service';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { AuthService } from '@auth0/auth0-angular';
import { GlobalService } from '../_services/global.service';
import { MenuItem } from 'primeng/api/menuitem';
import { venuesettings_returndata } from 'src/types/venuesettings_returndata';
import { cBookingfee } from 'src/types/bookingfee';
import { bookingfee_returndata } from 'src/types/bookingfee_returndata';
import { environment } from 'src/environments/environment';
import { Subscription } from 'rxjs';
import { users_returndata } from 'src/types/user_returndata';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})

export class HomeComponent implements OnInit {
  ListBookings: any[];
  ListBookingsGlobal: any[];
  bmxurl: string;
  VenueID: string = localStorage.getItem("SmartVenueID")!;
  CreateUpdateResponse: any;
  DisabledListResource: any;
  checked: boolean = true;
  isDisabled: boolean = true;
  selectedItems: any;
  BookingDate: Date;
  PreviousBookingDate: Date;
  hubConnection: HubConnection;
  httpResponse: any;
  navSubscription: Subscription = null;
  bookingsInProgress: number = 0;
  totalBookingsToday: number = 0;
  inProgress: boolean = false;

  bcItems: MenuItem[];
  home: MenuItem;
  PreviousHubs: string[] = [];
  AccessAllowed: boolean = false;
  CompletedLoading: boolean = false;
  CurrencySymbol: string = "-";
  //PageLinks: any;
  //currentPageIndex: number = 4;

  cols: any[];

  //@ViewChild('resourceTable') dataTable: any;
  //@ViewChild('table', { static: true }) dataTable!: any;
  
  constructor(
    private http: HttpClient, 
    private appComp: AppComponent, 
    private router: Router, 
    private messageService: MessageService, 
    private busyService: BusyService,
    private auth: AuthService,
    private globalService: GlobalService,
    private route: ActivatedRoute) {
      
    let userid = localStorage.getItem("SmartUserID");

    this.http.get(bmxurl + "User/" + userid).subscribe(response => {
      this.httpResponse =  (response as users_returndata)
      if(this.httpResponse.succeeded)
      {
        let user = this.httpResponse.data;

        if(getAccess("HOME", user)){
          this.AccessAllowed = true;
        }

        this.CompletedLoading = true;
      }
    }, error => {
      this.messageService.add({severity:'error', summary:'Error', detail:'Error while getting User, error:' + error.messages[0]});
    });
  }

  // goToPage(page: number): void {
  //   setTimeout(() => {
  //     this.dataTable.first = (page - 1) * 10;
  //     this.dataTable.firstChange.emit(this.dataTable.first);
  //   }, 0);
  // }

  // paginate(event)
  // {
  //   this.currentPageIndex = event.first/event.rows + 1
  // }

  ngOnDestroy() {
    this.hubConnection.stop();
  }

  showInProgress(){
    this.inProgress = true;
    this.loadgrid(false);
  }

  unshowInProgress(){ 
    this.inProgress = false;
    this.loadgrid(false);
  }

  ngOnInit(): void {

    this.BookingDate = new Date();

    if(this.route.snapshot.paramMap.get('startdate') != null && this.route.snapshot.paramMap.get('startdate') != 'null')
    {
      this.BookingDate = new Date(this.route.snapshot.paramMap.get('startdate'));
    }

    localStorage.removeItem("screenMemoryBookingss");
    localStorage.removeItem("screenMemoryEvents");
    
    this.auth.getAccessTokenSilently().subscribe(tokenReturned => {
      this.hubConnection = new HubConnectionBuilder()
	    .withUrl(environment.servicestarget + "/venue/" + this.VenueID, { 
		  accessTokenFactory: () => tokenReturned 
	  }).build();

    let menuItems: MenuItem[];

    let userid = localStorage.getItem("SmartUserID");

    this.http.get(bmxurl + "User/" + userid).subscribe(response => {
      this.httpResponse = (response as any)
      if(this.httpResponse.succeeded)
      {
        let user = this.httpResponse.data;

        menuItems = [{
          label: 'ADMIN',
          items: [
            // {label: 'Bookings Created Today', icon: 'pi pi-check-circle', routerLink: ['/home'], visible: getAccess("HOME", user)},
            // {label: 'At a Glance', icon: 'pi pi-eye', routerLink: ['/ataglance'], visible:  true},  
            // {label: 'Make A Booking', icon: 'pi pi-calendar', routerLink: ['/booking/0'], visible:  getAccess("MAKEABOOKING", user)},
            // {label: 'Admissions', icon: 'pi pi-user', routerLink: ['/admission'], visible:  getAccess("ADMISSIONS", user)},
            // {label: 'Booking Fees', icon: 'pi pi-money-bill', routerLink: ['/bookingfeeedit'], visible:  getAccess("BOOKINGFEES", user)},
            // {label: 'Booking Questions', icon: 'pi pi-question-circle', routerLink: ['/bookingquestions'], visible:  getAccess("BOOKINGQUESTIONS", user)},
            // {label: 'Booking Questionnaires', icon: 'pi pi-list', routerLink: ['/bookingquestionnaire'], visible:  getAccess("BOOKINGQUESTIONNAIRE", user)},
            // {label: 'Cash up', icon: 'pi pi-print', routerLink: ['/cashuplist'], visible:  getAccess("CASHUP", user)},
            // {label: 'Confirmations', icon: 'pi pi-at', routerLink: ['/confirmations'], visible:  getAccess("CONFIRMATIONS", user)},
            // {label: 'Classes', icon: 'pi pi-users', routerLink: ['/class'], visible:  getAccess("CLASSES", user)},
            // {label: 'Daysheet', icon: 'pi pi-align-left', routerLink: ['/daysheetstyle1'], visible:  getAccess("DAYSHEET", user)}, 
            // {label: 'Deposit Policies', icon: 'pi pi-wallet', routerLink: ['/depositpolicies'], visible:  getAccess("DEPOSITPOLICIES", user)},
            // {label: 'Discounts', icon: 'pi pi-arrow-circle-down', routerLink: ['/discounts'], visible:  getAccess("DISCOUNTS", user)},
            // {label: 'Email Templates', icon: 'pi pi-envelope', routerLink: ['/emailtemplates'], visible:  getAccess("EMAILTEMPLATES", user)},
            // {label: 'Events', icon: 'pi pi-ticket', routerLink: ['/event'], visible:  getAccess("EVENT", user)}, 
            // {label: 'Extras', icon: 'pi pi-star', routerLink: ['/extra'], visible:  getAccess("EXTRAS", user)},
            // {label: 'Package', icon: 'pi pi-box', routerLink: ['/package'], visible:  getAccess("PACKAGE", user)},
            // {label: 'Package Groups', icon: 'pi pi-clone', routerLink: ['/packagegroups'], visible:  getAccess("PACKAGEGROUPS", user)},
            // {label: 'Package Rules', icon: 'pi pi-tag', routerLink: ['/packagerule'], visible:  getAccess("PACKAGERULES", user)},
            // {label: 'Resources', icon: 'pi pi-clone', routerLink: ['/resource'], visible:  getAccess("RESOURCES", user)},
            // {label: 'Resource Adjustments', icon: 'pi pi-sort-alt', routerLink: ['/resourceadjustments'], visible:  getAccess("RESOURCES", user)},
            // {label: 'Resource Closings', icon: 'pi pi-ban', routerLink: ['/resourceclosings'], visible:  getAccess("RESOURCECLOSINGS", user)},
            // {label: 'Resource Groups', icon: 'pi pi-sitemap', routerLink: ['/resourcegroupnames'], visible:  getAccess("RESOURCEGROUPS", user)},
            // {label: 'Tags', icon: 'pi pi-tags', routerLink: ['/tag'], visible:  getAccess("TAGS", user)},
            // {label: 'Terms & Conditions', icon: 'pi pi-check-square', routerLink: ['/terms'], visible:  getAccess("TANDCS", user)},
            // //{label: 'Till', icon: 'pi pi-shopping-bag', routerLink: ['/till'], visible:  getAccess("TILL", user)},
            // //{label: 'Till Departments', icon: 'pi pi-table', routerLink: ['/tilldepartments'], visible:  getAccess("TILLDEPARTMENTS", user)},
            // {label: 'Time Sets', icon: 'pi pi-clock', routerLink: ['/timesets'], visible:  getAccess("TIMESETS", user)},
            // {label: 'User Management', icon: 'pi pi-user', routerLink: ['/users'], visible:  getAccess("USERMANAGEMENT", user)},
            // {label: 'Venue Closings', icon: 'pi pi-lock', routerLink: ['/venueclosings'], visible:  getAccess("VENUECLOSINGS", user)},
            // {label: 'Venue Settings', icon: 'pi pi-cog', routerLink: ['/venuesettings'], visible:  getAccess("VENUESETTINGS", user)},  
            // //{label: 'Drag Drop Test', icon: 'pi pi-cog', routerLink: ['/dragdroptest'], visible:  getAccess("VENUESETTINGS", user)},  
            
            

            {label: 'Bookings Created Today', icon: 'pi pi-check-circle', routerLink: ['/home'], visible: true},
            {label: 'At a Glance', icon: 'pi pi-eye', routerLink: ['/ataglance'], visible:  true},  
            //{label: 'App QR Code', icon: 'pi pi-qrcode', routerLink: ['/qrcode'], visible:  true},  
            {label: 'Make A Booking', icon: 'pi pi-calendar', routerLink: ['/booking/0'], visible:  true},
            //{label: 'Sell A Voucher', icon: 'pi pi-ticket', routerLink: ['/sellavoucher'], visible: true},
           // {label: 'Buy Email/SMS Credits', icon: 'pi pi-shopping-bag', routerLink: ['/buycredits'], visible: true},
            {label: 'Admissions', icon: 'pi pi-user', routerLink: ['/admission'], visible:  true},
            {label: 'Booking Fees', icon: 'pi pi-money-bill', routerLink: ['/bookingfeeedit'], visible:  true},
            {label: 'Booking Questions', icon: 'pi pi-question-circle', routerLink: ['/bookingquestions'], visible:  true},
            {label: 'Booking Questionnaires', icon: 'pi pi-list', routerLink: ['/bookingquestionnaire'], visible:  true},
            {label: 'Booking Questions Report', icon: 'pi pi-align-justify', routerLink: ['/bookinganswersreport'], visible:  true},
            {label: 'Cash up', icon: 'pi pi-print', routerLink: ['/cashuplist'], visible:  true},
            {label: 'Confirmations', icon: 'pi pi-at', routerLink: ['/confirmations'], visible:  true},
            {label: 'Classes', icon: 'pi pi-users', routerLink: ['/class'], visible:  true},
            {label: 'Daysheet', icon: 'pi pi-align-left', routerLink: ['/daysheetstyle1'], visible:  true}, 
            {label: 'Deposit Policies', icon: 'pi pi-wallet', routerLink: ['/depositpolicies'], visible: true},
            {label: 'Discounts', icon: 'pi pi-arrow-circle-down', routerLink: ['/discounts'], visible:  true},
            {label: 'Email Templates', icon: 'pi pi-envelope', routerLink: ['/emailtemplates'], visible:  true},
            {label: 'Events', icon: 'pi pi-ticket', routerLink: ['/event'], visible: true}, 
            {label: 'Extras', icon: 'pi pi-star', routerLink: ['/extra'], visible:  true},
            {label: 'Extras Report', icon: 'pi pi-align-justify', routerLink: ['/extrasreport'], visible:  true},
            {label: 'Link Manager', icon: 'pi pi-link', routerLink: ['/linkmanager'], visible:  true},
            {label: 'Marketing', icon: 'pi pi-send', routerLink: ['/marketing'], visible:  true},
            {label: 'Package', icon: 'pi pi-box', routerLink: ['/package'], visible:  true},
            {label: 'Package Groups', icon: 'pi pi-clone', routerLink: ['/packagegroups'], visible:  true},
            {label: 'Package Rules', icon: 'pi pi-tag', routerLink: ['/packagerule'], visible:  true},
            {label: 'Resources', icon: 'pi pi-clone', routerLink: ['/resource'], visible:  getAccess("RESOURCES", user)},
            //{label: 'Resource Adjustments', icon: 'pi pi-sort-alt', routerLink: ['/resourceadjustments'], visible:  true},
            {label: 'Resource Closings', icon: 'pi pi-ban', routerLink: ['/resourceclosings'], visible: true},
            {label: 'Resource Groups', icon: 'pi pi-sitemap', routerLink: ['/resourcegroupnames'], visible: true},
            {label: 'Tags', icon: 'pi pi-tags', routerLink: ['/tag'], visible: true},
            //{label: 'Task Types', icon: 'pi pi-paperclip', routerLink: ['/tasktypelist'], visible: true},
            //{label: 'Task Status List', icon: 'pi pi-list', routerLink: ['/testtask'], visible: true},
            {label: 'Terms & Conditions', icon: 'pi pi-check-square', routerLink: ['/terms'], visible: true},
            
            {label: 'Time Sets', icon: 'pi pi-clock', routerLink: ['/timesets'], visible: true},
            {label: 'User Management', icon: 'pi pi-user', routerLink: ['/users'], visible: true},
            {label: 'Venue Closings', icon: 'pi pi-lock', routerLink: ['/venueclosings'], visible: true},
            {label: 'Venue Settings', icon: 'pi pi-cog', routerLink: ['/venuesettings'], visible: true}, 
            //{label: 'Manage Vouchers', icon: 'pi pi-shopping-bag', routerLink: ['/voucherlist'], visible: true}, 
            {label: 'Warnings', icon: 'pi pi-info-circle', routerLink: ['/warnings'], visible: true},

            //{label: 'Reports', icon: 'pi pi-info-justify', routerLink: ['/reports'], visible: true},

            //{label: 'Booking Calendar', icon: 'pi pi-info-calendar', routerLink: ['/bookingcalendar'], visible: true},

            //{label: 'Create New Customer', icon: 'pi pi-cog', routerLink: ['/create'], visible: true}, 
            //{label: 'Add Venue to Business', icon: 'pi pi-cog', routerLink: ['/addvenuetobusiness'], visible: true}, 
            
          ]
        }];

        this.globalService.setMenuItems(menuItems);  

        this.globalService.setBCItem(['Home','/home']);

        let UseCounters = localStorage.getItem("UseCounter")
        this.globalService.setUseCounters(UseCounters);
    
      }
    }, error => {
      this.messageService.add({ severity: 'error', summary: 'Unable to get User' });
    });

    let BookingDateYear = this.BookingDate.getFullYear();
    let BookingDateMonth = ('00' + (this.BookingDate.getMonth() + 1)).slice(-2);
    let BookingDateDay = ('00' + this.BookingDate.getDate()).slice(-2);

    let BookingEventDate = "bookingEvent_" + BookingDateYear + BookingDateMonth + BookingDateDay;

    this.PreviousHubs.push(BookingEventDate);

    this.hubConnection.start();

    this.hubConnection.off(BookingEventDate);

    this.hubConnection.on(BookingEventDate, data => {
      console.log(data);
       this.loadgrid(false);
    });

    this.busyService.busy();
    
        //Get Settings and put them in Local Storage
        this.http.get(bmxurl + "venuesettings/" + this.VenueID).subscribe(response => {
          this.httpResponse =  (response as venuesettings_returndata)
          if(this.httpResponse.succeeded)
          {
            //Set Venue Settings ID
            localStorage.setItem("VenueSettingsID", this.httpResponse.data.id);
    
            this.httpResponse.data.venueSettings.forEach(element => {
              localStorage.setItem(element.name, element.value);
            });

            this.CurrencySymbol = localStorage.getItem("Currency") == null ? "GBP" : localStorage.getItem("Currency")!;
            this.loadgrid(false);
          }
          else{
            this.messageService.add({ severity: 'error', summary: 'Unable to get Venue Settings' });
          }
        }, error => {
          this.messageService.add({ severity: 'error', summary: 'Unable to get Venue Settings' });
        });

    });

    this.http.get(bmxurl + "bookingfee/" + this.VenueID).subscribe(response => {
      this.httpResponse = (response as bookingfee_returndata)
      if (this.httpResponse.succeeded)
      {
        if(this.httpResponse.data != null)
        {
          let BookingFee: cBookingfee = {
            id: (this.httpResponse.data as cBookingfee).id,
            venueid: (this.httpResponse.data as cBookingfee).venueid,
            disabled: (this.httpResponse.data as cBookingfee).disabled,
            bfid: (this.httpResponse.data as cBookingfee).bfid,
            bfpublicapply: (this.httpResponse.data as cBookingfee).bfpublicapply,
            bfadminapply: (this.httpResponse.data as cBookingfee).bfadminapply,
            bfpublicshowtext: (this.httpResponse.data as cBookingfee).bfpublicshowtext,
            bfpublictext: (this.httpResponse.data as cBookingfee).bfpublictext,
            bfpublictype: (this.httpResponse.data as cBookingfee).bfpublictype,
            bfadmintype: (this.httpResponse.data as cBookingfee).bfadmintype,
            bfpublicfee: (this.httpResponse.data as cBookingfee).bfpublicfee,
            bfpublicmin: (this.httpResponse.data as cBookingfee).bfpublicmin,
            bfpublicmax: (this.httpResponse.data as cBookingfee).bfpublicmax,
            bfadminfee: (this.httpResponse.data as cBookingfee).bfadminfee,
            bfadminmin: (this.httpResponse.data as cBookingfee).bfadminmin,
            bfadminmax: (this.httpResponse.data as cBookingfee).bfadminmax
          };

          localStorage.setItem("VenueBookingFee", JSON.stringify(BookingFee));
        }
      }
      else
      {
        this.messageService.add({severity:'error', summary:'Error', detail:'Error while getting Booking Fee'});
      }
    }, error => {
      this.messageService.add({severity:'error', summary:'Error', detail:'Error while getting Booking Fee, error:' + error.messages[0]});
    });


    this.cols = [
      { field: 'bookingIdHR', header: 'Sales ID' },
      { field: 'startTime', header: 'Start Time</th>' },
      { field: 'name', header: 'Name' },
      { field: 'packageName', header: 'Payment Name' },
      { field: 'guests', header: 'Guests' },
      { field: 'total', header: 'Total' },
      { field: 'outstanding', header: 'Outstanding' },
      { field: 'paymentlinksentdate', header: 'Payment Link Sent' },
      { field: 'extraslist', header: 'Extras' },
      ];
  }

  editdata(ID){

    localStorage.addItem("homeScreenBookings", JSON.stringify(this.ListBookings));
    localStorage.addItem("homeScreenNumberOfRecords", 0);
    localStorage.addItem("homeScreenCurrentPage", );

    this.router.navigateByUrl("/editbooking/" + ID);
  }

  editdatanew(ID){
    this.router.navigateByUrl("/editbooking/" + ID + "/" + this.BookingDate.toISOString().substring(0,10));
  }

  toggleDisabled(){
    this.loadgrid(!this.isDisabled);
  }

  disableData(BookingID)
  {
    if (confirm("Are you sure you want to disable this booking?"))
     {
        this.http.delete(bmxurl + "Booking/" + this.VenueID + "/" + BookingID).subscribe(response => {
          this.CreateUpdateResponse = response
          if(this.CreateUpdateResponse.succeeded = true)
          {
            this.messageService.add({severity:'success', summary:'Disabled', detail:'Booking successfully disabled'});
            this.loadgrid(false);
          }
        }, error => {
          this.messageService.add({severity:'error', summary:'Not Disabled', detail: 'An error occurred disabling this Booking'});
        })
      }
  }

  undisableData(BookingID)
  {
    this.http.get(bmxurl + "enablebooking/" + this.VenueID + "/" + BookingID).subscribe(response => {
      this.CreateUpdateResponse = response
      if(this.CreateUpdateResponse.succeeded = true)
      {
        this.messageService.add({severity:'success', summary:'Enabled', detail:'Booking successfully enabled'});
        this.loadgrid(false);
      }
    }, error => {
      this.messageService.add({severity:'error', summary:'Not Disabled', detail: 'An error occurred enabling this Booking'});
    })
  }

  abandonBooking(BookingID)
  {
    let abandonBooking: any = {
      bookingid: BookingID,
      jwt: null
    }
    //Booking Exists, abandon it
    this.http.post(bmxurl + "abandonbooking/", abandonBooking).subscribe(response => {
      this.messageService.add({severity:'success', summary:'Abandoned', detail:'Booking abandoned successfully.'});
    }, error => {
      this.messageService.add({severity:'error', summary:'Failure', detail:'Failed to abandon booking.'});
    });
  }

  loadgrid(showdisabled: boolean)
  {
    let BookingDateYear = this.BookingDate.getFullYear().toString().substr(-2);
    let BookingDateMonth = ('00' + (this.BookingDate.getMonth() + 1)).slice(-2);
    let BookingDateDay = ('00' + this.BookingDate.getDate()).slice(-2);

    let dateChosen = BookingDateDay + BookingDateMonth + BookingDateYear;

    //this.busyService.busy();
    this.http.get(bmxurl + "BookingCreatedMin/" + this.VenueID + "/" + dateChosen + "/" + showdisabled).subscribe(response => {
      try{
        this.CreateUpdateResponse = (response as booking_returndata);

        //Get VenueDate
        this.ListBookingsGlobal = this.CreateUpdateResponse.data;

        var thisYear: any = this.BookingDate.getFullYear();
        var thisMonth: any = ('00' + (this.BookingDate.getMonth() + 1)).slice(-2);
        var thisDay: any = ('00' + this.BookingDate.getDate()).slice(-2);
        var fullDate: any = thisYear + thisMonth + thisDay;

        let venueDate: string =  this.VenueID + "_" + fullDate;

        if(this.inProgress)
        {
          this.ListBookings = this.ListBookingsGlobal.filter(obj => obj.inProgress == true);

          this.ListBookings.forEach(element => {

            let extraslist: string = "";


                if(element.publicBooking == true)
                {
                  let expSecs = 300 - (new Date().getTime() - new Date(element.createddate).getTime()) / 1000;

                  if(expSecs < 0)
                  {
                    expSecs = 0;
                  }

                  element.expiry = new Date(0,0,0,0,0,expSecs);
                }
                else
                {
                  if(element.awaitingpayment == true)
                  {                
                    let expSecs = 14000 - (new Date().getTime() - new Date(element.createddate).getTime()) / 1000;

                    if(expSecs < 0)
                    {
                      expSecs = 0;
                    }

                    element.expiry = new Date(0,0,0,0,0,expSecs);
                  }
                  else
                  {
                    let expSecs = 300 - (new Date().getTime() - new Date(element.createddate).getTime()) / 1000;

                    if(expSecs < 0)
                    {
                      expSecs = 0;
                    }
  
                    element.expiry = new Date(0,0,0,0,0,expSecs);
                  }
                }
                
                if(element.extras != null)
                  {
                    if(element.extras.length > 0)
                    { 
                      element.extras.forEach(extra => {
                        extraslist += extra.name + " (" + extra.qty + "), ";
                      });
                    }

                    if(extraslist.length > 2)
                      element.extraslist = extraslist.substring(0, extraslist.length - 2);
                  }
          });
        }
        else
        {
          this.ListBookings = this.ListBookingsGlobal.filter(obj => obj.inProgress == false);
          this.ListBookings.forEach(element => {

            let extraslist: string = "";

            if(element.extras != null)
              {
                if(element.extras.length > 0)
                { 
                  element.extras.forEach(extra => {
                    if (extra.qty > 0)
                      extraslist += extra.name + " (" + extra.qty + "), ";
                  });
                }

                if(extraslist.length > 2)
                  element.extraslist = extraslist.substring(0, extraslist.length - 2);
              }
          });
          
        }

        this.bookingsInProgress = this.ListBookingsGlobal.filter(obj => obj.inProgress == true).length;
        this.totalBookingsToday = this.ListBookingsGlobal.filter(obj => obj.inProgress == false).length;;

        //this.setCurrentPage(2);
      }
      catch (error){
        this.messageService.add({severity:'error', summary:'Error getting bookings', detail: 'Failed to get bookings in loadgrid, possible data mismatch!'});
      }
      finally{
        this.busyService.idle();
      }
    }, error => {
      this.messageService.add({severity:'error', summary:'Error getting bookings', detail: 'An error occurred getting Bookings'});
      this.busyService.idle();
    });
  }

  disableMulti()
  {
    this.selectedItems.forEach(element => {

    this.http.delete(bmxurl + "Booking/" + this.VenueID + "/" + element.bookingId).subscribe(response => {
      this.CreateUpdateResponse = response
      if(this.CreateUpdateResponse.succeeded = true)
      {
        this.loadgrid(false);
      }
    }, error => {

    })
    });

  }

  doneDate(){

    this.inProgress = false;

    this.loadgrid(false)

    this.PreviousHubs.forEach(element => {
      this.hubConnection.off(element);
    });

    this.PreviousHubs = [];

    let BookingDateYear = this.BookingDate.getFullYear();
    let BookingDateMonth = ('00' + (this.BookingDate.getMonth() + 1)).slice(-2);
    let BookingDateDay = ('00' + this.BookingDate.getDate()).slice(-2);

    let BookingEventDate = "bookingEvent_" + BookingDateYear + BookingDateMonth + BookingDateDay;

    this.PreviousHubs.push(BookingEventDate);

    this.hubConnection.on(BookingEventDate, data => {
      console.log(data);
      this.loadgrid(false);
    });

  }

  addDays(date: Date, days: number): Date {
    date.setDate(date.getDate() + days);
    return date;
  }

  dateChange(num)
  {
    this.BookingDate = this.addDays(new Date(this.BookingDate), num);
    this.doneDate();
  }

  exportData()
  {


  }

}