<div *ngIf="!AccessAllowed && CompletedLoading">
    <h3 style="border: 2px solid;border-radius: 6px;position: absolute;top: 50%;left: 50%;transform: translate(-50%, -50%);padding: 10px;">You don't have access to this function</h3>
</div>

<div *ngIf="AccessAllowed" class="p-3 flex flex-column flex-auto">
    <div>
        <div class="text-900 font-medium text-xl mb-3"><h3>Booking Fee
        </h3></div>
        <p class="m-0 mb-4 p-0 text-600 line-height-3 mr-3">
            <span class="pi pi-info-circle" style="margin-right:2px;"></span>
             Booking Fees can be added onto a customer's purchase to help recover the cost of processing the transaction. The bookng fee is always in addition to the amount of the deposit
        </p>
        <div class="surface-card p-4 shadow-2 border-round">
            <div class="grid formgrid p-fluid">

                <!--<div class="field mb-4 col-12">
                    <label for="classname" class="font-medium">Show Public Message</label>
                    <p-selectButton id="selectionmode" [(ngModel)]="BookingFee.bfpublicshowtext" [style]="{'width': '400px'}" [options]="stateOptions"></p-selectButton>
                </div>
 
                <div *ngIf="BookingFee.bfpublicshowtext" class="field mb-4 col-12">
                    <label for="classname" class="font-medium">Public Message</label>
                    <input  id="classname" [(ngModel)]="BookingFee.bfpublictext" type="text" [ngStyle]="{'border-color': (classNameValid==false) ? '#FF0000':''}" (keyup)="DoValidCheck()" pInputText>
                </div>
                <div *ngIf="!publicTextValid" class="text-danger" Style="margin-left:10px; margin-top: -23px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>You must enter a Booking Fee Message.</div> -->

                <div class="field mb-4 col-12">
                    <label for="classname" class="font-medium">Apply Public Booking Fee</label>
                    <p-selectButton [(ngModel)]="BookingFee.bfpublicapply" id="selectionmode" [style]="{'width': '400px'}" [options]="stateOptions"></p-selectButton>
                </div>
                <div *ngIf="BookingFee.bfpublicapply">
                    <div class="field mb-4 col-12">
                        <label for="classname" class="font-medium">Public Booking Fee Type</label>
                        <p-selectButton [(ngModel)]="BookingFee.bfpublictype" [style]="{'width': '400px'}" id="selectionmode" [options]="stateOptionsType"></p-selectButton>
                    </div>

                    <div *ngIf="BookingFee.bfpublictype!='PERCENTAGE'" class="field mb-4 col-12">
                        <label for="classname" class="font-medium">Public Booking Fee Amount</label><br>
                        <p-inputNumber [(ngModel)]="BookingFee.bfpublicfee" [style]="{'width': '400px'}"  mode="currency" currency={{CurrencySymbol}} [showButtons]="true" max="100" min="0" buttonLayout="horizontal" spinnerMode="horizontal"></p-inputNumber>
                    </div>

                    <div *ngIf="BookingFee.bfpublictype=='PERCENTAGE'" class="field mb-4 col-12">
                        <label for="classname" class="font-medium">Public Booking Fee Amount</label><br>
                        <p-inputNumber [(ngModel)]="BookingFee.bfpublicfee" [style]="{'width': '400px'}"  suffix="%" [showButtons]="true" max="100" min="0" buttonLayout="horizontal" spinnerMode="horizontal"></p-inputNumber>
                    </div>
                    <div *ngIf="BookingFee.bfpublictype!='FIXED'">
                        <div class="field mb-4 col-12">
                            <label for="classname" class="font-medium">Public Booking Fee Min</label><br>
                            <p-inputNumber [(ngModel)]="BookingFee.bfpublicmin" [style]="{'width': '400px'}" mode="currency" currency={{CurrencySymbol}} [showButtons]="true" max="9999" min="0" buttonLayout="horizontal" spinnerMode="horizontal"></p-inputNumber>
                        </div>

                        <div class="field mb-4 col-12">
                            <label for="classname" class="font-medium">Public Booking Fee Max</label><br>
                            <p-inputNumber [(ngModel)]="BookingFee.bfpublicmax" [style]="{'width': '400px'}" mode="currency" currency={{CurrencySymbol}}  [showButtons]="true" max="9999" min="0" buttonLayout="horizontal" spinnerMode="horizontal"></p-inputNumber>
                        </div>
                        <div *ngIf="!publicMinMaxValid" class="text-danger" Style="margin-left:10px; margin-top: -23px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>Your Admin Booking Fee Max must be greater than the Min.</div>
                    </div>
                </div>

                <div class="field mb-4 col-12">
                    <label for="classname" class="font-medium">Apply Admin Booking Fee</label>
                    <p-selectButton [(ngModel)]="BookingFee.bfadminapply" id="selectionmode" [style]="{'width': '400px'}" [options]="stateOptions"></p-selectButton>
                </div>

                <div *ngIf="BookingFee.bfadminapply">
                    <div class="field mb-4 col-12">
                        <label for="classname" class="font-medium">Admin Booking Fee Type</label>
                        <p-selectButton [(ngModel)]="BookingFee.bfadmintype" id="selectionmode" [style]="{'width': '400px'}" [options]="stateOptionsType"></p-selectButton>
                    </div>

                    <div *ngIf="BookingFee.bfadmintype!='PERCENTAGE'" class="field mb-4 col-12">
                        <label for="classname" class="font-medium">Admin Booking Fee Amount</label>
                        <p-inputNumber [(ngModel)]="BookingFee.bfadminfee" [style]="{'width': '400px'}" mode="currency" currency={{CurrencySymbol}} [showButtons]="true" max="9999" min="0" buttonLayout="horizontal" spinnerMode="horizontal"></p-inputNumber>
                    </div>

                    <div *ngIf="BookingFee.bfadmintype=='PERCENTAGE'" class="field mb-4 col-12">
                        <label for="classname" class="font-medium">Admin Booking Fee Amount</label>
                        <p-inputNumber [(ngModel)]="BookingFee.bfadminfee" [style]="{'width': '400px'}" suffix="%" [showButtons]="true" max="9999" min="0" buttonLayout="horizontal" spinnerMode="horizontal"></p-inputNumber>
                    </div>
                    <div *ngIf="BookingFee.bfadmintype!='FIXED'">
                        <div class="field mb-4 col-12">
                            <label for="classname" class="font-medium">Admin Booking Fee Min</label>
                            <p-inputNumber [(ngModel)]="BookingFee.bfadminmin" [style]="{'width': '400px'}" mode="currency" currency={{CurrencySymbol}}  [showButtons]="true" max="100" min="0" buttonLayout="horizontal" spinnerMode="horizontal"></p-inputNumber>
                        </div>

                        <div class="field mb-4 col-12">
                            <label for="classname" class="font-medium">Admin Booking Fee Max</label>
                            <p-inputNumber  [(ngModel)]="BookingFee.bfadminmax"[style]="{'width': '400px'}" mode="currency" currency={{CurrencySymbol}} [showButtons]="true" max="100" min="0" buttonLayout="horizontal" spinnerMode="horizontal"></p-inputNumber>
                        </div>
                        <div *ngIf="!adminMinMaxValid" class="text-danger" Style="margin-left:10px; margin-top: -23px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>Your Public Booking Fee Max must be greater than the Min.</div>
                    </div>
                </div>


                <div class="col-12">
                    <button pButton pRipple type="button" class="p-button p-element p-button-success w-auto mt-3" (click)="updateData();">
                    <span class="pi pi-pencil" style="margin-right:8px;"></span>
                    Save Changes</button>
                    &nbsp;
                    <button pButton pRipple type="button" class="p-button p-element p-button-danger w-auto mt-3" (click)="returnToList()">
                    <span class="pi pi-times" style="margin-right:8px;"></span>
                    Cancel</button>
                </div>
            </div>
        </div>
    </div>
</div>