import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router'
import { HttpClient } from '@angular/common/http';
import { bmxurl, getAccess } from 'src/globals';
import { extra_returndata } from 'src/types/extra_returndata';
import { extraoption_returndata } from 'src/types/extraoption_returndata';
import { Router, RouterLink } from '@angular/router';
import { cExtra, extra } from 'src/types/extra';
import { extraoption } from 'src/types/extraoption';
import { tag } from 'src/types/tag';
import { tag_returndata } from 'src/types/tag_returndata';
import { MessageService } from 'primeng/api';

import { users_returndata } from 'src/types/user_returndata';


@Component({
  selector: 'app-extraedit',
  templateUrl: './extraedit.component.html',
  styleUrls: ['./extraedit.component.css']
})
export class ExtraeditComponent implements OnInit {
  VenueID: string = localStorage.getItem("SmartVenueID")!;
  Extra: cExtra;
  httpResponse: any;
  id: string;
  stateOptions: any;
  stateOptions2: any;
  stateOptions3: any;
  stateOptionsYesNo: any;

  pageReady: boolean = false;
  availableExtraOptions: extraoption[] = [];
  selectedExtraOptions: extraoption[] = [];
  draggedExtraOption: extraoption = null;

  extraNameValid: boolean = true;
  extraDescValid: boolean = true;

  questionValid: boolean = true;

  AccessAllowed: boolean = false;
  CompletedLoading: boolean = false;
  ChooseDays: boolean = false;
  askQuestion: boolean = false;

  CurrencySymbol: string = localStorage.getItem("Currency") == null ? "GBP" : localStorage.getItem("Currency")!;

  tags: tag[] = [];
  selectedTags: tag[] = [];

  constructor(private route: ActivatedRoute, private http: HttpClient, private router: Router, private messageService: MessageService) {
    this.stateOptions = [{label: 'Off', value: false}, {label: 'On', value: true}];
    this.stateOptionsYesNo = [{label: 'Yes', value: true}, {label: 'No', value: false}];
    this.stateOptions2 = [{label: 'Per Extra', value: false}, {label: 'Per Booking', value: true}];
    this.stateOptions3 = [{label: 'All Days', value: false}, {label: 'Choose Days', value: true}];

    this.Extra = {
      id: null,
      extraId: null,
      name: "",
      description: "",
      disabled: false,
      venueId: this.VenueID,
      monday: true,
      tuesday: true,
      wednesday: true,
      thursday: true,
      friday: true,
      saturday: true,
      sunday: true,
      extraOptions: [],
      tags: [],
      price: 0,
      perPerson: false,
      question: "",
      answer: ""
    }
   }

  ngOnInit(): void {

    let userid = localStorage.getItem("SmartUserID");

    this.http.get(bmxurl + "User/" + userid).subscribe(response => {
      this.httpResponse =  (response as users_returndata)
      if(this.httpResponse.succeeded)
      {
        let user = this.httpResponse.data;

        if(getAccess("EXTRAS", user)){
          this.AccessAllowed = true;

          
        this.id = this.route.snapshot.paramMap.get('id');
        if(this.id != "0")
        {
          this.pageReady = false;
          this.http.get(bmxurl + "extra/" + this.VenueID + "/" + this.id).subscribe(response => {
            this.httpResponse = (response as extra_returndata)
            if (this.httpResponse.succeeded)
            {
              this.Extra = {
                id: (this.httpResponse.data as cExtra).id,
                extraId: (this.httpResponse.data as cExtra).extraId,
                name: (this.httpResponse.data as cExtra).name,
                description: (this.httpResponse.data as cExtra).description,
                disabled: (this.httpResponse.data as cExtra).disabled,
                venueId: (this.httpResponse.data as cExtra).venueId,
                monday: (this.httpResponse.data as cExtra).monday,
                tuesday: (this.httpResponse.data as cExtra).tuesday,
                wednesday: (this.httpResponse.data as cExtra).wednesday,
                thursday: (this.httpResponse.data as cExtra).thursday,
                friday: (this.httpResponse.data as cExtra).friday,
                saturday: (this.httpResponse.data as cExtra).saturday,
                sunday: (this.httpResponse.data as cExtra).sunday,
                extraOptions: (this.httpResponse.data as cExtra).extraOptions,
                tags: (this.httpResponse.data as cExtra).tags,
                price: (this.httpResponse.data as cExtra).price,
                perPerson: (this.httpResponse.data as cExtra).perPerson,
                question: (this.httpResponse.data as cExtra).question,
                answer: (this.httpResponse.data as cExtra).answer,
              }
    
              if(this.Extra.question != "" && this.Extra.question != null)
              {
                this.askQuestion = true;
              }
              
              this.pageReady = true;
            }
            else
            {
              this.messageService.add({severity:'error', summary:'Error', detail:'Error while getting Extras'});
              this.pageReady = false;
            }
          }, error => {
            this.messageService.add({severity:'error', summary:'Error', detail:'Error while getting Extas, error:' + error.messages[0]});
            this.pageReady = false;
          });
        }
        else
        {
          this.pageReady = true;
        }
    
        this.loadExtraOptions(false);
        this.loadTags(false);
        }

        this.CompletedLoading = true;

      }
    }, error => {
      this.messageService.add({severity:'error', summary:'Error', detail:'Error while getting User, error:' + error.messages[0]});
    });


  }

RemoveFromList(product){
  this.Extra.extraOptions = this.Extra.extraOptions.filter(obj => obj.id !== product.id);
}

AddToList(product){
  if(this.Extra.extraOptions == null)
  {
    this.Extra.extraOptions = [];
    this.Extra.extraOptions.push(product);
  }
  else{
    this.Extra.extraOptions.push(product);
  }
}

loadExtraOptions(showdisabled: boolean)
  {
    this.http.get(bmxurl + "ExtraOptionList/" + this.VenueID + "/" + showdisabled).subscribe(response => {
      this.availableExtraOptions = (response as extraoption_returndata).data;


      this.selectedExtraOptions.forEach((value,index)=>{
        this.availableExtraOptions = this.availableExtraOptions.filter((val,i) => val.extraOptionID!=value.extraOptionID);
      });


    }, error => {
    });
}

updateData()
  {
    if (!this.ChooseDays)
    {
      this.Extra.monday = true;
      this.Extra.tuesday = true;
      this.Extra.wednesday = true;
      this.Extra.thursday = true;
      this.Extra.friday = true;
      this.Extra.saturday = true;
      this.Extra.sunday = true;
    }

    if(this.DoValidCheck())
    {
    if (this.selectedExtraOptions.length > 0) {
      this.selectedExtraOptions.forEach((value,index)=>{
        value.sortorder = index;
      });
    }

    if(!this.askQuestion)
    {
      this.Extra.question = "";
    }

    if(this.Extra.question != null)
    {
      this.Extra.question = this.Extra.question.trim();
    }

    if(this.Extra.answer != null)
    {
      this.Extra.answer = this.Extra.answer.trim();
    }

    if(this.id=="0")
    {
      this.http.post<extra>(bmxurl + "/extra", this.Extra).subscribe(response => {
        this.httpResponse = response
        if(this.httpResponse.succeeded == true)
        {
          this.messageService.add({severity:'success', summary:'Saved', detail:'Extra successfully created'});
          this.router.navigateByUrl("/extra");
        }
      }, error => {
        this.messageService.add({severity:'error', summary:'Not Saved', detail:error.messages[0]});
      })
    }
    else
    {
      this.Extra.disabled = false;
      
      this.http.put<extra>(bmxurl + "/extra", this.Extra).subscribe(response => {
        this.httpResponse = response
        if(this.httpResponse.succeeded == true)
        {
          this.messageService.add({severity:'success', summary:'Saved', detail:'Extra successfully updated'});
          this.router.navigateByUrl("/extra");
        }
      }, error => {
        this.messageService.add({severity:'error', summary:'Not Saved', detail:error.messages});
      })
    }
  }
}

DoValidCheck()
{
  let AllValid = true;

  if((this.Extra.question == null || this.Extra.question == "") && this.askQuestion)
  {
    AllValid = false;
    this.questionValid = false;
  }
  else
  {
    this.questionValid = true;
  }


  if(this.Extra.name == null || this.Extra.name == "")
  {
    AllValid = false;
    this.extraNameValid = false;
  }
  else
  {
    this.extraNameValid = true;
  }

  if(this.Extra.description == null || this.Extra.description == "")
  {
    AllValid = false;
    this.extraDescValid = false;
  }
  else
  {
    this.extraDescValid = true;
  }

  return AllValid;
}

loadTags(showdisabled: boolean) {
    this.http.get(bmxurl + "TagList/" + this.VenueID + "/" + showdisabled).subscribe(response => {
      this.tags = (response as tag_returndata).data;

    }, error => {
    });
}

returnToList()
  {
    this.router.navigateByUrl("/extra");
  }
}
