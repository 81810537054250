import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { cVoucher } from 'src/types/voucher';
import { MessageService } from 'primeng/api';
import { HttpClient } from '@angular/common/http';
import { bmxurl, getAccess } from 'src/globals';
import { users_returndata } from 'src/types/user_returndata';
import { voucher_returndata } from 'src/types/voucher_returndata';
import { Payment } from 'src/PackageClasses';
import { paymenttypes_returndata } from 'src/types/paymenttypes_returndata';

@Component({
  selector: 'app-buyvoucher',
  templateUrl: './buyvoucher.component.html',
  styleUrls: ['./buyvoucher.component.css']
})
export class BuyvoucherComponent implements OnInit{
  customAmountValue: number = 0;
  toggle5: boolean = false;
  toggle10: boolean = false;
  toggle20: boolean = false;
  toggle25: boolean = false;
  toggle50: boolean = false;
  toggle100: boolean = false;

  stateOptions: any[];
  NameValid: boolean = true;
  EmailValid: boolean = true;
  EmailFormatValid: boolean = true;

  RecipientNameValid: boolean = true;
  RecipientEmailValid: boolean = true;
  RecipientEmailFormatValid: boolean = true;

  ValueValid: boolean = true;

  messageValid: boolean = true;
  Voucher: cVoucher;
  VenueID: string = localStorage.getItem("SmartVenueID")!;
  httpResponse: any;
  AccessAllowed: boolean = false;
  CompletedLoading: boolean = false;
  pageReady: boolean = true;
  id: string;
  VoucherPayments: Payment[] = [];
  CurrencySymbol: string = localStorage.getItem("Currency") == null ? "GBP" : localStorage.getItem("Currency")!;

  paymentValueValid: boolean = true;
  paymentValueExceededValid: boolean = true;
  paymentDepositValid: boolean = true;
  PaymentTypes: any[] = [];

  VoucherComplete: boolean = false;

  constructor(private route: ActivatedRoute, private http: HttpClient, private router: Router, private messageService: MessageService) { 
    this.stateOptions = [{ label: 'No', value: false }, { label: 'Yes', value: true }];
    this.Voucher = {
      id: null,
      venueid: this.VenueID,
      voucherid: null,
      vouchercode: "",
      voucherpin: 0,
      emailaddress: "",
      customername: "",
      alternativerecipient: false,
      recipientsname: "",
      recipientsemailsaddress: "",
      message: "",
      disabled: false,
      created: new Date(),
      expiry: new Date(),
      initialvalue: 0,
      customamount: false,
      balance: 0,
      payments: []
    }
  }

  returnHome()
  {
    this.Voucher = {
      id: null,
      voucherid: null,
      venueid: this.VenueID,
      vouchercode: "",
      voucherpin: 0,
      emailaddress: "",
      customername: "",
      alternativerecipient: false,
      recipientsname: "",
      recipientsemailsaddress: "",
      message: "",
      disabled: false,
      created: new Date(),
      expiry: new Date(),
      initialvalue: 0,
      customamount: false,
      balance: 0,
      payments: []
    }

    this.VoucherComplete = false

    this.toggle5 = false;
    this.toggle10 = false;
    this.toggle20 = false;
    this.toggle25 = false;
    this.toggle50 = false;
    this.toggle100 = false;
  }

  printConfirmation(){
      window.print();
  }

  sendEmailConfirmation(){
    this.http.get<any>(bmxurl + "sendvoucher/" + this.VenueID + "/" + this.Voucher.voucherid).subscribe(response => {
      this.messageService.add({severity:'success', summary:'Success', detail:'Email Message sent.'})
    }, error => {
      this.messageService.add({severity:'error', summary:'Failure', detail: 'An error occurred whilst trying to send the Email'});
    })
  }

  ngOnInit(): void {

    this.http.get(bmxurl + "paymenttypes").subscribe(response => {
      if((response as paymenttypes_returndata).succeeded)
      {
        this.PaymentTypes = (response as paymenttypes_returndata).data;

        this.PaymentTypes = this.PaymentTypes.filter(pt => pt.system == false);
      }
      else
      {
        this.messageService.add({severity:'error', summary:'Failure', detail:'Failed to get Payment Types.'});
      }
    }, error => {
      this.messageService.add({severity:'error', summary:'Failure', detail:'Failed to get Payment Types.'});
    })
    
    let userid = localStorage.getItem("SmartUserID");

    this.http.get(bmxurl + "User/" + userid).subscribe(response => {
      this.httpResponse =  (response as users_returndata)
      if(this.httpResponse.succeeded)
      {
        let user = this.httpResponse.data;

        if(getAccess("VOUCHERS", user)){
          this.AccessAllowed = true;
        }
      }
    });

    //       this.id = this.route.snapshot.paramMap.get('id');
    //       if(this.id != "0")
    //       {
    //         this.pageReady = false;
    //         this.http.get(bmxurl + "Voucher/" + this.VenueID + "/" + this.id).subscribe(response => {
    //           this.httpResponse = (response as voucher_returndata)
    //           if (this.httpResponse.succeeded)
    //           {
    //             this.Voucher = {
    //               id: (this.httpResponse.data as cVoucher).id,
    //               venueId: this.VenueID,
    //               vouchercode: (this.httpResponse.data as cVoucher).vouchercode,
    //               emailaddress: (this.httpResponse.data as cVoucher).emailaddress,
    //               customername: (this.httpResponse.data as cVoucher).customername,
    //               recipientsname: (this.httpResponse.data as cVoucher).recipientsname,
    //               recipientsemailsaddress: (this.httpResponse.data as cVoucher).recipientsemailsaddress,
    //               message: (this.httpResponse.data as cVoucher).message,
    //               disabled: (this.httpResponse.data as cVoucher).disabled,
    //               created: (this.httpResponse.data as cVoucher).created,
    //               expiry: (this.httpResponse.data as cVoucher).expiry,
    //               initialvalue: (this.httpResponse.data as cVoucher).initialvalue,
    //               customamount: (this.httpResponse.data as cVoucher).customamount,
    //               balance: (this.httpResponse.data as cVoucher).balance,
    //             };
      
    //             this.pageReady = true;
    //           }
    //           else
    //           {
    //             this.messageService.add({severity:'error', summary:'Error', detail:'Error while getting voucher'});
    //             this.pageReady = false;
    //           }
    //         }, error => {
    //           this.messageService.add({severity:'error', summary:'Error', detail:'Error while getting voucher, error:' + error.messages[0]});
    //           this.pageReady = false;
    //         });
    //       }
    //     }

    //     this.CompletedLoading = true;

    //   }
    // }, error => {
    //   this.messageService.add({severity:'error', summary:'Error', detail:'Error while getting User, error:' + error.messages[0]});
    // });

  }

  updateData(id, name)
  {

    if(this.Voucher.customamount)
    {
      this.Voucher.initialvalue = this.customAmountValue;
    }

    if (this.DoValidCheck())
    {
      let newPayment: Payment = {
        id: null,
        PaymentId: null,
        BookingId: null,
        bookingIdHR: null,
        method: name,
        paymenttypeid: id,
        amount: this.Voucher.initialvalue,
        paymentDate: new Date,
        disabled: false,
        venueid: this.VenueID,
        refunds: null,
        balance: this.Voucher.initialvalue,
      }

      let payments: Payment[] = [];

      payments.push(newPayment);

      this.Voucher.payments = payments;

      this.http.post<cVoucher>(bmxurl + "/Voucher", this.Voucher).subscribe(response => {
        this.httpResponse = response
          if(this.httpResponse.succeeded)
          {
            this.Voucher = this.httpResponse.data;

            this.messageService.add({severity:'success', summary:'Saved', detail:'Voucher created successfully'});

            if(this.Voucher.recipientsemailsaddress == "")
            {
              this.Voucher.recipientsemailsaddress = this.Voucher.emailaddress;
              this.Voucher.recipientsname = this.Voucher.customername;
            }

            this.VoucherComplete = true;
            
          }
          }, error => {
            this.messageService.add({severity:'error', summary:'Error', detail:error.messages});
        })

    }
  }

  selectValue(value){
    switch(value){  
      case 5: {
        this.toggle5 = true;
        this.toggle10 = false;
        this.toggle20 = false;
        this.toggle25 = false;
        this.toggle50 = false;
        this.toggle100 = false;
        this.Voucher.customamount = false;
        this.Voucher.initialvalue = 5;
        break;
      }
      case 10: {
        this.toggle5 = false;
        this.toggle10 = true;
        this.toggle20 = false;
        this.toggle25 = false;
        this.toggle50 = false;
        this.toggle100 = false;
        this.Voucher.customamount = false;
        this.Voucher.initialvalue = 10;
        break;
      }
      case 20: {
        this.toggle5 = false;
        this.toggle10 = false;
        this.toggle20 = true;
        this.toggle25 = false;
        this.toggle50 = false;
        this.toggle100 = false;
        this.Voucher.customamount = false;
        this.Voucher.initialvalue = 20;
        break;
      }
      case 25: {
        this.toggle5 = false;
        this.toggle10 = false;
        this.toggle20 = false;
        this.toggle25 = true;
        this.toggle50 = false;
        this.toggle100 = false;
        this.Voucher.customamount = false;
        this.Voucher.initialvalue = 25;
        break;
      }
      case 50: {
        this.toggle5 = false;
        this.toggle10 = false;
        this.toggle20 = false;
        this.toggle25 = false;
        this.toggle50 = true;
        this.toggle100 = false;
        this.Voucher.customamount = false;
        this.Voucher.initialvalue = 50;
        break;
      }
      case 100: {
        this.toggle5 = false;
        this.toggle10 = false;
        this.toggle20 = false;
        this.toggle25 = false;
        this.toggle50 = false;
        this.toggle100 = true;
        this.Voucher.customamount = false;
        this.Voucher.initialvalue = 100;
        break;
      }
    }
  }

  setValue(){
    this.Voucher.initialvalue = this.customAmountValue;
  }
  
  customAmountToggle(){
    this.toggle5 = false;
    this.toggle10 = false;
    this.toggle20 = false;
    this.toggle25 = false;
    this.toggle50 = false;
    this.toggle100 = false;
  }

  DoValidCheck(){
    let AllValid = true;

    if(this.Voucher.customername == "")
    {
      AllValid = false;
      this.NameValid = false;
    }
    else
    {
      this.NameValid = true;
    }

    if(this.Voucher.emailaddress == "")
    {
      AllValid = false;
      this.EmailValid = false;
    }
    else
    {
      this.EmailValid = true;
    }

    if (this.Voucher.emailaddress != "") {
      if (!this.validateEmail(this.Voucher.emailaddress)) {
        AllValid = false;
        this.EmailFormatValid = false;
      }
      else {
        this.EmailFormatValid = true;
      }
    }

    if (this.Voucher.initialvalue <= 0) {
      AllValid = false;
      this.ValueValid = false;
    }
    else {
      this.ValueValid = true;
    }

    if(this.Voucher.alternativerecipient)
    {
      if(this.Voucher.recipientsname == "")
      {
        AllValid = false;
        this.RecipientNameValid = false;
      }
      else
      {
        this.RecipientNameValid = true;
      }
  
      if(this.Voucher.recipientsemailsaddress == "")
      {
        AllValid = false;
        this.RecipientEmailValid = false;
      }
      else
      {
        this.RecipientEmailValid = true;
      }

      if (this.Voucher.recipientsemailsaddress != "") {
        if (!this.validateEmail(this.Voucher.recipientsemailsaddress)) {
          AllValid = false;
          this.RecipientEmailValid = false;
        }
        else {
          this.RecipientEmailValid = true;
        }
      }

      if(this.Voucher.message == "")
      {
        AllValid = false;
        this.messageValid = false;
      }
      else
      {
        this.messageValid = true;
      }
    }

    return AllValid;
  }

  deferPayment(){
  }

  donePayment(id, name){
    this.updateData(id, name);
  }

  validateEmail(email){ 
    var re = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/; 
    return re.test(email); 
  }

}
