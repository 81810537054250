import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AvailableTimes, Package, AvailabilityReq, Admission, SelectedAdmission, BookingReq } from '../../PackageClasses';
import { AppRoutingModule } from '../app-routing.module';
import { admission } from 'src/types/admission';

@Component({
  selector: 'app-home',
  templateUrl: './booking.component.html',
  styleUrls: ['./booking.component.css']
})
export class BookingComponent implements OnInit {
  title = 'WannaBook Client';
  packages: any;
  startTimes: any;
  startTimesFiltered: any;
  thispackage: any;
  productionEnv: boolean = false; // CHANGE ME BEFORE COMMIT TO TRUE
  bmxurl: string;
  PackageAdmissions: admission[];
  SelectedAdmissions: admission[];
  SelectedPackage: any;
  BookingResponse: String;
  DiagnosticMode: boolean = false;
  VenueID: string = localStorage.getItem("SmartVenueID")!;

  constructor(
    private http: HttpClient,
    private router: Router
  ) {}

  ngOnInit(): void {

    this.bmxurl = "https://bmxv2.azurewebsites.net/";

    this.http.get(this.bmxurl + "/packagelist" + this.VenueID).subscribe(response => {
      this.packages = (response as Package)
    }, error => {
      console.log(error);
    })
  }

  numberChange(){
    this.startTimes = null;
    this.startTimesFiltered = null;
  }

  dateChange()
  {
    this.startTimes = null;
    this.startTimesFiltered = null;
  }

  onPackageSelect(PackageId: number)
  {
      this.startTimes = null;
      this.startTimesFiltered = null;

      this.SelectedAdmissions = [];
      this.PackageAdmissions = this.packages.find(x => {
        return this.SelectedPackage = x.packageId == PackageId
      }).admissions as admission[];

      this.PackageAdmissions.forEach(adm => {
        var newAdm: admission = {
        id: adm.id,
        admissionid: adm.admissionid,
        classid: adm.classid,
        noofpeople: adm.min,
        price: adm.price,
        name: adm.name,
        index: adm.index,
        max: adm.max,
        min: adm.min,
        venueid: adm.venueid,
        disabled: adm.disabled,
        bookingwizardtext: adm.bookingwizardtext,
        mustinclude: adm.mustinclude,
        participant: adm.participant,
        fixedPrice: adm.fixedPrice,
        admissionClass: adm.admissionClass,
        eventtickettype: adm.eventtickettype, 
        counter: adm.counter,
        uselinkeddiscounts: adm.uselinkeddiscounts,
        linkedadmissions: adm.linkedadmissions,
        linkeddiscounts: adm.linkeddiscounts,
        uselinkedadmissions: adm.uselinkedadmissions,
        maxlinkeddiscounts: adm.maxlinkeddiscounts,
        maxlinkedadmissions:adm.maxlinkedadmissions,
        fixed: adm.fixed,
        onlysetmax: adm.onlysetmax
      }

        this.SelectedAdmissions.push(newAdm);
      });
  }

  getAvailability(){

    let packageId = document.getElementById("ddlPackage") as HTMLSelectElement;
    let selectedTime = document.getElementById("txtDate") as HTMLInputElement;
    let totalNoOfPeople: number = 0;

    const AvailabilityReq = {} as AvailabilityReq;

    AvailabilityReq.Admissions = [] as admission[];

    this.SelectedAdmissions.forEach(adm => {
      AvailabilityReq.Admissions.push(adm);
      totalNoOfPeople += adm.noofpeople;
   });

    AvailabilityReq.packageId = Number.parseInt(packageId.value);
    AvailabilityReq.NoOfPeople =  totalNoOfPeople;
    //AvailabilityReq.StartTime = selectedTime.value;
    AvailabilityReq.DiagnosticMode = this.DiagnosticMode;

    this.http.post<AvailableTimes>(this.bmxurl + "/GetAvailability", AvailabilityReq).subscribe(response => {
      this.startTimes =(response as AvailableTimes)
      let found: Boolean = false;

      this.startTimesFiltered = [];
      this.startTimes.forEach(element => {
        if(element.timeAvailable)
        {
          this.startTimesFiltered.forEach(innerelement => {
            if(innerelement.time == element.time)
              found = true;
          });

          if(!found)
          {
            this.startTimesFiltered.push(element);
          }
          found = false;
        }
      });
      console.log(response);
    }, error => {
      console.log(error);
    })
  }


  bookSlot(time: Date)
  {
    //Get Package

    this.http.get(this.bmxurl + "/Package/" + this.VenueID + "/" + this.SelectedPackage.PackageId).subscribe(response => {
      this.thispackage =(response as Package)
    }, error => {
      console.log(error);
    })

    let totalNoOfPeople: number = 0;
    const BookingReq = {} as BookingReq;

    BookingReq.Admissions = [] as admission[];

    this.SelectedAdmissions.forEach(adm => {
      BookingReq.Admissions.push(adm);
      totalNoOfPeople += adm.noofpeople;
   });

    BookingReq.packageId = this.SelectedPackage.PackageId;
    BookingReq.NoOfPeople =  totalNoOfPeople;
    BookingReq.StartTime = time;
    BookingReq.PackageName = this.SelectedPackage.PackageName;
    BookingReq.VenueID = "";

    this.http.post<string>(this.bmxurl + "/booking", BookingReq).subscribe(response => {
      this.BookingResponse =(response as string)
      console.log(response);
    }, error => {
      console.log(error);
    })

    this.startTimes = null;
    this.startTimesFiltered = null;
  }

}

function getUrlParameter(name) {
  name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
  var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
  var results = regex.exec(location.search);
  return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
};
