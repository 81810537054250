<div *ngIf="!AccessAllowed && CompletedLoading">
  <h3 style="border: 2px solid;border-radius: 6px;position: absolute;top: 50%;left: 50%;transform: translate(-50%, -50%);padding: 10px;">You don't have access to this function</h3>
</div>

<div *ngIf="pageReady && AccessAllowed" class="p-3 flex flex-column flex-auto">
    <div>
        <div class="text-900 font-medium text-xl mb-3"><h3>Package Group</h3></div>
        <p class="m-0 mb-4 p-0 text-600 line-height-3 mr-3">
            <span class="pi pi-info-circle" style="margin-right:2px;"></span>
            Give the group a meaningful name, assign a colour and then add all of the individual packages to the group.
        </p>
        <div class="surface-card p-4 shadow-2 border-round">
            <div class="grid formgrid p-fluid">

            <div style="width:100%">
              <div class="field mb-4 col-12">
                  <label for="pgname" class="font-medium">Name of the Package Group</label>
                  
                  <input *ngIf="PackageGroup.packagegroupname == 'Popular'" [disabled]="true" [(ngModel)]="PackageGroup.packagegroupname" id="pgname" type="text" [ngStyle]="{'border-color': (packagegroupNameValid==false) ? '#FF0000':''}" (keyup)="DoValidCheck()" pInputText>
                  <input *ngIf="PackageGroup.packagegroupname != 'Popular'" [(ngModel)]="PackageGroup.packagegroupname" id="pgname" type="text" [ngStyle]="{'border-color': (packagegroupNameValid==false) ? '#FF0000':''}" (keyup)="DoValidCheck()" pInputText>
             
              </div>
              <div *ngIf="!packagegroupNameValid" class="text-danger" Style="margin-left:10px; margin-top: -23px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>You must enter an Package Group Name.</div>
            </div>

            <div class="field mb-4 col-12">
                <label for="colour" class="font-medium">Colour</label><br/>
                <p-colorPicker [style]="{'margin-right': '10px'}" [inline]="false" id="colour" [(ngModel)]="PackageGroup.colour"></p-colorPicker>
                <input style="width:200px;" [(ngModel)]="PackageGroup.colour" id="hexcolour" type="text" pInputText>
            </div>

            <div *ngIf="!packagegroupListValid" class="text-danger" Style="margin-left:10px; margin-top: -23px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>You must add packages to your package group.</div>


            <div style="clear:both; width:100%">
              &nbsp;
            </div>

            <div class="col-12 md:col-6 drag-column">
              <h5>Available Packages</h5>

              <p-table [value]="ListPackages">
                <ng-template pTemplate="header">
                    <tr>
                        <th style="width:90%">Name</th>
                        <th style="width:10%">&nbsp;</th>
                    </tr>
                </ng-template>
                    <ng-template pTemplate="body" let-product>
                      <tr pDraggable="products" (onDragStart)="dragStart($event, product)" (onDragEnd)="dragEnd()">
                          <td>{{product.name}}</td>
                          <td>
                            <button pButton pRipple type="button" [style]="{'margin-bottom': '-6px', 'margin-top': '-4px !important'}" class="p-button-sm p-element p-button-success w-auto mt-3" (click)="AddToList(product)">
                              <span class="pi pi-angle-right"></span>
                            </button>
                          </td>
                      </tr>
                    </ng-template>

              </p-table>
                <div *ngIf="ListPackages == null" style="padding: 15px;">
                  <i>No extras available, add some <a href="/packages/0">here</a></i>
                </div>
            </div>

            <div class="col-12 md:col-6 drop-column" pDroppable="products" (onDrop)="drop()">
            <h5>Selected Packages</h5>

              <p-table [value]="PackageGroup.packages">
                <ng-template  pTemplate="header" let-columns>
                    <tr>
                        <th>&nbsp;</th>
                        <th style="width:70%">Name</th>
                        <th style="width:10%">&nbsp;</th>
                    </tr>
                </ng-template>
                    <ng-template pTemplate="body" let-product let-columns="columns" let-index="rowIndex">
                      <tr [pReorderableRow]="index">
                        <td>
                          <span class="pi pi-bars" pReorderableRowHandle></span>
                      </td>
                          <td>{{product.name}}</td>
                          <td>
                            <button pButton pRipple type="button" [style]="{'margin-bottom': '-6px', 'margin-top': '-4px !important'}" class="p-button-sm p-element p-button-danger w-auto mt-3" (click)="RemoveFromList(product)">
                              <span class="pi pi-angle-left"></span>
                            </button>
                          </td>
                      </tr>
                    </ng-template>

                </p-table>

            </div>
            </div>

              <div class="col-12">
                  <button pButton pRipple type="button" class="p-button p-element p-button-success w-auto mt-3" (click)="updateData();">
                  <span class="pi pi-pencil" style="margin-right:8px;"></span>
                  Save Changes</button>
                  &nbsp;
                  <button pButton pRipple type="button" class="p-button p-element p-button-danger w-auto mt-3" (click)="returnToList()">
                  <span class="pi pi-times" style="margin-right:8px;"></span>
                  Cancel</button>
              </div>
          </div>

    </div>
</div>
