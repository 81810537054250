<div *ngIf="!AccessAllowed && CompletedLoading">
    <h3 style="border: 2px solid;border-radius: 6px;position: absolute;top: 50%;left: 50%;transform: translate(-50%, -50%);padding: 10px;">You don't have access to this function</h3>
</div>

<div *ngIf="pageReady && AccessAllowed" class="p-3 flex flex-column flex-auto">
    <div>
        <div class="text-900 font-medium text-xl mb-3"><h3>Deposit Policy
        </h3></div>
        <p class="m-0 mb-4 p-0 text-600 line-height-3 mr-3">
            <span class="pi pi-info-circle" style="margin-right:2px;"></span>
            Give the deposit policy a descriptive name and then define how this policy works.
        </p>
        <div class="surface-card p-4 shadow-2 border-round">
            <div class="grid formgrid p-fluid">
                <div class="field mb-4 col-12">
                    <label for="classname" class="font-medium">Deposit Policy Name</label>
                    <input [(ngModel)]="DepositPolicy.name" id="classname" type="text" [ngStyle]="{'border-color': (DepositPolicyNameValid==false) ? '#FF0000':''}" (keyup)="DoValidCheck()" pInputText>
                </div>
                <div *ngIf="!DepositPolicyNameValid" class="text-danger" Style="margin-left:10px; margin-top: -23px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>You must enter a Deposit Policy name.</div>
                <div class="field mb-4 col-12">
                    <label for="depositpolicytype" class="font-medium">Deposit Policy Type</label>
                    <p-selectButton id="depositpolicytype" [style]="{'width' : '600px'}" [options]="stateOptions" [(ngModel)]="DepositPolicy.type" ></p-selectButton>
                </div>

                <div *ngIf="DepositPolicy.type != 'NoDeposit'" class="field mb-4 col-12">
                    <label for="depositpolicytype" class="font-medium">Amount</label>
                    <p-inputNumber *ngIf="DepositPolicy.type == 'Fixed' || DepositPolicy.type == 'PerPerson' " [(ngModel)]="DepositPolicy.amount" mode="currency" currency="{{CurrencySymbol}}" locale="en-GB" [min]="0" [max]="9999"></p-inputNumber>
                    <p-inputNumber *ngIf="DepositPolicy.type == 'Percentage'" [(ngModel)]="DepositPolicy.amount" [maxFractionDigits]="2" suffix="%" locale="en-GB" [min]="0" [max]="9999"> </p-inputNumber>
                </div>

                <div *ngIf="DepositPolicy.type == 'Percentage'">
                    <div class="field mb-4 col-12">
                        <label for="depositpolicytype" class="font-medium">Use a different Percentage if booking is closer to Start Date?</label>
                        <p-selectButton [style]="{'width': '300px'}" [options]="stateOptions2" [(ngModel)]="alternativepercentage" ></p-selectButton>
                    </div>

                    <div *ngIf="alternativepercentage">
                        <div class="field mb-4 col-12">
                            <label for="depositpolicytype" class="font-medium">How many days before the Start Date do you want use this percentage</label>
                            <p-inputNumber id="concurrentUses" [style]="{'width': '300px'}" [(ngModel)]="DepositPolicy.alternativeamountthreshold== null ? 0: DepositPolicy.alternativeamountthreshold" [showButtons]="true" max="999" min="0" buttonLayout="horizontal" spinnerMode="horizontal"></p-inputNumber>
                        </div>

                        <div class="field mb-4 col-12">
                            <label for="depositpolicytype" class="font-medium">Percentage to use if closer to Start Date</label>
                            <p-inputNumber [(ngModel)]="DepositPolicy.alternativeamount" [maxFractionDigits]="2" suffix="%" locale="en-GB" [min]="0" [max]="9999"> </p-inputNumber>
                        </div>
                    </div>
                </div>

                <div class="col-12">
                    <button pButton pRipple type="button" class="p-button p-element p-button-success w-auto mt-3" (click)="updateData();">
                    <span class="pi pi-pencil" style="margin-right:8px;"></span>
                    Save Changes</button>
                    &nbsp;
                    <button pButton pRipple type="button" class="p-button p-element p-button-danger w-auto mt-3" (click)="returnToList()">
                    <span class="pi pi-times" style="margin-right:8px;"></span>
                    Cancel</button>
                </div>
            </div>
        </div>
    </div>
</div>