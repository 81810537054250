import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router'
import { HttpClient } from '@angular/common/http';
import { bmxurl, getAccess } from 'src/globals';
import { bookingfee_returndata } from 'src/types/bookingfee_returndata'; 
import { Router, RouterLink } from '@angular/router';
import { cBookingfee } from 'src/types/bookingfee';
import { returnmessage } from 'src/types/returnmessage';
import { MessageService } from 'primeng/api';

import { cTerms } from 'src/types/terms';
import { terms_returndata } from 'src/types/terms_returndata';
import { users_returndata } from 'src/types/user_returndata';

@Component({
  selector: 'app-termsandconditions',
  templateUrl: './termsandconditions.component.html',
  styleUrls: ['./termsandconditions.component.css']
})

export class TermsandconditionsComponent implements OnInit {
  VenueID: string = localStorage.getItem("SmartVenueID")!;
  tandc: cTerms;
  httpResponse: any;
  id: string;
  pageReady: boolean = true;
  tandcValid: boolean = true; 

  AccessAllowed: boolean = false;
  CompletedLoading: boolean = false;


  constructor(private route: ActivatedRoute, private http: HttpClient, private router: Router, private messageService: MessageService) {
    this.tandc = {
      id: null,
      venueid: this.VenueID,
      startdate: new Date(),
      enddate: new Date(3000,12,31,23,59,59),
      terms: ""
    }

   }

  ngOnInit(): void {

    localStorage.removeItem("screenMemoryBookingss");
    localStorage.removeItem("screenMemoryEvents");
    
    let userid = localStorage.getItem("SmartUserID");

    this.http.get(bmxurl + "User/" + userid).subscribe(response => {
      this.httpResponse =  (response as users_returndata)
      if(this.httpResponse.succeeded)
      {
        let user = this.httpResponse.data;

        if(getAccess("TANDCS", user)){
          this.AccessAllowed = true;
          this.pageReady = false;
          this.http.get(bmxurl + "terms/" + this.VenueID).subscribe(response => {
            this.httpResponse = (response as terms_returndata)
            if (this.httpResponse.succeeded)
            {
              if(this.httpResponse.data == null || this.httpResponse.data == 0)
              {
                this.messageService.add({severity:'warn', summary:'No T&Cs', detail:'No Terms & Conditions have been set up yet.'});
              }
              else
              {
                this.tandc = {
                  id: (this.httpResponse.data as cTerms).id,
                  venueid: (this.httpResponse.data as cTerms).venueid,
                  startdate: (this.httpResponse.data as cTerms).startdate,
                  enddate: (this.httpResponse.data as cTerms).enddate,
                  terms: (this.httpResponse.data as cTerms).terms,
                };
              }
      
              this.pageReady = true;
            }
            else
            {
              this.messageService.add({severity:'warn', summary:'No T&Cs', detail:'No Terms & Conditions have been set up yet.'});
              this.pageReady = true;
            }
          }, error => {
            this.messageService.add({severity:'error', summary:'Error', detail:'Error while getting Terms & Conditions, error:' + error.messages[0]});
            this.pageReady = false;
          });
        }

        this.CompletedLoading = true;

      }
    }, error => {
      this.messageService.add({severity:'error', summary:'Error', detail:'Error while getting User, error:' + error.messages[0]});
    });


  }

  updateData()
  {
    if (this.DoValidCheck())
    {
      this.http.post<cBookingfee>(bmxurl + "/terms/" + this.VenueID, this.tandc).subscribe(response => {
        this.httpResponse = response
          if(this.httpResponse.succeeded)
          {
            this.messageService.add({severity:'success', summary:'Saved', detail:'Terms and Conditions successfully updated'});
            this.router.navigateByUrl("/termsandconditions");
          }
          }, error => {
            this.messageService.add({severity:'error', summary:'Not Saved', detail:error.messages});
        })
    }
  }

  DoValidCheck()
  {
    let AllValid = true;

    if(this.tandc.terms)
    {
      if(this.tandc.terms == "")
      {
        this.tandcValid = false;
        AllValid = false;
      }
    }

    return AllValid;
  }

  returnToList()
  {
    this.router.navigateByUrl("/home");
  }

}
