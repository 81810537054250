import { Component, OnInit } from '@angular/core';
import { user } from 'src/types/users';
import { HttpClient } from '@angular/common/http';
import { bmxurl } from 'src/globals';
import { pinauth } from 'src/types/pinauth';
import { ActivatedRoute, Router } from '@angular/router';
import {MessageService} from 'primeng/api';
import { GlobalService } from '../_services/global.service';
import { pinchange_returndata } from 'src/types/pinchange_returndata';
import { pinchange } from 'src/types/pinchange';
import { AuthService } from '@auth0/auth0-angular';
import { businessVenue } from 'src/types/businessVenues';
import { BusyService } from '../_services/busy.service';
import { users_returndata } from 'src/types/user_returndata';

@Component({
  selector: 'app-pinpad',
  templateUrl: './pinpad.component.html',
  styleUrls: ['./pinpad.component.css']
})
export class PinpadComponent implements OnInit {

  pinNumber: string = "";
  Users: user[];
  selectedUser: string;
  selecteduserId: string;
  pinAuth: pinauth;
  VenueID: string = localStorage.getItem("SmartVenueID")!;
  httpResponse: any;
  showPasswordChange: boolean = false;
  NewPIN: string;
  RepeatNewPIN: string;
  PasswordsMatch: boolean = true;
  showPINPad: boolean = false;
  PINHeader: string = "";
  selecteduserNewPin: boolean = false;
  businessOwner: boolean = false;
  

  constructor(private route: ActivatedRoute, private http: HttpClient, private router: Router, private messageService: MessageService, private globalService: GlobalService, public auth: AuthService,private busyService: BusyService) { 

    let interval: number = 1 * 60 * 1000; // 1 minute
    //set up a heartbeat to keep the session alive
    setInterval(() => {
      this.http.get(bmxurl + "/heartbeat").subscribe(response => {
        this.httpResponse = response
        if(this.httpResponse.succeeded == false)
        {
          this.messageService.add({severity:'error', summary:'Error', detail:this.httpResponse.messages[0]});
        }
        else
        {
          //prevent the JWT from expiring
          this.auth.getAccessTokenSilently({cacheMode: 'off'}).subscribe(token => {
            const parts = token.split('.');
            const decoded = JSON.parse(atob(parts[1]));
          }, error => {
            this.messageService.add({severity:'error', summary:'Error', detail:error.messages[0]});
          })

        }
      }, error => {
        this.messageService.add({severity:'error', summary:'Error', detail:error.messages[0]});
      })
    }, interval);
  }

  logEntry(inText: string){
    // this.http.get(bmxurl + "/log?message=" + inText).subscribe(response => {
    //   this.httpResponse = response
    //   if (this.httpResponse.succeeded)
    //   {

    //   }
    //   else
    //   {
    //     this.messageService.add({severity:'error', summary:'Error', detail:'Error while getting Version Number'});
    //   }
    // }, error => {
    //   this.messageService.add({severity:'error', summary:'Error', detail:'Error while getting Version Number, error:' + error.messages[0]});
    // });

  }

  ngOnInit(): void {
    //Get Users from JWT

    this.logEntry("Loaded Pinpad");

    this.globalService.setShowPasswordChange(false);

    this.RepeatNewPIN = "";
    this.pinNumber = "";
    this.NewPIN = "";

    this.auth.getAccessTokenSilently({cacheMode:"off"}).subscribe(token => {
      this.busyService.busy();
      const parts = token.split('.');
      const decoded = JSON.parse(atob(parts[1]));
      let BusinessID = decoded["user.metadata"].businessid;
      let VenueID = decoded["user.metadata"].vid;

      localStorage.setItem("SmartVenueID", VenueID);

      let userid = decoded["user.metadata"].businessuserid;
      let venuename = decoded["user.metadata"].venuename;
      let businessid = decoded["user.metadata"].businessid;
      let multivenueuser = decoded["user.metadata"].multivenueuser;

      localStorage.setItem("SmartUserID", userid);
      localStorage.setItem("SmartBusinessID", businessid);

      localStorage.setItem("SmartVenueName", venuename);

      let users: user[] =  (decoded["app.users"] as user[]);
      let venues: businessVenue[] = (decoded["app.venues"] as businessVenue[]);

      let businessowner = decoded["user.metadata"].businessowner;
      if(businessowner == "true")
      {
        localStorage.setItem("businessowner", "TRUE");
        this.businessOwner = true;
      }
      else
      {
        localStorage.setItem("businessowner", "FALSE");
        this.businessOwner = false;
      }

      if(multivenueuser == "true")
      {
        localStorage.setItem("SmartMultiVenueUser", "TRUE");
      }
      else
      {
        localStorage.setItem("SmartMultiVenueUser", "FALSE");
      }
        
    localStorage.setItem("SmartBusinessVenues", JSON.stringify(venues));
    localStorage.setItem("SmartUsers", JSON.stringify(users));

    this.globalService.setVenueName(venuename);
    this.Users = (JSON.parse(localStorage.getItem("SmartUsers")) as user[]);

    if(this.Users.length == 1)
    {
      this.selectName(this.Users[0].name, this.Users[0].userid, this.Users[0].temppin);
     }

    this.busyService.idle();
      
    }),
    error => {
      this.busyService.idle();
    };
  }
  

  selectName(name: string, userid: string, newPin: boolean){
    this.selectedUser = name;
    this.selecteduserId = userid;
    this.selecteduserNewPin = newPin;

    this.PINHeader = "Please enter your PIN for " + this.selectedUser

    this.showPINPad = true;
  }

  doButtonPress(inNum: string){
    if(this.pinNumber.length < 6)
      this.pinNumber += inNum;
  }

  clear(){
    //this.selectedUser = "";
    this.selecteduserId = "";
    this.pinNumber = "";
  }

  login(){

    if(this.selecteduserId != null) 
    {
      this.pinAuth = {
        userid: this.selecteduserId,
        venueid: localStorage.getItem("VenueID"),
        pin: this.pinNumber
      }

      this.http.post<pinauth>(bmxurl + "/pinauth", this.pinAuth).subscribe(response => {
        this.httpResponse = response
        if(this.httpResponse.succeeded == true)
        {
          let firstTime:boolean = this.selecteduserNewPin;

          if(!firstTime)
          {
            localStorage.setItem("SmartUserID", this.selecteduserId);
            this.globalService.setShowPasswordChange(true);
            this.router.navigateByUrl("home");
          }
          else
          {
            localStorage.setItem("SmartUserID", this.selecteduserId);
            this.showPasswordChange = true;
          }
        }
        else{
          this.messageService.add({severity:'error', summary:'Error', detail:this.httpResponse.messages[0]});
        }
      }, error => {
        this.messageService.add({severity:'error', summary:'Error', detail:error.messages[0]});
      })
    }
    else
    {
      this.messageService.add({severity:'error', summary:'Error', detail: 'You must select a user.'});
    }
  }

  doPINChange(){
    if(this.NewPIN == this.RepeatNewPIN)
    {
      let pinChange: pinchange = {
        oldpin: this.pinNumber,
        newpin: this.NewPIN
      }

      this.http.post<pinchange_returndata>(bmxurl + "/PinChange", pinChange).subscribe(response => {
        this.httpResponse = response
        if(this.httpResponse.succeeded == true)
        {
          this.messageService.add({severity:'success', summary:'Success', detail:'Your PIN has been changed.'});
          localStorage.setItem("SmartUserID", this.selecteduserId);

          this.globalService.setShowPasswordChange(true);

          this.RepeatNewPIN = "";
          this.pinNumber = "";
          this.NewPIN = "";

          this.showPasswordChange = false;

          this.router.navigateByUrl("/home");
        }
        else{
          this.messageService.add({severity:'error', summary:'Error', detail:this.httpResponse.messages[0]});
        }
      }, error => {
        this.messageService.add({severity:'error', summary:'Error', detail:error.messages[0]});
      })
    }
    else
    {
      this.PasswordsMatch = false;
    }
  }
}
