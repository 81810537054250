import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router'
import { HttpClient } from '@angular/common/http';
import { bmxurl, getAccess } from 'src/globals';
import { class_returndata } from 'src/types/class_returndata'; 
import { Router, RouterLink } from '@angular/router';
import { cClass, classes } from 'src/types/class';
import { returnmessage } from 'src/types/returnmessage';
import {MessageService} from 'primeng/api';

import { cResourcegroupname } from 'src/types/resourcegroupname';
import { resourcegroupname_returndata } from 'src/types/resourcegroupname_returndata';
import { cResource, resource } from 'src/types/resource';
import { resource_returndata } from 'src/types/resource_returndata';
import { v4 as uuidv4  } from 'uuid';
import { users_returndata } from 'src/types/user_returndata';


@Component({
  selector: 'app-resourcegroupnameedit',
  templateUrl: './resourcegroupnameedit.component.html',
  styleUrls: ['./resourcegroupnameedit.component.css']
})
export class ResourcegroupnameeditComponent implements OnInit {
  VenueID: string = localStorage.getItem("SmartVenueID")!;
  resourcegroupname: cResourcegroupname;
  httpResponse: any;
  id: string;
  pageReady: boolean = true;
  allresources: resource[];

  rgnNameValid: boolean = true;
  rgnQLabelValid: boolean = true;

  AccessAllowed: boolean = false;
  CompletedLoading: boolean = false;
  
  constructor(private route: ActivatedRoute, private http: HttpClient, private router: Router, private messageService: MessageService) { 
    this.resourcegroupname = {
      id: null,
      resourcegroupnameId: null,
      name: "",
      quantityLabel: "",
      venueid: this.VenueID,
      disabled: false,
      resources: null
    }
  }
  
  ngOnInit(): void {

    let userid = localStorage.getItem("SmartUserID");

    this.http.get(bmxurl + "User/" + userid).subscribe(response => {
      this.httpResponse =  (response as users_returndata)
      if(this.httpResponse.succeeded)
      {
        let user = this.httpResponse.data;

        if(getAccess("RESOURCES", user)){
          this.AccessAllowed = true;

          this.id = this.route.snapshot.paramMap.get('id');
          if(this.id != "0")
          {
            this.pageReady = false;
            this.http.get(bmxurl + "resourcegroupname/" + this.VenueID + "/" + this.id).subscribe(response => {
              this.httpResponse = (response as resourcegroupname_returndata)
              if (this.httpResponse.succeeded)
              {
                this.resourcegroupname = {
                  id: (this.httpResponse.data as cResourcegroupname).id,
                  resourcegroupnameId: (this.httpResponse.data as cResourcegroupname).resourcegroupnameId,
                  name: (this.httpResponse.data as cResourcegroupname).name,
                  quantityLabel: (this.httpResponse.data as cResourcegroupname).quantityLabel,
                  venueid: (this.httpResponse.data as cResourcegroupname).venueid,
                  disabled: (this.httpResponse.data as cResourcegroupname).disabled,
                  resources: (this.httpResponse.data as cResourcegroupname).resources
                };
                this.pageReady = true;
              }
              else
              {
                this.messageService.add({severity:'error', summary:'Error', detail:'Error while getting Resource Group Name'});
                this.pageReady = false;
              }
            }, error => {
              this.messageService.add({severity:'error', summary:'Error', detail:'Error while getting Resource Group Name, error:' + error.messages[0]});
              this.pageReady = false;
            });
          }
      
          this.http.get(bmxurl + "resourcelist/" + this.VenueID).subscribe(response => {
            this.allresources = (response as resource_returndata).data;
      
            //Get rid of already selected resources
      
            this.resourcegroupname.resources.forEach(element => {
              this.allresources = this.allresources.filter(x => x.resourceId != element.resourceId);
            });
          }, error => {
            this.messageService.add({severity:'error', summary:'Error', detail:'Error while getting Resources'});
                  this.pageReady = false;
          });
        }

        this.CompletedLoading = true;

      }
    }, error => {
      this.messageService.add({severity:'error', summary:'Error', detail:'Error while getting User, error:' + error.messages[0]});
    });

  }

  updateData()
  {
    if (this.DoValidCheck())
    {
      if(this.id == "0")
      {
        this.http.post<cResourcegroupname>(bmxurl + "/resourcegroupname", this.resourcegroupname).subscribe(response => {
          this.httpResponse = response
            if(this.httpResponse.succeeded)
            {
              this.messageService.add({severity:'success', summary:'Saved', detail:'Resource Group Name successfully updated'});
              this.router.navigateByUrl("/resourcegroupnames");
            }
            }, error => {
              this.messageService.add({severity:'error', summary:'Not Saved', detail:error.messages});
          })
      }
      else
      {
        this.resourcegroupname.disabled = false;
        
        this.http.put<cResourcegroupname>(bmxurl + "/resourcegroupname", this.resourcegroupname).subscribe(response => {
        this.httpResponse = response
          if(this.httpResponse.succeeded)
          {
            this.messageService.add({severity:'success', summary:'Saved', detail:'Resource Group Name successfully updated'});
            this.router.navigateByUrl("/resourcegroupnames");
          }
          }, error => {
            this.messageService.add({severity:'error', summary:'Not Saved', detail:error.messages});
        })
      }
    }
  }

  DoValidCheck()
  {
    let AllValid = true;

    if(this.resourcegroupname.name == null || this.resourcegroupname.name == "")
    {
      AllValid = false;
      this.rgnNameValid = false;
    }
    else
    {
      this.rgnNameValid = true;
    }

    return AllValid;
  }

  returnToList()
  {
    this.router.navigateByUrl("/resourcegroupnames");
  }

  AddToResources(resource){
    if(this.resourcegroupname.resources == null)
      this.resourcegroupname.resources = [];

    let newRes: cResource = {
      groupname: "",
      id: uuidv4(),
      resourceId:resource.resourceId,
      name:resource.name,
      useClasses:resource.useClasses,
      exclusive:resource.exclusive,
      eventid: resource.eventid,
      concurrentUses:resource.concurrentUses,
      maxConcurrent:resource.maxConcurrent,
      disabled:resource.disabled,
      venueId:resource.venueId,
      colour:resource.colour,
      orderindex:resource.orderindex,
      length:resource.length,
      classes: resource.classes,
      showonbooking: resource.showonbooking,
      resources:resource.resources,
      isresourcecontainer:resource.isresourcecontainer,
      isconstructed: resource.isconstructed,
      resourcegroupname: resource.resourcegroupname,
      price:resource.price,
      fixedprice: resource.fixedprice,
      tags: resource.tags,
      resourcegroup: resource.resourcegroup,
      selectionmode: resource.selectionmode,
      selectableresources: resource.selectableresources,
      startwhen: resource.startwhen,
      numofminutes: resource.numofminutes,
      optional: resource.optional,
      quantityselector: resource.quantityselector,
      quantity: resource.quantity,
      mode: resource.mode,
      inline: resource.inline,
      ignore: resource.ignore,
      admissions: resource.admissions,
      inProgress: resource.inProgress,
      originatingid: resource.originatingid,
      wasoptional: resource.wasoptional,
      wasselected: resource.wasselected
    }

    this.resourcegroupname.resources.push(newRes);
    newRes = null;
    this.allresources = this.allresources.filter(x => x.resourceId != resource.resourceId);
  }

  removeFromResources(resource){
    this.resourcegroupname.resources = this.resourcegroupname.resources.filter(obj => obj.resourceId !== resource.resourceId);
    this.allresources.push(resource);
  }
}
