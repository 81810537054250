import { Component, ElementRef, OnInit, QueryList, ViewChildren } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppComponent } from '../app.component';
import { tillproducts_returndata } from 'src/types/tillproduct_returndata'; 
import { bmxurl, getAccess } from 'src/globals';
import { NgModule } from '@angular/core';
import { NgModel } from '@angular/forms';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { tillproduct } from 'src/types/tillproduct';
import { MessageService } from 'primeng/api';
import { Checkbox } from 'primeng/checkbox';
import { departments_returndata } from 'src/types/departments_returndata';
import { cDepartments } from 'src/types/departments';
import { users_returndata } from 'src/types/user_returndata';

@Component({
  selector: 'app-tillproducts',
  templateUrl: './tillproducts.component.html',
  styleUrls: ['./tillproducts.component.css']
})

export class TillproductsComponent implements OnInit {
  ListProducts: tillproduct[];
  ListFiltersProducts: tillproduct[];
  bmxurl: string;
  VenueID: string = localStorage.getItem("SmartVenueID")!;
  httpResponse: any;
  Disabledproduct: any;
  checked: boolean = true;
  isDisabled: boolean = true;
  selectedItems: any;
  Department: any;
  id: any;
  pageReady: boolean;

  AccessAllowed: boolean = false;
  CompletedLoading: boolean = false;

  constructor(
    private route: ActivatedRoute, private http: HttpClient, private appComp: AppComponent, private router: Router, private messageService: MessageService) { 
    

    }

  ngOnInit(): void {

    localStorage.removeItem("screenMemoryBookingss");
    localStorage.removeItem("screenMemoryEvents");

    let userid = localStorage.getItem("SmartUserID");

    this.http.get(bmxurl + "User/" + userid).subscribe(response => {
      this.httpResponse =  (response as users_returndata)
      if(this.httpResponse.succeeded)
      {
        let user = this.httpResponse.data;

        if(getAccess("TILLDEPARTMENTS", user)){
          this.AccessAllowed = true;
          this.loadgrid(false);
        }

        this.CompletedLoading = true;
        
      }
    }, error => {
      this.messageService.add({severity:'error', summary:'Error', detail:'Error while getting User, error:' + error.messages[0]});
    });


  }

  toggleDisabled(){
    this.loadgrid(!this.isDisabled);
  }

  disableData(productID)
  {
    this.http.delete(bmxurl + "product/" + this.VenueID + "/" + productID).subscribe(response => {
      this.httpResponse = response
      if(this.httpResponse.succeeded == true)
      {
        this.messageService.add({severity:'success', summary:'Disabled', detail:'product successfully disabled'});
        this.router.navigateByUrl("/product");

        this.loadgrid(false);
      }
    }, error => {
      this.messageService.add({severity:'error', summary:'Not Disabled', detail: 'An error occurred disabling this product'});
    })
  }
  
  loadgrid(showdisabled: boolean)
  {
    this.id = this.route.snapshot.paramMap.get('id');
    if(this.id != "0")
    {
      this.http.get(bmxurl + "tilldepartments/" + this.VenueID + "/" + this.id).subscribe(response => {
        this.httpResponse = (response as departments_returndata)
        if (this.httpResponse.succeeded)
        {
          this.Department = {
            id: (this.httpResponse.data as cDepartments).id,
            name: (this.httpResponse.data as cDepartments).name,
            venueId: (this.httpResponse.data as cDepartments).venueid,
            disabled: (this.httpResponse.data as cDepartments).disabled,
            colour: (this.httpResponse.data as cDepartments).colour
          };

          this.http.get(bmxurl + "tillproductList/" + this.VenueID + "/" + showdisabled).subscribe(response => {
            this.httpResponse = response
            if(this.httpResponse.succeeded)
            {
              this.ListProducts = (response as tillproducts_returndata).data;
              this.ListFiltersProducts = this.ListProducts.filter(x => x.departmentId == this.Department.id)
            }
            else
            {
              this.messageService.add({severity:'error', summary:'Not Saved', detail:'Error getting Products, error: ' + this.httpResponse.messages[0]});
            }
          }, error => {
            this.messageService.add({severity:'error', summary:'Not Saved', detail: 'Error getting Products, error: ' + error.messages});
          });

          this.pageReady = true;
        }
        else
        {
          this.messageService.add({severity:'error', summary:'Error', detail:'Error while getting Department'});
          this.pageReady = false;
        }
      }, error => {
        this.messageService.add({severity:'error', summary:'Error', detail:'Error while getting Department, error:' + error.messages[0]});
        this.pageReady = false;
      });
    }
  }

  editData(EditData)
  {
    let productID = EditData;
    this.router.navigateByUrl("/tillproductedit/" + productID);
  }

  disableMulti()
  {
    this.selectedItems.forEach(element => {

    this.http.delete(bmxurl + "tillproducts/" + this.VenueID + "/" + element.productID).subscribe(response => {
      this.httpResponse = response
      if(this.httpResponse.succeeded)
      {
        this.loadgrid(false);
      } 
      else{
        this.messageService.add({severity:'error', summary:'Not Saved', detail:'Error disabling Products, error: ' + this.httpResponse.messages[0]});
      }
    }, error => {
      this.messageService.add({severity:'error', summary:'Not Saved', detail: 'Error disabling Products, error: ' + error.messages});
    })
    });
    
  }

  
  }
