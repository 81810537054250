<div *ngIf="!AccessAllowed && CompletedLoading">
  <h3 style="border: 2px solid;border-radius: 6px;position: absolute;top: 50%;left: 50%;transform: translate(-50%, -50%);padding: 10px;">You don't have access to this function</h3>
</div>

<div *ngIf="pageReady && AccessAllowed" class="p-3 flex flex-column flex-auto">
    <div>
        <div class="text-900 font-medium text-xl mb-3"><h3>Extra</h3></div>
        <p class="m-0 mb-4 p-0 text-600 line-height-3 mr-3">
            <span class="pi pi-info-circle" style="margin-right:2px;"></span>
            After you have created an extra you will need to tell the system which packages can sell it.  This is done through the package editor page.
        </p>
        <div class="surface-card p-4 shadow-2 border-round">
            <div class="grid formgrid p-fluid">
              <div class="field mb-4 col-12">
                <label for="extraname" class="font-medium">Name</label>
                <input [(ngModel)]="Extra.name" [ngStyle]="{'border-color': (extraNameValid==false) ? '#FF0000':''}"  id="extraname" type="text" pInputText>
            </div>

            <div *ngIf="!extraNameValid" class="text-danger" Style="margin-left:10px; margin-top: -23px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>You must enter a Extra name.</div>

            <div class="field mb-4 col-12">
              <label for="extradesc" class="font-medium">Description</label>
              <input [(ngModel)]="Extra.description" [ngStyle]="{'border-color': (extraNameValid==false) ? '#FF0000':''}"  id="extradesc" type="text" pInputText>
          </div>

          <div *ngIf="!extraDescValid" class="text-danger" Style="margin-left:10px; margin-top: -23px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>You must enter a Extra Description.</div>
          
          <div class="field mb-4 col-12">
            <label for="extraoptionprice" class="font-medium">Price</label>
            <p-inputNumber [(ngModel)]="Extra.price" mode="currency" currency="{{CurrencySymbol}}" locale="en-GB" [min]="0" [max]="9999"></p-inputNumber>
         </div>
         
         <div class="field mb-4 col-12">
          <label for="agegroupname" class="font-medium">Would you like to ask a question with this extra?</label>
          <p-selectButton [style]="{'width': '350px'}" [options]="stateOptionsYesNo" [(ngModel)]="askQuestion" ></p-selectButton>
        </div>


         <div class="field mb-4 col-12" *ngIf="askQuestion">
          <label for="extradesc" class="font-medium">Question Text</label>
          <input [(ngModel)]="Extra.question" [ngStyle]="{'border-color': (questionValid==false) ? '#FF0000':''}" [maxlength]="255" id="extradesc" type="text" pInputText>
        </div>

        <div *ngIf="!questionValid" class="text-danger" Style="margin-left:10px; margin-top: -23px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>You must enter a Question.</div>
          
         <div class="field mb-4 col-12">
          <label for="agegroupname" class="font-medium">Do you want this extra to be available on all days or would you like to choose?</label>
          <p-selectButton [style]="{'width': '350px'}" [options]="stateOptions3" [(ngModel)]="ChooseDays" ></p-selectButton>
        </div>

         <div *ngIf="ChooseDays">
            <div class="field mb-4 col-12">
              <label for="agegroupname" class="font-medium">Monday</label>
              <p-selectButton [style]="{'width': '150px'}" [options]="stateOptions" [(ngModel)]="Extra.monday" ></p-selectButton>
            </div>
            <div class="field mb-4 col-12">
              <label for="agegroupname" class="font-medium">Tuesday</label>
              <p-selectButton [style]="{'width': '150px'}" [options]="stateOptions" [(ngModel)]="Extra.tuesday" ></p-selectButton>
            </div>
            <div class="field mb-4 col-12">
              <label for="agegroupname" class="font-medium">Wednesday</label>
              <p-selectButton [style]="{'width': '150px'}" [options]="stateOptions" [(ngModel)]="Extra.wednesday" ></p-selectButton>
            </div>
            <div class="field mb-4 col-12">
              <label for="agegroupname" class="font-medium">Thursday</label>
              <p-selectButton [style]="{'width': '150px'}" [options]="stateOptions" [(ngModel)]="Extra.thursday" ></p-selectButton>
            </div>
            <div class="field mb-4 col-12">
              <label for="agegroupname" class="font-medium">Friday</label>
              <p-selectButton [style]="{'width': '150px'}" [options]="stateOptions" [(ngModel)]="Extra.friday" ></p-selectButton>
            </div>
            <div class="field mb-4 col-12">
              <label for="agegroupname" class="font-medium">Saturday</label>
              <p-selectButton [style]="{'width': '150px'}" [options]="stateOptions" [(ngModel)]="Extra.saturday" ></p-selectButton>
            </div>
            <div class="field mb-4 col-12">
              <label for="agegroupname" class="font-medium">Sunday</label>
              <p-selectButton [style]="{'width': '150px'}" [options]="stateOptions" [(ngModel)]="Extra.sunday" ></p-selectButton>
            </div>
            <div class="field mb-4 col-12">
              <label for="agegroupname" class="font-medium">Per Extra or per Booking?</label>
              <p-selectButton [style]="{'width': '350px'}" [options]="stateOptions2" [(ngModel)]="Extra.perPerson" ></p-selectButton>
            </div>
        </div>

          <div class="field mb-4 col-12">
            <label for="" class="font-medium">Tag(s)</label><br/>
            <p-multiSelect [options]="tags" optionLabel="name"  [(ngModel)]="Extra.tags" display="chip">
            <ng-template let-value pTemplate="selectedItems">
                <span *ngFor="let tag of Extra.tags">
                    <p-chip label="{{tag.name}}" styleClass="mr-2" [style]="{'background': tag.colour, 'color': 'white', 'border-radius': '5px', 'font-size': '100%' }"></p-chip>
                </span>
                <div *ngIf="!Extra.tags || Extra.tags.length === 0" class="country-placeholder">
                    Select Tag(s)
                </div>
            </ng-template>
            </p-multiSelect>
          </div>
<!--
            <div class="col-12 md:col-6 drag-column">
                <div *ngFor="let product of availableExtraOptions">
                    <div class="product-item" pDraggable="products" (onDragStart)="dragStart($event, product)" (onDragEnd)="dragEnd()">
                        <div class="product-list-detail">
                            <h5 class="mb-2">{{product.name}}</h5>
                        </div>
                    </div>
                </div>
            </div>
-->

            <!--<div class="col-12 md:col-6 drag-column">
              <label for="agegroupname" class="font-medium">Available Extra Options</label>

              <p-table [value]="availableExtraOptions">
                <ng-template pTemplate="header">
                    <tr>
                        <th style="width:90%">Name</th>
                        <th style="width:10%">&nbsp;</th>
                    </tr>
                </ng-template>
                    <ng-template pTemplate="body" let-product>
                      <tr pDraggable="products" (onDragStart)="dragStart($event, product)" (onDragEnd)="dragEnd()">
                          <td>{{product.name}}</td>
                          <td>
                            <button pButton pRipple type="button" [style]="{'margin-bottom': '-6px', 'margin-top': '-4px !important'}" class="p-button-sm p-element p-button-success w-auto mt-3" (click)="AddToList(product)">
                              <span class="pi pi-angle-right"></span>
                            </button>
                          </td>
                      </tr>
                    </ng-template>

                </p-table>


                <div *ngIf="availableExtraOptions.length == 0" style="padding: 15px;">

                <i>No extras options available, add some <a href="/extraoptionedit/0">here</a></i>

                </div>
            </div>

            <div class="col-12 md:col-6 drop-column" pDroppable="products" (onDrop)="drop()">
              <label for="agegroupname" class="font-medium">Selected Extra Options</label>

              <p-table [value]="Extra.extraOptions">
                <ng-template  pTemplate="header" let-columns>
                    <tr>
                        <th>&nbsp;</th>
                        <th style="width:90%">Name</th>
                        <th style="width:10%">&nbsp;</th>
                    </tr>
                </ng-template>
                    <ng-template pTemplate="body" let-product let-columns="columns" let-index="rowIndex">
                      <tr [pReorderableRow]="index">
                        <td>
                          <span class="pi pi-bars" pReorderableRowHandle></span>
                      </td>
                          <td>{{product.name}}</td>
                          <td>
                            <button pButton pRipple type="button" [style]="{'margin-bottom': '-6px', 'margin-top': '-4px !important'}" class="p-button-sm p-element p-button-danger w-auto mt-3" (click)="RemoveFromList(product)">
                              <span class="pi pi-angle-left"></span>
                            </button>
                          </td>
                      </tr>
                    </ng-template>

                </p-table>

            </div>-->



            </div>

              <div class="col-12">
                  <button pButton pRipple type="button" class="p-button p-element p-button-success w-auto mt-3" (click)="updateData();">
                  <span class="pi pi-pencil" style="margin-right:8px;"></span>
                  Save Changes</button>
                  &nbsp;
                  <button pButton pRipple type="button" class="p-button p-element p-button-danger w-auto mt-3" (click)="returnToList()">
                  <span class="pi pi-times" style="margin-right:8px;"></span>
                  Cancel</button>
              </div>
          </div>

    </div>
</div>


<style>

</style>
