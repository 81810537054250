<div *ngIf="!AccessAllowed && CompletedLoading">
    <h3 style="border: 2px solid;border-radius: 6px;position: absolute;top: 50%;left: 50%;transform: translate(-50%, -50%);padding: 10px;">You don't have access to this function</h3>
</div>

<div *ngIf="ListCashup && AccessAllowed" class="p-3 flex flex-column flex-auto">
    <div class="text-900 font-medium text-xl mb-3"><h3>Cash-ups
    </h3></div>
    <p class="m-0 mb-4 p-0 text-600 line-height-3 mr-3">
        <span class="pi pi-info-circle" style="margin-right:2px;"></span>
        Cash-up list shows you all of your previous cash-ups and allows you to view them in detail.
    </p>
    <div style="text-align: right; margin-bottom: 20px;margin-top:-62px;">
        <button pButton type="button" style="height: 50px;" class="p-button-sm p-element p-ripple p-button-success p-button p-component" [routerLink]="['/cashup/0']">
        <span class="pi pi-list" style="margin-right:8px;"></span>
        <span i18>Current</span>
        </button>
    </div>
    <div class="surface-card p-4 shadow-2 border-round">
    <p-table  #cashupTable 
    [value]="ListCashup.data" sortField="datetime" [paginator]="true" [rows]="10" 
    [showCurrentPageReport]="true" responsiveLayout="scroll"
    currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" 
    [rowsPerPageOptions]="[10,25,50]" 
    [globalFilterFields]="['datetime']"
    [(selection)]="selectedItems"
    responsiveLayout="scroll"
    datakey="id"
    [selectionPageOnly]="true">
        <ng-template pTemplate="header">
            <tr>
                <th pSortableColumn="datetime" width="65%">Date of Cash-up
                    <p-sortIcon [field]="datetime"></p-sortIcon>
                </th>
                <th width="10%"></th>
                <th width="10%"></th>
                <th width="10%"></th>
                <th width="5%"></th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-data>
            <tr [ngStyle]="{'background-color': (data.disabled==true) ? '#fee' : '#ffffff'}">
                <td>{{data.datetime | date:'dd/MM/yyyy HH:mm:ss'}}</td>
                <td>{{data.expected | currency:CurrencySymbol}}</td>
                <td>{{data.actual | currency:CurrencySymbol}}</td>

                <td *ngIf="data.variance < 0" style="font-weight:bold; color:red">{{data.variance | currency:CurrencySymbol}}</td>
                <td *ngIf="data.variance >= 0">{{data.variance | currency:CurrencySymbol}}</td>

                <td><button pButton type="button" class="p-button-sm p-element p-ripple p-button-success p-button p-component" style="background-color: yellow o !important;" (click)="editData(data.id);">
                    <span class="pi pi-eye" style="margin-right:8px;"></span>
                    <span>View</span>
                </button></td>
            </tr>
        </ng-template>
    </p-table>
    </div>
</div>
