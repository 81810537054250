import { Component } from '@angular/core';

@Component({
  selector: 'app-resourceadjustmentedit',
  templateUrl: './resourceadjustmentedit.component.html',
  styleUrls: ['./resourceadjustmentedit.component.css']
})
export class ResourceadjustmenteditComponent {

}
