<div *ngIf="!AccessAllowed && CompletedLoading">
    <h3 style="border: 2px solid;border-radius: 6px;position: absolute;top: 50%;left: 50%;transform: translate(-50%, -50%);padding: 10px;">You don't have access to this function</h3>
</div>

<div *ngIf="pageReady && AccessAllowed" class="p-3 flex flex-column flex-auto">
    <div>
        <div class="text-900 font-medium text-xl mb-3"><h3>Admission
        </h3></div>
        <p class="m-0 mb-4 p-0 text-600 line-height-3 mr-3">
            <span class="pi pi-info-circle" style="margin-right:2px;"></span>
            Give the admission a name, this is the only field that can not be over-ridden in the package.  All the other fields can be over-ridden when you use the admission in a package.
        </p>
        <div class="surface-card p-4 shadow-2 border-round">
            <div class="grid formgrid p-fluid">
                <div class="field mb-4 col-12">
                    <label for="classname" class="font-medium">Name</label>
                    <input [(ngModel)]="Admission.name" id="classname" type="text" [ngStyle]="{'border-color': (admissionNameValid==false) ? '#FF0000':''}" (keyup)="DoValidCheck()" pInputText>
                </div>
                <div *ngIf="!admissionNameValid" class="text-danger" Style="margin-left:10px; margin-top: -23px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>You must enter an Admission name.</div>
               
                <div class="field mb-4 col-12">
                    <label for="classname" class="font-medium">Booking Wizard Text</label>
                    <input [(ngModel)]="Admission.bookingwizardtext" id="classname" type="text" [ngStyle]="{'border-color': (admissionBWTValid==false) ? '#FF0000':''}" (keyup)="DoValidCheck()" pInputText>
                </div>
                <div *ngIf="!admissionBWTValid" class="text-danger" Style="margin-left:10px; margin-top: -23px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>You must enter Booking Wizard Text.</div>
                
                <!--
                <div class="field mb-4 col-12">
                    <label for="classname" class="font-medium">Price</label>
                    <p-inputNumber [(ngModel)]="Admission.price" mode="currency" currency="{{CurrencySymbol}}" locale="en-GB" [min]="0" [max]="9999"></p-inputNumber>
                </div>
                <div class="field mb-4 col-12">
                    <label for="length" class="font-medium">Minimum</label>
                    <p-inputNumber id="length" [style]="{'width': '300px'}" [(ngModel)]="Admission.min" [showButtons]="true" max="100000" min="0" buttonLayout="horizontal" spinnerMode="horizontal" (onInput)="DoValidCheck()"></p-inputNumber>
                </div>
                <div class="field mb-4 col-12">
                    <label for="length" class="font-medium">Maximum</label>
                    <p-inputNumber id="length" [style]="{'width': '300px'}"  [(ngModel)]="Admission.max" [showButtons]="true" max="100000" min="0" buttonLayout="horizontal" spinnerMode="horizontal" (onInput)="DoValidCheck()"></p-inputNumber>
                </div>
                <div *ngIf="!admissionMaxValid" class="text-danger" Style="margin-left:10px; margin-top: -23px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>Your maximum cannot be lower than your minimum.</div>
                -->
                <div class="field mb-4 col-12">
                    <label for="useclasses" class="font-medium">Must Include</label>
                    <p-selectButton id="useclasses" [style]="{'width': '150px'}" [options]="stateOptions" [(ngModel)]="Admission.mustinclude" ></p-selectButton>
                </div>
                <div class="field mb-4 col-12">
                    <label for="useclasses" class="font-medium">Participant</label>
                    <p-selectButton id="useclasses" [style]="{'width': '150px'}" [options]="stateOptions" [(ngModel)]="Admission.participant" ></p-selectButton>
                </div>
                <div *ngIf="classes" class="field mb-4 col-12">
                    <div class="field mb-4 col-12">
                        <label for="ddlPackage" class="font-medium">Class</label>
                        <p-dropdown [options]="classes" [(ngModel)]="Admission.admissionClass" placeholder="No Class" [showClear]="true" optionLabel="name"></p-dropdown>
                    </div>
                </div>
                <div class="col-12">
                    <button pButton pRipple type="button" class="p-button p-element p-button-success w-auto mt-3" (click)="updateData();">
                    <span class="pi pi-pencil" style="margin-right:8px;"></span>
                    Save Changes</button>
                    &nbsp;
                    <button pButton pRipple type="button" class="p-button p-element p-button-danger w-auto mt-3" (click)="returnToList()">
                    <span class="pi pi-times" style="margin-right:8px;"></span>
                    Cancel</button>
                </div>
            </div>
        </div>
    </div>
</div>