import { Component, ElementRef, OnInit, QueryList, ViewChildren } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppComponent } from '../app.component';
import { extraoption_returndata } from 'src/types/extraoption_returndata';
import { bmxurl, getAccess } from 'src/globals';
import { Router, RouterLink } from '@angular/router';
import { MessageService } from 'primeng/api';
import { users_returndata } from 'src/types/user_returndata';


@Component({
  selector: 'app-ExtraOptions',
  templateUrl: './extraoption.component.html',
  styleUrls: ['./extraoption.component.css']
})
export class extraoptionsComponent implements OnInit {
  ListExtraOptions: any;
  bmxurl: string;
  VenueID: string = localStorage.getItem("SmartVenueID")!;
  CreateUpdateResponse: any;
  ExtraOption: any;
  DisabledExtraOption: any;
  checked: boolean = true;
  isDisabled: boolean = true;
  selectedItems: any;

  AccessAllowed: boolean = false;
  CompletedLoading: boolean = false;

  httpResponse: any;


  constructor(
    private http: HttpClient, private appComp: AppComponent, private router: Router, private messageService: MessageService) {
      //this.isRowSelectable = this.isRowSelectable.bind(this);
    
    }

  ngOnInit(): void {
    localStorage.removeItem("screenMemoryBookingss");
    localStorage.removeItem("screenMemoryEvents");
    this.loadgrid(false);
  }

  toggleDisabled(){

    let userid = localStorage.getItem("SmartUserID");

    this.http.get(bmxurl + "User/" + userid).subscribe(response => {
      this.httpResponse =  (response as users_returndata)
      if(this.httpResponse.succeeded)
      {
        let user = this.httpResponse.data;

        if(getAccess("EXTRAS", user)){
          this.AccessAllowed = true;
          this.loadgrid(!this.isDisabled);
        }

        this.CompletedLoading = true;

      }
    }, error => {
      this.messageService.add({severity:'error', summary:'Error', detail:'Error while getting User, error:' + error.messages[0]});
    });


  }

  disableData(ExtraOptionsID)
  {
    this.http.delete(bmxurl + "extraoption/" + this.VenueID + "/" + ExtraOptionsID).subscribe(response => {
      this.CreateUpdateResponse = response
      if(this.CreateUpdateResponse.succeeded = true)
      {
        this.messageService.add({severity:'success', summary:'Disabled', detail:'Extra option successfully disabled'});
        this.router.navigateByUrl("/extraoption");

        this.loadgrid(false);
      }
    }, error => {
      this.messageService.add({severity:'error', summary:'Not Disabled', detail: 'An error occurred disabling this Extra Option'});
    })
  }

  loadgrid(showdisabled: boolean)
  {
    this.http.get(bmxurl + "ExtraOptionList/" + this.VenueID + "/" + showdisabled).subscribe(response => {
      this.ListExtraOptions = (response as extraoption_returndata);
    }, error => {
    });
  }

  editData(EditData)
  {
    let ExtraOptionID = EditData;
    this.router.navigateByUrl("/extraoptionedit/" + ExtraOptionID);
  }

  disableMulti()
  {
    this.selectedItems.forEach(element => {

    this.http.delete(bmxurl + "extraoption/" + this.VenueID + "/" + element.ExtraOptionID).subscribe(response => {
      this.CreateUpdateResponse = response
      if(this.CreateUpdateResponse.succeeded = true)
      {
        this.loadgrid(false);
      }
    }, error => {

    })
    });

  }


  }
