<div *ngIf="!AccessAllowed && CompletedLoading">
  <h3 style="border: 2px solid;border-radius: 6px;position: absolute;top: 50%;left: 50%;transform: translate(-50%, -50%);padding: 10px;">You don't have access to this function</h3>
</div>

<div *ngIf="pageReady && AccessAllowed" class="p-3 flex flex-column flex-auto">
    <div>
        <div class="text-900 font-medium text-xl mb-3"><h3>Extra Option</h3></div>
        <p class="m-0 mb-4 p-0 text-600 line-height-3 mr-3">
            <span class="pi pi-info-circle" style="margin-right:2px;"></span>
            Extra Option text
        </p>
        <div class="surface-card p-4 shadow-2 border-round">
            <div class="grid formgrid p-fluid">
              <div class="field mb-4 col-12">
                <label for="extraoptionname" class="font-medium">Name</label>
                <input [(ngModel)]="ExtraOption.name" [ngStyle]="{'border-color': (extraOptionNameValid==false) ? '#FF0000':''}" id="extraoptionname" type="text" pInputText>
            </div>
            <div *ngIf="!extraOptionNameValid" class="text-danger" Style="margin-left:10px; margin-top: -23px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>You must enter a Extra Option name.</div>

            <div class="field mb-4 col-12">
              <label for="extraoptiondesc" class="font-medium">Description</label>
              <input [(ngModel)]="ExtraOption.description" [ngStyle]="{'border-color': (extraOptionDescValid==false) ? '#FF0000':''}" id="extraoptiondesc" type="text" pInputText>
           </div>
           <div *ngIf="!extraOptionDescValid" class="text-danger" Style="margin-left:10px; margin-top: -23px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>You must enter a Description.</div>

           <div class="field mb-4 col-12">
            <label for="extraoptionprice" class="font-medium">Price</label>
            <p-inputNumber [(ngModel)]="ExtraOption.price" mode="currency" currency="{{CurrencySymbol}}" locale="en-GB" [min]="0" [max]="9999"></p-inputNumber>
         </div>
         <div class="field mb-4 col-12">

          <div class="field mb-4 col-12">
            <label for="agegroupname" class="font-medium">Monday</label>
            <p-selectButton [style]="{'width': '150px'}" [options]="stateOptions" [(ngModel)]="ExtraOption.monday" ></p-selectButton>
          </div>
          <div class="field mb-4 col-12">
            <label for="agegroupname" class="font-medium">Tuesday</label>
            <p-selectButton [style]="{'width': '150px'}" [options]="stateOptions" [(ngModel)]="ExtraOption.tuesday" ></p-selectButton>
          </div>
          <div class="field mb-4 col-12">
            <label for="agegroupname" class="font-medium">Wednesday</label>
            <p-selectButton [style]="{'width': '150px'}" [options]="stateOptions" [(ngModel)]="ExtraOption.wednesday" ></p-selectButton>
          </div>
          <div class="field mb-4 col-12">
            <label for="agegroupname" class="font-medium">Thursday</label>
            <p-selectButton [style]="{'width': '150px'}" [options]="stateOptions" [(ngModel)]="ExtraOption.thursday" ></p-selectButton>
          </div>
          <div class="field mb-4 col-12">
            <label for="agegroupname" class="font-medium">Friday</label>
            <p-selectButton [style]="{'width': '150px'}" [options]="stateOptions" [(ngModel)]="ExtraOption.friday" ></p-selectButton>
          </div>
          <div class="field mb-4 col-12">
            <label for="agegroupname" class="font-medium">Saturday</label>
            <p-selectButton [style]="{'width': '150px'}" [options]="stateOptions" [(ngModel)]="ExtraOption.saturday" ></p-selectButton>
          </div>
          <div class="field mb-4 col-12">
            <label for="agegroupname" class="font-medium">Sunday</label>
            <p-selectButton [style]="{'width': '150px', 'float:': 'left'}" [options]="stateOptions" [(ngModel)]="ExtraOption.sunday" ></p-selectButton>
          </div>
          </div>
            </div>

              <div class="col-12">
                  <button pButton pRipple type="button" class="p-button p-element p-button-success w-auto mt-3" (click)="updateData();">
                  <span class="pi pi-pencil" style="margin-right:8px;"></span>
                  Save Changes</button>
                  &nbsp;
                  <button pButton pRipple type="button" class="p-button p-element p-button-danger w-auto mt-3" (click)="returnToList()">
                  <span class="pi pi-times" style="margin-right:8px;"></span>
                  Cancel</button>
              </div>
          </div>

    </div>
</div>
