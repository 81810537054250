import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-printconfirmation',
  templateUrl: './printconfirmation.component.html',
  styleUrls: ['./printconfirmation.component.css']
})
export class PrintconfirmationComponent implements OnInit {
  @Input() confHTML: string;
  
  constructor() { }

  ngOnInit(): void {
  }

}
