import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpHeaders
} from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class SmartAuthInterceptorInterceptor implements HttpInterceptor {

  constructor() {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    let pin = localStorage.getItem("SmartPinCode") == null ? "" : localStorage.getItem("SmartPinCode");
    let venueid = localStorage.getItem("SmartVenueID") == null ? "" : localStorage.getItem("SmartVenueID");
    let userid = localStorage.getItem("SmartUserID") == null ? "" : localStorage.getItem("SmartUserID");
    let businessid = localStorage.getItem("SmartBusinessID") == null ? "" : localStorage.getItem("SmartBusinessID");

    const hdrs = new HttpHeaders()
      .set('pincode', pin)
      .set('venueid', venueid)
      .set('userid', userid)
      .set('businessid', businessid)
      .set('venue', "TRUE");
    
    request = request.clone({
      headers: hdrs
    });

    return next.handle(request);
  }
}
