import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppComponent } from '../app.component';
import { BusyService } from '../_services/busy.service';
import { GlobalService } from '../_services/global.service';
import { HttpClient } from '@angular/common/http';
import { MessageService } from 'primeng/api';
import { AuthService } from '@auth0/auth0-angular';
import { bmxurl } from 'src/globals';

@Component({
  selector: 'app-counterslist',
  templateUrl: './counterslist.component.html',
  styleUrls: ['./counterslist.component.css']
})
export class CounterslistComponent implements OnInit {

  bookingId: string = "";
  selectedItems: any;
  ListAdmissions: any;
  VenueID: string = localStorage.getItem("SmartVenueID")!;
  startdate: string = "";
  screenBookingId: string = "";
  admitdisabled: boolean = true;
  leavedisabled: boolean = true;

  constructor(
    private route: ActivatedRoute,
    private http: HttpClient, 
    private appComp: AppComponent, 
    private router: Router, 
    private messageService: MessageService, 
    private busyService: BusyService,
    private auth: AuthService,
    private globalService: GlobalService) {
      
  }

  ngOnInit(): void {
    this.bookingId = this.route.snapshot.paramMap.get('id')!;
    this.startdate = this.route.snapshot.paramMap.get('date')!;

    this.http.get<any>(bmxurl + '/DayRegister/' + this.VenueID + '/' + this.startdate + '/' + this.bookingId)
    .subscribe(data => {

      this.screenBookingId = data.data.bookingidhr;
      this.ListAdmissions = data.data.admissions;
      if(this.ListAdmissions.length > 0)
      {
        let foundadmit = false;
        let foundleave = false;

        this.ListAdmissions.forEach(element => {
          if(element.admitted == true)
          {
            foundadmit = true;
            this.leavedisabled = false;
          }
          else
          {
            foundleave = true;
            this.admitdisabled = false;
          }

          if(foundadmit == false)
          {
            this.leavedisabled = true;
          }

          if(foundleave == false)
          {
            this.admitdisabled = true;
          }

        });
      }
    },
    error => {
      this.messageService.add({severity:'error', summary: 'Error', detail: error});
    });
  }

  admitall(id)
  {

  }

  leaveall(id)
  {

  }

  admitleave(counterid){
    

  }

}
