import { Component } from '@angular/core';

@Component({
  selector: 'app-resourceadjustments',
  templateUrl: './resourceadjustments.component.html',
  styleUrls: ['./resourceadjustments.component.css']
})
export class ResourceadjustmentsComponent {

}
