import { Component, ElementRef, OnInit, QueryList, ViewChildren } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Resource, Package } from '../../PackageClasses';
import { AppComponent } from '../app.component';
import { bmxurl, getAccess } from 'src/globals';
import { NgModule } from '@angular/core';
import { NgModel } from '@angular/forms';
import { Router, RouterLink } from '@angular/router';
import { MessageService } from 'primeng/api';
import { Checkbox } from 'primeng/checkbox';
import { timesets_returndata } from 'src/types/timesets_returndata';
import { users_returndata } from 'src/types/user_returndata';

@Component({
  selector: 'app-timesets',
  templateUrl: './timesets.component.html',
  styleUrls: ['./timesets.component.css']
})
export class TimesetsComponent implements OnInit {
  ListTimesets: any;
  bmxurl: string;
  VenueID: string = localStorage.getItem("SmartVenueID")!;
  CreateUpdateResponse: any;
  DisabledTimeSet: any;
  checked: boolean = true;
  isDisabled: boolean = true;
  selectedItems: any;

  AccessAllowed: boolean = false;
  CompletedLoading: boolean = false;

  httpResponse: any;

  constructor(
    private http: HttpClient, private appComp: AppComponent, private router: Router, private messageService: MessageService) { 
      //this.isRowSelectable = this.isRowSelectable.bind(this);

    }

  ngOnInit(): void {

    localStorage.removeItem("screenMemoryBookingss");
    localStorage.removeItem("screenMemoryEvents");
    
    let userid = localStorage.getItem("SmartUserID");

    this.http.get(bmxurl + "User/" + userid).subscribe(response => {
      this.httpResponse =  (response as users_returndata)
      if(this.httpResponse.succeeded)
      {
        let user = this.httpResponse.data;

        if(getAccess("PACKAGE", user)){
          this.AccessAllowed = true;
          this.loadgrid(false);
        }

        this.CompletedLoading = true;
          
      }
    }, error => {
      this.messageService.add({severity:'error', summary:'Error', detail:'Error while getting User, error:' + error.messages[0]});
    });


  }

  toggleDisabled(){
    this.loadgrid(!this.isDisabled);
  }

  disableData(TimeSetID)
  {
    this.http.delete(bmxurl + "timesets/" + this.VenueID + "/" + TimeSetID).subscribe(response => {
      this.CreateUpdateResponse = response
      if(this.CreateUpdateResponse.succeeded)
      {
        this.messageService.add({severity:'success', summary:'Disabled', detail:'Time Set successfully disabled'});
        this.router.navigateByUrl("/timesets");

        this.loadgrid(false);
      }
    }, error => {
      this.messageService.add({severity:'error', summary:'Not Disabled', detail: 'An error occurred disabling this Time Set'});
    })
  }
  
  loadgrid(showdisabled: boolean)
  {
    this.http.get(bmxurl + "TimesetList/" + this.VenueID + "/" + showdisabled).subscribe(response => {
      this.ListTimesets = (response as timesets_returndata);

      if ((response as timesets_returndata).messages.length > 0)
        {
          (response as timesets_returndata).messages.forEach(error => {
            this.messageService.add({severity:'warn', summary:'Response Message', detail: error});
          });
        }
    }, error => {
    });
  }

  editData(EditData)
  {
    let TimesetID = EditData;
    this.router.navigateByUrl("/timesetedit/" + TimesetID);
  }

  viewData(EditData)
  {
    let TimesetID = EditData;
    this.router.navigateByUrl("/timesetview/" + TimesetID);
  }

  disableMulti()
  {
    this.selectedItems.forEach(element => {

    this.http.delete(bmxurl + "Timesets/" + this.VenueID + "/" + element.timsetID).subscribe(response => {
      this.CreateUpdateResponse = response
      if(this.CreateUpdateResponse.succeeded)
      {
        this.loadgrid(false);
      } 
    }, error => {
    
    })
    });
    
  }
  }

