<div *ngIf="!AccessAllowed && CompletedLoading">
    <h3 style="border: 2px solid;border-radius: 6px;position: absolute;top: 50%;left: 50%;transform: translate(-50%, -50%);padding: 10px;">You don't have access to this function</h3>
</div>

<div *ngIf="ListBookings && AccessAllowed" class="p-3 flex flex-column flex-auto">
    <h3 style="margin-bottom: -38px" i18n>Bookings Created: </h3>
    <div style="width: calc(100vw - 607px); float: left; margin-left: 252px; margin-bottom:20px;">
        <button pButton type="button"  pTooltip="Previous day"  style="height: 50px; margin-right: 5px;width: 53px; float:left;" (click)="dateChange(-1);" class="p-button-sm p-element p-ripple p-button p-component">
            <span class="pi pi-angle-left" style="margin-right:8px;"></span>
        </button>
        <div style="float:left; width: 200px;">
            <p-calendar [(ngModel)]="BookingDate" firstDayOfWeek="1" dateFormat="dd/mm/yy" [showIcon]="true" (onSelect)="doneDate()" (onChange)="doneDate()"></p-calendar>  
        </div> 
        <button pButton type="button" pTooltip="Next day" style="height: 50px; margin-left: 5px;width: 53px; float:left;" (click)="dateChange(1);" class="p-button-sm p-element p-ripple p-button p-component">
            <span class="pi pi-angle-right" style="margin-right:8px;"></span>
        </button>
        &nbsp;
        <p-toggleButton pTooltip="Show disabled bookings" *ngIf="!inProgress" class="p-button-sm p-button-danger p-component" [(ngModel)]="isDisabled" offIcon="pi pi-eye-slash" onIcon="pi pi-eye" [style]="{'margin-right': '10px', 'color' : 'white', 'background-color': '#EF4444', 'font-weight': 'normal', 'height': '50px'}" (click)="toggleDisabled()">
        </p-toggleButton>

        <div style="float:right; width: 300px;text-align: right;">
            <button pTooltip="Bookings in progress" pButton type="button" style="height: 50px; margin-right:10px;" class="p-button-sm p-element p-ripple p-button-warning p-button p-component" (click)="showInProgress()">
                <span class="pi pi-clock" style="margin-right:8px; font-size: 16px;"></span>
                <span style="font-size:17px;">{{bookingsInProgress}}</span>
            </button>
            
            <button pTooltip="Bookings made today" pButton type="button" style="height: 50px;" class="p-button-sm p-element p-ripple p-button-success p-button p-component" (click)="unshowInProgress()">
                <span class="pi pi-check-circle" style="margin-right:8px; font-size: 16px;"></span>
                <span style="font-size:17px;">{{totalBookingsToday}}</span>
            </button>
        </div>
    </div>

    <div class="surface-card p-4 shadow-2 border-round">
    <p-table  #resourceTable (onPage)="paginate($event)"
    [columns]="cols"
    [value]="ListBookings" sortField="startTime" [paginator]="true" [rows]="10" 
    [showCurrentPageReport]="true" responsiveLayout="scroll"
    currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" 
    [rowsPerPageOptions]="[10,25,50]" 
    [globalFilterFields]="['name', 'bookingId', 'bookingIdHR', 'packageName']"
    [(selection)]="selectedItems"
    responsiveLayout="scroll"
    [selectionPageOnly]="true">
        <ng-template pTemplate="caption">
            <div class="flex">
            <button
                type="button"
                pButton
                pRipple
                icon="pi pi-file-excel"
                (click)="resourceTable.exportCSV()"
                class="mr-2"
                pTooltip="Export to CSV"
                tooltipPosition="bottom">
            </button>
            </div>
        </ng-template>
        <ng-template pTemplate="header">
            <tr>
                <th width="1%">
                    &nbsp;
                </th>
                <th width="1%">
                    &nbsp;
                </th>
                <th pSortableColumn="bookingId" width="8%">Sales ID
                    <p-sortIcon [field]="bookingIdHR"></p-sortIcon>
                </th>
                <th pSortableColumn="startTime" width="10%">Start Date
                    <p-sortIcon [field]="startTime"></p-sortIcon>
                </th>
                <th pSortableColumn="startTime" width="8%">Start
                    <p-sortIcon [field]="startTime"></p-sortIcon>
                </th>
                <th pSortableColumn="name" width="8%">Name
                    <p-sortIcon [field]="name"></p-sortIcon>
                </th>
                <th pSortableColumn="packageName" width="15%">Package
                    <p-sortIcon [field]="packageName"></p-sortIcon>
                </th>
                <th pSortableColumn="guests" width="6%">Guests
                    <p-sortIcon [field]="guests"></p-sortIcon>
                </th>
                <th pSortableColumn="total" width="5%">Total
                    <p-sortIcon [field]="total"></p-sortIcon>
                </th>
                <th pSortableColumn="outstanding" width="5%">O/S
                    <p-sortIcon [field]="outstanding"></p-sortIcon>
                </th>
                <th *ngIf="inProgress" pSortableColumn="paymentlinksentdate" width="10%">Link Sent
                    <p-sortIcon [field]="paymentlinksentdate"></p-sortIcon>
                </th>
                <th *ngIf="inProgress" pSortableColumn="paymentlinksentdate" width="10%">Time left to Expiry
                    <p-sortIcon [field]="paymentlinksentdate"></p-sortIcon>
                </th>
                <th width="20%">
                    Extras
                </th>
                <th width="5%"></th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-data>
            <tr [ngStyle]="{'background-color': (data.disabled==true) ? '#fee' : '#ffffff'}"  *ngIf="!data.inProgress && !inProgress" onmouseover="this.originalstyle=this.style.backgroundColor;this.style.backgroundColor='#dddddd'" onmouseout="this.style.backgroundColor=this.originalstyle;" [ngStyle]="{'background-color': (data.disabled==true) ? '#fee' : '#ffffff'}">
                <td (click)="editdatanew(data.bookingId)" >
                    <span *ngIf="data.publicBooking" class="pi pi-user"></span> 
                </td>
                <td (click)="editdatanew(data.bookingId)">
                    <span style="color:red" *ngIf="data.notes != '' && data.notes != null" class="pi pi-exclamation-circle" pTooltip="{{data.notes}}"></span> 
                </td>
                <td (click)="editdatanew(data.bookingId)">{{data.bookingIdHR}}</td>
                <td (click)="editdatanew(data.bookingId)">{{data.startTime | date:'dd/MM/yy'}}</td>
                <td (click)="editdatanew(data.bookingId)">{{data.startTime | date:'HH:mm' : 'UTC'}}</td>
                <td (click)="editdatanew(data.bookingId)">{{data.name}}</td>
                <td (click)="editdatanew(data.bookingId)">{{data.packageName}}</td>
                <td (click)="editdatanew(data.bookingId)">{{data.guests}}</td>
                <td (click)="editdatanew(data.bookingId)">{{data.total | currency: CurrencySymbol}}</td>
                <td (click)="editdatanew(data.bookingId)" *ngIf="data.outstanding <=0">{{data.outstanding | currency: CurrencySymbol}}</td>
                <td (click)="editdatanew(data.bookingId)" *ngIf="data.outstanding >0"><span style="color: Red; font-weight: bold;">{{data.outstanding | currency: CurrencySymbol}}</span></td>
                <td (click)="editdatanew(data.bookingId)">{{data.extraslist}}</td>

                <td *ngIf="!data.disabled"><button pButton type="button" class="p-button-sm p-element p-ripple p-button-danger p-button p-component" (click)="disableData(data.bookingId)" i18>
                    <span class="pi pi-trash"></span>
                </button></td>

                <td *ngIf="data.disabled"><button pButton type="button" class="p-button-sm p-element p-ripple p-button-success p-button p-component" (click)="undisableData(data.bookingId)" i18>
                    <span class="pi pi-check-circle"></span>
                </button></td>
            </tr>

            <tr *ngIf="data.inProgress && inProgress" style="background-color: #FCE1B5;">
                <td>
                    <span *ngIf="data.publicBooking" class="pi pi-user"></span> 
                </td>
                <td>
                    <span style="color:red" *ngIf="data.notes != '' && data.notes != null" class="pi pi-exclamation-circle" pTooltip="{{data.notes}}"></span> 
                </td>
                <td style="font-style: italic;">{{data.bookingIdHR}}</td>
                <td style="font-style: italic;">{{data.startTime | date:'dd/MM/yy'}}</td>
                <td style="font-style: italic;">{{data.startTime | date:'HH:mm' : 'UTC'}}</td>
                <td style="font-style: italic;">{{data.name}}</td>
                <td style="font-style: italic;">{{data.packageName}}</td>
                <td style="font-style: italic;">{{data.guests}}</td>
                <td style="font-style: italic;">{{data.total | currency: CurrencySymbol}}</td>
                <td style="font-style: italic;">{{data.outstanding | currency: CurrencySymbol}}</td>
                
                <td *ngIf="(data.paymentlinksentdate | date:'dd/MM/yy HH:mm') != '01/01/01 00:00'" style="font-style: italic; text-align: centre;">{{data.paymentlinksentdate | date:'dd/MM/yy HH:mm'}}</td>
                <td *ngIf="(data.paymentlinksentdate | date:'dd/MM/yy HH:mm') == '01/01/01 00:00'" style="font-style: italic; text-align: centre;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;--</td>

                <td style="font-style: italic;">{{data.expiry | date:'HH:mm:ss'}}</td>

                <td>
                    <td *ngIf="!data.publicBooking"><button pButton type="button" class="p-button-sm p-element p-ripple p-button-danger p-button p-component" (click)="abandonBooking(data.bookingId)" i18>
                        <span class="pi pi-times"></span>
                    </button>
                </td>
                <td *ngIf="data.publicBooking">
                </td>
            </tr>
        </ng-template>
    </p-table>

        <!-- <button pButton type="button" class="p-button-sm p-element p-ripple p-button p-component" (click)="exportData()" i18>
            <span class="pi pi-plus"></span> Export this data
        </button> -->
    </div>
</div>
