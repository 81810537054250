<div class="p-3 flex flex-column flex-auto">
    <h3>{{ReportName}}</h3>

    <div class="surface-card p-4 shadow-2 border-round">

    <table width="100%" style="font-family: Inter, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';font-size: 1rem;font-weight: normal;">
        <tr style="text-align: left;padding: 1rem 1rem;border: 1px solid #dee2e6;border-top-width: 1px; border-right-width: 1px;border-bottom-width: 1px;border-left-width: 1px;border-width: 0 0 1px 0;font-weight: 700;color: #343a40; background: #f8f9fa;transition: box-shadow 0.2s;">
            <th *ngFor="let c of cols" style="padding: 1rem;">
                {{c.header}}
            </th>
        </tr>
        <tr *ngFor="let r of reportData">
            <td *ngFor="let c of cols"  style="text-align: left;
            border: 1px solid #dee2e6;border-top-width: 1px;border-right-width: 1px;border-bottom-width: 1px;border-left-width: 1px;border-width: 0 0 1px 0;padding: 1rem 1rem;">
                {{r[c.field]}}
            </td>
        </tr>
    </table>

    </div>
</div>

<!-- <p-table  #reportTable 
    [columns]="cols"
    [value]="reportData" sortField="startTime" [paginator]="true" [rows]="10" 
    [showCurrentPageReport]="true" responsiveLayout="scroll"
    currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" 
    [rowsPerPageOptions]="[10,25,50]" 
    [globalFilterFields]="['name']"
    responsiveLayout="scroll"
    datakey="resourceID"
    [selectionPageOnly]="true">
    <ng-template pTemplate="caption">
        <div class="flex">
          <button
              type="button"
              pButton
              pRipple
              icon="pi pi-file-excel"
              (click)="reportTable.exportCSV()"
              class="mr-2"
              pTooltip="CSV"
              tooltipPosition="bottom">
          </button>
          <button
              type="button"
              pButton
              pRipple
              icon="pi pi-file-pdf"
              (click)="exportPdf()"
              class="mr-2"
              pTooltip="PDF"
              tooltipPosition="bottom">
          </button>
        </div>
    </ng-template>

        <ng-template pTemplate="header" let-columns>
            <tr>
                <th *ngFor="let col of columns">
                    {{ col.header }}
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-columns="columns">
            <tr>
                <td *ngFor="let col of columns">
                    {{ rowData[col.field] }}
                </td>
            </tr>
        </ng-template>
    </p-table> -->
    

