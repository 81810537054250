export interface paymentResponse {
    venueid: string;
    bookingid: string;
    amount: number;
    clientSecret: string;
  }

  export class cPaymentResponse implements paymentResponse {
    venueid: string;
    bookingid: string;
    amount: number;
    clientSecret: string;
  }
