  export interface paymentRequest {
    venueid: string;
    bookingid: string;
    amount: number;
  }

  export class cPaymentRequest implements paymentRequest {
    venueid: string;
    bookingid: string;
    amount: number;
  }
