import { Component } from '@angular/core';

@Component({
  selector: 'app-buycredits',
  templateUrl: './buycredits.component.html',
  styleUrls: ['./buycredits.component.css']
})
export class BuycreditsComponent {
  VenueID: string = localStorage.getItem("SmartVenueID")!;
  httpResponse: any;
  id: string;
  pageReady: boolean = true;

  classNameValid: boolean = true;

  AccessAllowed: boolean = true;
  CompletedLoading: boolean = true;
  
}
