<div *ngIf="!AccessAllowed && CompletedLoading">
  <h3 style="border: 2px solid;border-radius: 6px;position: absolute;top: 50%;left: 50%;transform: translate(-50%, -50%);padding: 10px;">You don't have access to this function</h3>
</div>

<div *ngIf="pageReady && AccessAllowed" class="p-3 flex flex-column flex-auto">
    <div>
        <div class="text-900 font-medium text-xl mb-3"><h3>Resource Group
        </h3></div>
        <p class="m-0 mb-4 p-0 text-600 line-height-3 mr-3">
            <span class="pi pi-info-circle" style="margin-right:2px;"></span>
              Give the resource group a name and then add the resources that make up the resource group.
        </p>
        <div class="surface-card p-4 shadow-2 border-round">
            <div class="grid formgrid p-fluid">

                <!-- <p-tabView style="width:100%">
                    <p-tabPanel header="Resource Group Details" leftIcon="pi pi-clone"> -->
                        <div class="field mb-4 col-12">
                            <label for="classname" class="font-medium">Resource Group Name</label>
                            <input [(ngModel)]="resourcegroupname.name" id="classname" type="text" [ngStyle]="{'border-color': (rgnNameValid==false) ? '#FF0000':''}" (keyup)="DoValidCheck()" pInputText>
                        </div>
                        <div *ngIf="!rgnNameValid" class="text-danger" Style="margin-left:10px; margin-top: -23px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>You must enter a Resource Group Name.</div>
                        
                        <!--<div class="field mb-4 col-12">
                            <label for="classname" class="font-medium">Quantity Label</label>
                            <input [(ngModel)]="resourcegroupname.quantityLabel" id="classname" type="text" [ngStyle]="{'border-color': (rgnQLabelValid==false) ? '#FF0000':''}" (keyup)="DoValidCheck()" pInputText>
                        </div>
                        <div *ngIf="!rgnQLabelValid" class="text-danger" Style="margin-left:10px; margin-top: -23px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>You must enter a Quantity Label.</div>-->
                    <!-- </p-tabPanel>
                    <p-tabPanel header="Group Resources" leftIcon="pi pi-sitemap"> -->
                        <div class="surface-card p-4 shadow-2 border-round" style="width:100%;float:left;">
                            <div style="float:left; width:32%">
                              <div class="drag-column">
                                <h5> Available Resources </h5>
                                <p-table [value]="allresources">
                                  <ng-template pTemplate="header">
                                    <tr>
                                      <th>Name</th>
                                      <th>&nbsp;</th>
                                    </tr>
                                  </ng-template>
                                  <ng-template pTemplate="body" let-resource>
                                    <tr>
                                      <td>{{resource.name}}</td>
                                      <td>
                                        <button pButton pRipple type="button" class="p-button-sm p-element p-button-success w-auto mt-3" (click)="AddToResources(resource)">
                                          <span class="pi pi-angle-right"></span>
                                        </button>
                                      </td>
                                    </tr>
                                  </ng-template>
                                </p-table>
                                <!--<div *ngIf="availableResources.length == 0" style="padding: 15px;">
                                  <i>No resources available</i>
                                </div>-->
                              </div>
                            </div>
                            <div style="float:right; width:65%">
                              <div class="drop-column">
                                <h5> Selected Resources </h5>
                                <p-table [value]="resourcegroupname.resources">
                                  <ng-template pTemplate="header" let-columns>
                                    <tr>
                                      <th style="width:90%">Name</th>
                                      <th>&nbsp;</th>
                                    </tr>
                                  </ng-template>
                                  <ng-template pTemplate="body" let-resource let-columns="columns" let-index="rowIndex">
                                    <tr>
                                      <td>{{resource.name}}</td>
                                      <td>
                                        <button pButton pRipple type="button" [style]="{'margin-bottom': '-6px', 'margin-top': '-4px !important'}" class="p-button-sm p-element p-button-danger w-auto mt-3" (click)="removeFromResources(resource)">
                                          <span class="pi pi-angle-left"></span>
                                        </button>
                                      </td>
                                    </tr>
                                  </ng-template>
                                </p-table>
                                <!---<div *ngIf="selectedResources" style="padding: 15px;">
                                  <i>No resources selected, drag some here.</i>
                                </div>-->
                              </div>
                            </div>
                          </div>
                    <!-- </p-tabPanel>
                </p-tabView> -->

                <div class="col-12">
                    <button pButton pRipple type="button" class="p-button p-element p-button-success w-auto mt-3" (click)="updateData();">
                    <span class="pi pi-pencil" style="margin-right:8px;"></span>
                    Save Changes</button>
                    &nbsp;
                    <button pButton pRipple type="button" class="p-button p-element p-button-danger w-auto mt-3" (click)="returnToList()">
                    <span class="pi pi-times" style="margin-right:8px;"></span>
                    Cancel</button>
                </div>
            </div>
        </div>
    </div>
</div>