import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router'
import { HttpClient } from '@angular/common/http';
import { bmxurl, getAccess } from 'src/globals';
import { Router, RouterLink } from '@angular/router';
import { returnmessage } from 'src/types/returnmessage';
import {MessageService} from 'primeng/api';
import { extraoption_returndata } from 'src/types/extraoption_returndata';
import { cExtraoption, extraoption } from 'src/types/extraoption';
import { tag } from 'src/types/tag';
import { tag_returndata } from 'src/types/tag_returndata';
import { cAdmission } from 'src/types/admission';
import { users_returndata } from 'src/types/user_returndata';

@Component({
  selector: 'app-extraoptionedit',
  templateUrl: './extraoptionedit.component.html',
  styleUrls: ['./extraoptionedit.component.css']
})
export class ExtraOptioneditComponent implements OnInit {
  VenueID: string = localStorage.getItem("SmartVenueID")!;
  ExtraOption: cExtraoption;
  httpResponse: any;
  id: string;
  stateOptions: any;
  pageReady: boolean = false;

  extraOptionNameValid: boolean = true;
  extraOptionDescValid: boolean = true;

  AccessAllowed: boolean = false;
  CompletedLoading: boolean = false;
  

  tags: tag[] = [];
  selectedTags: tag[] = [];

  constructor(private route: ActivatedRoute, private http: HttpClient, private router: Router, private messageService: MessageService) {
    this.stateOptions = [{label: 'Off', value: false}, {label: 'On', value: true}];

    this.ExtraOption = {
        id: null,
        extraOptionID: null,
        name: "",
        description: "",
        venueId: this.VenueID,
        price: 0,
        image: null,
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false,
        saturday: false,
        sunday: false,
        sortorder: 0,
        disabled: false
    }


   }

  ngOnInit(): void {

    let userid = localStorage.getItem("SmartUserID");

    this.http.get(bmxurl + "User/" + userid).subscribe(response => {
      this.httpResponse =  (response as users_returndata)
      if(this.httpResponse.succeeded)
      {
        let user = this.httpResponse.data;

        if(getAccess("EXTRAS", user)){
          this.AccessAllowed = true;
          this.id = this.route.snapshot.paramMap.get('id');

          if(this.id != "0")
          {
            this.pageReady = false;
            this.http.get(bmxurl + "extraoption/" + this.VenueID + "/" + this.id).subscribe(response => {
              this.httpResponse = (response as extraoption_returndata);
              if (this.httpResponse.succeeded)
              {
                this.ExtraOption = {
                  id: (this.httpResponse.data as cExtraoption).id,
                  extraOptionID: (this.httpResponse.data as cExtraoption).extraOptionID,
                  name: (this.httpResponse.data as cExtraoption).name,
                  description: (this.httpResponse.data as cExtraoption).description,
                  venueId: (this.httpResponse.data as cExtraoption).venueId,
                  price: (this.httpResponse.data as cExtraoption).price,
                  image: (this.httpResponse.data as cExtraoption).image,
                  monday: (this.httpResponse.data as cExtraoption).monday,
                  tuesday: (this.httpResponse.data as cExtraoption).tuesday,
                  wednesday: (this.httpResponse.data as cExtraoption).wednesday,
                  thursday: (this.httpResponse.data as cExtraoption).thursday,
                  friday: (this.httpResponse.data as cExtraoption).friday,
                  saturday: (this.httpResponse.data as cExtraoption).saturday,
                  sunday: (this.httpResponse.data as cExtraoption).sunday,
                  sortorder: (this.httpResponse.data as cExtraoption).sortorder,
                  disabled: (this.httpResponse.data as cExtraoption).disabled
                };
      
                this.pageReady = true;
              }
              else
              {
                this.messageService.add({severity:'error', summary:'Error', detail:'Error while getting Extra Option'});
                this.pageReady = false;
              }
            }, error => {
              this.messageService.add({severity:'error', summary:'Error', detail:'Error while getting Extra Option, error:' + error.messages[0]});
              this.pageReady = false;
            });
          }
          else
          {
            this.pageReady = true;
          }
        }

        this.CompletedLoading = true;


      }
    }, error => {
      this.messageService.add({severity:'error', summary:'Error', detail:'Error while getting User, error:' + error.messages[0]});
    });


}

  updateData()
  {
    if(this.DoValidCheck())
    {
      if(this.id=="0")
      {
        this.http.post<extraoption>(bmxurl + "/extraoption", this.ExtraOption).subscribe(response => {
          this.httpResponse = response
          if(this.httpResponse.succeeded)
          {
            this.messageService.add({severity:'success', summary:'Saved', detail:'Extra Option successfully created'});
            this.router.navigateByUrl("/extraoptions");
          }
        }, error => {
          this.messageService.add({severity:'error', summary:'Not Saved', detail:error.messages[0]});
        })
      }
      else
      {
        this.http.put<extraoption>(bmxurl + "/extraoption", this.ExtraOption).subscribe(response => {
          this.httpResponse = response
          if(this.httpResponse.succeeded)
          {
            this.messageService.add({severity:'success', summary:'Saved', detail:'Extra Option successfully updated'});
            this.router.navigateByUrl("/extraoptions");
          }
        }, error => {
          this.messageService.add({severity:'error', summary:'Not Saved', detail:error.messages});
        })
      }
    }
  }

  DoValidCheck()
  {
    let AllValid = true;

    if(this.ExtraOption.name == null || this.ExtraOption.name == "")
    {
      AllValid = false;
      this.extraOptionNameValid = false;
    }
    else
    {
      this.extraOptionNameValid = true;
    }

    if(this.ExtraOption.description == null || this.ExtraOption.description == "")
    {
      AllValid = false;
      this.extraOptionDescValid = false;
    }
    else
    {
      this.extraOptionDescValid = true;
    }

    return AllValid;
  }

  returnToList()
  {
    this.router.navigateByUrl("/extraoptions");
  }
}
