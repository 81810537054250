<div *ngIf="ListBookings" class="p-3 flex flex-column flex-auto">
    <h3 style="margin-bottom: -38px" i18n>Search Results</h3>

    <div style="text-align: right; margin-bottom: 20px;">

        <p-toggleButton class="p-button-sm p-button-danger p-component" [(ngModel)]="showDisabled" onLabel="Show Disabled" offLabel="Hide Disabled" offIcon="pi pi-times" onIcon="pi pi-check" [style]="{'margin-right': '10px', 'color' : 'white', 'background-color': '#EF4444', 'font-weight': 'normal', 'height': '50px'}" (click)="toggleDisabled()">
        </p-toggleButton>

        <span class="p-input-icon-left ml-auto" style="margin-right:20px; "> 
            <i class="pi pi-search"></i>
            <input pInputText type="text" placeholder="Filter Results" (input)="resourceTable.filterGlobal($event.target.value, 'contains')" style="height: 50px;padding-left:33px;" />
        </span>
        
    </div>
    <div class="surface-card p-4 shadow-2 border-round">
    <p-table  #resourceTable 
    [value]="ListBookings" sortField="startTime" [paginator]="true" [rows]="10" 
    [showCurrentPageReport]="true" responsiveLayout="scroll"
    currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" 
    [rowsPerPageOptions]="[10,25,50]" 
    [globalFilterFields]="['name', 'bookingId', 'bookingIdHR', 'packageName']"
    [(selection)]="selectedItems"
    responsiveLayout="scroll"
    datakey="resourceID"
    [selectionPageOnly]="true">
        <ng-template pTemplate="header">
            <tr>
                <th>
                    &nbsp;
                </th>
                <th>
                    &nbsp;
                </th>
                <th pSortableColumn="bookingId" width="12%">Sales ID
                    <p-sortIcon [field]="bookingIdHR"></p-sortIcon>
                </th>
                <th pSortableColumn="startTime" width="12%">Start Date
                    <p-sortIcon [field]="startTime"></p-sortIcon>
                </th>
                <th pSortableColumn="arrival" width="9%">Arrival
                    <p-sortIcon [field]="arrival"></p-sortIcon>
                </th>
                <th pSortableColumn="startTime" width="10%">Start
                    <p-sortIcon [field]="startTime"></p-sortIcon>
                </th>
                <th pSortableColumn="name" width="10%">Name
                    <p-sortIcon [field]="name"></p-sortIcon>
                </th>
                <th pSortableColumn="packageName" width="11%">Package
                    <p-sortIcon [field]="packageName"></p-sortIcon>
                </th>
                <th pSortableColumn="guests" width="10%">Guests
                    <p-sortIcon [field]="guests"></p-sortIcon>
                </th>
                <th pSortableColumn="total" width="8%">Total
                    <p-sortIcon [field]="total"></p-sortIcon>
                </th>
                <th pSortableColumn="outstanding" width="8%">O/S
                    <p-sortIcon [field]="outstanding"></p-sortIcon>
                </th>
                <th width="5%"></th>
                <th width="5%"></th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-data>
            <tr [ngStyle]="{'background-color': (data.disabled==true) ? '#fee' : '#ffffff'}">
                <td>
                    <span *ngIf="data.publicBooking" class="pi pi-user"></span> 
                </td>
                <td>
                    <span style="color:red" *ngIf="data.notes != '' && data.notes != null" class="pi pi-exclamation-circle" pTooltip="{{data.notes}}"></span> 
                </td>
                <td>{{data.bookingIdHR}}</td>
                <td>{{data.startTime | date:'dd/MM/yy'}}</td>
                <td>{{data.arrival | date:'HH:mm' : 'UTC'}}</td>
                <td>{{data.startTime | date:'HH:mm' : 'UTC'}}</td>
                <td>{{data.name}}</td>
                <td>{{data.packageName}}</td>
                <td>{{data.guests}}</td>
                <td>{{data.total | currency: this.CurrencySymbol}}</td>
                <td *ngIf="data.outstanding <=0">{{data.outstanding | currency: CurrencySymbol}}</td>
                <td *ngIf="data.outstanding >0"><span style="color: Red; font-weight: bold;">{{data.outstanding | currency: CurrencySymbol}}</span></td>
                <!--<td><button pButton type="button" class="p-button-sm p-element p-ripple p-button-success p-button p-component" (click)="editdata(data.bookingId)" style="background-color: yellow o !important;" i18>
                    <span class="pi pi-pencil" style="margin-right:8px;"></span>
                    <span i18n>Edit</span>
                </button></td>-->

                <td><button pButton type="button" class="p-button-sm p-element p-ripple p-button-success p-button p-component" (click)="editdatanew(data.bookingId)" style="background-color: yellow o !important;" i18>
                    <span class="pi pi-pencil" style="margin-right:8px;"></span>
                    <span i18n>Edit</span>
                </button></td>

                <td><button pButton type="button" class="p-button-sm p-element p-ripple p-button-danger p-button p-component" (click)="disableData(data.bookingId)" i18>
                    <span class="pi pi-calendar-times" style="margin-right:8px;"></span>
                    <span i18n>Disable</span>
                </button></td>
            </tr>
        </ng-template>
    </p-table>
    </div>
</div>
