import { Component, OnInit } from '@angular/core';
import { cDepartments, departments } from 'src/types/departments';
import { cTillproduct, tillproduct } from 'src/types/tillproduct';
import { cTillRoll, tillRoll } from 'src/types/tillRoll';
import { MessageService } from 'primeng/api';
import { HttpClient } from '@angular/common/http';
import { Router, RouterLink } from '@angular/router';
import { v4 } from 'uuid';
import { Resource, Package } from '../../PackageClasses';
import { AppComponent } from '../app.component';
import { class_returndata } from 'src/types/class_returndata'; 
import { bmxurl, getAccess } from 'src/globals';
import { NgModule } from '@angular/core';
import { NgModel } from '@angular/forms';
import { classes } from 'src/types/class';
import { Checkbox } from 'primeng/checkbox';
import { tillproducts_returndata } from 'src/types/tillproduct_returndata';
import { users_returndata } from 'src/types/user_returndata';

@Component({
  selector: 'app-till',
  templateUrl: './till.component.html',
  styleUrls: ['./till.component.css']
})
export class TillComponent implements OnInit {
  VenueID: string = localStorage.getItem("SmartVenueID")!;
  Departments: cDepartments[];
  Products: cTillproduct[];
  tillRole: tillRoll[];
  deptProducts: cTillproduct[];
  selectedIndex: number = 0;
  TotalCost: number = 0;
  displayModal: boolean = false;
  displayModalOverPaymentWarning: boolean = false;
  displayModalChange: boolean = false;
  Change: number = 0;
  Amount: number = 0;
  TillRoleTotal: number = 0;
  httpResponse: any;
  CurrencySymbol: string = localStorage.getItem("Currency") == null ? "GBP" : localStorage.getItem("Currency")!;

  CompletedLoading: boolean = false;
  AccessAllowed: boolean = false;

  constructor(private http: HttpClient, private router: Router, private messageService: MessageService) {


   }

  ngOnInit(): void {

    localStorage.removeItem("screenMemoryBookingss");
    localStorage.removeItem("screenMemoryEvents");
    
    let userid = localStorage.getItem("SmartUserID");

    this.http.get(bmxurl + "User/" + userid).subscribe(response => {
      this.httpResponse =  (response as users_returndata)
      if(this.httpResponse.succeeded)
      {
        let user = this.httpResponse.data;

        if(getAccess("TILL", user)){
          this.AccessAllowed = true;

              //Build Temp Till Data
    this.Departments = [];
    this.Products = [];
    this.tillRole = [];
    this.deptProducts = [];

    this.http.get("https://bmxv2.azurewebsites.net/TillDepartmentsList/" + this.VenueID + "/false").subscribe(response => {
      this.httpResponse = response
      if(this.httpResponse.succeeded)
      {
        this.Departments = (this.httpResponse.data as departments[]);
        this.http.get("https://bmxv2.azurewebsites.net/TillProductList/" + this.VenueID + "/false").subscribe(response => {
          this.httpResponse = response
          if(this.httpResponse.succeeded)
          {
            this.Products = (this.httpResponse.data as tillproduct[]);

            if ((response as tillproducts_returndata).messages.length > 0)
        {
          (response as tillproducts_returndata).messages.forEach(error => {
            this.messageService.add({severity:'warn', summary:'Response Message', detail: error});
          });
        }
          }
          else
          {
            this.messageService.add({severity:'error', summary:'Not Saved', detail:'Error getting Products, error: ' + this.httpResponse.messages[0]});
          }

          this.ShowProductsForDept();

        }, error => {
          this.messageService.add({severity:'error', summary:'Not Saved', detail: 'Error getting Products, error: ' + error.messages});
        });
      }
      else
      {
        this.messageService.add({severity:'error', summary:'Not Saved', detail:'Error getting Departments, error: ' + this.httpResponse.messages[0]});
      }
    }, error => {
      this.messageService.add({severity:'error', summary:'Not Saved', detail: 'Error getting Departments, error: ' + error.messages});
    });
        }

        this.CompletedLoading = true;

      }
    }, error => {
      this.messageService.add({severity:'error', summary:'Error', detail:'Error while getting User, error:' + error.messages[0]});
    });


  }

  pay(){
    this.TotalCost = this.TillRoleTotal;
    this.Amount = this.TotalCost;
    this.displayModal = true;
  }

  removeTillItem(prod){

    this.tillRole = this.tillRole.filter(x => x.id !== prod.id);

    this.TotalCost -= prod.price;
  }

  addProduct(prod)
  {
    let productFound = false;

    this.tillRole.forEach(element => {
      if(element.id == prod.id)
      {
        productFound = true;
        element.qty ++;
        element.price += prod.price;
      }
    });

    if(!productFound)
    {
      let newItem: cTillRoll =  {
        qty: 1,
        product: prod,
        disabled: false,
        venueid: this.VenueID,
        id: prod.id,
        price: prod.price
      }
  
      productFound = false;
      this.tillRole.push(newItem);
    }

    this.TillRoleTotal +=prod.price;
  }

  ShowProductsForDept()
  {
    let deptId: any = this.Departments[this.selectedIndex].id;
    this.deptProducts = this.Products.filter(x => x.departmentId == deptId);
  }

  payOff(amount, type)
  {
    if (amount == -1)
      amount = this.TotalCost;

    if(amount > this.TotalCost && type == "Card")
    {
      this.displayModalOverPaymentWarning = true;
    }
    else
    {
      this.Amount = this.TotalCost;
      this.TotalCost -= amount;
      
      if(this.TotalCost > 0)
      {
        //this.displayModal = false;
      }
      else if(this.TotalCost == 0)
      {
        this.Change = 0;
        this.displayModalChange = true;
        this.displayModal = false;
        this.tillRole = [];
        this.TillRoleTotal = 0;
      }
      else if (this.TotalCost < 0)
      {
        this.Change = ((this.TotalCost) * -1);
        this.displayModalChange = true;
        this.TotalCost = 0;
        this.displayModal = false;
        this.tillRole = [];
        this.TillRoleTotal = 0;
      }
    }
  }
}