import { Component, ElementRef, OnInit, QueryList, ViewChildren } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Resource, Package } from '../../PackageClasses';
import { AppComponent } from '../app.component';
import { packagerule_returndata } from 'src/types/packagerule_returndata'; 
import { bmxurl, getAccess } from 'src/globals';
import { NgModule } from '@angular/core';
import { NgModel } from '@angular/forms';
import { Router, RouterLink } from '@angular/router';
import { packagerule } from 'src/types/packagerule';
import { MessageService } from 'primeng/api';
import { Checkbox } from 'primeng/checkbox';
import { resourcegroupname_returndata } from 'src/types/resourcegroupname_returndata';
import { users_returndata } from 'src/types/user_returndata';

@Component({
  selector: 'app-resource',
  templateUrl: './resource.component.html',
  styleUrls: ['./resource.component.css']
})

export class ResourceComponent implements OnInit {
  ListResources: any[];
  bmxurl: string;
  VenueID: string = localStorage.getItem("SmartVenueID")!;
  CreateUpdateResponse: any;
  DisabledListResource: any;
  checked: boolean = true;
  isDisabled: boolean = true;
  selectedItems: any;
  httpResponse: any;
  ListResourceGroupNames: any[];

  AccessAllowed: boolean = false;
  CompletedLoading: boolean = false;

  constructor(
    private http: HttpClient, private appComp: AppComponent, private router: Router, private messageService: MessageService) { 

    }

  ngOnInit(): void {

    localStorage.removeItem("screenMemoryBookingss");
    localStorage.removeItem("screenMemoryEvents");
    
    let userid = localStorage.getItem("SmartUserID");

    this.http.get(bmxurl + "User/" + userid).subscribe(response => {
      this.httpResponse =  (response as users_returndata)
      if(this.httpResponse.succeeded)
      {
        let user = this.httpResponse.data;

        if(getAccess("RESOURCES", user)){
          this.AccessAllowed = true;
          this.loadgrid(false);
        }

        this.CompletedLoading = true;

      }
    }, error => {
      this.messageService.add({severity:'error', summary:'Error', detail:'Error while getting User, error:' + error.messages[0]});
    });


  }

  toggleDisabled(){
    this.loadgrid(!this.isDisabled);
  }

  disableData(ResourceID)
  {
    this.http.delete(bmxurl + "Resource/" + this.VenueID + "/" + ResourceID).subscribe(response => {
      this.CreateUpdateResponse = response
      if(this.CreateUpdateResponse.succeeded = true)
      {
        this.messageService.add({severity:'success', summary:'Disabled', detail:'Resource successfully disabled'});
        this.router.navigateByUrl("/resource");

        this.loadgrid(false);
      }
    }, error => {
      this.messageService.add({severity:'error', summary:'Not Disabled', detail: 'An error occurred disabling this Resourse'});
    })
  }
  
  loadgrid(showdisabled: boolean)
  {
    this.http.get(bmxurl + "ResourceList/" + this.VenueID + "/" + "/" + showdisabled).subscribe(response => {
      this.ListResources = (response as packagerule_returndata).data;

      if ((response as packagerule_returndata).messages.length > 0)
        {
          (response as packagerule_returndata).messages.forEach(error => {
            this.messageService.add({severity:'warn', summary:'Response Message', detail: error});
          });
        }

     // this.http.get(bmxurl + "ResourceGroupNameList/" + this.VenueID + "/" + showdisabled).subscribe(response => {
     //   this.httpResponse = response

     //     this.ListResourceGroupNames = (response as resourcegroupname_returndata).data;
     //
     //     this.ListResourceGroupNames.forEach(resGroups => {
     //       if(resGroups.resources != null)
     //       {
     //         resGroups.resources.forEach(rgResource => {
     //           this.ListResources.forEach(res => {
     //             if(rgResource.resourceId == res.resourceId)
     //               res.resourcegroupname = resGroups.name;
     //           });
     //         });
     //       }
     //     });
     // }, error => {
     //});

    }, error => {
    });
  }

  editData(EditData)
  {
    let ResourceID = EditData;
    this.router.navigateByUrl("/resourceedit/" + ResourceID);
  }

  disableMulti()
  {
    this.selectedItems.forEach(element => {

    this.http.delete(bmxurl + "Resource/" + this.VenueID + "/" + element.ResourceID).subscribe(response => {
      this.CreateUpdateResponse = response
      if(this.CreateUpdateResponse.succeeded = true)
      {
        this.loadgrid(false);
      } 
    }, error => {
    
    })
    });
    
  }

  
  }

