<div *ngIf="!AccessAllowed && CompletedLoading">
    <h3 style="border: 2px solid;border-radius: 6px;position: absolute;top: 50%;left: 50%;transform: translate(-50%, -50%);padding: 10px;">You don't have access to this function</h3>
  </div>

<div *ngIf="pageReady && AccessAllowed" class="p-3 flex flex-column flex-auto">
    <div>
        <div class="text-900 font-medium text-xl mb-3"><h3>Department
        </h3></div>
        <p class="m-0 mb-4 p-0 text-600 line-height-3 mr-3">
            <span class="pi pi-info-circle" style="margin-right:2px;"></span>
            Give the department a name and a colour.
        </p>
        <div class="surface-card p-4 shadow-2 border-round">
            <div class="grid formgrid p-fluid">
                <div class="field mb-4 col-12">
                    <label for="deptName" class="font-medium">Department Name</label>
                    <input [(ngModel)]="Department.name" id="classname" type="text" [ngStyle]="{'border-color': (departmentNameValid==false) ? '#FF0000':''}" (keyup)="DoValidCheck()" pInputText>
                </div>
                <div *ngIf="!departmentNameValid" class="text-danger" Style="margin-left:10px; margin-top: -23px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>You must enter a Department name.</div>
                <div class="field mb-4 col-12">
                    <label for="classname" class="font-medium">Colour</label><br/>
                    <p-colorPicker [style]="{'margin-right': '10px'}" [inline]="false" id="colour" [(ngModel)]="Department.colour"></p-colorPicker>
                        <input style="width:200px;" [(ngModel)]="Department.colour" id="hexcolour" type="text" pInputText>
                </div>
                <div class="col-12">
                    <button pButton pRipple type="button" class="p-button p-element p-button-success w-auto mt-3" (click)="updateData();">
                    <span class="pi pi-pencil" style="margin-right:8px;"></span>
                    Save Changes</button>
                    &nbsp;
                    <button pButton pRipple type="button" class="p-button p-element p-button-danger w-auto mt-3" (click)="returnToList()">
                    <span class="pi pi-times" style="margin-right:8px;"></span>
                    Cancel</button>
                </div>
            </div>
        </div>
    </div>
</div>