<div *ngIf="!AccessAllowed && CompletedLoading">
    <h3 style="border: 2px solid;border-radius: 6px;position: absolute;top: 50%;left: 50%;transform: translate(-50%, -50%);padding: 10px;">You don't have access to this function</h3>
  </div>

<div *ngIf="pageReady && AccessAllowed" class="p-3 flex flex-column flex-auto">
    <div>
        <div class="text-900 font-medium text-xl mb-3"><h3>Restriction</h3></div>
        <p class="m-0 mb-4 p-0 text-600 line-height-3 mr-3">
            <span class="pi pi-info-circle" style="margin-right:2px;"></span>
            Restrictions allow you to add a message to a package, requiring the customer to agree that this package can only be booked for those that match this Restriction
        </p>
        <div class="surface-card p-4 shadow-2 border-round">
            <div class="grid formgrid p-fluid">
                <div class="field mb-4 col-12">
                    <label for="agegroupname" class="font-medium">Restriction Name</label>
                    <input [(ngModel)]="Restriction.name" id="agegroupname" type="text" [ngStyle]="{'border-color': (restrictionNameValid==false) ? '#FF0000':''}" (keyup)="DoValidCheck()" pInputText>
                </div>
                <div *ngIf="!restrictionNameValid" class="text-danger" Style="margin-left:10px; margin-top: -23px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>You must enter an Restriction name.</div>

                <div style="width:100%">
                    <div class="field mb-4 col-12">
                        <label for="venueinstructions" class="font-medium">Venue Instructions on how to pick the appropriate Restriction</label>
                        <textarea id="venueinstructions" type="text" pInputTextarea rows="2" [(ngModel)]="Restriction.venueInstructions" [autoResize]="true"></textarea>
                    </div>

                    <div class="field mb-4 col-12">
                        <label for="publicinstructions" class="font-medium">Public Instructions on how to pick the appropriate Restriction</label>
                        <textarea id="publicinstructions" type="text" pInputTextarea rows="2" [(ngModel)]="Restriction.publicInstructions" [autoResize]="true"></textarea>
                    </div>

                    <div class="field mb-4 col-12">
                        <label for="restrictionGroup" class="font-medium">Grouped Restriction</label>
                        <p-selectButton id="restrictionGroup" [style]="{'width': '150px'}" [options]="stateOptions" [(ngModel)]="Restriction.group" ></p-selectButton>
                    </div>
                    
                    <div *ngIf="Restriction.group" class="surface-card p-4 shadow-2 border-round">

                            <div class="field mb-4 col-12">
                                <div style="width:90%; margin-right:10px; float: left; margin-bottom:20px;">
                                    <label for="subRestrictionName" class="font-medium">Sub-restriction Name</label>
                                    <input id="subRestrictionName" type="text" [(ngModel)]="subResName" pInputText>
                                </div>
                                <div style="width:5%; float: left; margin-top: 31px;">
                                    <button pButton type="button" style="height: 50px;width: 90px;" class="p-button-sm p-element p-ripple p-button-success p-button p-component" [routerLink]="['/restrictionedit/0']" (click)="insertSubRestriction();">
                                        <span class="pi pi-plus" style="margin-right:8px;"></span>
                                        <span i18>Add</span>
                                    </button>
                                </div>
                            </div>
                        <div style="clear:both;"></div>
                        <p-table #SubRestrictionTable 
                        [value]="Restriction.subRestrictions"
                        responsiveLayout="scroll"
                        datakey="subRestrictionID"
                        [selectionPageOnly]="true"
                        [reorderableColumns]="true">
                            <ng-template pTemplate="header">
                                <tr>
                                    <th width="5%"></th>
                                    <th width="90%">Name</th>
                                    <th width="5%"></th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-data let-index="rowIndex">
                                <tr [pReorderableRow]="index" [ngStyle]="{'background-color': (data.disabled==true) ? '#fee' : '#ffffff'}">
                                    <td>
                                        <span class="pi pi-bars" pReorderableRowHandle></span>
                                    </td>
                                    <td>{{data.subRestrictionName}}</td>
                                    <td><button pButton type="button" class="p-button-sm p-element p-ripple p-button-danger p-button p-component" (click)="deleteData(data.subRestrictionID)" i18>
                                        <span class="pi pi-trash" style="margin-right:8px;"></span>
                                        <span i18n>Disable</span>
                                    </button></td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </div>
                </div>

                <div class="col-12">
                    <button pButton pRipple type="button" class="p-button p-element p-button-success w-auto mt-3" (click)="updateData();">
                    <span class="pi pi-pencil" style="margin-right:8px;"></span>
                    Save Changes</button>
                    &nbsp;
                    <button pButton pRipple type="button" class="p-button p-element p-button-danger w-auto mt-3" (click)="returnToList()">
                    <span class="pi pi-times" style="margin-right:8px;"></span>
                    Cancel</button>
                </div>
            </div>
        </div>
    </div>
</div>