<div *ngIf="!AccessAllowed && CompletedLoading">
    <h3 style="border: 2px solid;border-radius: 6px;position: absolute;top: 50%;left: 50%;transform: translate(-50%, -50%);padding: 10px;">You don't have access to this function</h3>
</div>

<div *ngIf="AccessAllowed" class="p-3 flex flex-column flex-auto">
    <h3 style="margin-bottom: -38px" i18n>Bookings for: </h3>
    <div style="width: calc(100vw - 607px); float: left; margin-left: 252px; margin-bottom:20px;">
        <button pButton type="button"  pTooltip="Previous day"  style="height: 50px; margin-right: 5px;width: 53px; float:left;" (click)="dateChange(-1);" class="p-button-sm p-element p-ripple p-button p-component">
            <span class="pi pi-angle-left" style="margin-right:8px;"></span>
        </button>
        <div style="float:left; width: 200px;">
            <p-calendar [(ngModel)]="BookingDate" firstDayOfWeek="1" dateFormat="dd/mm/yy" [showIcon]="true" (onSelect)="doneDate()" (onChange)="doneDate()"></p-calendar>  
        </div> 
        <button pButton type="button" pTooltip="Next day" style="height: 50px; margin-left: 5px;width: 53px; float:left;" (click)="dateChange(1);" class="p-button-sm p-element p-ripple p-button p-component">
            <span class="pi pi-angle-right" style="margin-right:8px;"></span>
        </button>
        &nbsp;
    </div>

    <div class="surface-card p-4 shadow-2 border-round">
        <p-table  #resourceTable 
        [columns]="cols"
        [value]="ListBookingsGlobal" sortField="name" [paginator]="true" [rows]="10" 
        [showCurrentPageReport]="true" responsiveLayout="scroll"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" 
        [rowsPerPageOptions]="[10,25,50]" 
        [globalFilterFields]="['name', 'bookingId', 'bookingIdHR', 'packageName']"
        [(selection)]="selectedItems"
        responsiveLayout="scroll"
        [selectionPageOnly]="true">
        <ng-template pTemplate="caption">
            <div class="flex">
            <button
                type="button"
                pButton
                pRipple
                icon="pi pi-file-excel"
                (click)="doReport()"
                class="mr-2"
                pTooltip="Export to CSV"
                tooltipPosition="bottom">
            </button>
            </div>
        </ng-template>

        <ng-template pTemplate="header" let-columns>
            <tr>
                <th *ngFor="let col of columns">
                    {{ col.header }}
                </th>
            </tr>
        </ng-template>

        <ng-template pTemplate="body" let-rowData let-columns="columns">
            <tr>
                <td *ngFor="let col of columns">
                    {{ rowData[col.field] }}
                </td>

            </tr>

        </ng-template>
    </p-table>
    </div>
</div>
