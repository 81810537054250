import { Admission } from "src/PackageClasses";
import { resourceClass } from "./class";
import { resourcegroupname } from "./resourcegroupname";
import { tag } from "./tag";
import { admission } from "./admission";

export interface resource {
  groupname:string;
  id: string;
  resourceId: string;    
  name: string;  
  useClasses: boolean;
  exclusive: boolean;
  eventid: string;
  concurrentUses: number;
  maxConcurrent: number;
  disabled: boolean;  
  venueId: string;  
  colour: string;
  orderindex: number;
  length: number;
  classes: resourceClass[];
  showonbooking: boolean;
  resources: resource[];
  isresourcecontainer: boolean;
  isconstructed: boolean;
  resourcegroupname: resourcegroupname;
  price: number;
  fixedprice: boolean;
  tags: tag[];
  resourcegroup: resourcegroupname;  
  selectionmode: string;  
  selectableresources: resource[];  
  startwhen:string;
  numofminutes: number;
  optional: boolean;
  quantityselector: string;
  quantity: number;
  mode: string;
  inline: boolean;
  ignore: boolean;
  admissions: admission[];
  inProgress: boolean;
  originatingid: string;
  wasoptional: boolean;
  wasselected: boolean;
}

export class cResource implements resource {
  groupname:string;
  id: string;
  resourceId: string;    
  name: string;  
  useClasses: boolean;
  exclusive: boolean;
  eventid: string;
  concurrentUses: number;
  maxConcurrent: number;
  disabled: boolean;  
  venueId: string;  
  colour: string;
  orderindex: number;
  length: number;
  classes: resourceClass[];
  showonbooking: boolean;
  resources: resource[];
  isresourcecontainer: boolean;
  isconstructed: boolean;
  resourcegroupname: resourcegroupname;
  price: number;
  fixedprice: boolean;
  tags: tag[];
  resourcegroup: resourcegroupname;  
  selectionmode: string;  
  selectableresources: resource[];  
  startwhen:string;
  numofminutes: number;
  optional: boolean;
  quantityselector: string;
  quantity: number;
  mode: string;
  inline: boolean;
  ignore: boolean;
  admissions: admission[];
  inProgress: boolean;
  originatingid: string;
  wasoptional: boolean;
  wasselected: boolean;
}