import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { InputNumberModule } from 'primeng/inputnumber';
import { DropdownModule } from 'primeng/dropdown';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { FormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { BookingComponent } from './booking/booking.component';
import { HomeComponent } from './home/home.component';
import { ResourceComponent } from './resource/resource.component';
import { RestrictionComponent } from './restriction/restriction.component';
import { ClassComponent } from './class/class.component';
import { PackageruleComponent } from './packagerule/packagerule.component';
import { TableModule } from 'primeng/table';
import { RestrictioneditComponent } from './restriction/restrictionedit.component';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { LoadingInterceptor } from './_interceptors/loading.interceptor';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ToastModule} from 'primeng/toast';
import { MessageService } from 'primeng/api';
import { VenueclosingsComponent } from './venueclosings/venueclosings.component';
import { VenueclosingsEditComponent } from './venueclosings/venueclosingsedit.component';
import { MenuModule } from 'primeng/menu';
import { CalendarModule } from 'primeng/calendar';
import { ClasseditComponent } from './class/classedit.component';
import { PackageruleeditComponent } from './packagerule/packageruleedit.component';
import { SelectButtonModule } from 'primeng/selectbutton';
import { ColorPickerModule } from 'primeng/colorpicker';
import { ResourceeditComponent } from './resource/resourceedit.component';
import { AtaglanceComponent } from './ataglance/ataglance.component';
import { AccordionModule } from 'primeng/accordion';
import { MakeabookingComponent } from './makeabooking/makeabooking.component';
import { tagComponent } from './tag/tag.component';
import { TageditComponent } from './tag/tagedit.component';
import { extraComponent } from './extra/extra.component';
import { ExtraeditComponent } from './extra/extraedit.component';
import { TagModule } from 'primeng/tag';
import { ChipModule } from 'primeng/chip';
import { MultiSelectModule } from 'primeng/multiselect';
import { extraoptionsComponent } from './extraoption/extraoption.component';
import { ExtraOptioneditComponent } from './extraoption/extraoptionedit.component';
import { PackageeditComponent } from './package/packageedit.component';
import { packageComponent } from './package/package.component';
import { TabViewModule } from 'primeng/tabview';
import { VenuesettingsComponent } from './venuesettings/venuesettings.component';
import { GMapModule } from 'primeng/gmap';
import { AdmissionsComponent } from './admissions/admissions.component';
import { AdmissioneditComponent } from './admissions/admissionedit.component';
import { SliderModule } from 'primeng/slider';
import { PackagestarttimesComponent } from './packagestarttimes/packagestarttimes.component';
import { TimesetsComponent } from './timesets/timesets.component';
import { TimeseteditComponent } from './timesets/timesetedit.component';
import {StepsModule} from 'primeng/steps';
import {BadgeModule} from 'primeng/badge';
import { PackageextrasComponent } from './packageextras/packageextras.component';
import {DialogModule} from 'primeng/dialog';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {ConfirmationService} from 'primeng/api';
import {ChartModule} from 'primeng/chart';
import { FullCalendarModule } from '@fullcalendar/angular';
import {DynamicDialogModule} from 'primeng/dynamicdialog';
import {RadioButtonModule} from 'primeng/radiobutton';
import { InputSwitchModule } from 'primeng/inputswitch';

import { TimesetviewComponent } from './timesets/timesetview.component';
import { DepositpoliciesComponent } from './depositpolicies/depositpolicies.component';
import { DepositpolicieseditComponent } from './depositpolicies/depositpoliciesedit.component';
import { EditbookingComponent } from './editbooking/editbooking.component';
import { PaymentTestComponent } from './payment-test/payment-test.component';
import { StaffloginComponent } from './stafflogin/stafflogin.component';
import { BookingcalendarComponent } from './bookingcalendar/bookingcalendar.component';
import { TillComponent } from './till/till.component';
import { TilldepartmentsComponent } from './tilldepartments/tilldepartments.component';
import { TillproductsComponent } from './tillproducts/tillproducts.component';
import { TilldepartmentseditComponent } from './tilldepartments/tilldepartmentsedit.component';
import { TillproductseditComponent } from './tillproducts/tillproductsedit.component';
import { ResourcegroupnameeditComponent } from './resourcegroupname/resourcegroupnameedit.component';
import { ResourcegroupnameComponent } from './resourcegroupname/resourcegroupname.component';
import { BookingfeeComponent } from './bookingfee/bookingfee.component';
import { BookingfeeeditComponent } from './bookingfee/bookingfeeedit.component';
import { AuthModule } from '@auth0/auth0-angular';
import { AuthHttpInterceptor } from '@auth0/auth0-angular';
import { environment as env } from '../environments/environment';
import { TermsandconditionsComponent } from './termsandconditions/termsandconditions.component';
import { EditorModule } from 'primeng/editor';
import { BusinessComponent } from './business/business.component';
import { GlobalService } from './_services/global.service';
import { PinpadComponent } from './pinpad/pinpad.component';
import { SmartAuthInterceptorInterceptor } from './_interceptors/smart-auth-interceptor.interceptor';
import { ReportsComponent } from './reports/reports.component';
import { CashupComponent } from './cashup/cashup.component';
import { RouterModule } from '@angular/router';
import { SummaryComponent } from './summary/summary.component';
import { CardModule } from 'primeng/card';
import { DiscountsEditComponent } from './discounts/discountsedit.component';
import { ConfirmationeditorComponent } from './confirmationeditor/confirmationeditor.component';
import { PackagegroupsComponent } from './packagegroups/packagegroups.component';
import { DiscountsComponent } from './discounts/discounts.component';
import { PackagegroupeditComponent } from './packagegroups/packagegroupedit.component';
import { ConfirmationComponent } from './confirmationeditor/confirmation.component';
import { ImageuploadtestComponent } from './imageuploadtest/imageuploadtest.component';
import { FileUploadModule } from 'primeng/fileupload';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { UsersComponent } from './users/users.component';
import { UsereditComponent } from './users/useredit.component';
import { SafePipe } from './safe.pipe';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { PublictermsandconditionsComponent } from './publictermsandconditions/publictermsandconditions.component';
import { DaysheetStyle1Component } from './daysheetStyle1/daysheetStyle1.component';
import { PrintconfirmationComponent } from './printconfirmation/printconfirmation.component';
import { SearchComponent } from './search/search.component';
import { BookingquestionsComponent } from './bookingquestions/bookingquestions.component';
import { BookingquestioneditComponent } from './bookingquestions/bookingquestionedit.component';
import { BookingquestionnaireComponent } from './bookingquestionnaire/bookingquestionnaire.component';
import { BookingquestionnaireeditComponent } from './bookingquestionnaire/bookingquestionnaireedit.component';
import { ResourceclosingsComponent } from './resourceclosings/resourceclosings.component';
import { ResourceclosingseditComponent } from './resourceclosings/resourceclosingsedit.component';
import { CashuplistComponent } from './cashup/cashuplist.component';
import { EventsComponent } from './events/events.component';
import { EventeditComponent } from './events/eventedit.component';
import { ResourceadjustmentsComponent } from './resourceadjustments/resourceadjustments.component';
import { ResourceadjustmenteditComponent } from './resourceadjustments/resourceadjustmentedit.component';
import { EmailtemplateComponent } from './emailtemplate/emailtemplate.component';
import { EmailtemplateeditComponent } from './emailtemplate/emailtemplateedit.component';
import { DragDropModule } from 'primeng/dragdrop'
import { BookingblockComponent } from './bookingblock/bookingblock.component';
import { CounterslistComponent } from './counterslist/counterslist.component';
import { VoucherlistComponent } from './voucherlist/voucherlist.component';
import { TasktypelistComponent } from './tasktype/tasktypelist.component';
import { BuyvoucherComponent } from './buyvoucher/buyvoucher.component';
import { VouchereditComponent } from './voucherlist/voucheredit.component';
import { TasktypeeditComponent } from './tasktype/tasktypeedit.component'
import { StyleClassModule } from 'primeng/styleclass';
import { CreatenewcustomerComponent } from './createnewbusinessuser/createnewcustomer.component';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { CreatenewbusinessuserComponent } from './createnewbusinessuser/createnewbusinessuser.component';
import { CreatenewmasterkeyuserComponent } from './createnewbusinessuser/createnewmasterkeyuser.component';
import { AddnewvenuetobusinessComponent } from './createnewbusinessuser/addnewvenuetobusiness.component';
import { ExtrasreportComponent } from './extrasreport/extrasreport.component';
import { QrcodeComponent } from './qrcode/qrcode.component';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { BookinganswersreportComponent } from './bookinganswersreport/bookinganswersreport.component';
import { MarketingComponent } from './marketing/marketing.component';
import { MarketingbirthdayComponent } from './marketing/marketingbirthday/marketingbirthday.component';
import { MarketingabandonedComponent } from './marketing/marketingabandoned/marketingabandoned.component';
import { MarketingmissingComponent } from './marketing/marketingmissing/marketingmissing.component';
import { MarketinggroupComponent } from './marketing/marketinggroup/marketinggroup.component';
import { MarketingbirthdayeditComponent } from './marketing/marketingbirthday/marketingbirthdayedit.component';
import { MarketingemailsComponent } from './marketing/marketingemails/marketingemails.component';
import { MarketingrevenueComponent } from './marketing/marketingrevenue/marketingrevenue.component';
import { WarningsComponent } from './warnings/warnings.component';
import { WarningeditComponent } from './warnings/warningedit.component';
import { TestingtasklistComponent } from './testingtasklist/testingtasklist.component';
import { BuycreditsComponent } from './buycredits/buycredits.component';
import { LinkmanagerComponent } from './linkmanager/linkmanager.component';
import { LinkeditorComponent } from './linkmanager/linkeditor.component';
import { ClipboardModule } from 'ngx-clipboard';


@NgModule({

  declarations: [
    AppComponent,
    BookingComponent,
    HomeComponent,
    ResourceComponent,
    RestrictionComponent,
    ClassComponent,
    PackageruleComponent,
    RestrictioneditComponent,
    VenueclosingsComponent,
    VenueclosingsEditComponent,
    ClasseditComponent,
    PackageruleeditComponent,
    ResourceeditComponent,
    AtaglanceComponent,
    MakeabookingComponent,
    tagComponent,
    TageditComponent,
    extraComponent,
    ExtraeditComponent,
    extraoptionsComponent,
    ExtraOptioneditComponent,
    packageComponent,
    PackageeditComponent,
    VenuesettingsComponent,
    AdmissionsComponent,
    AdmissioneditComponent,
    PackagestarttimesComponent,
    TimesetsComponent,
    TimeseteditComponent,
    PackageextrasComponent,
    TimesetviewComponent,
    DepositpoliciesComponent,
    DepositpolicieseditComponent,
    EditbookingComponent,
    PaymentTestComponent,
    StaffloginComponent,
    BookingcalendarComponent,
    TillComponent,
    TilldepartmentsComponent,
    TillproductsComponent,
    TilldepartmentseditComponent,
    TillproductseditComponent,
    ResourcegroupnameComponent,
    ResourcegroupnameeditComponent,
    BookingfeeComponent,
    BookingfeeeditComponent,
    TermsandconditionsComponent,
    BusinessComponent,
    PinpadComponent,
    ReportsComponent,
    CashupComponent,
    SummaryComponent,
    DiscountsEditComponent,
    ConfirmationeditorComponent,
    PackagegroupsComponent,
    DiscountsComponent,
    PackagegroupeditComponent,
    ConfirmationComponent,
    ImageuploadtestComponent,
    UsersComponent,
    UsereditComponent,
    SafePipe,
    PublictermsandconditionsComponent,
    DaysheetStyle1Component,
    PrintconfirmationComponent,
    SearchComponent,
    BookingquestionsComponent,
    BookingquestioneditComponent,
    BookingquestionnaireComponent,
    BookingquestionnaireeditComponent,
    ResourceclosingsComponent,
    ResourceclosingseditComponent,
    CashuplistComponent,
    EventsComponent,
    EventeditComponent,
    ResourceadjustmentsComponent,
    ResourceadjustmenteditComponent,
    EmailtemplateComponent,
    EmailtemplateeditComponent,
    BookingblockComponent,
    CounterslistComponent,
    VoucherlistComponent,
    TasktypelistComponent,
    BuyvoucherComponent,
    VouchereditComponent,
    TasktypeeditComponent,
    CreatenewcustomerComponent,
    CreatenewbusinessuserComponent,
    CreatenewmasterkeyuserComponent,
    AddnewvenuetobusinessComponent,
    ExtrasreportComponent,
    QrcodeComponent,
    BookinganswersreportComponent,
    MarketingComponent,
    MarketingbirthdayComponent,
    MarketingabandonedComponent,
    MarketingmissingComponent,
    MarketinggroupComponent,
    MarketingbirthdayeditComponent,
    MarketingemailsComponent,
    MarketingrevenueComponent,
    WarningsComponent,
    WarningeditComponent,
    TestingtasklistComponent,
    BuycreditsComponent,
    LinkmanagerComponent,
    LinkeditorComponent
  ],
  imports: [
    ClipboardModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    InputNumberModule,
    DropdownModule,
    FormsModule,
    ToggleButtonModule,
    TableModule,
    ButtonModule,
    CheckboxModule,
    InputTextModule,
    InputTextareaModule,
    NgxSpinnerModule,
    ToastModule,
    MenuModule,
    CalendarModule,
    SelectButtonModule,
    ColorPickerModule,
    AccordionModule,
    DragDropModule,
    ChipModule,
    TagModule,
    TabViewModule,
    GMapModule,
    SliderModule,
    StepsModule,
    BadgeModule,
    DialogModule,
    ConfirmDialogModule,
    ChartModule,
    FullCalendarModule,
    DynamicDialogModule,
    RadioButtonModule,
    InputSwitchModule,
    EditorModule,
    CardModule,
    FileUploadModule,
    BreadcrumbModule,
    StyleClassModule,
    TieredMenuModule,
    MultiSelectModule,
    OverlayPanelModule,
    NgIdleKeepaliveModule.forRoot(),
    // Import the module into the application, with configuration
    AuthModule.forRoot({
      ...env.auth,
      httpInterceptor: {
        ...env.httpInterceptor,
      },
    })
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SmartAuthInterceptorInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthHttpInterceptor,
      multi: true,
    },
    MessageService,
    ConfirmationService,
    GlobalService
    
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
