<div *ngIf="!AccessAllowed && CompletedLoading">
    <h3 style="border: 2px solid;border-radius: 6px;position: absolute;top: 50%;left: 50%;transform: translate(-50%, -50%);padding: 10px;">You don't have access to this function</h3>
  </div>

<div *ngIf="pageReady && AccessAllowed" class="p-3 flex flex-column flex-auto">
    <div>
        <div class="text-900 font-medium text-xl mb-3"><h3>Venue Closing</h3></div>
        <p class="m-0 mb-4 p-0 text-600 line-height-3 mr-3">
            <span class="pi pi-info-circle" style="margin-right:2px;"></span>
            Venue Closings allow you to specify any temporary closings for your venue for example, you have a special event running and your venue is not open to the public.<br><br>During this time you will not be able to take any bookings.
        </p>
        <div class="surface-card p-4 shadow-2 border-round">
            <div class="grid formgrid p-fluid">
                <div class="field mb-4 col-12">
                    <label for="venueclosingname" class="font-medium">Venue Closing Name</label>
                    <input [(ngModel)]="VenueClosing.name"  id="venueclosingname" type="text" required (keyup)="DoValidCheck()" pInputText>
                    <div *ngIf="!venueNameValid" class="text-danger" Style="margin-left:10px; margin-top: 0px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>You must enter a Venue Closing name.</div>
                
                <div *ngIf="!venueDateLoginValid" class="text-danger" Style="margin-left:10px; margin-top: 0px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>The Start Date must be before the End Date.</div>
            </div>
                <div style="width:100%;">
                   <div class="field mb-4 col-12" style="max-width: 500px;">
                        <label for="venueclosingstartdate">Venue Closing Start Date</label>
                        <p-calendar [(ngModel)]="newStartDate" firstDayOfWeek="1" dateFormat="dd/mm/yy" (onSelect)="DoDateValidCheck(); DoDateCheck();" [showIcon]="true" inputId="venueclosingstartdate"></p-calendar>
                        <div *ngIf="!venueStartDateValid" class="text-danger" Style="margin-left:10px; margin-top: 0px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>You must enter a Start Date.</div>
                    </div>

                    <div class="field mb-4 col-12" style="max-width: 500px;">
                        <label for="venueclosingenddate">Venue Closing Start Time</label>
                        <br>
                        <p-dropdown [options]="venueStartTimeHours" [style]="{'width': '95px', 'float': 'left'}"  [(ngModel)]="venueSelectedStartTimeHours" optionValue="code" optionLabel="name"></p-dropdown>
                        <span style="font-size: 29px;float: left;margin-left: 10px;margin-right: 10px;">:</span>
                        <p-dropdown [options]="venueStartTimeMins" [style]="{'width': '95px', 'float': 'left'}"  [(ngModel)]="venueSelectedStartTimeMins" optionValue="code" optionLabel="name"></p-dropdown>
                        <div style="clear:both;"></div>
                    </div>

                    <div class="field mb-4 col-12" style="max-width: 500px;">
                        <label for="venueclosingstartdate">Venue Closing End Date</label>
                        <p-calendar [(ngModel)]="newEndDate" firstDayOfWeek="1" dateFormat="dd/mm/yy" (onSelect)="DoDateValidCheck(); DoDateCheck();" [showIcon]="true" inputId="venueclosingstartdate"></p-calendar>
                        <div *ngIf="!venueStartDateValid" class="text-danger" Style="margin-left:10px; margin-top: 0px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>You must enter a End Date.</div>
                    </div>

                    <div class="field mb-4 col-12" style="max-width: 500px;">
                        <label for="venueclosingenddate">Venue Closing End Time</label>
                        <br>
                        <p-dropdown [options]="venueStartTimeHours" [style]="{'width': '95px', 'float': 'left'}"  [(ngModel)]="venueSelectedEndTimeHours" optionValue="code" optionLabel="name"></p-dropdown>
                        <span style="font-size: 29px;float: left;margin-left: 10px;margin-right: 10px;">:</span>
                        <p-dropdown [options]="venueStartTimeMins" [style]="{'width': '95px', 'float': 'left'}"  [(ngModel)]="venueSelectedEndTimeMins" optionValue="code" optionLabel="name"></p-dropdown>
                        <div style="clear:both;"></div>
                    </div>
             
                </div>

                <p>The message fields allow you to pop up a message when someone selects a date which falls on a venue closing.</p>

                <div class="field mb-4 col-12">
                    <label for="packageobjdescription" class="font-medium">Public Message</label>
                    <textarea [(ngModel)]="VenueClosing.publicMessage" [ngStyle]="{'border-color': (packageDescValid==false) ? '#FF0000':''}" (keyup)="DoValidCheck()" [rows]="5" [cols]="50" pInputTextarea autoResize="autoResize"></textarea>
                </div>

                <div class="field mb-4 col-12">
                    <label for="packageobjdescription" class="font-medium">Venue Message</label>
                    <textarea [(ngModel)]="VenueClosing.venueMessage" [ngStyle]="{'border-color': (packageDescValid==false) ? '#FF0000':''}" (keyup)="DoValidCheck()" [rows]="5" [cols]="50" pInputTextarea autoResize="autoResize"></textarea>
                </div>


                <div class="col-12">
                    <button pButton pRipple type="button" class="p-button p-element p-button-success w-auto mt-3" (click)="updateData();">
                    <span class="pi pi-pencil" style="margin-right:8px;"></span>
                    Save Changes</button>
                    &nbsp;
                    <button pButton pRipple type="button" class="p-button p-element p-button-danger w-auto mt-3" (click)="returnToList()">
                    <span class="pi pi-times" style="margin-right:8px;"></span>
                    Cancel</button>
                </div>
            </div>
        </div>
    </div>
</div>