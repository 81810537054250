
<div class="p-3 flex flex-column flex-auto">
    <div class="text-900 font-medium text-xl mb-3"><h3>App QR Code
    </h3></div>
    <p class="m-0 mb-4 p-0 text-600 line-height-3 mr-3">
        <span class="pi pi-info-circle" style="margin-right:2px;"></span>
        Scan this code with a mobile device to log in to the app and allow scanning
    </p>

    <div class="surface-card p-4 shadow-2 border-round">
        
    </div>
</div>
