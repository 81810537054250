<div *ngIf="!AccessAllowed && CompletedLoading">
    <h3 style="border: 2px solid;border-radius: 6px;position: absolute;top: 50%;left: 50%;transform: translate(-50%, -50%);padding: 10px;">You don't have access to this function</h3>
  </div>

<div *ngIf="ListPackageGroups && AccessAllowed" class="p-3 flex flex-column flex-auto">
    <div class="text-900 font-medium text-xl mb-3"><h3>Package Groups
    </h3></div>
    <p class="m-0 mb-4 p-0 text-600 line-height-3 mr-3">
        <span class="pi pi-info-circle" style="margin-right:2px;"></span>
        Split your packages in to distinct groups. This helps customers navigate more complex pricing lists, a simple example would be 'General Admissions' and 'Parties'. If you do not add a package to a group then the system will create an 'Other' group.
    </p>
    <div style="text-align: right; margin-bottom: 20px;">

        <p-toggleButton class="p-button-sm p-button-danger p-component" [(ngModel)]="isDisabled" onLabel="Show Disabled" offLabel="Hide Disabled" offIcon="pi pi-times" onIcon="pi pi-check" [style]="{'margin-right': '10px', 'color' : 'white', 'background-color': '#EF4444', 'height': '50px'}" (click)="toggleDisabled()">
        </p-toggleButton>

        <span class="p-input-icon-left ml-auto" style="margin-right:20px; "> 
            <i class="pi pi-search"></i>
            <input pInputText type="text" (input)="packageGroups.filterGlobal($event.target.value, 'contains')" style="height: 50px;padding-left:33px;" />
        </span>
        
        <button pButton type="button" style="height: 50px;" class="p-button-sm p-element p-ripple p-button-success p-button p-component" [routerLink]="['/packagegroupedit/0']" i18>
        <span class="pi pi-plus" style="margin-right:8px;"></span>
        <span i18>Add</span>
        </button>
        &nbsp;
        <button pButton type="button" style="height: 50px;" class="p-button-sm p-element p-ripple p-button-danger p-button p-component" (click)="disableMulti()">
        <span class="pi pi-trash" style="margin-right:8px;"></span>
        <span i18>Disable</span>
        </button>
    </div>
    <div class="surface-card p-4 shadow-2 border-round">
        <p-table [value]="ListPackageGroups" #packageGroups >
            <ng-template  pTemplate="header" let-columns>
                <tr>
                    <th>&nbsp;</th>
                    <th style="width:90%">Name</th>
                    <th style="width: 5%">&nbsp;</th>
                    <th style="width: 5%">&nbsp;</th>
                </tr>
            </ng-template>
                <ng-template pTemplate="body" let-product let-columns="columns" let-index="rowIndex">
                  <tr [pReorderableRow]="index" [ngStyle]="{'background-color': (product.disabled==true) ? '#fee' : '#ffffff'}">
                    <td>
                      <span class="pi pi-bars" pReorderableRowHandle></span>
                  </td>
                      <td>{{product.packagegroupname}}</td>
                
                      <td *ngIf="product.packagegroupname=='Other Packages'"><button pButton type="button" [disabled]="true" class="p-button-sm p-element p-ripple p-button-success p-button p-component" style="margin-left:-20px" (click)="editData(product.packagegroupid);">
                        <span class="pi pi-pencil" style="margin-right:8px;"></span>
                        <span i18n>Edit</span>
                    </button></td>
                    <td *ngIf="product.packagegroupname!='Other Packages'"><button pButton type="button" class="p-button-sm p-element p-ripple p-button-success p-button p-component" style="margin-left:-20px" (click)="editData(product.packagegroupid);">
                        <span class="pi pi-pencil" style="margin-right:8px;"></span>
                        <span i18n>Edit</span>
                    </button></td>
                    <td><button pButton type="button" class="p-button-sm p-element p-ripple p-button-danger p-button p-component" style="margin-left:-20px"  (click)="disableData(product.packagegroupid)">
                        <span class="pi pi-trash" style="margin-right:8px;"></span>
                        <span i18n>Disable</span>
                    </button></td>
                  </tr>
                </ng-template>

            </p-table>
    </div>
</div>


