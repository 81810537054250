<div *ngIf="!AccessAllowed && CompletedLoading">
    <h3 style="border: 2px solid;border-radius: 6px;position: absolute;top: 50%;left: 50%;transform: translate(-50%, -50%);padding: 10px;">You don't have access to this function</h3>
</div>

<div *ngIf="AccessAllowed && pageReady" class="p-3 flex flex-column flex-auto">
    <div>
        <div class="text-900 font-medium text-xl mb-3"><h3>Booking Question
        </h3></div>
        <p class="m-0 mb-4 p-0 text-600 line-height-3 mr-3">
            <span class="pi pi-info-circle" style="margin-right:2px;"></span>
            Create a questions to ask customers making bookings.
        </p>
        <div class="surface-card p-4 shadow-2 border-round">
            <div class="grid formgrid p-fluid">
                <div class="field mb-4 col-12">
                    <label for="questiontext" class="font-medium">Question Text</label>
                    <input [(ngModel)]="bookingQuestion.questiontext" id="questiontext" type="text" [ngStyle]="{'border-color': (questiontextNameValid==false) ? '#FF0000':''}" (keyup)="DoValidCheck()" pInputText>
                </div>
                <div *ngIf="!questiontextNameValid" class="text-danger" Style="margin-left:10px; margin-top: -23px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>You must enter some Question text.</div>

                <div class="field mb-4 col-12">
                    <label for="questiontext" class="font-medium">Question Type</label>
                    <p-dropdown [options]="QuestionTypes" [(ngModel)]="bookingQuestion.questiontype" optionLabel="name" optionValue="questiontype" placeholder="Select a Question Type" [showClear]="true" (onChange)="checkQuestionType()"></p-dropdown>
                </div>
                <div *ngIf="!questionTypeValid" class="text-danger" Style="margin-left:10px; margin-top: -23px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>You must choose a Question type.</div>

                <div *ngIf="bookingQuestion.questiontype=='BIRTHDAY'" class="clear" style="width:100%;">
                    <!-- <div class="field mb-4 col-12">
                        <label for="includeName" class="font-medium">Include Name</label>
                        <p-selectButton [(ngModel)]="bookingQuestion.showname" [style]="{'width': '150px'}" [options]="include" ></p-selectButton>
                    </div> -->
                    <div class="field mb-4 col-12">
                        <label for="includeage" class="font-medium">Include Age</label>
                        <p-selectButton [(ngModel)]="bookingQuestion.showage" [style]="{'width': '150px'}" [options]="include" ></p-selectButton>
                    </div>
                    <div class="field mb-4 col-12">
                        <label for="includedob" class="font-medium">Include Date of Birth</label>
                        <p-selectButton [(ngModel)]="bookingQuestion.showdob" [style]="{'width': '150px'}" [options]="include" ></p-selectButton>
                    </div>
                    <div class="field mb-4 col-12">
                        <label for="includeage" class="font-medium">Include Gender</label>
                        <p-selectButton [(ngModel)]="bookingQuestion.showgender" [style]="{'width': '150px'}" [options]="include" ></p-selectButton>
                    </div>
                    <div class="field mb-4 col-12">
                        <label for="includeage" class="font-medium">Include Interests</label>
                        <p-selectButton [(ngModel)]="bookingQuestion.showinterests" [style]="{'width': '150px'}" [options]="include" ></p-selectButton>
                    </div>
                    <!-- <div class="field mb-4 col-12">
                        <label for="Required" class="font-medium">Required</label>
                        <p-selectButton [(ngModel)]="bookingQuestion.mandatory" [style]="{'width': '150px'}" [options]="include" ></p-selectButton>
                    </div> -->
                </div>

                <!-- <div *ngIf="bookingQuestion.questiontype=='ALLERGY' || bookingQuestion.questiontype=='FREETEXT'" class="clear" style="width:100%;">
                    <div class="field mb-4 col-12">
                        <label for="Required" class="font-medium">Required</label>
                        <p-selectButton [(ngModel)]="bookingQuestion.mandatory" [style]="{'width': '150px'}" [options]="include" ></p-selectButton>
                    </div>
                </div> -->

                <div *ngIf="bookingQuestion.questiontype=='MULTICHOICESINGLE' || bookingQuestion.questiontype=='MULTICHOICEMULTIPLE'" class="clear" style="width:100%;">
                    <div>
                        <strong>Add Answers</strong>
                    </div>
                    <div>
                        <label for="Required" class="font-medium">Answer</label>
                        <input [(ngModel)]="AnswerText" id="answertext" type="text" [ngStyle]="{'border-color': (ansertextNameValid==false) ? '#FF0000':''}" (keyup)="DoAnswerValidCheck()" pInputText>
                    </div>
                    <div *ngIf="!answertextNameValid" class="text-danger" Style="margin-left:10px; width:100%"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>You must enter some Answer text.</div>
                    <div>
                        <button pButton pRipple type="button" class="p-button p-element p-button-success w-auto mt-3" (click)="addAnswer()">
                            <span class="pi pi-plus" style="margin-right:8px;"></span>
                            Add Answer</button>
                    </div>

                    <div class="surface-card p-4 shadow-2 border-round" style="margin-top:20px;margin-bottom:20px;">
                            
                    <p-table [value]="bookingQuestion.listitems">
                    <ng-template  pTemplate="header" let-columns>
                        <tr>
                            <th style="width:5%">&nbsp;</th>
                            <th style="width:85%">Answer</th>
                            <th style="width:10%">&nbsp;</th>
                        </tr>
                    </ng-template>
                        <ng-template pTemplate="body" let-data let-columns="columns" let-index="rowIndex">
                        <tr [pReorderableRow]="index">
                            <td>
                                <span class="pi pi-bars" pReorderableRowHandle></span>
                            </td>
                            <td>{{data.listitemtext}}</td>
                            <td><button pButton type="button" class="p-button-sm p-element p-ripple p-button-danger p-button p-component" style="margin-left:-20px; width:104px;"  (click)="disableData(data.id)">
                                <span class="pi pi-trash" style="margin-right:8px;"></span>
                                <span i18n>Delete</span>
                            </button></td>
                        </tr>
                        </ng-template>

                    </p-table>

                    </div>
                    
                </div>

                <div class="field mb-4 col-12">
                    <label for="Required" class="font-medium">Required</label>
                    <p-selectButton [(ngModel)]="bookingQuestion.mandatory" [style]="{'width': '150px'}" [options]="include" ></p-selectButton>
                </div>

                <div class="col-12">
                    <button pButton pRipple type="button" class="p-button p-element p-button-success w-auto mt-3" (click)="updateData();">
                    <span class="pi pi-pencil" style="margin-right:8px;"></span>
                    Save Changes</button>
                    &nbsp;
                    <button pButton pRipple type="button" class="p-button p-element p-button-danger w-auto mt-3" (click)="returnToList()">
                    <span class="pi pi-times" style="margin-right:8px;"></span>
                    Cancel</button>
                </div>
            </div>
        </div>
    </div>
</div>