import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { bmxurl, getAccess } from 'src/globals';

import { HttpClient } from '@angular/common/http';
import { MessageService } from 'primeng/api';
import { business } from 'src/types/business';
import { business_returndata } from 'src/types/business_returndata';
import { AppComponent } from '../app.component';
import { businessVenue } from 'src/types/businessVenues';
import { GlobalService } from '../_services/global.service';
import { MenuItem } from 'primeng/api/menuitem';
import { users_returndata } from 'src/types/user_returndata';

@Component({
  selector: 'app-business',
  templateUrl: './business.component.html',
  styleUrls: ['./business.component.css']
})
export class BusinessComponent implements OnInit {

  emailValid: boolean = true;
  businessNameValid: boolean = true;
  addressValid: boolean = true;
  countyValid: boolean = true;
  townValid: boolean = true;
  postcodeValid: boolean = true;
  phoneValid: boolean = true;
  BusinessID: string;
  Business: any;
  BusinessName: any = "TEST";
  PostCodeAPIResult: any;
  BusinessReturnData: any;
  BusinessVenues: businessVenue[];

  constructor(private http: HttpClient, 
    private router: Router, 
    private messageService: MessageService, 
    private globalService: GlobalService) { 

    }

  ngOnInit(): void {

    localStorage.removeItem("screenMemoryBookingss");
    localStorage.removeItem("screenMemoryEvents");
    
    //this.BusinessName = "TEST";
    //Get Business ID
    this.BusinessID = localStorage.getItem("SmartBusinessID")!;

    this.http.get(bmxurl + "business/" + this.BusinessID).subscribe(response => {
      this.Business = (response as business_returndata).data;
      
      this.globalService.setVenueName(this.Business.name);

      //this.BusinessVenues = (JSON.parse(localStorage.getItem("SmartBusinessVenues")) as businessVenue[]);
    }, error => {
    });

    this.BusinessVenues = (JSON.parse(localStorage.getItem("SmartBusinessVenues")) as businessVenue[]);

    let menuItems: MenuItem[];

    menuItems = [{
      label: 'BUSINESS',
      items: [
        {label: 'Business', icon: 'pi pi-globe', routerLink: ['/business'], visible: true},
    ]
    }];

    this.globalService.setMenuItems(menuItems);
  }

  getLatLong(postcode){
    this.http.get("https://findthatpostcode.uk/postcodes/" + postcode + ".json").subscribe(response => {
      this.PostCodeAPIResult = response;
      this.Business.lat = this.PostCodeAPIResult.data.attributes.lat;
      this.Business.lon = this.PostCodeAPIResult.data.attributes.long;
    });
  }

  DoValidCheck()
  {
    let AllValid = true;

    if(this.Business.name == null || this.Business.name == "")
    {
      AllValid = false;
      this.businessNameValid = false;
    }
    else
    {
      this.businessNameValid = true;
    }

    if(this.Business.address1 == null || this.Business.address1 == "")
    {
      AllValid = false;
      this.addressValid = false;
    }
    else
    {
      this.addressValid = true;
    }

    if(this.Business.town == null || this.Business.town == "")
    {
      AllValid = false;
      this.townValid = false;
    }
    else
    {
      this.townValid = true;
    }

    if(this.Business.county == null || this.Business.county == "")
    {
      AllValid = false;
      this.countyValid = false;
    }
    else
    {
      this.countyValid = true;
    }

    if(this.Business.postcode == null || this.Business.postcode == "")
    {
      AllValid = false;
      this.postcodeValid = false;
    }
    else
    {
      this.postcodeValid = true;
    }

    if(this.Business.email == null || this.Business.email == "")
    {
      AllValid = false;
      this.emailValid = false;
    }
    else
    {
      this.emailValid = true;
    }

    if(this.Business.telephone == null || this.Business.telephone == "")
    {
      AllValid = false;
      this.phoneValid = false;
    }
    else
    {
      this.phoneValid = true;
    }
   
    return AllValid;
  }

  updateData()
  {
  }

  startDay(){
  }

  enterVenue(Venue)
  {
    localStorage.setItem("SmartVenueID", Venue.venueid);
    localStorage.setItem("SmartVenueName", Venue.name);
    this.router.navigateByUrl("/home");

    this.globalService.setVenueName(Venue.name);

  }
}
