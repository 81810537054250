
<p-dialog header="Temporary PIN Number" [(visible)]="displayTempPIN" [style]="{width: '50vw'}" [baseZIndex]="10000"  [modal]="true">
    <p style="font-size: 17px;width: 100%;text-align: center;">please give this to the user, they will be required to change it when they first log in.</p>
    <p style="font-size: 90px;width: 100%;text-align: center;">{{DecodedPIN}}</p>
  <ng-template pTemplate="footer">
      <p-button icon="pi pi-check" (click)="returnToUsers()" label="OK" styleClass="p-button-text"></p-button>
  </ng-template>
</p-dialog>

<div class="p-3 flex flex-column flex-auto">
    <div>
        <div class="text-900 font-medium text-xl mb-3"><h3>Create New Customer
        </h3></div>
        <div class="surface-card p-4 shadow-2 border-round">

            <p-tabView style="width:100%">
                <p-tabPanel header="Business Details" leftIcon="pi pi-home">
                    <div class="grid formgrid p-fluid">
                        <div class="field mb-4 col-12">
                            <label for="classname" class="font-medium">Business Name</label>
                            <input [(ngModel)]="business.Name" id="classname" type="text" [ngStyle]="{'border-color': (businessnamevalid==false) ? '#FF0000':''}" pInputText>
                        </div>
                        <div *ngIf="!businessnamevalid" class="text-danger" Style="margin-left:10px; margin-top: -23px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>You must enter a Business name.</div>
        
                        <div class="field mb-4 col-12">
                            <label for="classname" class="font-medium">Address 1</label>
                            <input [(ngModel)]="business.Address1" id="classname" type="text" [ngStyle]="{'border-color': (businessnamevalid==false) ? '#FF0000':''}" pInputText>
                        </div>
                        <div *ngIf="!businessAddress1valid" class="text-danger" Style="margin-left:10px; margin-top: -23px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>You must enter a Business name.</div>
                        <div class="field mb-4 col-12">
                            <label for="classname" class="font-medium">Address 2</label>
                            <input [(ngModel)]="business.Address2" id="classname" type="text" [ngStyle]="{'border-color': (businessnamevalid==false) ? '#FF0000':''}" pInputText>
                        </div>
                        <div class="field mb-4 col-12">
                            <label for="classname" class="font-medium">Town</label>
                            <input [(ngModel)]="business.Town" id="classname" type="text" [ngStyle]="{'border-color': (businessnamevalid==false) ? '#FF0000':''}" pInputText>
                        </div>
                        <div *ngIf="!businessTownvalid" class="text-danger" Style="margin-left:10px; margin-top: -23px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>You must enter a Business name.</div>
                        <div class="field mb-4 col-12">
                            <label for="classname" class="font-medium">County</label>
                            <input [(ngModel)]="business.County" id="classname" type="text" [ngStyle]="{'border-color': (businessnamevalid==false) ? '#FF0000':''}" pInputText>
                        </div>
                        <div *ngIf="!businessCountyvalid" class="text-danger" Style="margin-left:10px; margin-top: -23px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>You must enter a Business name.</div>
                        <div class="field mb-4 col-12">
                            <label for="classname" class="font-medium">Postcode</label>
                            <input [(ngModel)]="business.Postcode" id="classname" type="text" [ngStyle]="{'border-color': (businessnamevalid==false) ? '#FF0000':''}" pInputText>
                        </div>
                        <div *ngIf="!businessPostcodevalid" class="text-danger" Style="margin-left:10px; margin-top: -23px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>You must enter a Business name.</div>
                        <div class="field mb-4 col-12">
                            <label for="classname" class="font-medium">Telephone</label>
                            <input [(ngModel)]="business.Telephone" id="classname" type="text" [ngStyle]="{'border-color': (businessnamevalid==false) ? '#FF0000':''}" pInputText>
                        </div>
                        <div *ngIf="!businessTelephonevalid" class="text-danger" Style="margin-left:10px; margin-top: -23px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>You must enter a Business name.</div>
                        <div class="field mb-4 col-12">
                            <label for="classname" class="font-medium">Email</label>
                            <input [(ngModel)]="business.Email" id="classname" type="text" [ngStyle]="{'border-color': (businessnamevalid==false) ? '#FF0000':''}" pInputText>
                        </div>
                        <div *ngIf="!businessEmailvalid" class="text-danger" Style="margin-left:10px; margin-top: -23px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>You must enter a Business name.</div>
                    </div>
                </p-tabPanel>
                <p-tabPanel header="Venue Details" leftIcon="pi pi-building">
                    <div class="grid formgrid p-fluid">
                        <div class="field mb-4 col-12">
                            <label for="classname" class="font-medium">Venue Name</label>
                            <input [(ngModel)]="venue.Name" id="classname" type="text" [ngStyle]="{'border-color': (businessnamevalid==false) ? '#FF0000':''}" pInputText>
                        </div>
                        <div *ngIf="!venuenamevalid" class="text-danger" Style="margin-left:10px; margin-top: -23px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>You must enter a Venue name.</div>
        
                        <div class="field mb-4 col-12">
                            <label for="classname" class="font-medium">Address 1</label>
                            <input [(ngModel)]="venue.Address1" id="classname" type="text" [ngStyle]="{'border-color': (businessnamevalid==false) ? '#FF0000':''}" pInputText>
                        </div>
                        <div class="field mb-4 col-12">
                            <label for="classname" class="font-medium">Address 2</label>
                            <input [(ngModel)]="venue.Address2" id="classname" type="text" [ngStyle]="{'border-color': (businessnamevalid==false) ? '#FF0000':''}" pInputText>
                        </div>
                        <div class="field mb-4 col-12">
                            <label for="classname" class="font-medium">Town</label>
                            <input [(ngModel)]="venue.Town" id="classname" type="text" [ngStyle]="{'border-color': (businessnamevalid==false) ? '#FF0000':''}" pInputText>
                        </div>
                        <div class="field mb-4 col-12">
                            <label for="classname" class="font-medium">County</label>
                            <input [(ngModel)]="venue.County" id="classname" type="text" [ngStyle]="{'border-color': (businessnamevalid==false) ? '#FF0000':''}" pInputText>
                        </div>
                        <div class="field mb-4 col-12">
                            <label for="classname" class="font-medium">Postcode</label>
                            <input [(ngModel)]="venue.Postcode" id="classname" type="text" [ngStyle]="{'border-color': (businessnamevalid==false) ? '#FF0000':''}" pInputText>
                        </div>
                        <div class="field mb-4 col-12">
                            <label for="classname" class="font-medium">Telephone</label>
                            <input [(ngModel)]="venue.Telephone" id="classname" type="text" [ngStyle]="{'border-color': (businessnamevalid==false) ? '#FF0000':''}" pInputText>
                        </div>
                        <div class="field mb-4 col-12">
                            <label for="classname" class="font-medium">Email</label>
                            <input [(ngModel)]="venue.Email" id="classname" type="text" [ngStyle]="{'border-color': (businessnamevalid==false) ? '#FF0000':''}" pInputText>
                        </div>
                        <div class="field mb-4 col-12">
                            <label for="classname" class="font-medium">Prefix</label>
                            <input [(ngModel)]="venue.prefix" id="classname" type="text" [ngStyle]="{'border-color': (businessnamevalid==false) ? '#FF0000':''}" pInputText>
                        </div>
                        <div *ngIf="!prefixnamevalid" class="text-danger" Style="margin-left:10px; margin-top: -23px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>You must enter a Prefix.</div>
                    
                        <div class="field mb-4 col-12">

                            <label for="packageobjFile" class="font-medium">Upload a header image for Booking Wizard. The image must be smaller than 100KB</label>
                            <div style="clear:both;width:100%">&nbsp;</div>
                            <p-fileUpload style="float:left;margin-right:10px;" mode="basic" [auto]="true" name="image" url="{{HTMLbmxurl}}/imageupload/{{VenueID}}/{{NewVenueID}}" accept="image/*" maxFileSize="100000" (onUpload)="uploadedFile($event)" (onError)="onErrorFileUpload($event)" chooseLabel="Upload File"></p-fileUpload>
                            
                            <button *ngIf="FileChosen != null && FileChosen != ''" pButton pRipple type="button" style="float: left;margin-bottom: 10px;margin-top: 1px !important;height: 50px;" class="p-button-sm p-element p-button-danger w-auto mt-3" (click)="removeImage()">
                              <span style="margin-right:10px;" class="pi pi-times"></span>
                              Remove Image
                            </button>
              
                            <div style="clear:both;width:100%">&nbsp;</div>
              
                            <div *ngIf="FileChosen != null && FileChosen != ''">
                              <div id="image">
                                  <img src="{{ FileChosen + '?n=' + genRandomNum()}}" style="width: 150px;margin-top: -23px;margin-left: -23px;margin-right: -23px;">
                              </div>
                            </div>
                          </div>
                    
                    </div>
                </p-tabPanel>
                <p-tabPanel header="User Details" leftIcon="pi pi-user">
                    <div class="grid formgrid p-fluid">

                        <div class="field mb-4 col-12">
                            <label for="includeParticipants" class="font-medium">Create Business Owner?</label>
                            <p-selectButton [(ngModel)] = "createbusinessowner" [style]="{'width': '150px'}" [options]="yesno" ></p-selectButton>
                          </div>

                        <div *ngIf="createbusinessowner">
                            <h5>Business User:</h5>
                        
                            <div class="field mb-4 col-12">
                                <label for="classname" class="font-medium">Auth0 User Name</label>
                                <input [(ngModel)]="buUserName" id="classname" type="text" [ngStyle]="{'border-color': (businessnamevalid==false) ? '#FF0000':''}" pInputText>
                            </div>

                            <div class="field mb-4 col-12">
                                <label for="classname" class="font-medium">Auth0 Password</label>
                                <input [(ngModel)]="buPassword" id="classname" type="text" [ngStyle]="{'border-color': (businessnamevalid==false) ? '#FF0000':''}" pInputText>
                            </div>

                            <div class="field mb-4 col-12">
                                <label for="classname" class="font-medium">Login Name</label>
                                <input [(ngModel)]="buLoginName" id="classname" type="text" [ngStyle]="{'border-color': (businessnamevalid==false) ? '#FF0000':''}" pInputText>
                            </div>
                        </div>

                        <div style="clear:both; width:100%"></div>
                        <h5>Master Key:</h5>

                        <div class="field mb-4 col-12">
                            <label for="classname" class="font-medium">Auth0 User Name</label>
                            <input [(ngModel)]="mkUserName" id="classname" type="text" [ngStyle]="{'border-color': (businessnamevalid==false) ? '#FF0000':''}" pInputText>
                        </div>

                        <div class="field mb-4 col-12">
                            <label for="classname" class="font-medium">Auth0 Password</label>
                            <input [(ngModel)]="mkPassword" id="classname" type="text" [ngStyle]="{'border-color': (businessnamevalid==false) ? '#FF0000':''}" pInputText>
                        </div>

                        <div class="field mb-4 col-12">
                            <label for="classname" class="font-medium">Login Name</label>
                            <input [(ngModel)]="mkLoginName" id="classname" type="text" [ngStyle]="{'border-color': (businessnamevalid==false) ? '#FF0000':''}" pInputText>
                        </div>
                    </div>
                    <div class="col-12">
                        <button pButton pRipple type="button" class="p-button p-element p-button-success w-auto mt-3" (click)="updateData();">
                        <span class="pi pi-pencil" style="margin-right:8px;"></span>
                        Save Changes</button>
                        &nbsp;
                        <button pButton pRipple type="button" class="p-button p-element p-button-danger w-auto mt-3">
                        <span class="pi pi-times" style="margin-right:8px;"></span>
                        Cancel</button>
                    </div>
                </p-tabPanel>
            </p-tabView>
        </div>
    </div>
</div>