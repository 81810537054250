
<div  class="p-3 flex flex-column flex-auto">
    <div class="text-900 font-medium text-xl mb-3"><h3>Revenue - May 2024
    </h3></div>
    <p class="m-0 mb-4 p-0 text-600 line-height-3 mr-3">
        <span class="pi pi-info-circle" style="margin-right:2px;"></span>
        [NO TEXT YET]
    </p>
    <div class="surface-card p-4 shadow-2 border-round">
        <div style="width:100% !important; height:50px !important;float:left;">
            <p-chart type="bar" [data]="chartData" [options]="basicOptions" width="100%" height="200px;"></p-chart>
        </div>
        <div style="clear:both;">&nbsp;</div>

        <div style="margin-top: 180px;">
            <p-table  #classTable 
            [value]="pTableData" sortField="datetimesent" [paginator]="true" [rows]="10" 
            [showCurrentPageReport]="true" responsiveLayout="scroll"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" 
            [rowsPerPageOptions]="[10,25,50]" 
            responsiveLayout="scroll"
            datakey="datetimesent"
            [selectionPageOnly]="true">
                <ng-template pTemplate="header">
                    <tr>
                        <th width="5%"></th>
                        <th pSortableColumn="datetimesent" width="70%">Date
                            <p-sortIcon [field]="datetimesent"></p-sortIcon>
                        </th>
                        <th pSortableColumn="value" width="25%">Value
                            <p-sortIcon [field]="value"></p-sortIcon>
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-data>
                    <tr>
                        <td><button pButton type="button" class="p-button-sm p-element p-ripple p-button-success p-button p-component" style="width: 52px;" (click)="viewData()">
                            <span class="pi pi-eye"></span>
                        </button></td>
                        <td>{{data.datetimesent | date:'dd/MM/yyyy'}}</td>
                        <td>{{data.value | currency: CurrencySymbol}}</td>
                    </tr>
                    <tr *ngIf="data.value == 12 && viewInnerData">
                        <td colspan="3">
                        <p-table
                        [value]="pTableInnerData">
                            <ng-template pTemplate="header">
                                <tr>
                                    <th>Booking ID</th>
                                    <th>Campaign</th>
                                    <th>Value</th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-innerdata>
                            <tr>
                                <td>{{innerdata.bookingid}}</td>
                                <td><a href="#">{{innerdata.campaign}}</a></td>
                                <td>{{innerdata.value}}</td>
                            </tr>
                            </ng-template>
                        </p-table>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
</div>
