
<div class="content" role="main">
    <div class="container">

      <div class="makeABookingText">
        <div class="title">Please make a booking</div>
        <div class="diagnostics">
          <p-toggleButton [(ngModel)]="DiagnosticMode" onLabel="Diagnostics On" offLabel="Diagnostics Off" offIcon="pi pi-times" onIcon="pi pi-check" (click)="getAvailability()">
          </p-toggleButton>
        </div>
      </div>
  
      <div class="clr"></div>
  
      <div class="packageSelection">
        <div class="PackageSelectLabel">
          Select Package:
        </div>
        <div class="packageSelect" >
          <select class="dropDownList" name="ddlPackage" id="ddlPackage" (change)="onPackageSelect($event.target.value)">
            <option value="0">Select a package</option>
            <option *ngFor="let c of packages" value="{{c.packageId}}">{{c.name}}</option>
          </select>
        </div>
      </div>
  
      <div class="clr"></div>
  
      <div class="packageSelection">
        <div class="PackageSelectLabel">
          Select Date:
        </div>
        <div class="packageSelect" >
          <input class="dropDownList" id="txtDate" type="date" (click)="dateChange()" />
        </div>
      </div>
  
      <div class="clr"></div>
  
      <div class="packageSelection clr" *ngFor="let a of (PackageAdmissions)">
        <div class="PackageSelectLabel">
          Number of {{a.name}}:
        </div>
        <div class="packageSelect" >
          <p-inputNumber [(ngModel)]="SelectedAdmissions[a.index].noOfPeople" [id]="'ad'+a.name" [showButtons]="true" max="{{a.max}}" min="{{a.min}}" buttonLayout="horizontal" spinnerMode="horizontal" (onInput)="numberChange()"></p-inputNumber>
        </div>
        
      </div>
  
      <div class="clr"></div>
  
     
      <div class="packageSelection">
        <div class="PackageSelectLabel">
  
        </div>
      <div class="packageSelect" >
        <input class="gaButton" id="btnGetAvailability" type="button" value="Get Availability" (click)="getAvailability()" />
      </div>
  
      <div class="clr"></div>
      <br>
  
      <table *ngIf="DiagnosticMode" class="table table-striped tableWidth">
        <tr *ngFor="let st of (startTimes)">
  
          <td *ngIf="!st.timeAvailable" style='background-color: pink;' class="listTime">{{ st.time | date:'HH:mm' }}</td>
          <td *ngIf="st.timeAvailable" class="listTime">{{ st.time | date:'HH:mm' }}</td>
  
          <td *ngIf="st.timeAvailable" class="listQuantity">{{ st.quantityRemaining }}</td>
          <td *ngIf="!st.timeAvailable" style='background-color: pink;' class="listQuantity">{{ st.quantityRemaining }}</td>
  
          <td *ngIf="st.timeAvailable" class="listReason">{{ st.reason }}</td>
          <td *ngIf="!st.timeAvailable" style='background-color: pink;' class="listReason">{{ st.reason }}</td>
  
          <td *ngIf="st.timeAvailable" class="listBook">Resource: {{st.resourceName}}</td>
          <td *ngIf="!st.timeAvailable" style='background-color: pink;' class="listBook">Resource: {{st.resourceName}}</td>
  
          <td *ngIf="st.timeAvailable" class="listBook">Resource length: {{st.resourceLength}}</td>
          <td *ngIf="!st.timeAvailable" style='background-color: pink;' class="listBook">Resource length: {{st.resourceLength}}</td>
        </tr>
      </table>
  
      <div *ngIf="!DiagnosticMode" class="timeContainer">
        <div *ngFor="let st of (startTimesFiltered)" class="times">
          <input type=button class='bookButtonND' value="{{ st.time | date:'HH:mm' }}" id='btnbook' (click)="bookSlot(st.time)">
        </div>
      </div>
    </div>
  </div>
  
  <router-outlet></router-outlet>
  