<div class="p-3 flex flex-column flex-auto">
    <h3 style="width:100%; float:left;margin-bottom:15px;">Admissions for Booking ID: {{screenBookingId}}</h3>

    <div style="margin-bottom: 10px;">
        <button [disabled]="admitdisabled" pButton type="button" class="p-button-sm p-element p-ripple p-button-success p-button p-component" style="width:133px; margin-right:10px;" (click)="admitall(bookingId)">
            <span class="pi pi-sign-in" style="margin-right:8px;"></span>
            <span>Admit All</span>
        </button>

        <button [disabled]="leavedisabled" pButton type="button" class="p-button-sm p-element p-ripple p-button-danger p-button p-component" style="width:133px;" (click)="leaveall(bookingId)">
            <span class="pi pi-sign-out" style="margin-right:8px;"></span>
            <span>Leave All</span>
        </button>
    </div>

    <div class="surface-card p-4 shadow-2 border-round">
    <p-table  #resourceTable 
    [value]="ListAdmissions" sortField="startTime" [paginator]="true" [rows]="10" 
    [showCurrentPageReport]="true" responsiveLayout="scroll"
    currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" 
    [rowsPerPageOptions]="[10,25,50]" 
    [globalFilterFields]="['name', 'bookingId', 'bookingIdHR']"
    [(selection)]="selectedItems"
    responsiveLayout="scroll"
    [selectionPageOnly]="true">
        <ng-template pTemplate="header">
            <tr>
                <th width="95%">Name</th>
                <th width="5%"></th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-data>
            <tr *ngIf="!data.inProgress && !inProgress" onmouseover="this.originalstyle=this.style.backgroundColor;this.style.backgroundColor='#dddddd'" onmouseout="this.style.backgroundColor=this.originalstyle;" [ngStyle]="{'background-color': (data.disabled==true) ? '#fee' : '#ffffff'}">
                <td>{{data.name}}</td>

                <td *ngIf="!data.admitted"><button pButton type="button" class="p-button-sm p-element p-ripple p-button-success p-button p-component" style="width:133px;" (click)="admitleave(data.id)">
                    <span class="pi pi-sign-in" style="margin-right:8px;"></span>
                    <span>Admit</span>
                </button></td>

                <td *ngIf="data.admitted"><button pButton type="button" class="p-button-sm p-element p-ripple p-button-danger p-button p-component" style="width:133px;" (click)="admitleave(data.id)">
                    <span class="pi pi-sign-out" style="margin-right:8px;"></span>
                    <span>Leave</span>
                </button></td>
            </tr>
        </ng-template>
    </p-table>
    </div>
</div>
