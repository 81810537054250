<div *ngIf="!AccessAllowed && CompletedLoading">
    <h3 style="border: 2px solid;border-radius: 6px;position: absolute;top: 50%;left: 50%;transform: translate(-50%, -50%);padding: 10px;">You don't have access to this function</h3>
</div>

<div class="p-3 flex flex-column flex-auto">
    <div class="text-900 font-medium text-xl mb-3"><h3>Marketing</h3></div>
    <p class="m-0 mb-4 p-0 text-600 line-height-3 mr-3">
        <span class="pi pi-info-circle" style="margin-right:2px;"></span>
        Allows you to manage marketing tasks.
    </p>

    <div style="width:100%;margin-bottom:20px; margin-bottom:20px;">

        <div style="width: 49%;float:left;">
            <div class="surface-card p-4 shadow-2 border-round" style="height:225px;padding-top: 8px !important;">
                <div style="margin-bottom: 20px;font-weight: bold;font-size: 20px;"><span style="font-size: 20px;margin-right:10px;" class="pi pi-envelope"></span>Email Credits Remaining</div>

                <div style="width:150px !important; height:50px !important;float:left;">
                    <p-chart type="pie" [data]="chartData2" [options]="basicOptions"></p-chart>
                </div>

                <div style="width:50%;float:left;font-size: 36px; color: #64748b;margin-left: 20px;">
                    <div style="float:left; font-size: 21px !important; width: 170px;">
                    Emails remaining:
                    </div>
                    <div style="float:left; font-size: 21px !important; width: 20px;">
                        257
                    </div>
                    <div style="width:100%; float:left;"></div>
                    <div style="float:left; font-size: 21px !important; width: 170px;">
                    Average usage:
                    </div>
                    <div style="float:left; font-size: 21px !important; width: 20px;">
                        2042
                    </div>
                    <div style="width:100%; float:left;margin-top:20px;"></div>
                    <div style="float: left;font-size: 16px !important;">
                        <span style="font-style: italic; color: #E10000;">At your current rate of usage, we estimate that you will run out of credits in 3 days.</span>
                    </div>
                </div>

                <div style="float: right;height: 80px;">
                    <button pButton type="button" value="View" class="p-button" style="margin-top:100px;width: 100%;float: right;height: 50px;width: 145px;margin-right: -8px;padding-left: 12px;padding-right: 12px;" (click)="buyMore()">
                       Buy More Now
                    </button>
                </div>
                <div style="width:100%; clear: both;"></div>

            </div>
        </div>

        <div style="width: 50%; margin-left:1%; float:left; margin-bottom:20px;">
            <div class="surface-card p-4 shadow-2 border-round" style="height:225px;padding-top: 8px !important;">
            <div style="margin-bottom: 20px;font-weight: bold;font-size: 20px;"><span style="font-size: 20px;margin-right:10px;" class="pi pi-mobile"></span>SMS Credits Remaining</div>

            <div style="width:150px !important; height:50px !important;float:left;">
                <p-chart type="pie" [data]="chartData3" [options]="basicOptions"></p-chart>
            </div>

            <div style="width:50%;float:left;font-size: 36px; color: #64748b;margin-left: 20px;">
                <div style="float:left; font-size: 21px !important; width: 170px;">
                SMS remaining:
                </div>
                <div style="float:left; font-size: 21px !important; width: 20px;">
                    126
                </div>
                <div style="width:100%; float:left;"></div>
                <div style="float:left; font-size: 21px !important; width: 170px;">
                Average usage:
                </div>
                <div style="float:left; font-size: 21px !important; width: 20px;">
                    1052
                </div>
                <div style="width:100%; float:left;margin-top:20px;"></div>
                <div style="float: left;font-size: 16px !important;">
                    <span style="font-style: italic; color: #E10000;">At your current rate of usage, we estimate that you will run out of credits in 1 day.</span>
                </div>
            </div>

            <div style="float: right;height: 80px;">
                <button pButton type="button" value="View" class="p-button" style="margin-top:100px;width: 100%;float: right;height: 50px;width: 145px;margin-right: -8px;padding-left: 12px;padding-right: 12px;" (click)="buyMore()">
                   Buy More Now
                </button>
            </div>
            <div style="width:100%; clear: both;"></div>
            </div>
        </div>

        <div style="width:100%;clear:both;">

        <div style="width: 33%;float:left;">
            <div class="surface-card p-4 shadow-2 border-round" style="height:225px;padding-top: 8px !important;">
                <div style="margin-bottom: 20px;font-weight: bold;font-size: 20px;">Marketing Statistics</div>
                <div style="width:150px !important; height:50px !important;float:left;">
                    <p-chart type="pie" [data]="chartData" [options]="basicOptions"></p-chart>
                </div>
                <div style="float: left;width: 200px;margin-left: 172px;margin-top: -29px;color: #64748b;">
                    <div style="width:100%; float:left;">
                        <div style="float:left; font-size:18px; width:116px;">Emails Sent:</div>
                        <div style="float:left; font-size:18px;">2045</div>
                    </div>
                    
                    <div style="width:100%; float:left;">
                        <div style="float:left; font-size:18px; width:116px;">Emails Read:</div>
                        <div style="float:left; font-size:18px;">746</div>
                    </div>

                    <div style="width:100%; float:left;">
                        <div style="float:left; font-size:18px; width:116px;">Links Clicked:</div>
                        <div style="float:left; font-size:18px;">240</div>
                    </div>
                </div>
            </div>
            <button pButton type="button" value="View" class="p-button-outlined" style="width: 100%;margin-top: -46px;float: right;height: 40px;width: 115px;margin-right: 6px;padding-left: 12px;padding-right: 12px;" (click)="viewMoreEmails()">
                more info...
            </button>
        </div>

        <div style="width: 32%; margin-right:1%; margin-left:1%; float:left;">
            <div class="surface-card p-4 shadow-2 border-round" style="height:225px;padding-top: 8px !important;">
                <div style="margin-bottom: 20px;font-weight: bold;font-size: 20px;">Revenue Due to Marketing</div>
                <div style="float:left;width:70%; font-size: 18px;; color: #64748b;margin-top: 20px;">
                    Revenue this week:
                </div>
                <div style="float:left;width:29%; font-size: 18px; text-align: right; color: #64748b;margin-top: 20px;">
                    £902.60
                </div>
                <div style="float:left;width:70%; font-size: 18px;; color: #64748b;">
                    Revenue this month:
                </div>
                <div style="float:left;width:29%; font-size: 18px; text-align: right; color: #64748b;">
                    £3023.44
                </div>
                <div style="float:left;width:70%; font-size: 18px;; color: #64748b;">
                    Revenue this year:
                </div>
                <div style="float:left;width:29%; font-size: 18px; text-align: right; color: #64748b;">
                    £12042.98
                </div>
                <button pButton type="button" value="View" class="p-button-outlined" style="width: 100%;margin-top: 19px;float: right;height: 40px;width: 115px;margin-right: -18px;padding-left: 12px;padding-right: 12px;" (click)="viewMoreRevenue()">
                    more info...
                </button>
            </div>
        </div>

        <div style="width: 33%;float:left;">
            <div class="surface-card p-4 shadow-2 border-round" style="height:225px;padding-top: 8px !important;">
                <div style="margin-bottom: 20px;font-weight: bold;font-size: 20px;">Market Engagement</div>
                <div style="font-size: 108px; text-align: center; margin-top: -32px; color: #64748b;">
                    38%
                </div>
                <!-- <button pButton type="button" value="View" class="p-button-outlined" style="width: 100%;margin-top: -10px;float: right;height: 40px;width: 115px;margin-right: -18px;padding-left: 12px;padding-right: 12px;" (click)="viewMoreEngagement()">
                    more info...
                </button> -->
            </div>
        </div>
    </div>
    <div class="surface-card p-4 shadow-2 border-round">
    <div style="margin-top:20px;width:100%;float:left;">
        Pleaase select a marketing task to proceed.
    </div>
    <br>
    <div style="float: left;width: 100%;margin-top: 20px;">
        <button pButton type="button" value="Birthday" class="p-button-outlined" style="width:200px;margin-left:5px;margin-right:5px;height:135px;" (click)="doBirthday()" (mouseover)="setText('Birthday')" (mouseout)="setText('')">
            <div style="width:100%; margin: auto;">
                <img src="assets/images/birthday.png" style="width: 50px;">
                <div style="width:100%;margin-top:10px;">Birthday</div>
            </div>
        </button>
        <button pButton type="button" value="Missing You" class="p-button-outlined" style="width:200px;margin-left:5px;margin-right:5px;height:135px;" (click)="doMissing()" (mouseover)="setText('Missing')" (mouseout)="setText('')">
            <div style="width:100%; margin: auto;">
                <img src="assets/images/missing.png" style="width: 50px;">
                <div style="width:100%;margin-top:10px;">Missing You</div>
            </div>
        </button>
        <button pButton type="button" value="Group Email" class="p-button-outlined" style="width:200px;margin-left:5px;margin-right:5px;height:135px;" (click)="doGroup()" (mouseover)="setText('Customers')" (mouseout)="setText('')">
            <div style="width:100%; margin: auto;">
                <img src="assets/images/Multiemail.png" style="width: 50px;">
                <div style="width:100%;margin-top:10px;">Send Emails to a group of customers</div>
            </div>
        </button>
        <button pButton type="button" value="Abandoned Bookings" class="p-button-outlined" style="width:200px;margin-left:5px;margin-right:5px;height:135px;" (click)="doAbandoned()" (mouseover)="setText('Abandoned')" (mouseout)="setText('')">
            <div style="width:100%; margin: auto;">
                <img src="assets/images/Abandoned.png" style="width: 50px;">
                <div style="width:100%;margin-top:10px;">Abandoned Bookings</div>
            </div>
        </button>
    </div>
    <hr>
    <div [innerHTML]="buttonDesc">

    </div>
    <div style="width:100%;clear:both;"></div>
  </div>
</div>
