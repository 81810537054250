<div *ngIf="!AccessAllowed && CompletedLoading">
    <h3 style="border: 2px solid;border-radius: 6px;position: absolute;top: 50%;left: 50%;transform: translate(-50%, -50%);padding: 10px;">You don't have access to this function</h3>
</div>

<div *ngIf="AccessAllowed && pageReady" class="p-3 flex flex-column flex-auto">
    <div>
        <div class="text-900 font-medium text-xl mb-3"><h3>Buy Credits
        </h3></div>
        <div class="surface-card p-4 shadow-2 border-round">
            <div class="grid formgrid p-fluid">
                <p-tabView style="width:100%">
                    <p-tabPanel header="Emails" leftIcon="pi pi-envelope">
                        <div>
                            <div style="float:left;width: 100%;margin-top: 20px">
                                <div style="border: 2px solid red; border-radius: 5px; background-color: rgb(255, 238, 238);padding: 10px;">
                                    <p><b>Recommened for your level of usage</b></p>
                                    <div>
                                        <div style="width:226px;float:left;">
                                            <button pButton type="button" value="Birthday" class="p-button-outlined" style="width:200px;margin-left:5px;margin-right:5px;height:135px;" (click)="doBirthday()" (mouseover)="setText('Birthday')" (mouseout)="setText('')">
                                                <div style="width:100%; margin: auto;">
                                                    <img src="assets/images/Email3.png" style="width: 130px;">
                                                    <div style="width:100%;margin-top:10px;">25,000 Emails</div>
                                                </div>
                                            </button>
                                        </div>
                                        <div style="width:70%;float:left;">
                                            <p><b>Our heavy use package</b></p>
                                            <p>Choose this option if you expect to send a large amount of task or marketing emails. The system will stop sending emails if you run out of credits, please ensure you have enough to complete any operation that you start.</p>
                                            <div style="font-size:20px; font-weight:bold;">£15.00</div>
                                        </div>
                                    </div>
                                    <div style="clear:both;"></div>
                                </div>  
                                <div style="clear:both;">
                                    &nbsp;
                                </div>

                                <div style="padding: 10px;">
                                    <!-- <p><b>Recommened for your level of usage</b></p> -->
                                    <div>
                                        <div style="width:226px;float:left;">
                                            <button pButton type="button" value="Birthday" class="p-button-outlined" style="width:200px;margin-left:5px;margin-right:5px;height:135px;" (click)="doBirthday()" (mouseover)="setText('Birthday')" (mouseout)="setText('')">
                                                <div style="width:100%; margin: auto;">
                                                    <img src="assets/images/Email2.png" style="width: 130px;">
                                                    <div style="width:100%;margin-top:10px;">10,000 Emails</div>
                                                </div>
                                            </button>
                                        </div>
                                        <div style="width:70%;float:left;">
                                            <p><b>Our medium use package</b></p>
                                            <p>Choose this option if you expect to send a reasonable amount of task or marketing emails. The system will stop sending emails if you run out of credits, please ensure you have enough to complete any operation that you start.</p>
                                            <div style="font-size:20px; font-weight:bold;">£7.00</div>
                                        </div>
                                    </div>
                                    <div style="clear:both;"></div>
                                </div>  
                                <div style="clear:both;">
                                    &nbsp;
                                </div>

                                <div style="padding: 10px;">
                                    <!-- <p><b>Recommened for your level of usage</b></p> -->
                                    <div>
                                        <div style="width:226px;float:left;">
                                            <button pButton type="button" value="Birthday" class="p-button-outlined" style="width:200px;margin-left:5px;margin-right:5px;height:135px;" (click)="doBirthday()" (mouseover)="setText('Birthday')" (mouseout)="setText('')">
                                                <div style="width:100%; margin: auto;">
                                                    <img src="assets/images/Email1.png" style="width: 130px;">
                                                    <div style="width:100%;margin-top:10px;">5,000 Emails</div>
                                                </div>
                                            </button>
                                        </div>
                                        <div style="width:70%;float:left;">
                                            <p><b>Our light use package</b></p>
                                            <p>Choose this option if you expect to only send a few task or marketing emails. The system will stop sending emails if you run out of credits, please ensure you have enough to complete any operation that you start.</p>
                                            <div style="font-size:20px; font-weight:bold;">£5.00</div>
                                        </div>
                                    </div>
                                    <div style="clear:both;"></div>
                                </div>  
                                <div style="clear:both;">
                                    &nbsp;
                                </div>
 
                            </div>
                        </div>
                    </p-tabPanel>
                    <p-tabPanel header="SMS" leftIcon="pi pi-mobile">
                        <div>
                            <div style="float:left;width: 100%;margin-top: 20px">
                                <div style="padding: 10px;">
                                    <!-- <p><b>Recommened for your level of usage</b></p> -->
                                    <div>
                                        <div style="width:226px;float:left;">
                                            <button pButton type="button" value="Birthday" class="p-button-outlined" style="width:200px;margin-left:5px;margin-right:5px;height:135px;" (click)="doBirthday()" (mouseover)="setText('Birthday')" (mouseout)="setText('')">
                                                <div style="width:100%; margin: auto;">
                                                    <img src="assets/images/SMS3.png" style="width: 130px;">
                                                    <div style="width:100%;margin-top:10px;">25,000 SMSs</div>
                                                </div>
                                            </button>
                                        </div>
                                        <div style="width:70%;float:left;">
                                            <p><b>Our heavy use package</b></p>
                                            <p>Choose this option if you expect to send a large amount of task or marketing SMSs. The system will stop sending SMSs if you run out of credits, please ensure you have enough to complete any operation that you start.</p>
                                            <div style="font-size:20px; font-weight:bold;">£110.00</div>
                                        </div>
                                    </div>
                                    <div style="clear:both;"></div>
                                </div>  
                                <div style="clear:both;">
                                    &nbsp;
                                </div>

                                <div style="border: 2px solid red; border-radius: 5px; background-color: rgb(255, 238, 238);padding: 10px;">
                                    <p><b>Recommened for your level of usage</b></p>
                                    <div>
                                        <div style="width:226px;float:left;">
                                            <button pButton type="button" value="Birthday" class="p-button-outlined" style="width:200px;margin-left:5px;margin-right:5px;height:135px;" (click)="doBirthday()" (mouseover)="setText('Birthday')" (mouseout)="setText('')">
                                                <div style="width:100%; margin: auto;">
                                                    <img src="assets/images/SMS2.png" style="width: 130px;">
                                                    <div style="width:100%;margin-top:10px;">10,000 SMSs</div>
                                                </div>
                                            </button>
                                        </div>
                                        <div style="width:70%;float:left;">
                                            <p><b>Our medium use package</b></p>
                                            <p>Choose this option if you expect to send a reasonable amount of task or marketing SMSs. The system will stop sending SMSs if you run out of credits, please ensure you have enough to complete any operation that you start.</p>
                                            <div style="font-size:20px; font-weight:bold;">£50.00</div>
                                        </div>
                                    </div>
                                    <div style="clear:both;"></div>
                                </div>  
                                <div style="clear:both;">
                                    &nbsp;
                                </div>

                                <div style="padding: 10px;">
                                    <!-- <p><b>Recommened for your level of usage</b></p> -->
                                    <div>
                                        <div style="width:226px;float:left;">
                                            <button pButton type="button" value="Birthday" class="p-button-outlined" style="width:200px;margin-left:5px;margin-right:5px;height:135px;" (click)="doBirthday()" (mouseover)="setText('Birthday')" (mouseout)="setText('')">
                                                <div style="width:100%; margin: auto;">
                                                    <img src="assets/images/SMS1.png" style="width: 130px;">
                                                    <div style="width:100%;margin-top:10px;">5,000 SMSs</div>
                                                </div>
                                            </button>
                                        </div>
                                        <div style="width:70%;float:left;">
                                            <p><b>Our light use package</b></p>
                                            <p>Choose this option if you expect to only send a few task or marketing SMSs. The system will stop sending SMSs if you run out of credits, please ensure you have enough to complete any operation that you start.</p>
                                            <div style="font-size:20px; font-weight:bold;">£30.00</div>
                                        </div>
                                    </div>
                                    <div style="clear:both;"></div>
                                </div>  
                                <div style="clear:both;">
                                    &nbsp;
                                </div>
 
                            </div>
                        </div>
                    </p-tabPanel>
                </p-tabView>
            </div>
        </div>
    </div>
</div>