
<div *ngIf="ParseBS">
    <button style="margin-top: -6px !important; width: 100% !important; margin-bottom: 20px;" pButton pRipple type="button" class="p-button p-element p-button-success w-auto mt-3" (click)="editData(ParseBS.bookingId);">
        <span class="pi pi-pencil" style="margin-right:8px;"></span>
        Edit this booking
    </button>

    <div style="width:100%; margin-bottom: 15px;">
        <span class="pi pi-calendar" style="margin-right: 10px;"></span><b>Sales ID</b> : {{ParseBS.bookingIdHR}}
    </div>
    <div style="width:100%; margin-bottom: 15px;">
        <span class="pi pi-calendar" style="margin-right: 10px;"></span><b>Start Date/Time</b> : {{ParseBS.startTime | date:'HH:mm' : 'UTC'}}
    </div>
    <div style="width:100%; margin-bottom: 15px;">
        <span class="pi pi-calendar" style="margin-right: 10px;"></span><b>End Date/Time</b> : {{ParseBS.endTime| date:'HH:mm' : 'UTC'}}
    </div>
    <div style="width:100%; margin-bottom: 15px;">
        <span class="pi pi-calendar" style="margin-right: 10px;"></span><b>Arrival Date/Time</b> : {{ParseBS.arrival| date:'HH:mm' : 'UTC'}}
    </div>
    <div style="width:100%; margin-bottom: 15px;">
        <span class="pi pi-box" style="margin-right: 10px;"></span><b>Package</b> : {{ParseBS.packageName}}
    </div>
    <div style="width:100%; margin-bottom: 15px;">
        <span class="pi pi-users" style="margin-right: 10px;"></span><b>Admissions</b> : {{ParseBS.admissions}}
        <button pButton type="button" style="height: 25px; margin-right: 5px;width: 25px; float:right;" class="p-button-sm p-element p-ripple p-button p-component">
            <span class="pi pi-users" style="margin-left: -8px; font-size: 15px;" (click)="editbookingadmissions(ParseBS.bookingId)"></span>
        </button>
    </div>
    <div style="width:100%; margin-bottom: 15px;">
        <span class="pi pi-user" style="margin-right: 10px;"></span><b>Customer</b> : {{ParseBS.customerAddress}}
    </div>
    <div style="width:100%; margin-bottom: 15px;">
        <span class="pi pi-envelope" style="margin-right: 10px;"></span><b>Email</b> : {{ParseBS.customerEmail}}
    </div>
    <div style="width:100%; margin-bottom: 15px;">
        <span class="pi pi-mobile" style="margin-right: 10px;"></span><b>Mobile</b> : {{ParseBS.customerMobile}}
    </div>
    <div style="width:100%; margin-bottom: 15px;">
        <span class="pi pi-pound" style="margin-right: 10px;"></span><b>Sales Value</b> : {{ParseBS.total | currency:CurrencySymbol }}
    </div>

    <div *ngIf="ParseBS.outstanding" style="width:100%; margin-bottom: 15px;">
        <span class="pi pi-pound" style="margin-right: 10px;"></span><b>Outstanding</b> : <span style="color: red; font-weight: bold;">{{ParseBS.outstanding | currency:CurrencySymbol }}</span>
        <button pButton type="button" style="height: 25px; margin-right: 5px;width: 25px; float:right;" class="p-button-sm p-element p-ripple p-button p-component" (click)="editbookingpayoff(ParseBS.bookingId)">
            <span class="pi pi-pound" style="margin-left: -8px; font-size: 15px;"></span>
        </button>
    </div>
    <div *ngIf="ParseBS.outstanding <= 0" style="width:100%; margin-bottom: 15px;">
        <span class="pi pi-pound" style="margin-right: 10px;"></span><b>Outstanding</b> : {{ParseBS.outstanding | currency:CurrencySymbol }}
    </div>

    <div *ngIf="ParseBS.totaldiscounts > 0" style="width:100%; margin-bottom: 15px;">
        <span class="pi pi-pound" style="margin-right: 10px;"></span><b>Discounts</b> : {{ParseBS.totaldiscounts | currency:CurrencySymbol }}
    </div>

    <div *ngIf="ParseBS.totalbookingfees > 0" style="width:100%; margin-bottom: 15px;">
        <span class="pi pi-pound" style="margin-right: 10px;"></span><b>Booking Fee</b> : {{ParseBS.totalbookingfees | currency:CurrencySymbol }}
    </div>

    <div *ngIf="ParseBS.outstanding" style="width:100%; margin-bottom: 15px;">
        <span class="pi pi-pound" style="margin-right: 10px;"></span><b>Paid</b> : <span>{{ParseBS.paid | currency:CurrencySymbol }}</span>
    </div>
    <div *ngIf="ParseBS.outstanding <= 0" style="width:100%; margin-bottom: 15px;">
        <span class="pi pi-pound" style="margin-right: 10px;"></span><b>Paid</b> : {{ParseBS.paid | currency:CurrencySymbol }}
    </div>

    <div style="width:100%; margin-bottom: 15px;">
        <span class="pi pi-list" style="margin-right: 10px;"></span><b>Itinerary</b> : {{ParseBS.itinerary}}
    </div>
    <div *ngIf="ParseBS.extras!=''" style="width:100%; margin-bottom: 15px;">
        <span class="pi pi-star" style="margin-right: 10px;"></span><b>Extras</b> : {{ParseBS.extras}}
    </div>
    <div style="width:100%; margin-bottom: 15px;">
        <span class="pi pi-file" style="margin-right: 10px;"></span><b>Notes</b> : {{ParseBS.notes}}
    </div>
</div>
