<div *ngIf="!AccessAllowed && CompletedLoading">
    <h3 style="border: 2px solid;border-radius: 6px;position: absolute;top: 50%;left: 50%;transform: translate(-50%, -50%);padding: 10px;">You don't have access to this function</h3>
</div>

<div *ngIf="AccessAllowed && pageReady" class="p-3 flex flex-column flex-auto">
    <div>
        <div class="text-900 font-medium text-xl mb-3"><h3>Booking Questionnaire
        </h3></div>
        <p class="m-0 mb-4 p-0 text-600 line-height-3 mr-3">
            <span class="pi pi-info-circle" style="margin-right:2px;"></span>
            Create a collections of questions into a questionnaire.
        </p>
        <div class="surface-card p-4 shadow-2 border-round">
            <div class="grid formgrid p-fluid">

                <div class="field mb-4 col-12">
                    <label for="questiontext" class="font-medium">Questionnaire Name</label>
                    <input [(ngModel)]="bookingQuestionnaire.name" id="questionnaireimage" type="text" [ngStyle]="{'border-color': (questiontextNameValid==false) ? '#FF0000':''}" (keyup)="DoValidCheck()" pInputText>
                </div>
                <div *ngIf="!questionnaireNameValid" class="text-danger" Style="margin-left:10px; margin-top: -23px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>You must enter a Questionnaire name.</div>

                <div class="grid formgrid p-fluid" style="width:100%">

                    <div class="col-12 md:col-6 drag-column">
                      <label for="agegroupname" class="font-medium">Available Questions</label>
        
                      <p-table [value]="AvailableQuestions">
                        <ng-template pTemplate="header">
                            <tr>
                                <th style="width:90%">Question</th>
                                <th style="width:10%">&nbsp;</th>
                            </tr>
                        </ng-template>
                            <ng-template pTemplate="body" let-product>
                              <tr pDraggable="products" (onDragStart)="dragStart($event, product)" (onDragEnd)="dragEnd()">
                                  <td>{{product.questiontext}}</td>
                                  <td>
                                    <button pButton pRipple type="button" [style]="{'margin-bottom': '-6px', 'margin-top': '-4px !important'}" class="p-button-sm p-element p-button-success w-auto mt-3" (click)="AddToList(product)">
                                      <span class="pi pi-angle-right"></span>
                                    </button>
                                  </td>
                              </tr>
                            </ng-template>
        
                        </p-table>
        
        
                        <div *ngIf="AvailableQuestions == null" style="padding: 15px;">
        
                        <i>No questions available, add some <a href="/bookingquestions">here</a></i>
        
                        </div>
                    </div>
        
                    <div class="col-12 md:col-6 drop-column" pDroppable="products" (onDrop)="drop()">
                      <label for="agegroupname" class="font-medium">Selected Questons</label>
        
                      <p-table [value]="SelectedQuestions">
                        <ng-template  pTemplate="header" let-columns>
                            <tr>
                                <th>&nbsp;</th>
                                <th style="width:70%">Question</th>
                                <th style="width:10%">&nbsp;</th>
                            </tr>
                        </ng-template>
                            <ng-template pTemplate="body" let-product let-columns="columns" let-index="rowIndex">
                              <tr [pReorderableRow]="index">
                                  <td>
                                    <span class="pi pi-bars" pReorderableRowHandle></span>
                                  </td>
                                  <td>{{product.questiontext}}</td>
                                  <td>
                                    <button pButton pRipple type="button" [style]="{'margin-bottom': '-6px', 'margin-top': '-4px !important'}" class="p-button-sm p-element p-button-danger w-auto mt-3" (click)="RemoveFromList(product)">
                                      <span class="pi pi-angle-left"></span>
                                    </button>
                                  </td>
                              </tr>
                            </ng-template>
        
                        </p-table>
        
                    </div>
        
        
        
                    </div>



                <div class="col-12">
                    <button pButton pRipple type="button" class="p-button p-element p-button-success w-auto mt-3" (click)="updateData();">
                    <span class="pi pi-pencil" style="margin-right:8px;"></span>
                    Save Changes</button>
                    &nbsp;
                    <button pButton pRipple type="button" class="p-button p-element p-button-danger w-auto mt-3" (click)="returnToList()">
                    <span class="pi pi-times" style="margin-right:8px;"></span>
                    Cancel</button>
                </div>
            </div>
        </div>
    </div>
</div>