import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router'
import { HttpClient } from '@angular/common/http';
import { bmxurl, getAccess } from 'src/globals';
import { class_returndata } from 'src/types/class_returndata'; 
import { Router, RouterLink } from '@angular/router';
import { cClass, classes } from 'src/types/class';
import { returnmessage } from 'src/types/returnmessage';
import {MessageService} from 'primeng/api';

import { users_returndata } from 'src/types/user_returndata';


@Component({
  selector: 'app-classedit',
  templateUrl: './classedit.component.html',
  styleUrls: ['./classedit.component.css']
})
export class ClasseditComponent implements OnInit {
  VenueID: string = localStorage.getItem("SmartVenueID")!;
  Class: cClass;
  httpResponse: any;
  id: string;
  pageReady: boolean = true;

  classNameValid: boolean = true;

  AccessAllowed: boolean = false;
  CompletedLoading: boolean = false;
  
  constructor(private route: ActivatedRoute, private http: HttpClient, private router: Router, private messageService: MessageService) { 
    this.Class = {
      id: null,
      classID: null,
      name: "",
      venueid: this.VenueID,
      disabled: false
    }
  }

  ngOnInit(): void {

    let userid = localStorage.getItem("SmartUserID");

    this.http.get(bmxurl + "User/" + userid).subscribe(response => {
      this.httpResponse =  (response as users_returndata)
      if(this.httpResponse.succeeded)
      {
        let user = this.httpResponse.data;

        if(getAccess("CLASSES", user)){
          this.AccessAllowed = true;

          this.id = this.route.snapshot.paramMap.get('id');
          if(this.id != "0")
          {
            this.pageReady = false;
            this.http.get(bmxurl + "Class/" + this.VenueID + "/" + this.id).subscribe(response => {
              this.httpResponse = (response as class_returndata)
              if (this.httpResponse.succeeded)
              {
                this.Class = {
                  id: (this.httpResponse.data as cClass).id,
                  classID: (this.httpResponse.data as cClass).classID,
                  name: (this.httpResponse.data as cClass).name,
                  venueid: (this.httpResponse.data as cClass).venueid,
                  disabled: (this.httpResponse.data as cClass).disabled
                };
      
                this.pageReady = true;
              }
              else
              {
                this.messageService.add({severity:'error', summary:'Error', detail:'Error while getting classes'});
                this.pageReady = false;
              }
            }, error => {
              this.messageService.add({severity:'error', summary:'Error', detail:'Error while getting classes, error:' + error.messages[0]});
              this.pageReady = false;
            });
          }
        }

        this.CompletedLoading = true;

      }
    }, error => {
      this.messageService.add({severity:'error', summary:'Error', detail:'Error while getting User, error:' + error.messages[0]});
    });

  }

  updateData()
  {
    if (this.DoValidCheck())
    {
      if(this.id == "0")
      {
        this.http.post<cClass>(bmxurl + "/Class", this.Class).subscribe(response => {
          this.httpResponse = response
            if(this.httpResponse.succeeded)
            {
              this.messageService.add({severity:'success', summary:'Saved', detail:'Class successfully updated'});
              this.router.navigateByUrl("/class");
            }
            }, error => {
              this.messageService.add({severity:'error', summary:'Not Saved', detail:error.messages});
          })
      }
      else
      {
        this.Class.disabled = false;
        
        this.http.put<cClass>(bmxurl + "/Class", this.Class).subscribe(response => {
        this.httpResponse = response
          if(this.httpResponse.succeeded)
          {
            this.messageService.add({severity:'success', summary:'Saved', detail:'Class successfully updated'});
            this.router.navigateByUrl("/class");
          }
          }, error => {
            this.messageService.add({severity:'error', summary:'Not Saved', detail:error.messages});
        })
      }
    }
  }

  DoValidCheck()
  {
    let AllValid = true;

    if(this.Class.name == null || this.Class.name == "")
    {
      AllValid = false;
      this.classNameValid = false;
    }
    else
    {
      this.classNameValid = true;
    }

    return AllValid;
  }

  returnToList()
  {
    this.router.navigateByUrl("/class");
  }
}
