import { Component } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { bmxurl, getAccess } from 'src/globals';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-testingtasklist',
  templateUrl: './testingtasklist.component.html',
  styleUrls: ['./testingtasklist.component.css']
})

export class TestingtasklistComponent {
  taskList: any;
  bmxurl: string;
  cols: any[];
  VenueID: string = localStorage.getItem("SmartVenueID")!;
  httpResponse: any;

  constructor(
    private http: HttpClient,
    private router: Router,
    private messageService: MessageService,) {}

  ngOnInit(): void {

    this.cols = [
      { field: 'email', header: 'Email Address' },
      { field: 'bookingtasktypename', header: 'Name' },
      { field: 'bookingidhr', header: 'Sales ID' },
      { field: 'taskstatus', header: 'Task Status' },
      { field: 'senddate', header: 'Send Date' },
      { field: 'sent', header: 'Sent' },
      ];

    this.loadgrid(false);
  }

  goToBooking(bookingtaskid: number)
  {
    this.router.navigateByUrl("editbooking/" + bookingtaskid);
  }

  disableMulti()
  {
    this.taskList.forEach(element => {
      if(element.taskstatus != 10)
      {
        this.http.get(bmxurl + "/deletebookingtask/" + element.id + "/" + element.bookingtaskid).subscribe(response => {
          this.httpResponse = response
          if(this.httpResponse.succeeded = true)
          {
            this.loadgrid(false);
          }
        }, error => {

        })
      }
    });
  }

  disableTask(bookingtaskid: string, id: string)
  {
    this.http.get(bmxurl + "/deletebookingtask/" + id + "/" + bookingtaskid).subscribe(response => {
      this.httpResponse = response;
      if(this.httpResponse.succeeded)
      {
        this.messageService.add({severity:'success', summary:'Success', detail:'Task removed'});
        this.loadgrid(false);
      }
      else
      {
        this.messageService.add({severity:'error', summary:'Failure', detail: this.httpResponse.messages[0]});
      }
    });
  }

  loadgrid(showdisabled: boolean)
  {
    this.http.get(bmxurl + "globalbookingtasklist/" + this.VenueID).subscribe(response => {
      if((response as any).succeeded == true)
      {
        this.taskList = (response as any).data.filter((item: any) => item.eventid == null);
      }
    }, error => {
    });
  }


}
