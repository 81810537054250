<div *ngIf="!AccessAllowed && CompletedLoading">
  <h3 style="border: 2px solid;border-radius: 6px;position: absolute;top: 50%;left: 50%;transform: translate(-50%, -50%);padding: 10px;">You don't have access to this function</h3>
</div>

<p-dialog header="No Package Start Times for this timeset" [(visible)]="displayModalWarning" [style]="{width: '50vw'}" [baseZIndex]="10000"  [modal]="true">
    <!-- <p>You have not saved any Package Start Times for this timeset, times shown in grey have been imported from your Venue Start Times, please ensure you save these times, even if they are the same as the times imported.</p> -->
    <p>You have not saved any Package Start Times for this timeset, please ensure you save these times.</p>
    <ng-template pTemplate="footer">
      <p-button icon="pi pi-check" (click)="displayModalWarning=false" label="OK" styleClass="p-button-text"></p-button>
  </ng-template>
</p-dialog>


<div *ngIf="pageReady && AccessAllowed" class="p-3 flex flex-column flex-auto">
    <div class="text-900 font-medium text-xl mb-3"><h3>Package Start Times</h3></div>
    <p class="m-0 mb-4 p-0 text-600 line-height-3 mr-3">
        <span class="pi pi-info-circle" style="margin-right:2px;"></span>
        Specify the start times for this package.
    </p>
            <div class="surface-card p-4 shadow-2 border-round">
              <div class="grid formgrid p-fluid">
                <div class="field mb-4 col-12">
                    <h3><label for="ddlPackage">Package: {{packageName}}</label></h3>
                </div>
            </div>
            <div class="grid formgrid p-fluid">
              <div class="field mb-4 col-12">
                  <label for="ddlPackage" class="font-medium">Time Set {{DefaultTimeSetText}}</label>
                  <p-dropdown [(ngModel)]="SelectedTimeSet" placeholder="Select a Time Set" [showClear]="true" optionLabel="name" [options]="ListTimesets" (onChange)="getPackageStartTimeSet()"></p-dropdown>
              </div>
          </div>

          <!-- <div class="grid formgrid p-fluid">
            <div class="field mb-4 col-12">
                <label for="ddlPackage" class="font-medium">Copy from this Package</label>
                <p-dropdown [(ngModel)]="SelectedPackage" placeholder="Select a Package" [showClear]="true" optionLabel="name" [options]="ListPackages"></p-dropdown>
           
              <button pButton type="button" style="height: 42px; width: 237px;font-size: 15px;margin-top: 7px;" class="p-button-sm p-button-success p-element p-ripple p-button p-component" (click)="copyStartTimesFromPackage()">
                <span class="pi pi-copy" style="margin-right:5px; font-size:14px;margin-left: -2px;"></span>
                <span>Copy from this Package</span>
              </button>
            </div>
          </div> -->

            <p-accordion *ngIf="SelectedTimeSet" [style]="{'width':'100%'}">
              <p-accordionTab >
                <p-header style="width:100%">
                  <div *ngIf="mondaySelectedTimes.length > 0" style="width:100%">
                    <span class="pi pi-clock" style="margin-right: 10px;font-size:20px;"></span><span style="font-size:17px;">Monday ({{venueStartTime}} - {{venueEndTime}})&nbsp;({{mondaySelectedTimes.length}}&nbsp;times selected from {{mondaySelectedTimes[0].time}}&nbsp;to&nbsp;{{mondaySelectedTimes[mondaySelectedTimes.length - 1].time}})</span>
                  </div>
                  <div *ngIf="mondaySelectedTimes.length == 0" style="width:100%">
                    <span class="pi pi-clock" style="margin-right: 10px;font-size:20px;"></span><span style="font-size:17px;">Monday ({{venueStartTime}} - {{venueEndTime}})&nbsp;(No times selected)</span>
                  </div>
              </p-header>
              <div class="field mb-4 col-12">
                <div class="field mb-4 col-12">
                  <label for="MondayTimes" class="font-medium">Selected Times</label>
                  <div *ngFor="let t of mondaySelectedTimes">
                      <p-chip *ngIf="(packagestarttimesetID == null && t.index >= startTimeIndex && t.index < endTimeIndex)" label="{{t.time}}" [removable]="true" styleClass="custom-chip" [style]="{'float':'left','margin-right':'5px','margin-bottom':'5px','width':'100px;'}" (onRemove)="RemoveMondayChip(t.time)"></p-chip>
                      <p-chip *ngIf="(packagestarttimesetID != null && t.index >= startTimeIndex && t.index < endTimeIndex)" label="{{t.time}}" [removable]="true" styleClass="custom-chip2" [style]="{'float':'left','margin-right':'5px','margin-bottom':'5px','width':'100px;'}" (onRemove)="RemoveMondayChip(t.time)"></p-chip>
                      <p-chip *ngIf="t.index < startTimeIndex || t.index >= endTimeIndex" label="{{t.time}}"  label="{{t.time}}" [removable]="true" styleClass="custom-chip3" [style]="{'float':'left','margin-right':'5px','margin-bottom':'5px','width':'100px;'}" (onRemove)="RemoveMondayChip(t.time)"></p-chip>
                    </div>
                  <div style="clear:both; margin-top:65px;"></div> 

                  <div style="width: 424px;float: left;border-right: dashed black 1px;margin-right: 15px;margin-top: 10px;">
                    <div style="margin-top:10px; margin-bottom:10px;">
                      Template
                    </div>
                    <p-dropdown [options]="mondayTemplate" [style]="{'width': '300px', 'float':'left', 'margin-right':'10px'}"  [(ngModel)]="mondaySelectedTemplate" optionValue="time" optionLabel="templatename"></p-dropdown>
                    <button pButton type="button" style="height: 35px; width: 80px;float: left;margin-right: -1px;font-size: 15px;margin-top: 7px;" class="p-button-sm p-button-success p-element p-ripple p-button p-component" (click)="addTemplatedMonday()">
                      <span class="pi pi-plus" style="margin-right:5px; font-size:14px;margin-left: -2px;"></span>
                      <span>Add</span>
                    </button>
                  </div>

                  <div style="width: 424px; float: left;margin-top: 10px;">
                    <div style="margin-top:10px; margin-bottom:10px;">
                      Ad Hoc
                    </div>
                      <p-dropdown [options]="venueMondayStartTimeHours" [style]="{'width': '95px', 'float': 'left'}"  [(ngModel)]="venueMondaySelectedStartTimeHours" optionValue="code" optionLabel="name"></p-dropdown>
                      <span style="font-size: 29px;float: left;margin-left: 10px;margin-right: 10px;">:</span>
                      <p-dropdown [options]="venueMondayStartTimeMins" [style]="{'width': '95px', 'float': 'left'}"  [(ngModel)]="venueMondaySelectedStartTimeMins" optionValue="code" optionLabel="name"></p-dropdown>
                    
                    <button pButton type="button" style="margin-left: 10px;height: 35px; width: 80px;float: left;margin-right: -1px;font-size: 15px;margin-top: 7px;" class="p-button-sm p-button-success p-element p-ripple p-button p-component" (click)="addAdHocMonday()">
                      <span class="pi pi-plus" style="margin-right:5px; font-size:14px;margin-left: -2px;"></span>
                      <span>Add</span>
                    </button>

                  </div>
                  <div style="float:left; margin-top:20px; text-align:center;">
                    <button pButton type="button" style="height: 37px; width:200px; float:left; margin-right:10px; font-size:16px; margin-bottom:5px;" class="p-button-sm p-button-secondary p-element p-ripple p-button p-component" (click)="MondayCopy('ToWeek')">
                      <span class="pi pi-angle-double-down" style="margin-right:8px;"></span>
                      <span>Copy to all Week</span>
                    </button>

                    <button pButton type="button" style="height: 37px; width:320px; float:left; margin-right:10px; font-size:16px; margin-bottom:5px;" class="p-button-sm p-button-secondary p-element p-ripple p-button p-component" (click)="MondayCopy('ToWeekExcWE')">
                      <span class="pi pi-angle-down" style="margin-right:8px;"></span>
                      <span>Copy to all Week (Excl Weekend)</span>
                    </button>

                    <button pButton type="button" style="height: 37px; width:200px; float:left; margin-right:10px; font-size:16px; margin-bottom:5px;" class="p-button-sm p-button-secondary p-element p-ripple p-button p-component" (click)="MondayCopy('ToWeekEnd')">
                      <span class="pi pi-angle-right" style="margin-right:8px;"></span>
                      <span>Copy to Weekend</span>
                    </button>

                    <button pButton type="button" style="height: 37px; width:200px; float:left; margin-right:10px; font-size:16px; margin-bottom:5px;" class="p-button-sm p-button-secondary p-element p-ripple p-button p-component" (click)="ClearTimesMonday()">
                      <span class="pi pi-times" style="margin-right:8px;"></span>
                      <span>Clear Times</span>
                    </button>
                  </div>
                  
                </div>
              </div><div style="clear:both;"></div>
            </p-accordionTab>
            <p-accordionTab>
              <p-header style="width:100%">
                <div *ngIf="tuesdaySelectedTimes.length > 0" style="width:100%">
                    <span class="pi pi-clock" style="margin-right: 10px;font-size:20px;"></span><span style="font-size:17px;">Tuesday ({{venueStartTime}} - {{venueEndTime}})&nbsp;({{tuesdaySelectedTimes.length}}&nbsp;times selected from {{tuesdaySelectedTimes[0].time}}&nbsp;to&nbsp;{{tuesdaySelectedTimes[tuesdaySelectedTimes.length - 1].time}})</span>
                </div>
                <div *ngIf="tuesdaySelectedTimes.length == 0"  style="width:100%">
                  <span class="pi pi-clock" style="margin-right: 10px;font-size:20px;"></span><span style="font-size:17px;">Tuesday ({{venueStartTime}} - {{venueEndTime}})&nbsp;(No times selected)</span>
              </div>
            </p-header>
            <div class="field mb-4 col-12">
              <div class="field mb-4 col-12">
                <label for="TuesdayTimes" class="font-medium">Selected Times</label>
                <div *ngFor="let t of tuesdaySelectedTimes">
                    <p-chip *ngIf="(packagestarttimesetID == null && t.index >= startTimeIndex && t.index < endTimeIndex)" label="{{t.time}}" [removable]="true" styleClass="custom-chip" [style]="{'float':'left','margin-right':'5px','margin-bottom':'5px','width':'100px;'}" (onRemove)="RemoveTuesdayChip(t.time)"></p-chip>
                    <p-chip *ngIf="(packagestarttimesetID != null && t.index >= startTimeIndex && t.index < endTimeIndex)" label="{{t.time}}" [removable]="true" styleClass="custom-chip2" [style]="{'float':'left','margin-right':'5px','margin-bottom':'5px','width':'100px;'}" (onRemove)="RemoveTuesdayChip(t.time)"></p-chip>
                    <p-chip *ngIf="t.index < startTimeIndex || t.index >= endTimeIndex" label="{{t.time}}"  label="{{t.time}}" [removable]="true" styleClass="custom-chip3" [style]="{'float':'left','margin-right':'5px','margin-bottom':'5px','width':'100px;'}" (onRemove)="RemoveTuesdayChip(t.time)"></p-chip>
                  </div>
                <div style="clear:both; margin-top:65px;"></div> 

                <div style="width: 424px;float: left;border-right: dashed black 1px;margin-right: 15px;margin-top: 10px;">
                  <div style="margin-top:10px; margin-bottom:10px;">
                    Template
                  </div>
                  <p-dropdown [options]="tuesdayTemplate" [style]="{'width': '300px', 'float':'left', 'margin-right':'10px'}"  [(ngModel)]="tuesdaySelectedTemplate" optionValue="time" optionLabel="templatename"></p-dropdown>
                  <button pButton type="button" style="height: 35px; width: 80px;float: left;margin-right: -1px;font-size: 15px;margin-top: 7px;" class="p-button-sm p-button-success p-element p-ripple p-button p-component" (click)="addTemplatedTuesday()">
                    <span class="pi pi-plus" style="margin-right:5px; font-size:14px;margin-left: -2px;"></span>
                    <span>Add</span>
                  </button>
                </div>

                <div style="width: 424px; float: left;margin-top: 10px;">
                  <div style="margin-top:10px; margin-bottom:10px;">
                    Ad Hoc
                  </div>
                    <p-dropdown [options]="venueTuesdayStartTimeHours" [style]="{'width': '95px', 'float': 'left'}"  [(ngModel)]="venueTuesdaySelectedStartTimeHours" optionValue="code" optionLabel="name"></p-dropdown>
                    <span style="font-size: 29px;float: left;margin-left: 10px;margin-right: 10px;">:</span>
                    <p-dropdown [options]="venueTuesdayStartTimeMins" [style]="{'width': '95px', 'float': 'left'}"  [(ngModel)]="venueTuesdaySelectedStartTimeMins" optionValue="code" optionLabel="name"></p-dropdown>
                  
                  <button pButton type="button" style="margin-left: 10px;height: 35px; width: 80px;float: left;margin-right: -1px;font-size: 15px;margin-top: 7px;" class="p-button-sm p-button-success p-element p-ripple p-button p-component" (click)="addAdHocTuesday()">
                    <span class="pi pi-plus" style="margin-right:5px; font-size:14px;margin-left: -2px;"></span>
                    <span>Add</span>
                  </button>

                </div>
                <div style="float:left; margin-top:20px; text-align:center;">
                  <button pButton type="button" style="height: 37px; width:200px; float:left; margin-right:10px; font-size:16px; margin-bottom:5px;" class="p-button-sm p-button-secondary p-element p-ripple p-button p-component" (click)="TuesdayCopy('ToWeek')">
                    <span class="pi pi-angle-double-down" style="margin-right:8px;"></span>
                    <span>Copy to all Week</span>
                  </button>

                  <button pButton type="button" style="height: 37px; width:320px; float:left; margin-right:10px; font-size:16px; margin-bottom:5px;" class="p-button-sm p-button-secondary p-element p-ripple p-button p-component" (click)="TuesdayCopy('ToWeekExcWE')">
                    <span class="pi pi-angle-down" style="margin-right:8px;"></span>
                    <span>Copy to all Week (Excl Weekend)</span>
                  </button>

                  <button pButton type="button" style="height: 37px; width:200px; float:left; margin-right:10px; font-size:16px; margin-bottom:5px;" class="p-button-sm p-button-secondary p-element p-ripple p-button p-component" (click)="TuesdayCopy('ToWeekEnd')">
                    <span class="pi pi-angle-right" style="margin-right:8px;"></span>
                    <span>Copy to Weekend</span>
                  </button>

                  <button pButton type="button" style="height: 37px; width:200px; float:left; margin-right:10px; font-size:16px; margin-bottom:5px;" class="p-button-sm p-button-secondary p-element p-ripple p-button p-component" (click)="ClearTimesTuesday()">
                    <span class="pi pi-times" style="margin-right:8px;"></span>
                    <span>Clear Times</span>
                  </button>
                </div>
               
              </div> 
            </div><div style="clear:both;"></div>
            </p-accordionTab>
            <p-accordionTab>
              <p-header style="width:100%">
                <div *ngIf="wednesdaySelectedTimes.length > 0"  style="width:100%">
                    <span class="pi pi-clock" style="margin-right: 10px;font-size:20px;"></span><span style="font-size:17px;">Wednesday ({{venueStartTime}} - {{venueEndTime}})&nbsp;({{wednesdaySelectedTimes.length}}&nbsp;times selected from {{wednesdaySelectedTimes[0].time}}&nbsp;to&nbsp;{{wednesdaySelectedTimes[wednesdaySelectedTimes.length - 1].time}})</span>
                </div>
                <div *ngIf="wednesdaySelectedTimes.length == 0"  style="width:100%">
                  <span class="pi pi-clock" style="margin-right: 10px;font-size:20px;"></span><span style="font-size:17px;">Wednesday ({{venueStartTime}} - {{venueEndTime}})&nbsp;(No times selected)</span>
              </div>
            </p-header>
            <div class="field mb-4 col-12">
              <div class="field mb-4 col-12">
                <label for="WednesdayTimes" class="font-medium">Selected Times</label>
                <div *ngFor="let t of wednesdaySelectedTimes">
                    <p-chip *ngIf="(packagestarttimesetID == null && t.index >= startTimeIndex && t.index < endTimeIndex)" label="{{t.time}}" [removable]="true" styleClass="custom-chip" [style]="{'float':'left','margin-right':'5px','margin-bottom':'5px','width':'100px;'}" (onRemove)="RemoveWednesdayChip(t.time)"></p-chip>
                    <p-chip *ngIf="(packagestarttimesetID != null && t.index >= startTimeIndex && t.index < endTimeIndex)" label="{{t.time}}" [removable]="true" styleClass="custom-chip2" [style]="{'float':'left','margin-right':'5px','margin-bottom':'5px','width':'100px;'}" (onRemove)="RemoveWednesdayChip(t.time)"></p-chip>
                    <p-chip *ngIf="t.index < startTimeIndex || t.index >= endTimeIndex" label="{{t.time}}"  label="{{t.time}}" [removable]="true" styleClass="custom-chip3" [style]="{'float':'left','margin-right':'5px','margin-bottom':'5px','width':'100px;'}" (onRemove)="RemoveWednesdayChip(t.time)"></p-chip>
                  </div>
                <div style="clear:both; margin-top:65px;"></div> 

                <div style="width: 424px;float: left;border-right: dashed black 1px;margin-right: 15px;margin-top: 10px;">
                  <div style="margin-top:10px; margin-bottom:10px;">
                    Template
                  </div>
                  <p-dropdown [options]="wednesdayTemplate" [style]="{'width': '300px', 'float':'left', 'margin-right':'10px'}"  [(ngModel)]="wednesdaySelectedTemplate" optionValue="time" optionLabel="templatename"></p-dropdown>
                  <button pButton type="button" style="height: 35px; width: 80px;float: left;margin-right: -1px;font-size: 15px;margin-top: 7px;" class="p-button-sm p-button-success p-element p-ripple p-button p-component" (click)="addTemplatedWednesday()">
                    <span class="pi pi-plus" style="margin-right:5px; font-size:14px;margin-left: -2px;"></span>
                    <span>Add</span>
                  </button>
                </div>

                <div style="width: 424px; float: left;margin-top: 10px;">
                  <div style="margin-top:10px; margin-bottom:10px;">
                    Ad Hoc
                  </div>
                    <p-dropdown [options]="venueWednesdayStartTimeHours" [style]="{'width': '95px', 'float': 'left'}"  [(ngModel)]="venueWednesdaySelectedStartTimeHours" optionValue="code" optionLabel="name"></p-dropdown>
                    <span style="font-size: 29px;float: left;margin-left: 10px;margin-right: 10px;">:</span>
                    <p-dropdown [options]="venueWednesdayStartTimeMins" [style]="{'width': '95px', 'float': 'left'}"  [(ngModel)]="venueWednesdaySelectedStartTimeMins" optionValue="code" optionLabel="name"></p-dropdown>
                  
                  <button pButton type="button" style="margin-left: 10px;height: 35px; width: 80px;float: left;margin-right: -1px;font-size: 15px;margin-top: 7px;" class="p-button-sm p-button-success p-element p-ripple p-button p-component" (click)="addAdHocWednesday()">
                    <span class="pi pi-plus" style="margin-right:5px; font-size:14px;margin-left: -2px;"></span>
                    <span>Add</span>
                  </button>

                </div>
                <div style="float:left; margin-top:20px; text-align:center;">
                  <button pButton type="button" style="height: 37px; width:200px; float:left; margin-right:10px; font-size:16px; margin-bottom:5px;" class="p-button-sm p-button-secondary p-element p-ripple p-button p-component" (click)="WednesdayCopy('ToWeek')">
                    <span class="pi pi-angle-double-down" style="margin-right:8px;"></span>
                    <span>Copy to all Week</span>
                  </button>

                  <button pButton type="button" style="height: 37px; width:320px; float:left; margin-right:10px; font-size:16px; margin-bottom:5px;" class="p-button-sm p-button-secondary p-element p-ripple p-button p-component" (click)="WednesdayCopy('ToWeekExcWE')">
                    <span class="pi pi-angle-down" style="margin-right:8px;"></span>
                    <span>Copy to all Week (Excl Weekend)</span>
                  </button>

                  <button pButton type="button" style="height: 37px; width:200px; float:left; margin-right:10px; font-size:16px; margin-bottom:5px;" class="p-button-sm p-button-secondary p-element p-ripple p-button p-component" (click)="WednesdayCopy('ToWeekEnd')">
                    <span class="pi pi-angle-right" style="margin-right:8px;"></span>
                    <span>Copy to Weekend</span>
                  </button>

                  <button pButton type="button" style="height: 37px; width:200px; float:left; margin-right:10px; font-size:16px; margin-bottom:5px;" class="p-button-sm p-button-secondary p-element p-ripple p-button p-component" (click)="ClearTimesWednesday()">
                    <span class="pi pi-times" style="margin-right:8px;"></span>
                    <span>Clear Times</span>
                  </button>
                </div>
              </div>
              
            </div><div style="clear:both;"></div>
            </p-accordionTab>
            <p-accordionTab>
              <p-header style="width:100%">
                <div *ngIf="thursdaySelectedTimes.length > 0"  style="width:100%">
                    <span class="pi pi-clock" style="margin-right: 10px;font-size:20px;"></span><span style="font-size:17px;">Thursday ({{venueStartTime}} - {{venueEndTime}})&nbsp;({{thursdaySelectedTimes.length}}&nbsp;times selected from {{thursdaySelectedTimes[0].time}}&nbsp;to&nbsp;{{thursdaySelectedTimes[thursdaySelectedTimes.length - 1].time}})</span>
                </div>
                <div *ngIf="thursdaySelectedTimes.length == 0"  style="width:100%">
                  <span class="pi pi-clock" style="margin-right: 10px;font-size:20px;"></span><span style="font-size:17px;">Thursday ({{venueStartTime}} - {{venueEndTime}})&nbsp;(No times selected)</span>
              </div>
            </p-header>
            <div class="field mb-4 col-12">
              <div class="field mb-4 col-12">
                <label for="ThursdayTimes" class="font-medium">Selected Times</label>
                <div *ngFor="let t of thursdaySelectedTimes">
                    <p-chip *ngIf="(packagestarttimesetID == null && t.index >= startTimeIndex && t.index < endTimeIndex)" label="{{t.time}}" [removable]="true" styleClass="custom-chip" [style]="{'float':'left','margin-right':'5px','margin-bottom':'5px','width':'100px;'}" (onRemove)="RemoveThursdayChip(t.time)"></p-chip>
                    <p-chip *ngIf="(packagestarttimesetID != null && t.index >= startTimeIndex && t.index < endTimeIndex)" label="{{t.time}}" [removable]="true" styleClass="custom-chip2" [style]="{'float':'left','margin-right':'5px','margin-bottom':'5px','width':'100px;'}" (onRemove)="RemoveThursdayChip(t.time)"></p-chip>
                    <p-chip *ngIf="t.index < startTimeIndex || t.index >= endTimeIndex" label="{{t.time}}"  label="{{t.time}}" [removable]="true" styleClass="custom-chip3" [style]="{'float':'left','margin-right':'5px','margin-bottom':'5px','width':'100px;'}" (onRemove)="RemoveThursdayChip(t.time)"></p-chip>
                  </div>
                <div style="clear:both; margin-top:65px;"></div> 

                <div style="width: 424px;float: left;border-right: dashed black 1px;margin-right: 15px;margin-top: 10px;">
                  <div style="margin-top:10px; margin-bottom:10px;">
                    Template
                  </div>
                  <p-dropdown [options]="thursdayTemplate" [style]="{'width': '300px', 'float':'left', 'margin-right':'10px'}"  [(ngModel)]="thursdaySelectedTemplate" optionValue="time" optionLabel="templatename"></p-dropdown>
                  <button pButton type="button" style="height: 35px; width: 80px;float: left;margin-right: -1px;font-size: 15px;margin-top: 7px;" class="p-button-sm p-button-success p-element p-ripple p-button p-component" (click)="addTemplatedThursday()">
                    <span class="pi pi-plus" style="margin-right:5px; font-size:14px;margin-left: -2px;"></span>
                    <span>Add</span>
                  </button>
                </div>

                <div style="width: 424px; float: left;margin-top: 10px;">
                  <div style="margin-top:10px; margin-bottom:10px;">
                    Ad Hoc
                  </div>
                    <p-dropdown [options]="venueThursdayStartTimeHours" [style]="{'width': '95px', 'float': 'left'}"  [(ngModel)]="venueThursdaySelectedStartTimeHours" optionValue="code" optionLabel="name"></p-dropdown>
                    <span style="font-size: 29px;float: left;margin-left: 10px;margin-right: 10px;">:</span>
                    <p-dropdown [options]="venueThursdayStartTimeMins" [style]="{'width': '95px', 'float': 'left'}"  [(ngModel)]="venueThursdaySelectedStartTimeMins" optionValue="code" optionLabel="name"></p-dropdown>
                  
                  <button pButton type="button" style="margin-left: 10px;height: 35px; width: 80px;float: left;margin-right: -1px;font-size: 15px;margin-top: 7px;" class="p-button-sm p-button-success p-element p-ripple p-button p-component" (click)="addAdHocThursday()">
                    <span class="pi pi-plus" style="margin-right:5px; font-size:14px;margin-left: -2px;"></span>
                    <span>Add</span>
                  </button>

                </div>
                <div style="float:left; margin-top:20px; text-align:center;">
                  <button pButton type="button" style="height: 37px; width:200px; float:left; margin-right:10px; font-size:16px; margin-bottom:5px;" class="p-button-sm p-button-secondary p-element p-ripple p-button p-component" (click)="ThursdayCopy('ToWeek')">
                    <span class="pi pi-angle-double-down" style="margin-right:8px;"></span>
                    <span>Copy to all Week</span>
                  </button>

                  <button pButton type="button" style="height: 37px; width:320px; float:left; margin-right:10px; font-size:16px; margin-bottom:5px;" class="p-button-sm p-button-secondary p-element p-ripple p-button p-component" (click)="ThursdayCopy('ToWeekExcWE')">
                    <span class="pi pi-angle-down" style="margin-right:8px;"></span>
                    <span>Copy to all Week (Excl Weekend)</span>
                  </button>

                  <button pButton type="button" style="height: 37px; width:200px; float:left; margin-right:10px; font-size:16px; margin-bottom:5px;" class="p-button-sm p-button-secondary p-element p-ripple p-button p-component" (click)="ThursdayCopy('ToWeekEnd')">
                    <span class="pi pi-angle-right" style="margin-right:8px;"></span>
                    <span>Copy to Weekend</span>
                  </button>

                  <button pButton type="button" style="height: 37px; width:200px; float:left; margin-right:10px; font-size:16px; margin-bottom:5px;" class="p-button-sm p-button-secondary p-element p-ripple p-button p-component" (click)="ClearTimesThursday()">
                    <span class="pi pi-times" style="margin-right:8px;"></span>
                    <span>Clear Times</span>
                  </button>
                </div>
              </div>
              
            </div><div style="clear:both;"></div>
            </p-accordionTab>
            <p-accordionTab>
              <p-header style="width:100%">
                <div *ngIf="fridaySelectedTimes.length > 0"  style="width:100%">
                    <span class="pi pi-clock" style="margin-right: 10px;font-size:20px;"></span><span style="font-size:17px;">Friday ({{venueStartTime}} - {{venueEndTime}})&nbsp;({{fridaySelectedTimes.length}}&nbsp;times selected from {{fridaySelectedTimes[0].time}}&nbsp;to&nbsp;{{fridaySelectedTimes[fridaySelectedTimes.length - 1].time}})</span>
                </div>
                <div *ngIf="fridaySelectedTimes.length == 0"  style="width:100%">
                  <span class="pi pi-clock" style="margin-right: 10px;font-size:20px;"></span><span style="font-size:17px;">Friday ({{venueStartTime}} - {{venueEndTime}})&nbsp;(No times selected)</span>
              </div>
            </p-header>
            <div class="field mb-4 col-12">
              <div class="field mb-4 col-12">
                <label for="FridayTimes" class="font-medium">Selected Times</label>
                <div *ngFor="let t of fridaySelectedTimes">
                    <p-chip *ngIf="(packagestarttimesetID == null && t.index >= startTimeIndex && t.index < endTimeIndex)" label="{{t.time}}" [removable]="true" styleClass="custom-chip" [style]="{'float':'left','margin-right':'5px','margin-bottom':'5px','width':'100px;'}" (onRemove)="RemoveFridayChip(t.time)"></p-chip>
                    <p-chip *ngIf="(packagestarttimesetID != null && t.index >= startTimeIndex && t.index < endTimeIndex)" label="{{t.time}}" [removable]="true" styleClass="custom-chip2" [style]="{'float':'left','margin-right':'5px','margin-bottom':'5px','width':'100px;'}" (onRemove)="RemoveFridayChip(t.time)"></p-chip>
                    <p-chip *ngIf="t.index < startTimeIndex || t.index >= endTimeIndex" label="{{t.time}}"  label="{{t.time}}" [removable]="true" styleClass="custom-chip3" [style]="{'float':'left','margin-right':'5px','margin-bottom':'5px','width':'100px;'}" (onRemove)="RemoveFridayChip(t.time)"></p-chip>
                  </div>
                <div style="clear:both; margin-top:65px;"></div> 

                <div style="width: 424px;float: left;border-right: dashed black 1px;margin-right: 15px;margin-top: 10px;">
                  <div style="margin-top:10px; margin-bottom:10px;">
                    Template
                  </div>
                  <p-dropdown [options]="fridayTemplate" [style]="{'width': '300px', 'float':'left', 'margin-right':'10px'}"  [(ngModel)]="fridaySelectedTemplate" optionValue="time" optionLabel="templatename"></p-dropdown>
                  <button pButton type="button" style="height: 35px; width: 80px;float: left;margin-right: -1px;font-size: 15px;margin-top: 7px;" class="p-button-sm p-button-success p-element p-ripple p-button p-component" (click)="addTemplatedFriday()">
                    <span class="pi pi-plus" style="margin-right:5px; font-size:14px;margin-left: -2px;"></span>
                    <span>Add</span>
                  </button>
                </div>

                <div style="width: 424px; float: left;margin-top: 10px;">
                  <div style="margin-top:10px; margin-bottom:10px;">
                    Ad Hoc
                  </div>
                    <p-dropdown [options]="venueFridayStartTimeHours" [style]="{'width': '95px', 'float': 'left'}"  [(ngModel)]="venueFridaySelectedStartTimeHours" optionValue="code" optionLabel="name"></p-dropdown>
                    <span style="font-size: 29px;float: left;margin-left: 10px;margin-right: 10px;">:</span>
                    <p-dropdown [options]="venueFridayStartTimeMins" [style]="{'width': '95px', 'float': 'left'}"  [(ngModel)]="venueFridaySelectedStartTimeMins" optionValue="code" optionLabel="name"></p-dropdown>
                  
                  <button pButton type="button" style="margin-left: 10px;height: 35px; width: 80px;float: left;margin-right: -1px;font-size: 15px;margin-top: 7px;" class="p-button-sm p-button-success p-element p-ripple p-button p-component" (click)="addAdHocFriday()">
                    <span class="pi pi-plus" style="margin-right:5px; font-size:14px;margin-left: -2px;"></span>
                    <span>Add</span>
                  </button>

                </div>
                <div style="float:left; margin-top:20px; text-align:center;">
                  <button pButton type="button" style="height: 37px; width:200px; float:left; margin-right:10px; font-size:16px; margin-bottom:5px;" class="p-button-sm p-button-secondary p-element p-ripple p-button p-component" (click)="FridayCopy('ToWeek')">
                    <span class="pi pi-angle-double-down" style="margin-right:8px;"></span>
                    <span>Copy to all Week</span>
                  </button>

                  <button pButton type="button" style="height: 37px; width:320px; float:left; margin-right:10px; font-size:16px; margin-bottom:5px;" class="p-button-sm p-button-secondary p-element p-ripple p-button p-component" (click)="FridayCopy('ToWeekExcWE')">
                    <span class="pi pi-angle-down" style="margin-right:8px;"></span>
                    <span>Copy to all Week (Excl Weekend)</span>
                  </button>

                  <button pButton type="button" style="height: 37px; width:200px; float:left; margin-right:10px; font-size:16px; margin-bottom:5px;" class="p-button-sm p-button-secondary p-element p-ripple p-button p-component" (click)="FridayCopy('ToWeekEnd')">
                    <span class="pi pi-angle-right" style="margin-right:8px;"></span>
                    <span>Copy to Weekend</span>
                  </button>

                  <button pButton type="button" style="height: 37px; width:200px; float:left; margin-right:10px; font-size:16px; margin-bottom:5px;" class="p-button-sm p-button-secondary p-element p-ripple p-button p-component" (click)="ClearTimesFriday()">
                    <span class="pi pi-times" style="margin-right:8px;"></span>
                    <span>Clear Times</span>
                  </button>
                </div>
                
              </div>
            </div><div style="clear:both;"></div>
            </p-accordionTab>
            <p-accordionTab>
              <p-header style="width:100%">
                <div  *ngIf="saturdaySelectedTimes.length > 0" style="width:100%">
                    <span class="pi pi-clock" style="margin-right: 10px;font-size:20px;"></span><span style="font-size:17px;">Saturday ({{venueStartTime}} - {{venueEndTime}})&nbsp;({{saturdaySelectedTimes.length}}&nbsp;times selected from {{saturdaySelectedTimes[0].time}}&nbsp;to&nbsp;{{saturdaySelectedTimes[saturdaySelectedTimes.length - 1].time}})</span>
                </div>
                <div  *ngIf="saturdaySelectedTimes.length == 0" style="width:100%">
                  <span class="pi pi-clock" style="margin-right: 10px;font-size:20px;"></span><span style="font-size:17px;">Saturday ({{venueStartTime}} - {{venueEndTime}})&nbsp;(No times selected)</span>
                </div>
            </p-header>
            <div class="field mb-4 col-12">
              <div class="field mb-4 col-12">
                <label for="SaturdayTimes" class="font-medium">Selected Times</label>
                <div *ngFor="let t of saturdaySelectedTimes">
                    <p-chip *ngIf="(packagestarttimesetID == null && t.index >= startTimeIndex && t.index < endTimeIndex)" label="{{t.time}}" [removable]="true" styleClass="custom-chip" [style]="{'float':'left','margin-right':'5px','margin-bottom':'5px','width':'100px;'}" (onRemove)="RemoveSaturdayChip(t.time)"></p-chip>
                    <p-chip *ngIf="(packagestarttimesetID != null && t.index >= startTimeIndex && t.index < endTimeIndex)" label="{{t.time}}" [removable]="true" styleClass="custom-chip2" [style]="{'float':'left','margin-right':'5px','margin-bottom':'5px','width':'100px;'}" (onRemove)="RemoveSaturdayChip(t.time)"></p-chip>
                    <p-chip *ngIf="t.index < startTimeIndex || t.index >= endTimeIndex" label="{{t.time}}"  label="{{t.time}}" [removable]="true" styleClass="custom-chip3" [style]="{'float':'left','margin-right':'5px','margin-bottom':'5px','width':'100px;'}" (onRemove)="RemoveSaturdayChip(t.time)"></p-chip>
                  </div>
                <div style="clear:both; margin-top:65px;"></div> 

                <div style="width: 424px;float: left;border-right: dashed black 1px;margin-right: 15px;margin-top: 10px;">
                  <div style="margin-top:10px; margin-bottom:10px;">
                    Template
                  </div>
                  <p-dropdown [options]="saturdayTemplate" [style]="{'width': '300px', 'float':'left', 'margin-right':'10px'}"  [(ngModel)]="saturdaySelectedTemplate" optionValue="time" optionLabel="templatename"></p-dropdown>
                  <button pButton type="button" style="height: 35px; width: 80px;float: left;margin-right: -1px;font-size: 15px;margin-top: 7px;" class="p-button-sm p-button-success p-element p-ripple p-button p-component" (click)="addTemplatedSaturday()">
                    <span class="pi pi-plus" style="margin-right:5px; font-size:14px;margin-left: -2px;"></span>
                    <span>Add</span>
                  </button>
                </div>

                <div style="width: 424px; float: left;margin-top: 10px;">
                  <div style="margin-top:10px; margin-bottom:10px;">
                    Ad Hoc
                  </div>
                    <p-dropdown [options]="venueSaturdayStartTimeHours" [style]="{'width': '95px', 'float': 'left'}"  [(ngModel)]="venueSaturdaySelectedStartTimeHours" optionValue="code" optionLabel="name"></p-dropdown>
                    <span style="font-size: 29px;float: left;margin-left: 10px;margin-right: 10px;">:</span>
                    <p-dropdown [options]="venueSaturdayStartTimeMins" [style]="{'width': '95px', 'float': 'left'}"  [(ngModel)]="venueSaturdaySelectedStartTimeMins" optionValue="code" optionLabel="name"></p-dropdown>
                  
                  <button pButton type="button" style="margin-left: 10px;height: 35px; width: 80px;float: left;margin-right: -1px;font-size: 15px;margin-top: 7px;" class="p-button-sm p-button-success p-element p-ripple p-button p-component" (click)="addAdHocSaturday()">
                    <span class="pi pi-plus" style="margin-right:5px; font-size:14px;margin-left: -2px;"></span>
                    <span>Add</span>
                  </button>

                </div>
                <div style="float:left; margin-top:20px; text-align:center;">
                  <button pButton type="button" style="height: 37px; width:200px; float:left; margin-right:10px; font-size:16px; margin-bottom:5px;" class="p-button-sm p-button-secondary p-element p-ripple p-button p-component" (click)="SaturdayCopy('ToWeek')">
                    <span class="pi pi-angle-double-down" style="margin-right:8px;"></span>
                    <span>Copy to all Week</span>
                  </button>

                  <button pButton type="button" style="height: 37px; width:320px; float:left; margin-right:10px; font-size:16px; margin-bottom:5px;" class="p-button-sm p-button-secondary p-element p-ripple p-button p-component" (click)="SaturdayCopy('ToWeekExcWE')">
                    <span class="pi pi-angle-down" style="margin-right:8px;"></span>
                    <span>Copy to all Week (Excl Weekend)</span>
                  </button>

                  <button pButton type="button" style="height: 37px; width:200px; float:left; margin-right:10px; font-size:16px; margin-bottom:5px;" class="p-button-sm p-button-secondary p-element p-ripple p-button p-component" (click)="SaturdayCopy('ToWeekEnd')">
                    <span class="pi pi-angle-right" style="margin-right:8px;"></span>
                    <span>Copy to Weekend</span>
                  </button>

                  <button pButton type="button" style="height: 37px; width:200px; float:left; margin-right:10px; font-size:16px; margin-bottom:5px;" class="p-button-sm p-button-secondary p-element p-ripple p-button p-component" (click)="ClearTimesSaturday()">
                    <span class="pi pi-times" style="margin-right:8px;"></span>
                    <span>Clear Times</span>
                  </button>
                </div>
                
              </div>
            </div><div style="clear:both;"></div>
            </p-accordionTab>
            <p-accordionTab>
              <p-header style="width:100%">
                <div *ngIf="sundaySelectedTimes.length > 0"  style="width:100%">
                    <span class="pi pi-clock" style="margin-right: 10px;font-size:20px;"></span><span style="font-size:17px;">Sunday ({{venueStartTime}} - {{venueEndTime}})&nbsp;({{sundaySelectedTimes.length}}&nbsp;times selected from {{sundaySelectedTimes[0].time}}&nbsp;to&nbsp;{{sundaySelectedTimes[sundaySelectedTimes.length - 1].time}})</span>
                </div>
                <div  *ngIf="sundaySelectedTimes.length == 0" style="width:100%">
                    <span class="pi pi-clock" style="margin-right: 10px;font-size:20px;"></span><span style="font-size:17px;">Sunday ({{venueStartTime}} - {{venueEndTime}})&nbsp;(No times selected)</span>
                </div>
            </p-header>
            <div class="field mb-4 col-12">
              <div class="field mb-4 col-12">
                <label for="SundayTimes" class="font-medium">Selected Times</label>
                <div *ngFor="let t of sundaySelectedTimes">
                    <p-chip *ngIf="(packagestarttimesetID == null && t.index >= startTimeIndex && t.index < endTimeIndex)" label="{{t.time}}" [removable]="true" styleClass="custom-chip" [style]="{'float':'left','margin-right':'5px','margin-bottom':'5px','width':'100px;'}" (onRemove)="RemoveSundayChip(t.time)"></p-chip>
                    <p-chip *ngIf="(packagestarttimesetID != null && t.index >= startTimeIndex && t.index < endTimeIndex)" label="{{t.time}}" [removable]="true" styleClass="custom-chip2" [style]="{'float':'left','margin-right':'5px','margin-bottom':'5px','width':'100px;'}" (onRemove)="RemoveSundayChip(t.time)"></p-chip>
                    <p-chip *ngIf="t.index < startTimeIndex || t.index >= endTimeIndex" label="{{t.time}}"  label="{{t.time}}" [removable]="true" styleClass="custom-chip3" [style]="{'float':'left','margin-right':'5px','margin-bottom':'5px','width':'100px;'}" (onRemove)="RemoveSundayChip(t.time)"></p-chip>
                  </div>
                <div style="clear:both; margin-top:65px;"></div> 

                <div style="width: 424px;float: left;border-right: dashed black 1px;margin-right: 15px;margin-top: 10px;">
                  <div style="margin-top:10px; margin-bottom:10px;">
                    Template
                  </div>
                  <p-dropdown [options]="sundayTemplate" [style]="{'width': '300px', 'float':'left', 'margin-right':'10px'}"  [(ngModel)]="sundaySelectedTemplate" optionValue="time" optionLabel="templatename"></p-dropdown>
                  <button pButton type="button" style="height: 35px; width: 80px;float: left;margin-right: -1px;font-size: 15px;margin-top: 7px;" class="p-button-sm p-button-success p-element p-ripple p-button p-component" (click)="addTemplatedSunday()">
                    <span class="pi pi-plus" style="margin-right:5px; font-size:14px;margin-left: -2px;"></span>
                    <span>Add</span>
                  </button>
                </div>

                <div style="width: 424px; float: left;margin-top: 10px;">
                  <div style="margin-top:10px; margin-bottom:10px;">
                    Ad Hoc
                  </div>
                    <p-dropdown [options]="venueSundayStartTimeHours" [style]="{'width': '95px', 'float': 'left'}"  [(ngModel)]="venueSundaySelectedStartTimeHours" optionValue="code" optionLabel="name"></p-dropdown>
                    <span style="font-size: 29px;float: left;margin-left: 10px;margin-right: 10px;">:</span>
                    <p-dropdown [options]="venueSundayStartTimeMins" [style]="{'width': '95px', 'float': 'left'}"  [(ngModel)]="venueSundaySelectedStartTimeMins" optionValue="code" optionLabel="name"></p-dropdown>
                  
                  <button pButton type="button" style="margin-left: 10px;height: 35px; width: 80px;float: left;margin-right: -1px;font-size: 15px;margin-top: 7px;" class="p-button-sm p-button-success p-element p-ripple p-button p-component" (click)="addAdHocSunday()">
                    <span class="pi pi-plus" style="margin-right:5px; font-size:14px;margin-left: -2px;"></span>
                    <span>Add</span>
                  </button>

                </div>
                <div style="float:left; margin-top:20px; text-align:center;">
                  <button pButton type="button" style="height: 37px; width:200px; float:left; margin-right:10px; font-size:16px; margin-bottom:5px;" class="p-button-sm p-button-secondary p-element p-ripple p-button p-component" (click)="SundayCopy('ToWeek')">
                    <span class="pi pi-angle-double-down" style="margin-right:8px;"></span>
                    <span>Copy to all Week</span>
                  </button>

                  <button pButton type="button" style="height: 37px; width:320px; float:left; margin-right:10px; font-size:16px; margin-bottom:5px;" class="p-button-sm p-button-secondary p-element p-ripple p-button p-component" (click)="SundayCopy('ToWeekExcWE')">
                    <span class="pi pi-angle-down" style="margin-right:8px;"></span>
                    <span>Copy to all Week (Excl Weekend)</span>
                  </button>

                  <button pButton type="button" style="height: 37px; width:200px; float:left; margin-right:10px; font-size:16px; margin-bottom:5px;" class="p-button-sm p-button-secondary p-element p-ripple p-button p-component" (click)="SundayCopy('ToWeekEnd')">
                    <span class="pi pi-angle-right" style="margin-right:8px;"></span>
                    <span>Copy to Weekend</span>
                  </button>

                  <button pButton type="button" style="height: 37px; width:200px; float:left; margin-right:10px; font-size:16px; margin-bottom:5px;" class="p-button-sm p-button-secondary p-element p-ripple p-button p-component" (click)="ClearTimesSunday()">
                    <span class="pi pi-times" style="margin-right:8px;"></span>
                    <span>Clear Times</span>
                  </button>
                </div>
                
              </div>
            </div><div style="clear:both;"></div>
            </p-accordionTab>
          </p-accordion>
          <div class="col-12"> 
            <button pButton pRipple type="button" class="p-button p-element p-button-success w-auto mt-3" (click)="updateStartTimeData();">
            <span class="pi pi-pencil" style="margin-right:8px;"></span>
            Save Changes</button>
            &nbsp;
            <button pButton pRipple type="button" class="p-button p-element p-button-danger w-auto mt-3" (click)="returnHome()">
            <span class="pi pi-times" style="margin-right:8px;"></span>
            Cancel</button>
        </div>
    </div>
</div>
<style>

    :host ::ng-deep .p-chip.custom-chip3 {
      background: #EE0000;
      color: var(--primary-color-text);
      width: 90px;
    }

    :host ::ng-deep .p-chip.custom-chip {
      background: var(--bluegray-300);
      color: var(--primary-color-text);
      width: 90px;
    }

    :host ::ng-deep .p-chip.custom-chip2 {
      background: var(--primary-color);
      color: var(--primary-color-text);
      width: 90px;
    }
    
    .p-chip-text{
      width: 90px;
    }
    </style>
