import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { bookingsummary, cBookingsummary } from 'src/types/bookingsummary';

@Component({
  selector: 'app-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.css']
})
export class SummaryComponent implements OnInit {
  @Input() BookingSummary: cBookingsummary;
  ParseBS: cBookingsummary;
  CurrencySymbol: string = localStorage.getItem("Currency") == null ? "GBP" : localStorage.getItem("Currency")!;

  constructor(private router: Router) {
    this.ParseBS = {
      bookingId: "",
      bookingIdHR: "",
      Disabled: false,
      admissions: "",
      startTime: new Date(),
      endTime: new Date(),
      name: "",
      arrival: new Date(),
      guests: 0,
      total: 0,
      outstanding: 0,
      paid: 0,
      totaldiscounts: 0,
      bookingfee: 0,
      customerName: "",
      customerAddress: "",
      customerMobile: "",
      customerEmail: "",
      packageName: "",
      publicBooking: false,
      extras: "",
      itinerary: "",
      notes: ""
    }
   }

  ngOnInit(): void {
    this.ParseBS = this.BookingSummary
  }

  editbookingadmissions(BookingID)
  {
    this.router.navigate(
      ['/editbooking/' + BookingID],
      { queryParams: { jumpto: 'admissions' } }
    );
  }

  editbookingpayoff(BookingID)
  {
    this.router.navigate(
      ['/editbooking/' + BookingID],
      { queryParams: { jumpto: 'payment' } }
    );
  }

  editData(ID)
  {
    this.router.navigateByUrl("/editbooking/" + ID);
  }

}
