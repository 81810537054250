import { Component } from '@angular/core';

@Component({
  selector: 'app-createnewbusinessuser',
  templateUrl: './createnewbusinessuser.component.html',
  styleUrls: ['./createnewbusinessuser.component.css']
})
export class CreatenewbusinessuserComponent {

}
