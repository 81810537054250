
<div  class="p-3 flex flex-column flex-auto">
    <div class="text-900 font-medium text-xl mb-3"><h3>Emails Sent - May 2024
    </h3></div>
    <p class="m-0 mb-4 p-0 text-600 line-height-3 mr-3">
        <span class="pi pi-info-circle" style="margin-right:2px;"></span>
        [NO TEXT YET]
    </p>
    <div class="surface-card p-4 shadow-2 border-round">
        <div style="width:100% !important; height:50px !important;float:left;">
            <p-chart type="line" [data]="chartData" [options]="basicOptions" width="100%" height="200px;"></p-chart>
        </div>
        <div style="clear:both;">&nbsp;</div>

        <div style="text-align: right;margin-top: 180px;">
            <span class="p-input-icon-left ml-auto" style="margin-right:20px;"> 
                <i class="pi pi-search"></i>
                <input pInputText type="text" (input)="classTable.filterGlobal($event.target.value, 'contains')" style="height: 50px;padding-left:33px;" />
            </span>
        </div>

        <div style="margin-top: 20px;">
            <p-table  #classTable 
            [value]="pTableData" sortField="datetimesent" [paginator]="true" [rows]="10" 
            [showCurrentPageReport]="true" responsiveLayout="scroll"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" 
            [rowsPerPageOptions]="[10,25,50]" 
            [globalFilterFields]="['emailaddress','status']"
            responsiveLayout="scroll"
            datakey="datetimesent"
            [selectionPageOnly]="true">
                <ng-template pTemplate="header">
                    <tr>
                        <th></th>
                        <th pSortableColumn="datetimesent" width="30%">Date
                            <p-sortIcon [field]="datetimesent"></p-sortIcon>
                        </th>
                        <th pSortableColumn="emailaddress" width="60%">Email Address
                            <p-sortIcon [field]="emailaddress"></p-sortIcon>
                        </th>
                        <th pSortableColumn="status" width="10%">Status
                            <p-sortIcon [field]="status"></p-sortIcon>
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-data>
                    <tr>
                        <td *ngIf="data.status=='Delivered'"><span style="color:#64748b" class="pi pi-circle-on"></span></td>
                        <td *ngIf="data.status=='Read'"><span style="color:#f59e0b" class="pi pi-circle-on"></span></td>
                        <td *ngIf="data.status=='Link Clicked'"><span style="color:#22c55e" class="pi pi-circle-on"></span></td>

                        <td>{{data.datetimesent | date:'MM/dd/yyyy HH:mm'}}</td>
                        <td>{{data.emailaddress}}</td>
                        <td>{{data.status}}</td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
</div>
