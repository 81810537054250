import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router'
import { HttpClient } from '@angular/common/http';
import { bmxurl, getAccess } from 'src/globals';
import { bookingfee_returndata } from 'src/types/bookingfee_returndata'; 
import { Router, RouterLink } from '@angular/router';
import { cBookingfee } from 'src/types/bookingfee';
import { returnmessage } from 'src/types/returnmessage';
import { MessageService } from 'primeng/api';

import { users_returndata } from 'src/types/user_returndata';

@Component({
  selector: 'app-bookingfeeedit',
  templateUrl: './bookingfeeedit.component.html',
  styleUrls: ['./bookingfeeedit.component.css']
})
export class BookingfeeeditComponent implements OnInit {
  VenueID: string = localStorage.getItem("SmartVenueID")!;
  BookingFee: cBookingfee;
  httpResponse: any;
  id: string;
  pageReady: boolean = true;
  stateOptions: any;
  stateOptionsType: any;

  publicTextValid: boolean = true;
  publicMinMaxValid: boolean = true;
  adminMinMaxValid: boolean = true;
  AccessAllowed: boolean = false;
  CompletedLoading: boolean = false;
  CurrencySymbol: string = localStorage.getItem("Currency") == null ? "GBP" : localStorage.getItem("Currency")!;
  
  constructor(private route: ActivatedRoute, private http: HttpClient, private router: Router, private messageService: MessageService) { 

    this.stateOptions = [{ label: 'Yes', value: true }, { label: 'No', value: false }];
    this.stateOptionsType = [{ label: 'Fixed', value: 'FIXED' }, { label: 'Per Person', value: 'PERPERSON' }, { label: 'Percentage', value: 'PERCENTAGE' }];

    this.BookingFee = {
      id: null,
      venueid: this.VenueID,
      disabled: false,
      bfid: null,
      bfpublicapply: false,
      bfadminapply: false,
      bfpublicshowtext: false,
      bfpublictext:"",
      bfpublictype: "FIXED",
      bfadmintype: "FIXED",
      bfpublicfee: 0,
      bfpublicmin: 0,
      bfpublicmax: 0,
      bfadminfee: 0,
      bfadminmin: 0,
      bfadminmax: 0,
    }
    
  }

  ngOnInit(): void {

    localStorage.removeItem("screenMemoryBookingss");
    localStorage.removeItem("screenMemoryEvents");

    let userid = localStorage.getItem("SmartUserID");

    this.http.get(bmxurl + "User/" + userid).subscribe(response => {
      this.httpResponse =  (response as users_returndata)
      if(this.httpResponse.succeeded)
      {
        let user = this.httpResponse.data;

        if(getAccess("BOOKINGFEES", user)){
          this.AccessAllowed = true;

          this.pageReady = false;
          this.http.get(bmxurl + "bookingfee/" + this.VenueID).subscribe(response => {
            this.httpResponse = (response as bookingfee_returndata)
            if (this.httpResponse.succeeded)
            {
              this.BookingFee = {
                id: (this.httpResponse.data as cBookingfee).id,
                venueid: (this.httpResponse.data as cBookingfee).venueid,
                disabled: (this.httpResponse.data as cBookingfee).disabled,
                bfid: (this.httpResponse.data as cBookingfee).bfid,
                bfpublicapply: (this.httpResponse.data as cBookingfee).bfpublicapply,
                bfadminapply: (this.httpResponse.data as cBookingfee).bfadminapply,
                bfpublicshowtext: (this.httpResponse.data as cBookingfee).bfpublicshowtext,
                bfpublictext: (this.httpResponse.data as cBookingfee).bfpublictext,
                bfpublictype: (this.httpResponse.data as cBookingfee).bfpublictype,
                bfadmintype: (this.httpResponse.data as cBookingfee).bfadmintype,
                bfpublicfee: (this.httpResponse.data as cBookingfee).bfpublicfee,
                bfpublicmin: (this.httpResponse.data as cBookingfee).bfpublicmin,
                bfpublicmax: (this.httpResponse.data as cBookingfee).bfpublicmax,
                bfadminfee: (this.httpResponse.data as cBookingfee).bfadminfee,
                bfadminmin: (this.httpResponse.data as cBookingfee).bfadminmin,
                bfadminmax: (this.httpResponse.data as cBookingfee).bfadminmax
              };
    
              this.pageReady = true;
            }
            else
            {
              this.messageService.add({severity:'error', summary:'Error', detail:'Error while getting Booking Fee'});
              this.pageReady = false;
            }
          }, error => {
            this.messageService.add({severity:'error', summary:'Error', detail:'Error while getting Booking Fee, error:' + error.messages[0]});
            this.pageReady = false;
          });
        }

        this.CompletedLoading = true;

      }
    }, error => {
      this.messageService.add({severity:'error', summary:'Error', detail:'Error while getting User, error:' + error.messages[0]});
    });

  }

  updateData()
  {
    if (this.DoValidCheck())
    {
      if(this.BookingFee.bfadmintype == "FIXED")
      {
        this.BookingFee.bfadminmin = 0;
        this.BookingFee.bfadminmax = 0;
      }

      if(this.BookingFee.bfpublictype == "FIXED")
      {
        this.BookingFee.bfpublicmin = 0;
        this.BookingFee.bfpublicmax = 0;
      }
      
      if(this.BookingFee.id == null)
      {
        this.http.post<cBookingfee>(bmxurl + "/bookingfee", this.BookingFee).subscribe(response => {
          this.httpResponse = response
            if(this.httpResponse.succeeded)
            {
              localStorage.setItem("VenueBookingFee", JSON.stringify(this.BookingFee));

              this.messageService.add({severity:'success', summary:'Saved', detail:'Booking Fee successfully updated'});
              this.router.navigateByUrl("/home");
            }
            }, error => {
              this.messageService.add({severity:'error', summary:'Not Saved', detail:error.messages});
          })
      }
      else
      {
        this.http.put<cBookingfee>(bmxurl + "/bookingfee", this.BookingFee).subscribe(response => {
          this.httpResponse = response
            if(this.httpResponse.succeeded)
            {
              localStorage.setItem("VenueBookingFee", JSON.stringify(this.BookingFee));

              this.messageService.add({severity:'success', summary:'Saved', detail:'Booking Fee successfully updated'});
              this.router.navigateByUrl("/home");
            }
            }, error => {
              this.messageService.add({severity:'error', summary:'Not Saved', detail:error.messages});
          })
      }

    }
  }

  DoValidCheck()
  {
    let AllValid = true;

    if(this.BookingFee.bfpublicshowtext)
    {
      if(this.BookingFee.bfpublictext == "")
      {
        this.publicTextValid = false;
        AllValid = false;
      }

      if(this.BookingFee.bfadminapply)
      {
        if(this.BookingFee.bfadminmin > this.BookingFee.bfadminmax) 
        {
          this.adminMinMaxValid = false;
          AllValid = false;
        }
      }

      if(this.BookingFee.bfpublicapply)
      {
        if(this.BookingFee.bfpublicmin > this.BookingFee.bfpublicmax) 
        {
          this.publicMinMaxValid = false;
          AllValid = false;
        }
      }
    }

    return AllValid;
  }

  returnToList()
  {
    this.router.navigateByUrl("/bookingfee");
  }
}
