
<div *ngIf="!AccessAllowed && CompletedLoading">
    <h3 style="border: 2px solid;border-radius: 6px;position: absolute;top: 50%;left: 50%;transform: translate(-50%, -50%);padding: 10px;">You don't have access to this function</h3>
</div>

<div *ngIf="AccessAllowed" class="p-3 flex flex-column flex-auto">
    <div>
        <div class="text-900 font-medium text-xl mb-3"><h3>Terms & Conditions
        </h3></div>
        <p class="m-0 mb-4 p-0 text-600 line-height-3 mr-3">
            <span class="pi pi-info-circle" style="margin-right:2px;"></span>
             These are the terms and conditions that you customers agree with when they purchase from you. There is only one set of terms and conditions and they need to incorporate all the terms from all packages. In addition we add some of our own terms at the bottom of the agreement as they need to agree to us storing their information.
        </p>
        <div class="surface-card p-4 shadow-2 border-round">
            <div class="grid formgrid p-fluid">

                <div class="field mb-4 col-12">
                    <label for="classname" class="font-medium">Terms & Conditions Text</label>
                    <p-editor [(ngModel)]="tandc.terms" [style]="{'height':'320px'}">
                        <ng-template pTemplate="header">
                            <span class="ql-formats">
                                <p-header>
                                    <span class="ql-formats">
                                      <select class="ql-size">
                                        <option value="small">Small</option>
                                        <option selected value="normal">Normal</option>
                                        <option value="large">Sub Heading</option>
                                        <option value="huge">Heading</option>
                                      </select>

                                      <select class="ql-font">
                                        <option selected value="san serif">San-Serif</option>
                                        <option value="monospace">Monospace</option>
                                        <option value="serif">Serif</option>
                                      </select>
                                    </span>
                                    <span class="ql-formats">
                                      <button class="ql-bold" aria-label="Bold"></button>
                                      <button class="ql-italic" aria-label="Italic"></button>
                                      <button class="ql-underline" aria-label="Underline"></button>
                                      <button class="ql-strike" aria-label="Strike"></button>
                                      <button class="ql-script" value="sub" title="Subscript"></button>
                                      <button class="ql-script" value="super" title="Superscript"></button>
                                      <button class="ql-indent" value="-1" title="Indent"></button>
                                      <button class="ql-indent" value="+1" title="Outdent"></button>
                                    </span>
                                    <span class="ql-formats">
                                      <select title="Text Color" class="ql-color" defaultValue="rgb(0, 0, 0)">
                                      </select>
                                      <span class="ql-format-separator"></span>
                                      <select title="Background Color" class="ql-background" defaultValue="rgb(255, 255, 255)">
                                      </select>
                                  </span>
                                    <span class="ql-formats">
                                      <button class="ql-list" title="Ordered" value="ordered"></button>
                                      <button class="ql-list" title="Bullet" value="bullet"></button>

                                      <select title="Text Alignment" class="ql-align" >
                                        <option label="Left"></option>
                                        <option value="center" label="Center"></option>
                                        <option value="right" label="Right"></option>
                                        <option value="justify" label="Justify"></option>
                                      </select>            
                                    </span> 
                                    <span class="ql-formats">
                                      <button aria-label="Link" class="ql-link"></button>
                                    </span>
                                  </p-header>
                            </span>
                        </ng-template>
                    </p-editor>
                </div>
                <div *ngIf="!tandcValid" class="text-danger" Style="margin-left:10px; margin-top: -23px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>You must enter some text for your Terms and Conditions.</div>

                <div class="col-12">
                    <button pButton pRipple type="button" class="p-button p-element p-button-success w-auto mt-3" (click)="updateData();">
                    <span class="pi pi-pencil" style="margin-right:8px;"></span>
                    Save Changes</button>
                    &nbsp;
                    <button pButton pRipple type="button" class="p-button p-element p-button-danger w-auto mt-3" (click)="returnToList()">
                    <span class="pi pi-times" style="margin-right:8px;"></span>
                    Cancel</button>
                </div>
            </div>
        </div>
    </div>
</div>