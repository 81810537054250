<div *ngIf="!AccessAllowed && CompletedLoading">
    <h3 style="border: 2px solid;border-radius: 6px;position: absolute;top: 50%;left: 50%;transform: translate(-50%, -50%);padding: 10px;">You don't have access to this function</h3>
</div>

<div *ngIf="pageReady && AccessAllowed" class="p-3 flex flex-column flex-auto">
    <div>
        <div class="text-900 font-medium text-xl mb-3"><h3>Discount</h3></div>
        <p class="m-0 mb-4 p-0 text-600 line-height-3 mr-3">
            <span class="pi pi-info-circle" style="margin-right:2px;"></span>
            [[Needs text explanation]]
        </p>
        <div class="surface-card p-4 shadow-2 border-round">
            <div class="grid formgrid p-fluid">

                <p-tabView style="width:100%" [(activeIndex)]="activeIndex" >

                    <p-tabPanel header="Basic Settings">
                        <div class="field mb-4 col-12">
                            <label for="discountname" class="font-medium">Name of the discount</label>
                            <input [(ngModel)]="Discount.name" id="discountname" type="text" [ngStyle]="{'border-color': (discountNameValid==false) ? '#FF0000':''}" (keyup)="DoValidCheck()" pInputText>
                        </div>
                        <div *ngIf="!discountNameValid" class="text-danger" Style="margin-left:10px; margin-top: -23px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>You must enter an Discount name.</div>
                        
                        <div class="field mb-4 col-12">
                            <label for="venueclosingstartdate">Discount type</label>
                            <p-selectButton [style]="{'width': '450px'}" [options]="stateOptions3" [(ngModel)]="Discount.discounttype" ></p-selectButton>
                        </div>

                        <div *ngIf="Discount.discounttype == 'FIXED'" class="field mb-4 col-12">
                            <label for="autogeneratenumber" class="font-medium">Discount Amount</label>
                            <p-inputNumber id="autogeneratenumber" [style]="{'width': '300px'}" mode="currency" currency="{{CurrencySymbol}}" [(ngModel)]="Discount.discountamount == null ? 0: Discount.discountamount" [showButtons]="true" max="10000" min="0" buttonLayout="horizontal" spinnerMode="horizontal"></p-inputNumber>
                        </div>

                        <div *ngIf="Discount.discounttype == 'PERCENTAGE'" class="field mb-4 col-12">
                            <label for="autogeneratenumber" class="font-medium">Discount Amount</label>
                            <p-inputNumber id="autogeneratenumber" [style]="{'width': '300px'}" suffix="%" [(ngModel)]="Discount.discountamount == null ? 0: Discount.discountamount" [showButtons]="true" max="100" min="0" buttonLayout="horizontal" spinnerMode="horizontal"></p-inputNumber>
                        </div>
                        
                        <div class="field mb-4 col-12">
                            <label for="autogeneratecodes" class="font-medium">Auto Generate Codes</label>
                            <p-selectButton id="autogeneratecodes" [style]="{'width': '350px'}" [options]="stateOptions" [(ngModel)]="Discount.autogeneratecodes" ></p-selectButton>
                        </div>

                        <div *ngIf="Discount.autogeneratecodes == true" class="field mb-4 col-12">
                            <label for="autogeneratenumber" class="font-medium">Number of Auto Generated codes</label>
                            <p-inputNumber id="autogeneratenumber" [style]="{'width': '300px'}" [(ngModel)]="Discount.numberofuses == null ? 0: Discount.numberofuses" [showButtons]="true" max="100000" min="0" buttonLayout="horizontal" spinnerMode="horizontal"></p-inputNumber>
                        </div>
                        
                        <div *ngIf="Discount.autogeneratecodes == false && Discount.code != '[[INSERT NEW CODE]]'" class="field mb-4 col-12">
                            <label for="codename" class="font-medium">Discount code</label>
                            <input [(ngModel)]="Discount.code" id="codename" type="text" pInputText>
                        </div>
                        <div *ngIf="!discountCodeValid" class="text-danger" Style="margin-left:10px; margin-top: -23px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>You must enter an Discount Code.</div>
                        
                        
                        <div *ngIf="Discount.autogeneratecodes == false && Discount.code == '[[INSERT NEW CODE]]'" class="field mb-4 col-12">
                            <label for="codename" class="font-medium">Discount code</label>
                            <input [(ngModel)]="Discount.code" id="codename" type="text" style="border-color:red;" pInputText>
                        </div>

                    </p-tabPanel>

                    <p-tabPanel header="Advanced Settings">
                <div class="field mb-4 col-12">
                    <label for="visibility" class="font-medium">Visibility</label>
                    <p-selectButton id="visibility" [style]="{'width': '450px'}" [options]="stateOptions1" [(ngModel)]="Discount.visibility" ></p-selectButton>
                </div>

                <div class="field mb-4 col-12" style="max-width: 500px;">
                    <label for="discountstartdate">Discount Start Date</label>
                    <p-calendar id="discountstartdate" [(ngModel)]="startDate" readonlyInput="true" firstDayOfWeek="1" dateFormat="dd/mm/yy" (onSelect)="DoDateValidCheck(); DoDateCheck();" [showIcon]="true"></p-calendar>
                    <div *ngIf="!discountStartDateValid" class="text-danger" Style="margin-left:10px; margin-top: 0px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>You must enter a Start Date.</div>
                </div>
                <div style="clear:both;width:100%"></div>
                <div class="field mb-4 col-12" style="max-width: 500px;">
                    <label for="discountenddate">Discount End Date</label>
                    <p-calendar id="discountenddate" readonlyInput="true" [(ngModel)]="endDate" firstDayOfWeek="1" dateFormat="dd/mm/yy" (onSelect)="DoDateValidCheck(); DoDateCheck();" [showIcon]="true"></p-calendar>
                    <div *ngIf="!discountEndDateValid" class="text-danger" Style="margin-left:10px; margin-top: 0px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>You must enter a Start Date.</div>
                </div>

                <div *ngIf="!discountDateOrder" class="text-danger" Style="margin-left:10px; margin-top: 0px;width:100%;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>The Start Date must be before the End Date.</div>
                
                <div class="field mb-4 col-12">
                    <label for="venueclosingstartdate">Available on all days?</label>
                    <p-selectButton id="valiedDaysMonday" [style]="{'width': '350px'}" [options]="stateOptions" [(ngModel)]="Discount.availablealldays" ></p-selectButton>
                </div>

                <div *ngIf="Discount.availablealldays == false">
                    <div class="field mb-4 col-12">
                        <label style="font-weight: bold">Valid days:</label>
                    </div>

                    <div class="field mb-4 col-12">
                        <label for="venueclosingstartdate">Monday</label>
                        <p-selectButton id="valiedDaysMonday" [style]="{'width': '350px'}" [options]="stateOptions" [(ngModel)]="Discount.monday" ></p-selectButton>
                    </div>

                    <div class="field mb-4 col-12">
                        <label for="venueclosingstartdate">Tuesday</label>
                        <p-selectButton id="valiedDaysTuesday" [style]="{'width': '350px'}" [options]="stateOptions" [(ngModel)]="Discount.tuesday" ></p-selectButton>
                    </div>

                    <div class="field mb-4 col-12">
                        <label for="venueclosingstartdate">Wednesday</label>
                        <p-selectButton id="valiedDaysWednesday" [style]="{'width': '350px'}" [options]="stateOptions" [(ngModel)]="Discount.wednesday" ></p-selectButton>
                    </div>

                    <div class="field mb-4 col-12">
                        <label for="venueclosingstartdate">Thursday</label>
                        <p-selectButton id="valiedDaysThursday" [style]="{'width': '350px'}" [options]="stateOptions" [(ngModel)]="Discount.thursday" ></p-selectButton>
                    </div>

                    <div class="field mb-4 col-12">
                        <label for="venueclosingstartdate">Friday</label>
                        <p-selectButton id="valiedDaysFriday" [style]="{'width': '350px'}" [options]="stateOptions" [(ngModel)]="Discount.friday" ></p-selectButton>
                    </div>

                    <div class="field mb-4 col-12">
                        <label for="venueclosingstartdate">Saturday</label>
                        <p-selectButton id="valiedDaysSaturday" [style]="{'width': '350px'}" [options]="stateOptions" [(ngModel)]="Discount.saturday" ></p-selectButton>
                    </div>

                    <div class="field mb-4 col-12">
                        <label for="venueclosingstartdate">Sunday</label>
                        <p-selectButton id="valiedDaysSunday" [style]="{'width': '350px'}" [options]="stateOptions" [(ngModel)]="Discount.sunday" ></p-selectButton>
                    </div>
                </div>
                <div *ngIf="Discount.autogeneratecodes == false" class="field mb-4 col-12">
                    <label for="numberofuses" class="font-medium">Number of uses</label>
                    <p-inputNumber id="numberofuses" [style]="{'width': '300px'}" [(ngModel)]="Discount.numberofuses == null ? 0: Discount.numberofuses" [showButtons]="true" max="100000" min="0" buttonLayout="horizontal" spinnerMode="horizontal"></p-inputNumber>
                </div>
                <div class="field mb-4 col-12">
                    <label>Use with: </label>
                    <p-selectButton id="UseOnAllPackages" [style]="{'width': '400px'}" [options]="stateOptions2" [(ngModel)]="Discount.useonallpackages" ></p-selectButton>
                </div>

                <div *ngIf="Discount.useonallpackages==false" class="surface-card p-4 shadow-2 border-round" style="width:100%;">
                    <div *ngIf="!packagesValid" class="text-danger" Style="margin-left:10px; margin-top: -23px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>You must select at least one package.</div>
                
                    <div class="grid formgrid p-fluid">
                    
                    <div class="col-12 md:col-6 drag-column">
                      <label class="font-medium">Available Packages</label>
        
                      <p-table [value]="ListPackages">
                        <ng-template pTemplate="header">
                            <tr>
                                <th style="width:90%">Name</th>
                                <th style="width:10%">&nbsp;</th>
                            </tr>
                        </ng-template>
                            <ng-template pTemplate="body" let-package>
                              <tr pDraggable="packages" (onDragStart)="dragStart($event, package)" (onDragEnd)="dragEnd()">
                                  <td>{{package.name}}</td>
                                  <td>
                                    <button pButton pRipple type="button" [style]="{'margin-bottom': '-6px', 'margin-top': '-4px !important'}" class="p-button-sm p-element p-button-success w-auto mt-3" (click)="AddToList(package)">
                                      <span class="pi pi-angle-right"></span>
                                    </button>
                                  </td>
                              </tr>
                            </ng-template>
        
                        </p-table>
        
        
                        <div *ngIf="ListPackages == null" style="padding: 15px;">
                        <i>No packages available, add some <a href="packageedit/0">here</a></i>
        
                        </div>
                    </div>
        
                    <div class="col-12 md:col-6 drop-column" pDroppable="packages" (onDrop)="drop()">
                      <label class="font-medium">Selected Packages</label>
        
                      <p-table [value]="Discount.packages">
                        <ng-template  pTemplate="header" let-columns>
                            <tr>
                                <th style="width:90%">Name</th>
                                <th style="width:10%">&nbsp;</th>
                            </tr>
                        </ng-template>
                            <ng-template pTemplate="body" let-package let-columns="columns" let-index="rowIndex">
                              <tr>
                                  <td>{{package.name}}</td>
                                  <td>
                                    <button pButton pRipple type="button" [style]="{'margin-bottom': '-6px', 'margin-top': '-4px !important'}" class="p-button-sm p-element p-button-danger w-auto mt-3" (click)="RemoveFromList(package)">
                                      <span class="pi pi-angle-left"></span>
                                    </button>
                                  </td>
                              </tr>
                            </ng-template>
        
                        </p-table>
                    </div>
                    </div>
                </div>
                    </p-tabPanel>

                </p-tabView>


                



                <div class="col-12">
                    <button pButton pRipple type="button" class="p-button p-element p-button-success w-auto mt-3" (click)="updateData();">
                    <span class="pi pi-pencil" style="margin-right:8px;"></span>
                    Save Changes</button>
                    &nbsp;
                    <button pButton pRipple type="button" class="p-button p-element p-button-danger w-auto mt-3" (click)="returnToList()">
                    <span class="pi pi-times" style="margin-right:8px;"></span>
                    Cancel</button>
                </div>
            </div>
        </div>
    </div>
</div>