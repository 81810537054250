import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { package_returndata } from 'src/types/package_returndata';
import { HttpClient } from '@angular/common/http';
import { MessageService } from 'primeng/api';
import { bmxurl, getAccess } from 'src/globals';

import { packagegroups_returndata } from 'src/types/packagegroup_returndata';
import { cPackagegroups, packagegroups } from 'src/types/packagegroups';
import { cPackage, packageObj } from 'src/types/package';
import { v4 } from 'uuid';
import { users_returndata } from 'src/types/user_returndata';

@Component({
  selector: 'app-packagegroupedit',
  templateUrl: './packagegroupedit.component.html',
  styleUrls: ['./packagegroupedit.component.css']
})
export class PackagegroupeditComponent implements OnInit {
  PackageGroup: any;
  ListPackages: any[];
  VenueID: string = localStorage.getItem("SmartVenueID")!;
  pageReady: boolean = true;
  id: string;
  httpResponse: any;
  packagegroupNameValid: boolean = true;
  packagegroupListValid: boolean = true;
  colour: string = "#FF0000";

  AccessAllowed: boolean = false;
  CompletedLoading: boolean = false;

  constructor(private route: ActivatedRoute, private http: HttpClient, private router: Router, private messageService: MessageService) { 
    this.PackageGroup  = {
      id: null,
      packagegroupid: null,
      packagegroupname: "",
      order: 0,
      packages: [],
      disabled: false,
      venueid: this.VenueID,
      colour: this.colour
    }



  }

  ngOnInit(): void {

    let userid = localStorage.getItem("SmartUserID");

    this.http.get(bmxurl + "User/" + userid).subscribe(response => {
      this.httpResponse =  (response as users_returndata)
      if(this.httpResponse.succeeded)
      {
        let user = this.httpResponse.data;

        if(getAccess("PACKAGEGROUPS", user)){
          this.AccessAllowed = true;
          this.id = this.route.snapshot.paramMap.get('id');
          if(this.id == "0")
          {
            this.pageReady = true;
      
              //Get packages
              this.http.get(bmxurl + "/packagelist/" + this.VenueID + "/false").subscribe(response => {
                if((response as package_returndata).succeeded == true)
                {
                  this.ListPackages = (response as package_returndata).data;
      
                  this.PackageGroup.packages.forEach(item => {
                    this.ListPackages = this.ListPackages.filter(obj => obj.packageId !== item.packageId);
                  });
                }
              }, error => {
            });
          }
          else
          {
            this.pageReady = false;
            //Get Package Group
            this.http.get(bmxurl + "/PackageGroup/" + this.VenueID + "/" + this.id).subscribe(response => {
              if((response as packagegroups_returndata).succeeded == true)
              {
                this.PackageGroup = (response as packagegroups_returndata).data;
      
                  this.pageReady = true;
      
                      //Get packages
                      this.http.get(bmxurl + "/packagelist/" + this.VenueID + "/false").subscribe(response => {
                        if((response as package_returndata).succeeded == true)
                        {
                          this.ListPackages = (response as package_returndata).data;
      
                          this.PackageGroup.packages.forEach(item => {
                            this.ListPackages = this.ListPackages.filter(obj => obj.packageId !== item.packageId);
                          });
                        }
                      }, error => {
                    });
                }
              }, error => {
              });
          }
        }

        this.CompletedLoading = true;


      }
    }, error => {
      this.messageService.add({severity:'error', summary:'Error', detail:'Error while getting User, error:' + error.messages[0]});
    });


  }

  updateData() {

    if (this.PackageGroup.colour == "")
    this.PackageGroup.colour == "#FF0000";


    if (this.DoValidCheck()) {
      if (this.id == "0") {
        this.http.post<cPackagegroups>(bmxurl + "/packagegroup", this.PackageGroup).subscribe(response => {
          this.httpResponse = response
          if (this.httpResponse.succeeded == true) {
            this.messageService.add({ severity: 'success', summary: 'Saved', detail: 'Package Group successfully created' });
            this.returnToList(); 
          }
        }, error => {
          this.messageService.add({ severity: 'error', summary: 'Not Saved', detail: error.messages[0] });
        })
      }
      else {
        this.PackageGroup.disabled = false;

        this.http.put<cPackagegroups>(bmxurl + "/packagegroup", this.PackageGroup).subscribe(response => {
          this.httpResponse = response
          if (this.httpResponse.succeeded == true) {
            this.messageService.add({ severity: 'success', summary: 'Saved', detail: 'Package Group successfully updated' });
            this.returnToList();
          }
        }, error => {
          this.messageService.add({ severity: 'error', summary: 'Not Saved', detail: error.messages });
        })
      }
    }
  }

  returnToList() {
    this.router.navigateByUrl("/packagegroups");
  }

  DoValidCheck() {
    let AllValid = true;

    if(this.PackageGroup.packagegroupname == "" || this.PackageGroup.packagegroupname == null)
    {
      AllValid = false;
      this.packagegroupNameValid = false;
    }
    else
    {
      this.packagegroupNameValid = true;
    }

    if(this.PackageGroup.packages == null || this.PackageGroup.packages.length == 0)
    {
      this.packagegroupListValid = false;
      AllValid = false;
    }
    else
    {
      this.packagegroupListValid = true;
    }
    return AllValid;
  }


  AddToList(product){
    if(this.PackageGroup.packages == null)
    {
      let newId = v4();
      let newpeId = v4();

      this.PackageGroup.packages = [];
      let newPackage: cPackage = {
        id: product.id,
        packageId: product.packageId,
        name: product.name,
        eventid: product.eventid,
        description: product.description,
        imageurl: product.image,
        message: product.message,
        disabled: product.disabled,
        venueID: this.VenueID,
        resources: product.resources,
        startTimes: product.startTimes,
        admissions: product.admissions,
        packageLength: product.packageLength,
        questionnaire: product.questionnaire,
        visibility: product.visibility,
        min:product.min,
        max:product.max,
        tags: product.tags,
        startDate: product.startDate,
        endDate: product.endDate,
        colour: product.colour,
        packageRule: product.packageRule,
        packageRestriction: product.packageRestriction,
        extras: product.extras,
        publicDepositPolicy: product.publicDepositPolicy,
        venueDepositPolicy: product.venueDepositPolicy,
        additionalresources: product.additionalresources,
        confirmation: product.confirmation,
        warning: product.warning,
        originatingid: product.originatingid,
        bookingscannotbemadewithinxminutes: product.bookingscannotbemadewithinxminutes,
        bookingscannotbemadeafterxminutes: product.bookingscannotbemadeafterxminutes,
        cannotmakebookingstoday: product.cannotmakebookingstoday,
        discounttype: product.discounttype,
        discountamount: product.discountamount,
        discountcode: product.discountcode,
        discountstart: product.discountstart,
        discountend: product.discountend,
        earlybirddiscount: product.earlybirddiscount,
        discountrangetype: product.discountrangetype,
        discountdaysbefore: product.discountdaysbefore,
        imageguid: product.imageguid
      }

      this.PackageGroup.packages.push(newPackage);
    }
    else{
      let newId = v4();
      let newpeId = v4();

      let newPackage: cPackage = {
        id: product.id,
        packageId: product.packageId,
        name: product.name,
        eventid: product.eventid,
        description: product.description,
        imageurl: product.imageurl,
        message: product.message,
        disabled: product.disabled,
        venueID: this.VenueID,
        resources: product.resources,
        startTimes: product.startTimes,
        admissions: product.admissions,
        packageLength: product.packageLength,
        questionnaire: product.questionnaire,
        visibility: product.visibility,
        min:product.min,
        max:product.max,
        tags: product.tags,
        startDate: product.startDate,
        endDate: product.endDate,
        colour: product.colour,
        packageRule: product.packageRule,
        packageRestriction: product.packageRestriction,
        extras: product.extras,
        publicDepositPolicy: product.publicDepositPolicy,
        venueDepositPolicy: product.venueDepositPolicy,
        additionalresources: product.additionalresources,
        confirmation: product.confirmation,
        warning: product.warning,
        originatingid: product.originatingid,
        bookingscannotbemadewithinxminutes: product.bookingscannotbemadewithinxminutes,
        bookingscannotbemadeafterxminutes: product.bookingscannotbemadeafterxminutes,
        cannotmakebookingstoday: product.cannotmakebookingstoday,
        discounttype: product.discounttype,
        discountamount: product.discountamount,
        discountcode: product.discountcode,
        discountstart: product.discountstart,
        discountend: product.discountend,
        earlybirddiscount: product.earlybirddiscount,
        discountrangetype: product.discountrangetype,
        discountdaysbefore: product.discountdaysbefore,
        imageguid: product.imageguid
      }

      this.PackageGroup.packages.push(newPackage);
    }

    this.ListPackages = this.ListPackages.filter(obj => obj.id !== product.id);
  }

  RemoveFromList(product){
    this.PackageGroup.packages = this.PackageGroup.packages.filter(obj => obj.id !== product.id);

    this.ListPackages.push(product);

    var sortedArray:any[] = this.ListPackages.sort((n1,n2) => {
      if (n1.name > n2.name) {
          return 1;
      }
  
      if (n1.name < n2.name) {
          return -1;
      }
    });

    this.ListPackages = sortedArray;
  }

}
