import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router'
import { HttpClient } from '@angular/common/http';
import { bmxurl, getAccess } from 'src/globals';
import { restriction_returndata } from 'src/types/restriction_returndata'; 
import { Router, RouterLink } from '@angular/router';
import { cRestriction, restriction } from 'src/types/restriction';
import { returnmessage } from 'src/types/returnmessage';
import { MessageService } from 'primeng/api';
import { subRestriction } from 'src/types/subRestriction';

import { users_returndata } from 'src/types/user_returndata';

@Component({
  selector: 'app-restrictionedit',
  templateUrl: './restrictionedit.component.html',
  styleUrls: ['./restrictionedit.component.css']
})
export class RestrictioneditComponent implements OnInit {
  VenueID: string = localStorage.getItem("SmartVenueID")!;
  Restriction: cRestriction;
  httpResponse: any;
  id: string;
  stateOptions: any[];
  subResName: string;
  newSR: subRestriction[];

  pageReady: boolean = true;
  restrictionNameValid: boolean = true;

  AccessAllowed: boolean = false;
  CompletedLoading: boolean = false;
  
  constructor(private route: ActivatedRoute, private http: HttpClient, private router: Router, private messageService: MessageService) { 
    this.stateOptions = [{ label: 'No', value: false }, { label: 'Yes', value: true }];

    this.Restriction = {
      id: null,
      RestrictionID: null,    
      name: "", 
      disabled: false,  
      venueid: this.VenueID,
      group: false,
      subRestrictions: [],
      venueInstructions: "",
      publicInstructions: ""
    }


  }

  ngOnInit(): void {

    let userid = localStorage.getItem("SmartUserID");

    this.http.get(bmxurl + "User/" + userid).subscribe(response => {
      this.httpResponse =  (response as users_returndata)
      if(this.httpResponse.succeeded)
      {
        let user = this.httpResponse.data;

        if(getAccess("RESTRICTIONS", user)){
          this.AccessAllowed = true;
          this.id = this.route.snapshot.paramMap.get('id');
          if(this.id != "0")
          {
            this.pageReady = false;
            this.http.get(bmxurl + "Restriction/" + this.VenueID + "/" + this.id).subscribe(response => {
              this.httpResponse = (response as restriction_returndata)
              if (this.httpResponse.succeeded)
              {
                this.Restriction = {
                  id: (this.httpResponse.data as cRestriction).id,
                  RestrictionID: (this.httpResponse.data as cRestriction).RestrictionID, 
                  name: (this.httpResponse.data as cRestriction).name,
                  disabled: (this.httpResponse.data as cRestriction).disabled,
                  venueid: (this.httpResponse.data as cRestriction).venueid,
                  group: (this.httpResponse.data as cRestriction).group,
                  subRestrictions: (this.httpResponse.data as cRestriction).subRestrictions,
                  venueInstructions: (this.httpResponse.data as cRestriction).venueInstructions,
                  publicInstructions: (this.httpResponse.data as cRestriction).publicInstructions,
                };
      
                this.pageReady = true;
              }
              else
              {
                this.messageService.add({severity:'error', summary:'Error', detail:'Error while getting Restriction'});
                this.pageReady = false;
              }
            }, error => {
              this.messageService.add({severity:'error', summary:'Error', detail:'Error while getting Restriction, error:' + error.messages[0]});
              this.pageReady = false;
            });
          }
        }

        this.CompletedLoading = true;


      }
    }, error => {
      this.messageService.add({severity:'error', summary:'Error', detail:'Error while getting User, error:' + error.messages[0]});
    });


  }

  updateData()
  {
    if (this.DoValidCheck())
    {
      if(this.id=="0")
      {
        this.Restriction.subRestrictions.forEach((value, index)=>{
          value.position = index;
        });
        
        this.http.post<restriction>(bmxurl + "/Restriction", this.Restriction).subscribe(response => {
          this.httpResponse = response
          if(this.httpResponse.succeeded == true)
          {
            this.messageService.add({severity:'success', summary:'Saved', detail:'Restriction successfully created'});
            this.returnToList();
          }
        }, error => {
          this.messageService.add({severity:'error', summary:'Not Saved', detail:error.messages[0]});
        })

        this.returnToList();
      }
      else
      {
        this.Restriction.disabled = false;

        this.Restriction.subRestrictions.forEach((value, index)=>{
          value.position = index;
        });
        
        this.http.put<restriction>(bmxurl + "/Restriction", this.Restriction).subscribe(response => {
          this.httpResponse = response
          if(this.httpResponse.succeeded == true)
          {
            this.messageService.add({severity:'success', summary:'Saved', detail:'Restriction successfully updated'});
            this.returnToList();
          }
        }, error => {
          this.messageService.add({severity:'error', summary:'Not Saved', detail:error.messages});
        })
      }
    }
  }

  DoValidCheck()
  {
    let AllValid = true;

    if(this.Restriction.name == null || this.Restriction.name == "")
    {
      AllValid = false;
      this.restrictionNameValid = false;
    }
    else
    {
      this.restrictionNameValid = true;
    }

    return AllValid;
  }

  insertSubRestriction()
  {
    let newIndSR: subRestriction = {
      subRestrictionName: this.subResName,
      position: 999999
  }

   this.Restriction.subRestrictions.push(newIndSR);
  }

  returnToList()
  {
    this.router.navigateByUrl("/restriction");
  }

}
