import { Component } from '@angular/core';

@Component({
  selector: 'app-createnewmasterkeyuser',
  templateUrl: './createnewmasterkeyuser.component.html',
  styleUrls: ['./createnewmasterkeyuser.component.css']
})
export class CreatenewmasterkeyuserComponent {

}
