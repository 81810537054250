import { Injectable } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {

  venueName$ = new BehaviorSubject<any>({});
  venueName = this.venueName$.asObservable();

  menuItems$ = new BehaviorSubject<any>({});
  menuItems = this.menuItems$.asObservable();

  menuBCItem$ = new BehaviorSubject<any>({});
  menuBCItem = this.menuBCItem$.asObservable();

  ShowPasswordChange$ = new BehaviorSubject<any>({});
  ShowPasswordChange = this.ShowPasswordChange$.asObservable();

  useCounters$ = new BehaviorSubject<any>({});
  useCounters = this.useCounters$.asObservable();
  

  constructor() { }

  setVenueName(val: string){
    this.venueName$.next(val);
  }

  setUseCounters(val: string){
    this.useCounters$.next(val);
  }

  setMenuItems(val: any){
    this.menuItems$.next(val);
  }

  setBCItem(val: any){
    this.menuBCItem$.next(val);
  }

  setShowPasswordChange(val: any){
    this.ShowPasswordChange$.next(val);
  }
}
