<div *ngIf="!AccessAllowed && CompletedLoading">
    <h3 style="border: 2px solid;border-radius: 6px;position: absolute;top: 50%;left: 50%;transform: translate(-50%, -50%);padding: 10px;">You don't have access to this function</h3>
  </div>

<p-dialog header="Whoops" [(visible)]="displayModalOverPaymentWarning" [style]="{width: '50vw'}" [baseZIndex]="10000"  [modal]="true">
    <p style="font-size: 17px;width: 100%;text-align: center;">You cannot pay more than the value of the sale on a Card.</p>
  <ng-template pTemplate="footer">
      <p-button icon="pi pi-check" (click)="displayModalOverPaymentWarning=false" label="OK" styleClass="p-button-text"></p-button>
  </ng-template>
</p-dialog>

<p-dialog header="Change" [(visible)]="displayModalChange" [style]="{width: '50vw'}" [baseZIndex]="10000"  [modal]="true">
    <p style="font-size: 20px;width: 100%;text-align: center;">Please give the following amount to the customer</p>
    <p style="font-size: 70px;width: 100%;text-align: center;">{{Change | currency:CurrencySymbol}}</p>
  <ng-template pTemplate="footer">
      <p-button icon="pi pi-check" (click)="displayModalChange=false" label="OK" styleClass="p-button-text"></p-button>
  </ng-template>
</p-dialog>

<div *ngIf="AccessAllowed" class="surface-card p-4 shadow-2 border-round" style="height: calc(100vh - 154px);">
    <p-tabView (onChange)="ShowProductsForDept()"  [(activeIndex)]="selectedIndex">
        <p-tabPanel header="{{depts.name}}" *ngFor="let depts of Departments">
            <div class="surface-card p-4 shadow-2 border-round" style="height: calc(100vh - 285px);">
                <div style="width: 75%; float: left; margin-right:20px;height: calc(100vh - 322px);overflow-x: scroll;">
                    <div *ngFor="let product of deptProducts">
                        <button type="button" (click)="addProduct(product)" [style.backgroundColor]="product.colour" style="padding-left: 15px; padding-right:15px;border: none;border-radius: 8px;width: 19%;color: white;float: left; margin-right: 10px; margin-bottom: 10px; text-overflow:ellipsis;height: 110px;">  
                            {{product.name}}
                        </button>
                    </div>
                </div>
                <div style="width: 22%; float:left;margin-right: -105px; margin-left: 0px; height:calc(100vh - 387px);" class="surface-card p-4 shadow-2 border-round">
                    <div style="height:calc(100vh - 455px); overflow-y: scroll;overflow-x: hidden;">
                        <div *ngFor="let tillprods of tillRole" style="width:100%;">
                            <div style="width:59%; float:left;margin-bottom:10px;">{{tillprods.product.name}}</div>
                            <div style="width:9%; float:left;">{{tillprods.qty}}</div>
                            <div style="width:14%; float:left;">{{tillprods.price | currency:CurrencySymbol}}</div>
                            <div style="width:9%; float:left;">
                                <button (click)="removeTillItem(tillprods)" style="width:29px; border: none; border-radius: 3px; background-color: #DC2626; color: white; margin-left:20px">
                                    <span class="pi pi-trash" style="margin-left:-1px;"></span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div style="font-size:20px;text-align: left; font-weight: bold;width:48%; float:left;margin-top:10px;">Total:</div>
                    <div style="font-size:20px;text-align: right; font-weight: bold;width:48%; float:right;margin-top:10px;">{{TillRoleTotal | currency:CurrencySymbol}}</div>
                </div>
                <div style="width: 22%;float:left;">
                    <button pButton pRipple type="button" class="p-button p-element p-button-success w-auto mt-3" style="width:100% !important" (click)="pay();">
                        <span class="pi pi-pound" style="margin-left: 38%; margin-right: 8px;"></span>
                        PAY</button>
                </div>
            </div>
        </p-tabPanel>
    </p-tabView>
</div>

<p-dialog header="Make Payment" [(visible)]="displayModal" [modal]="true" [style]="{width: '50vw'}"
    [draggable]="true" [resizable]="false">
    
        <div>
            <span style="font-size: 20px; font-weight: bold;">Total Outstanding: {{TotalCost | currency:CurrencySymbol}}</span>
        </div>
        <div style="float: left;width: 430px;margin-top: 7px;">
            <div class="field mb-4 col-12">
                <label for="amountToPay" class="font-medium" style="margin-right:10px;">Amount to pay</label>
                <p-inputNumber [(ngModel)]="TotalCost"  mode="currency" currency="{{CurrencySymbol}}"></p-inputNumber>
            </div>
        </div>
        <div style="float:left;width:400px">
            <button (click)="payOff(5, 'Cash')" pButton pRipple type="button" class="p-button p-element p-button-success w-auto mt-3">
            £5</button>
            &nbsp;
            <button (click)="payOff(10, 'Cash')" pButton pRipple type="button" class="p-button p-element p-button-success w-auto mt-3">
            £10</button>
            &nbsp;
            <button (click)="payOff(20, 'Cash')" pButton pRipple type="button" class="p-button p-element p-button-success w-auto mt-3">
            £20</button>
            &nbsp;
            <button (click)="payOff(50, 'Cash')" pButton pRipple type="button" class="p-button p-element p-button-success w-auto mt-3">
            £50</button>
        </div>

        <div style="float:left; width:70%">
            <button (click)="payOff(-1, 'Cash')" pButton pRipple type="button" style="width:130px !important; height:55px" class="p-button p-element p-button-success w-auto mt-3">
            <span style="margin-left: 19px;">&nbsp;</span>
            Cash</button>
            &nbsp;
            <button (click)="payOff(-1, 'Card')" pButton pRipple type="button" style="width:130px !important; height:55px" class="p-button p-element p-button-success w-auto mt-3">
            <span style="margin-left: 19px;">&nbsp;</span>
             Card</button>
        </div>
        <div style="float:left; width:30%; text-align:right;">
            <button pButton pRipple type="button" style="width:130px !important; height:55px" class="p-button p-element p-button-danger w-auto mt-3">
            <span style="margin-left: 19px;">&nbsp;</span>
            Void</button>
        </div>
</p-dialog>

<div>

</div>


