<div *ngIf="!AccessAllowed && CompletedLoading">
    <h3 style="border: 2px solid;border-radius: 6px;position: absolute;top: 50%;left: 50%;transform: translate(-50%, -50%);padding: 10px;">You don't have access to this function</h3>
</div>

<div *ngIf="AccessAllowed && pageReady" class="p-3 flex flex-column flex-auto">
    <div>
        <div class="text-900 font-medium text-xl mb-3"><h3>Warning
        </h3></div>
        <div class="surface-card p-4 shadow-2 border-round">
            <div class="grid formgrid p-fluid">
                <div class="field mb-4 col-12">
                    <label for="warningname" class="font-medium">Warning Name</label>
                    <input [(ngModel)]="Warning.name" id="classname" type="text" [ngStyle]="{'border-color': (warningNameValid==false) ? '#FF0000':''}" pInputText>
                </div>
                <div *ngIf="!warningNameValid" class="text-danger" Style="margin-left:10px; margin-top: -23px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>You must enter a Warning name.</div>

                <div class="field mb-4 col-12">
                    <label for="classname" class="font-medium">Add Public Message</label>
                    <p-selectButton [(ngModel)]="WantPublicMessage" id="selectionmode" [style]="{'width': '400px'}" [options]="stateOptions" (onChange)="setPublicMessage()"></p-selectButton>
                </div>

                <div *ngIf="WantPublicMessage" style="width:100%">
                    <div class="field mb-4 col-12">
                        <label for="warningpublicmessage" class="font-medium">Public Message</label>
                        <textarea [(ngModel)]="Warning.publicmessage" id="classname" type="text" [ngStyle]="{'border-color': (warningPublicValid==false) ? '#FF0000':''}" pInputText></textarea>
                    </div>
                    <div *ngIf="!warningPublicValid && WantPublicMessage" class="text-danger" Style="margin-left:10px; margin-top: -23px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>You must enter a Warning Publiic Message.</div>
                </div>

                <div class="field mb-4 col-12">
                    <label for="classname" class="font-medium">Add Venue Message</label>
                    <p-selectButton [(ngModel)]="WantVenueMessage" id="selectionmode" [style]="{'width': '400px'}" [options]="stateOptions" (onChange)="setVenueMessage()"></p-selectButton>
                </div>

                <div *ngIf="WantVenueMessage" style="width:100%">
                    <div class="field mb-4 col-12">
                        <label for="warningvenuemessage" class="font-medium">Venue Message</label>
                        <textarea [(ngModel)]="Warning.venuemessage" id="classname" type="text" [ngStyle]="{'border-color': (warningVenueValid==false) ? '#FF0000':''}" pInputText></textarea>
                    </div>
                </div>
                <div *ngIf="!warningVenueValid && WantVenueMessage" class="text-danger" Style="margin-left:10px; margin-top: -23px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>You must enter a Warning Venue Message.</div>


                <div *ngIf="!atLeastOneWarning" class="text-danger" Style="margin-left:10px; margin-top: -23px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>You must have at least one warning.</div>

                <div class="col-12">
                    <button pButton pRipple type="button" class="p-button p-element p-button-success w-auto mt-3" (click)="updateData();">
                    <span class="pi pi-pencil" style="margin-right:8px;"></span>
                    Save Changes</button>
                    &nbsp;
                    <button pButton pRipple type="button" class="p-button p-element p-button-danger w-auto mt-3" (click)="returnToList()">
                    <span class="pi pi-times" style="margin-right:8px;"></span>
                    Cancel</button>
                </div>
            </div>
        </div>
    </div>
</div>


