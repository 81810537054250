<div  class="p-3 flex flex-column flex-auto">
    <div>
      <div class="text-900 font-medium text-xl mb-3">
        <h3>Business Home Page</h3>
      </div>
      <p class="m-0 mb-4 p-0 text-600 line-height-3 mr-3">
        <span class="pi pi-info-circle" style="margin-right:2px;"></span> The Business Home Page allows you to view your business, make changes to you venues and start sessions on venues.
      </p>

      <div style="float:right">
        <span style="font-size: 19px; margin-right: 7px;">
          Search Businesses: 
        </span>
        <span class="p-input-icon-left ml-auto" style="margin-right:20px; ">
          <i class="pi pi-search"></i>
          <input pInputText type="text" (input)="businessVanueTable.filterGlobal($event.target.value, 'contains')" style="height: 50px;padding-left:33px;" />
        </span>
      </div>

      <div style="clear:both">&nbsp;</div>

      <div class="surface-card p-4 shadow-2 border-round">
        <div class="grid formgrid p-fluid">
          <p-tabView style="width:100%">
            <p-tabPanel header="Venues" leftIcon="pi pi-building">
              <p-table  #businessVanueTable 
              [value]="BusinessVenues" sortField="name" [paginator]="true" [rows]="10" 
              [showCurrentPageReport]="true" responsiveLayout="scroll"
              currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" 
              [rowsPerPageOptions]="[10,25,50]" 
              [globalFilterFields]="['name']"
              responsiveLayout="scroll"
              datakey="venueid"
              [selectionPageOnly]="true">
                  <ng-template pTemplate="header">
                      <tr>
                          <th pSortableColumn="name" width="95%">Venue Name
                              <p-sortIcon [field]="name"></p-sortIcon>
                          </th>
                          <!--<th pSortableColumn="name" width="5%">Bookings
                            <p-sortIcon [field]="noOfBookings"></p-sortIcon>
                        </th>
                        <th pSortableColumn="name" width="5%">Value
                          <p-sortIcon [field]="bookingValue"></p-sortIcon>
                      </th>-->
                          <th width="5%"></th>
                      </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-data>
                      <tr>
                          <td>{{data.name}}</td>
                          <!--<td>124</td>
                          <td>£120.92</td>-->
                          <td><button pButton type="button" class="p-button-sm p-element p-ripple p-button-success p-button p-component" style="width: 154px;" (click)="enterVenue(data);" i18>
                              <span class="pi pi-sign-in" style="margin-right:8px;"></span>
                              <span i18n>Enter Venue</span>
                          </button></td>
                      </tr>
                  </ng-template>
              </p-table>
            </p-tabPanel>
            <!--<p-tabPanel header="Business Details" leftIcon="pi pi-globe">
                <div class="surface-card p-4 shadow-2 border-round">
                    <div class="grid formgrid p-fluid">
                    <div class="field mb-4 col-12">
                    <label for="packageobjname" class="font-medium">Name</label>
                    <input id="VenueName" [(ngModel)]="Business.name" [ngStyle]="{'border-color': (businessNameValid==false) ? '#FF0000':''}" (keyup)="DoValidCheck()" type="text" pInputText>
                    </div>
                  <div *ngIf="!businessNameValid" class="text-danger" Style="margin-left:10px; margin-top: -23px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>You must enter a Business name.</div>
                  <div class="field mb-4 col-12">
                    <label for="packageobjname" class="font-medium">Address 1</label>
                    <input  id="Address1" [(ngModel)]="Business.address1" [ngStyle]="{'border-color': (addressValid==false) ? '#FF0000':''}" (keyup)="DoValidCheck()" type="text" pInputText>
                  </div>
                  <div *ngIf="!addressValid" class="text-danger" Style="margin-left:10px; margin-top: -23px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>You must enter an Address.</div>
                  <div class="field mb-4 col-12">
                    <label for="packageobjname" class="font-medium">Address 2</label>
                    <input id="Address2" [(ngModel)]="Business.address2"  type="text" pInputText>
                  </div>
                  <div class="field mb-4 col-12">
                    <label for="packageobjname" class="font-medium">Town/City</label>
                    <input  id="County" [(ngModel)]="Business.town" [ngStyle]="{'border-color': (townValid==false) ? '#FF0000':''}" (keyup)="DoValidCheck()" type="text" pInputText>
                  </div>
                  <div *ngIf="!townValid" class="text-danger" Style="margin-left:10px; margin-top: -23px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>You must enter a Town/City.</div>
                  <div class="field mb-4 col-12">
                    <label for="packageobjname" class="font-medium">County</label>
                    <input  id="County" [(ngModel)]="Business.county" [ngStyle]="{'border-color': (countyValid==false) ? '#FF0000':''}" (keyup)="DoValidCheck()" type="text" pInputText>
                  </div>
                  <div *ngIf="!countyValid" class="text-danger" Style="margin-left:10px; margin-top: -23px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>You must enter a County.</div>
                  <div class="field mb-4 col-12">
                    <label for="packageobjname" class="font-medium">PostCode</label>
                    <input id="PostCode"  [(ngModel)]="Business.postcode" [ngStyle]="{'border-color': (postcodeValid==false) ? '#FF0000':''}" (keyup)="DoValidCheck()" type="text" (change)="getLatLong(Business.postcode)" pInputText>
                  </div>
                  <div *ngIf="!postcodeValid" class="text-danger" Style="margin-left:10px; margin-top: -23px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>You must enter a Postcode.</div>
                  <div class="field mb-4 col-12">
                    <label for="packageobjname" class="font-medium">Telephone</label>
                    <input id="Telephone" [(ngModel)]="Business.telephone" [ngStyle]="{'border-color': (phoneValid==false) ? '#FF0000':''}" (keyup)="DoValidCheck()" type="text" pInputText>
                  </div>
                  <div *ngIf="!phoneValid" class="text-danger" Style="margin-left:10px; margin-top: -23px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>You must enter a Telephone number.</div>
                  <div class="field mb-4 col-12">
                    <label for="packageobjname" class="font-medium">Email</label>
                    <input id="Email" [(ngModel)]="Business.email" [ngStyle]="{'border-color': (emailValid==false) ? '#FF0000':''}" (keyup)="DoValidCheck()" type="text" pInputText>
                  </div>
                  <div *ngIf="!emailValid" class="text-danger" Style="margin-left:10px; margin-top: -23px;"><span class="pi pi-exclamation-triangle" style="margin-right:5px;"></span>You must enter an Email.</div>
                  <div style="display:none;">
                    <div class="field mb-4 col-12">
                      <label for="packageobjname" class="font-medium">Latitude</label>
                      <input id="Latitude"  type="text"  [(ngModel)]="Business.lat" readonly="true" pInputText>
                    </div>
                    <div class="field mb-4 col-12">
                      <label for="packageobjname" class="font-medium">Longitude</label>
                      <input id="Longitude"  [(ngModel)]="Business.lon" type="text" readonly="true" pInputText>
                    </div>
                </div>
            
                  <div class="col-12"> 
                    <button pButton pRipple type="button" class="p-button p-element p-button-success w-auto mt-3" (click)="updateData();">
                    <span class="pi pi-pencil" style="margin-right:8px;"></span>
                    Save Changes</button>
                </div>
            </div>
                </div>
            </p-tabPanel>-->
        </p-tabView>
        </div>
    </div>
</div>
<style>

:host ::ng-deep .p-chip.custom-chip3 {
    background: #EE0000;
    color: var(--primary-color-text);
    width: 90px;
  }

:host ::ng-deep .p-chip.custom-chip {
  background: var(--primary-color);
  color: var(--primary-color-text);
  width: 90px;
}

.p-chip-text{
  width: 90px;
}
</style>