<div *ngIf="!AccessAllowed && CompletedLoading">
    <h3 style="border: 2px solid;border-radius: 6px;position: absolute;top: 50%;left: 50%;transform: translate(-50%, -50%);padding: 10px;">You don't have access to this function</h3>
</div>

<div *ngIf="AccessAllowed && ListDiscounts" class="p-3 flex flex-column flex-auto">
    <div class="text-900 font-medium text-xl mb-3"><h3>Discounts
    </h3></div>
    <p class="m-0 mb-4 p-0 text-600 line-height-3 mr-3">
        <span class="pi pi-info-circle" style="margin-right:2px;"></span>
        Allow your customers to enter a discount code to discount the total cost of their booking. If you use a booking fee, this is not affected by the discount. <br><br>You can have as many discount codes as you need.
    </p>
    <div style="text-align: right; margin-bottom: 20px;">

        <p-toggleButton class="p-button-sm p-button-danger p-component" [(ngModel)]="isDisabled" onLabel="Show Disabled" offLabel="Hide Disabled" offIcon="pi pi-times" onIcon="pi pi-check" [style]="{'margin-right': '10px', 'color' : 'white', 'background-color': '#EF4444', 'height': '50px'}" (click)="toggleDisabled()">
        </p-toggleButton>

        <span class="p-input-icon-left ml-auto" style="margin-right:20px; "> 
            <i class="pi pi-search"></i>
            <input pInputText type="text" (input)="discountsTable.filterGlobal($event.target.value, 'contains')" style="height: 50px;padding-left:33px;" />
        </span>
        
        <button pButton type="button" style="height: 50px;" class="p-button-sm p-element p-ripple p-button-success p-button p-component" [routerLink]="['/discountedit/0']" i18>
        <span class="pi pi-plus" style="margin-right:8px;"></span>
        <span i18>Add</span>
        </button>
        &nbsp;
        <button pButton type="button" style="height: 50px;" class="p-button-sm p-element p-ripple p-button-danger p-button p-component" (click)="disableMulti()">
        <span class="pi pi-trash" style="margin-right:8px;"></span>
        <span i18>Disable</span>
        </button>
    </div>
    <div class="surface-card p-4 shadow-2 border-round">
    <p-table  #discountsTable 
    [value]="ListDiscounts.data" sortField="name" [paginator]="true" [rows]="10" 
    [showCurrentPageReport]="true" responsiveLayout="scroll"
    currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" 
    [rowsPerPageOptions]="[10,25,50]" 
    [globalFilterFields]="['code']"
    [(selection)]="selectedItems"
    responsiveLayout="scroll"
    datakey="discountId"
    [selectionPageOnly]="true">
        <ng-template pTemplate="header">
            <tr>
                <th>
                    <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                </th>
                <th pSortableColumn="name" width="40%">Name
                    <p-sortIcon [field]="name"></p-sortIcon>
                </th>
                <th pSortableColumn="code" width="20%">Code
                    <p-sortIcon [field]="code"></p-sortIcon>
                </th>
                <th pSortableColumn="name" width="10%">Start Date
                    <p-sortIcon [field]="startDate"></p-sortIcon>
                </th>
                <th pSortableColumn="name" width="10%">Expiry Date
                    <p-sortIcon [field]="endDate"></p-sortIcon>
                </th>

                <!--<th width="3%"></th>-->
                <th width="3%"></th>
                <th width="3%"></th>
                <th width="3%"></th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-data>
            <tr [ngStyle]="{'background-color': (data.disabled==true) ? '#fee' : '#ffffff'}">
                <td>
                    <p-tableCheckbox [value]="data"></p-tableCheckbox>
                </td>
                <td>{{data.name}}</td>
                <td>{{data.code}}</td>
                <td>{{data.startdate | date:'dd/MM/yyyy'}}</td>
                <td>{{data.enddate | date:'dd/MM/yyyy'}}</td>
            
                <!--<td><button pButton type="button" class="p-button-sm p-element p-ripple p-button-info p-button p-component" (click)="listHistoryData(data.discountid);">
                    <span class="pi pi-list" style="margin-right:8px;"></span>
                    <span i18n>History</span>
                </button></td>-->

                <td *ngIf="isgenerated"><button pButton type="button" class="p-button-sm p-element p-ripple p-button-warning p-button p-component" [disabled]="true" style="margin-left:-20px">
                    <span class="pi pi-copy" stylme="margin-right:8px;"></span>
                    <span i18n>Copy</span>
                </button></td>

                <td *ngIf="!isgenerated"><button pButton type="button" class="p-button-sm p-element p-ripple p-button-warning p-button p-component" style="margin-left:-20px" (click)="copyData(data.discountid);">
                    <span class="pi pi-copy" stylme="margin-right:8px;"></span>
                    <span i18n>Copy</span>
                </button></td>


                <td><button pButton type="button" class="p-button-sm p-element p-ripple p-button-success p-button p-component" style="margin-left:-20px" (click)="editData(data.discountid);">
                    <span class="pi pi-pencil" style="margin-right:8px;"></span>
                    <span i18n>Edit</span>
                </button></td>
                <td><button pButton type="button" class="p-button-sm p-element p-ripple p-button-danger p-button p-component" style="margin-left:-20px"  (click)="disableData(data.discountid)">
                    <span class="pi pi-trash" style="margin-right:8px;"></span>
                    <span i18n>Disable</span>
                </button></td>
            </tr>
        </ng-template>
    </p-table>
    </div>
</div>
